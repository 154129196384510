import React from "react";
import { LocaleBL } from "../../bl/locale/LocaleBL";
import { LocationNoteBL } from "../../bl/location/LocationNoteBL";
import ComponentCommon from "../../core/ComponentCommon";
import LocationNoteFormProp from "../../entity/location/props/LocationNoteFormProp";
import LocationNoteFormState from "../../entity/location/states/LocationNoteFormState";
import { Button, Card, CardBody, CardHeader, IconFa } from '../../../cuba/components/utils/reactstrap'
import LoaderMini from "../utils/LoaderMini";
import Alert from "../utils/Alert";
import { LOCATION_NOTE_TYPES } from "../../constants/data/location";
import { FormInputTranslate } from "../../core/Translate";
import { _ } from "../../bl/admin/AdminLocaleBL";
import TextBox from "../control/TextBox";

/**
 * Performs updated and saved locationNote data operations
 * @class LocationNoteElement
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class LocationNoteForm extends ComponentCommon<LocationNoteFormProp, LocationNoteFormState> {

    /**
     * Constructor
     * @param props Property 
     */
    public constructor(props){
        super(props);
        this.state = new LocationNoteFormState();
    }

    /**
     * Component did mount
     */
    componentDidMount(){
        let me = this;
        me.prepare();
    }

    /**
     * Initialize component
     */
    private async prepare() {
        let me = this;
        var languages = await LocaleBL.getLanguages();
        
        // Generate form inputs
        var forms = FormInputTranslate.prepare(
            e => LocationNoteBL.updateLocationNote(e),
            e => LocationNoteBL.updateLocationNoteTranslate(e)
        ).generate(languages, me.props.locationNote);

        me.setState({
            languages: languages,
            locationNote: me.props.locationNote,
            forms: forms
        });
    }

    /**
     * Select tab language
     * @param language 
     */
    private selectLanguage(language: string){
        let me = this;
        me.setState({
            selectedLanguage: language
        });
    }

    /**
     * Close LocationNote editor
     */
    private close(){
        let me = this;
        me.setState({
            visible: false
        });
        me.props.onClose && me.props.onClose();
    }

    /**
     * Handle onSave button click
     */
    private async handleSave(){
        let me = this;
        var result = await Alert.confirm(_("key_locationnote_save"), _("key_locationnote_save_message"));
        if(result.isConfirmed) {
            var forms = me.state.forms;
            forms = await FormInputTranslate.save(forms);

            me.setState({
                forms: forms
            });

            me.props.onChange && me.props.onChange(me.state.locationNote);
            Alert.success();
        }
    }

    render() {
        let me = this;
        var forms = me.state.forms;

        return me.state.visible && me.state.locationNote && me.state.languages.length > 0 ?
            <Card>
                <CardHeader className="p-3">
                    <b>{LOCATION_NOTE_TYPES[me.state.locationNote.typeNote]}: </b>
                    {me.state.locationNote.NoteText}
                    
                    <li className="list-inline-item float-right">
                        <a onClick={ () => me.close() }><i className="icon-close"></i></a>
                    </li>
                </CardHeader>
                <CardBody className="p-3">
                    <ul className="nav nav-tabs">
                        {forms.map( (l, i) => 
                            <li key={i} onClick={ e => me.selectLanguage(l.code) } className={`nav-item`}>
                                <a className={`nav-link ${l.code == me.state.selectedLanguage ? "active" : ""}`}>{l.label}</a>
                            </li>
                        )}
                    </ul>
                    {forms.map( (l, i) => 
                        <div key={i} className={ me.state.selectedLanguage == l.code ? "" : "d-none" }>
                            <TextBox ref={ l.createRef("note") } value={l.value.note} />
                        </div>
                    )}
                    <br/>
                    <Button color="primary" type="button" onClick={ e => me.handleSave() }>
                        <IconFa icon="save"/> {_("key_save")}
                    </Button>
                </CardBody>
            </Card>
        : 
            <LoaderMini visible={true} message={_("key_locationnote_loading")}/>
        ;
    }
}

export default LocationNoteForm;