import React from 'react';
import ComponentCommon from "../../core/ComponentCommon";
import { IconFa, Button, Card, CardHeader, CardBody, Row, Col } from '../../../cuba/components/utils/reactstrap';
import { LocaleBL } from '../../bl/locale/LocaleBL';
import { FormInputTranslate } from '../../core/Translate';
import TextBox from '../control/TextBox';
import Alert from '../utils/Alert';
import { _ } from '../../bl/admin/AdminLocaleBL';
import { LANDING_SCHEMA_EDITOR_CONFIG } from '../../constants/data/landing';
import InputSearch, { InputSearchConfig } from '../control/InputSearch';
import System from '../../helper/System';
import FaqsFormProp from '../../entity/faqs/props/FaqsFormProp';
import FaqsFormState from '../../entity/faqs/states/FaqsFormState';
import { FaqsBL } from '../../bl/faqs/FaqsBL';
import Switch from '../control/Switch';
import { AuditorConfig } from '../../entity/auditor/AuditorEntity';
import AuditorLogList from '../auditor/AuditorLogList';
import { ISPlace } from '../landing/ISPlace';

/**
 * Manage an element of static content and its translations
 * @class SchemaElement
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class FaqsForm extends ComponentCommon<FaqsFormProp, FaqsFormState>{
    private _auditorLog = null;
    private _auditorLogConfig: AuditorConfig = null;
    private _searchPlaceConfig: InputSearchConfig = null;
    
    /**
     * Initialize component
     * @param props Property
     */
    constructor(props: FaqsFormProp){
        super(props);
        this.state = new FaqsFormState();
        this._auditorLog = React.createRef();
        this._auditorLogConfig = new AuditorConfig();
        this._searchPlaceConfig = new InputSearchConfig("/Home/PlaceAPI/GetPlaces");
    }

    /**
     * Component did mount
     */
    componentDidMount(){
        let me = this;
        me.prepare();
    }

    /**
     * Initialize component
     */
    private async prepare() {
        let me = this;
        var languages = await LocaleBL.getLanguages();
        
        // Generate form inputs
        var forms = FormInputTranslate.prepare(
            e => FaqsBL.updateFaq(e),
            e => FaqsBL.updateFaqGeolocation(e)
        ).generate(languages, me.props.faq);

        me.setState({
            languages: languages,
            faq: me.props.faq,
            forms: forms
        });
    }

    /**
     * Handle onSave button click
     */
    private async handleSave(){
        let me = this;
        var result = await Alert.confirm(_("key_save_changes"), _("key_save_changes_message"));
        if(result.isConfirmed) {
            var forms = me.state.forms;
            forms = await FormInputTranslate.save(forms);

            me.setState({
                forms: forms
            });

            me.props.onChange && me.props.onChange(me.state.faq);
            me.flushRedis();

            // Update auditor
            me._auditorLog.current?.update();
        }
    }

    /**
     * Flush redis caché
     */
    private async flushRedis(){
        let me = this;
        // Redis flush command
        try {
            await System.redisFlushById('faqs', me.state.faq.id);
            // Success message
            Alert.success();
        }catch {
            // Redis error
            Alert.error(_("key_redis_error"));
        }
    }

    /**
     * Update all places (scheme geolocation)
     * @param value place identifier
     */
    private updatePlaces(value: number){
        let me = this;
        var forms = me.state.forms;
        forms.map( f => {
            f.value.idPlace = value;
        });
        me.setState({
            forms: forms
        })
    }

    /**
     * On close editor event
     */
    private close(){
        let me = this;
        me.props.onClose && me.props.onClose();
    }

    render(){
        let me = this;
        var parent = FormInputTranslate.getParent(me.state.forms);

        if(!parent) {
            return null;
        }

        /* UPDATE AUDITOR */
        me._auditorLogConfig.clear();
        me._auditorLogConfig.addCriteria({module: "Faqs", identifier: me.state.faq?.id});

        return ( 
            <>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col md="6" className="p-0">
                                <h5 className="card-title mb-0">{_("key_edit")}</h5>
                            </Col>
                            <Col md="6" className="p-0">
                                {/* Actions */}
                                <button onClick={ () => me.handleSave() } className="btn btn-primary my-2 mx-md-2 float-right m-2">
                                    <IconFa icon="save"/> {_("key_save")}
                                </button>
                                <button onClick={ () => me.close() } className="btn btn-info my-2 mx-md-2 float-right m-2">
                                    <IconFa icon="close"/> {_("key_return")}
                                </button>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        {/* Parent element form inputs */}
                        <div className="m-2">
                            <label>{_("key_place")}: </label>
                        </div>
                        {me.state.faq &&
                            <InputSearch 
                                component={ISPlace}
                                onChange={ id => me.updatePlaces(id) } 
                                value={me.state.faq.idPlace}  
                                ref={ parent.createRef("idPlace") } 
                                config={ me._searchPlaceConfig } />
                        }
                        <div className="m-2">
                            <label>{_("key_icon")}: </label>
                        </div>
                        <input className="form-control" ref={ parent.createRef("icon") } defaultValue={me.state.faq.icon } />
                        <Row>
                            <Col sm="12" md="6">
                                <div className="m-2">
                                    <label>{_("key_iso")}: </label>
                                </div>
                                <input className="form-control" ref={ parent.createRef("iso") } defaultValue={me.state.faq.iso } />
                            </Col>
                            <Col sm="12" md="6">
                                <div className="m-2">
                                    <label>{_("key_position")}: </label>
                                </div>
                                <input className="form-control" type="number" ref={ parent.createRef("position") } defaultValue={me.state.faq.position } />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Switch size="sm" title={_("key_frequent")} ref={ parent.createRef("isFrequent") } checked={me.state.faq.isFrequent}/>
                            </Col>
                            <Col>
                                <Switch size="sm" title={_("key_default")} ref={ parent.createRef("isDefault") } checked={me.state.faq.isDefault}/>
                            </Col>
                            <Col>
                                <Switch size="sm" title={_("key_enabled")} ref={ parent.createRef("enabled") } checked={me.state.faq.enabled}/>
                            </Col>
                        </Row>

                        {/* Language tabs */}
                        <ul className="nav nav-tabs mt-3">
                            {me.state.forms.map( (l, i) => 
                                <li key={i} onClick={ e => me.setState({selectedLanguage: l.code}) } className={`nav-item`}>
                                    <a className={`nav-link ${l.code == me.state.selectedLanguage ? "active" : ""}`}>{l.label}</a>
                                </li>
                            )}
                        </ul>      

                        {/* Dynamic values of translations */}
                        {me.state.forms.map( (l, i) => 
                            <div key={i} className={ me.state.selectedLanguage == l.code ? "" : "d-none" }>
                                {/* SCHEMA EDITOR */}
                                <div className="m-2">
                                    <label>{_("key_question")}: </label>
                                </div>
                                <input className="form-control" ref={ l.createRef("question") } defaultValue={l.value.question} />
                                <div className="m-2">
                                    <label>{_("key_answer")}: </label>
                                </div>
                                <TextBox config={LANDING_SCHEMA_EDITOR_CONFIG} ref={ l.createRef("answer") } value={l.value.answer} />
                            </div>
                        )}
                    </CardBody>
                </Card>
                { me.state.faq.id > 0 && <AuditorLogList ref={ me._auditorLog } config={ me._auditorLogConfig }/> }
            </>
        );
    }
}
export default FaqsForm;
