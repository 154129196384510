import React from 'react';
import { Row, Col, Card, CardBody, Button, Form, CardHeader, IconFa } from '../../cuba/components/utils/reactstrap';
import RequestPageState from '../entity/request/States/RequestPageState';
import RequestPageProp from '../entity/request/Props/RequestPageProp';
import { UserInfo } from '../entity/chat/ChatEntity';
import Config from '../core/component/chat/config/Config';
import Paginator from '../component/control/Paginator';
import { PaginatedRequest, Pagination } from '../entity/control/ControlEntity';
import LoaderMini from '../component/utils/LoaderMini';
import PageCommon from '../core/PageCommon';
import { RequestForm } from '../component/request/RequestForm';
import ChatUserInfoBL from '../bl/chat/ChatUserInfoBL';
import { REQUEST_PLATFORM } from '../constants/data/request';
import AdminLocaleBL, { _ } from '../bl/admin/AdminLocaleBL';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import UrlHash from '../helper/UrlHash';
import { ADMIN_SITES } from '../constants/data/sites';

/**
 * Manage refurbished messages when agents are not available.
 * @class RequestPage
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class RequestPage extends PageCommon<RequestPageProp, RequestPageState> {
    private configuration: Config = null;
    private _paginatorRef = null;

    constructor(props: RequestPageProp) {
        super(props);
        let me = this;
        me.state = new RequestPageState();
    }

    componentDidMount() {
        if(super.componentDidMount){
            super.componentDidMount();
        }
        let me = this;
        me.prepare();
    }

    /**
     * Prepare component
     */
    private prepare() {
        let me = this;

        me.configuration = new Config();
        me._paginatorRef = React.createRef();
        me.checkHash();
    }

    /**
     * Open the request that agrees with the ID of the URL
     */
    public async checkHash(){
        let me = this;
        const id = UrlHash.get("id");
        if(id){
            const request = await ChatUserInfoBL.getRequest(id);
            me.selectRequest(request);
        }
    }

    /**
     * Handle search form
     * @param e Event
     */
    private handleFormSubmit(e) {
        let me = this;
        e.preventDefault();
        me.setState({
            pagination: me.state.pagination.setCurrentPage(1)
        }, () => {
            me.searchResult();
        });
    }

	/**
	 * Realiza la solicitud de búsqueda a la API
	 */
    public async searchResult(loader: boolean = true) {
		let me = this;
        me.setState({
            loadingRequestResults: loader
        })

		let data = {
			criteria: me.state.criteria,
            platform: me.state.platform,
			startDate: moment(me.state.dateStart).format("YYYY-MM-DD"),
			endDate: moment(me.state.dateEnd).format("YYYY-MM-DD"),
            siteCode: me.state.siteCode
		};

        // Prepare request!
        let request = new PaginatedRequest();
        request.data = data;
        request.pagination = me.state.pagination;
        let results = await ChatUserInfoBL.getUserInfo(request);
        /*let pairs = Pair.fromArray<UserInfo, RequestForm>(results.data, (pair: Pair<UserInfo>) => {
            return <RequestForm onChange={ () => me.searchResult(false) } request={pair.entity}/>
        });*/

        // Clear list
        // The comments are not updated, so this is put ¿?
        await new Promise( (resolve: any) =>{
            me.setState({
                requestList: []
            }, resolve)
        });

        me.setState({
            pagination: results.pagination,
            loadingRequestResults: false,
            requestList: results.data
        }, () => {
            // Update paginator
            me._paginatorRef.current?.update();
        });
	}
    
    /**
     * Update pagination state
     * @param pagination Pagination object
     */
    public passPage(pagination: Pagination){
        let me = this;
        me.setState({
            pagination: pagination
        }, () => {
            me.searchResult();
        });
    }

    /**
     * Select current request
     * @param request UserInfo element
     */
    private selectRequest(request: UserInfo){
        let me = this;
        // Delete current component
        me.setState({
            currentRequest: null
        }, () => {
            me.setState({
                currentRequest: request
            });
        });
    }

    /**
     * Handle on close form
     */
    private handleClose(){
        let me = this;
        me.setState({
            currentRequest: null
        });
    }

    render() {
        let me = this;
        return (
            <>
                { !me.state.currentRequest ? 
                    <Card>
                        <CardHeader>
                            <h5>{_("key_request_list")}</h5> 
                        </CardHeader>
                        <CardBody>
                            <Form onSubmit={ e => me.handleFormSubmit(e) } className="mb-3">
                                <Row>
                                    <Col sm="12" md="4">
                                        <label className="mt-3"
                                               htmlFor="search_criteria">{_("key_search_criteria")}: </label><br/>            
                                        <input className="form-control" 
                                            id="search_criteria"
                                            onChange={ e => me.setState({ criteria: e.target.value }) } 
                                            value={ me.state.criteria } 
                                            type="text" 
                                            placeholder={_("key_search_criteria")}
                                            />
                                    </Col>
                                    <Col sm="12" md="2">
                                        <div className="form-group mb-3">
                                            <label className="mt-3" htmlFor="search_date_start">{_("key_platform")}: </label><br/>
                                            <select onChange={ e => me.setState({platform: e.target.value }) } name="platform" value={ me.state.platform } className="form-control">
                                                <option value={""}>{_("key_platform_all")}</option>
                                                {REQUEST_PLATFORM.map( (rp) => {
                                                    return <option key={ rp.identifier } value={rp.identifier}>{rp.name}</option>
                                                })}
                                            </select>
                                        </div>
                                    </Col>
                                    <Col sm="12" md="2">
                                        <div className="form-group mb-3">
                                            <label className="mt-3">{_("key_sites")}: </label><br/>
                                            <select onChange={ e => me.setState({siteCode: e.target.value }) } name="siteCode" value={ me.state.siteCode } className="form-control">
                                                <option value={""}>{_("key_sites_all")}</option>
                                                {ADMIN_SITES.map( (rp) => {
                                                    return <option key={ rp.code } value={rp.code}>{rp.name}</option>
                                                })}
                                            </select>
                                        </div>
                                    </Col>
                                    <Col sm="12" md="2">               
                                        <label className="mt-3" htmlFor="search_date_start">{_("key_date_start")}: </label><br/>      
                                        <DatePicker 
                                            className="form-control" 
                                            locale={AdminLocaleBL.Language.toLowerCase()} 
                                            maxDate={ me.state.dateStart } 
                                            onChange={ e => me.setState({dateStart: e}) } 
                                            value={me.state.dateStart}
                                            selected={me.state.dateStart}  
                                            required={true}/>
                                    </Col>
                                    <Col sm="12" md="2">
                                        <label className="mt-3" htmlFor="search_date_end">{_("key_date_end")}: </label><br/>
                                        <DatePicker 
                                            className="form-control" 
                                            locale={AdminLocaleBL.Language.toLowerCase()} 
                                            minDate={ me.state.dateStart } 
                                            maxDate={ new Date() } 
                                            onChange={ e => me.setState({dateEnd: e}) } 
                                            value={me.state.dateEnd}
                                            selected={me.state.dateEnd} 
                                            required={true} />
                                    </Col>
                                </Row>
                                <div className="text-center p-2">
                                    <Button type="submit" color="primary">
                                        <IconFa icon="search"/> {_("key_filter_results")}
                                    </Button>
                                </div>
                            </Form>
                            <table className="table table-m-2 tableDsn">
                                <thead>
                                    <tr>
                                        <th scope="col">{_("key_id")}</th>
                                        <th scope="col">{_("key_date")}</th>
                                        <th scope="col">{_("key_state")}</th>
                                        <th scope="col">{_("key_chat_topic")}</th>
                                        <th scope="col">{_("key_platform")}</th>
                                        <th scope="col">{_("key_site")}</th>
                                        <th scope="col">{_("key_profile")}</th>
                                        <th scope="col">{_("key_request")}</th>
                                        <th scope="col">{_("key_user")}</th>
                                        <th scope="col">{_("key_phone")}</th>
                                        <th scope="col">{_("key_email")}</th>
                                        <th scope="col">{_("key_actions")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {me.state.loadingRequestResults &&
                                    <tr>
                                        <td colSpan={10}>
                                            <LoaderMini message={_("key_search_loading_results")}/>     
                                        </td>
                                    </tr>
                                }
                                {me.state.requestList.map( (request: UserInfo) => 
                                    <tr key={request.id}>
                                        <td data-label={_("key_id")}>{request.id}</td>
                                        <td data-label={_("key_date")}>{request.CreatedDateText}</td>
                                        <td data-label={_("key_state")}>{request.state.name}</td>
                                        <td data-label={_("key_chat_topic")}>{request.ChatTopic}</td>
                                        <td data-label={_("key_platform")}><IconFa icon={request.platform}/> {request.PlatformText}</td>
                                        <td data-label={_("key_site")}>{request.siteCode}</td>
                                        <td data-label={_("key_profile")}>{request.profile}</td>
                                        <td data-label={_("key_request")}>{request.RequestText}</td>
                                        <td data-label={_("key_user")}>{request.userName}</td>
                                        <td data-label={_("key_phone")}>{request.userPhone}</td>
                                        <td data-label={_("key_email")}>{request.userEmail}</td>
                                        <td data-label={_("key_actions")} className="p-2">
                                            <Button onClick={ e =>  me.selectRequest(request)} size="sm" className="p-2">
                                                <IconFa icon="pencil"/> {_("key_edit")}
                                            </Button>
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>                    
                            <Paginator visible={me.state.requestList.length > 0} ref={ me._paginatorRef } pagination={me.state.pagination} onChange={ pagination => me.passPage(pagination) }/>
                        </CardBody>
                    </Card>
                :
                    <RequestForm onClose={ e => me.handleClose() } onChange={ () => me.searchResult(false) } request={me.state.currentRequest}/>
                }
            </>
        );
    }
}

export default RequestPage;
