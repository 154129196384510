import axios from "axios";
import BaseBL from "../../core/BaseBL";
import { AuditColumn, AuditDevice, AuditorLog } from "../../entity/auditor/AuditorEntity";
import { PaginatedReponse, PaginatedRequest, Pagination } from "../../entity/control/ControlEntity";
import { User } from "../../entity/login/LoginEntity";
/**
 * Performs Chat Operations
 * @Class AuditorLogBL 
 * @author Samael Fierro <sfierro@viajemos.com>
*/
export class AuditorLogBL extends BaseBL {
	/**
	 * Get chats dispositions (API)
	 */
	public static async getAuditorLogs(paramsData: PaginatedRequest) {
        let me = this;
        let data = {
            params: paramsData
        };
        let response = await axios.get('/Home/MongoAPI/ListAuditorLog', data);
        let responseData = me.getResponseData(response);
        let result = me.parseEntity(responseData, PaginatedReponse);

        result.data = me.parseEntityArray(result.data, AuditorLog, (log: AuditorLog) => {
            log.device = me.parseEntity(log.device, AuditDevice);
            log.user = me.parseEntity(log.user, User);
            return log;
        });
        result.pagination = me.parseEntity(result.pagination, Pagination);
        return result;
	}
}

export default AuditorLogBL;