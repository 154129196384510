import ChatBL from "../../../bl/chat/ChatBL";

/**
 * Esta clase controla la manera en que se guardan los mensajes en el servidor, permitiendo
 * que se envien en orden o en paquetes si se escriben diferentes palabras muy rápido.
 * Igualmente establece que se envien los mensajes de manera única y no todo el historial.
 * @class ChatStack
 */
export class ChatStack {

    lastTimestamp:number = 0;
    lastMessage = { datMessage: "", usrMessage: "", strMsgType: "", strMessage: "" };
    chatTopic:string = "";
    baseUrl:string = "";
    chatStack:Array<any> = [];
    lastTimeout:any = null;
    stampDiff:number = 1500;

    constructor(chatTopic: string, baseUrl: string){
        this.chatTopic = chatTopic;
        this.baseUrl = baseUrl;
    }

    /**
     * Set current topic
     * @param topic Topic
     */
    setTopic(topic:string){
        this.chatTopic = topic;
    }

    /**
     * Topic validation
     * @returns 
     */
    isValidTopic(){
        return this.chatTopic !== undefined && this.chatTopic.length > 0;
    }

    /**
     * Mercure: Send chat message
     * @param nowDate Current time
     * @param userType User type
     * @param strMsgType Message type
     * @param message Message
     * @returns lastMessage
     */
    registerChat(nowDate:string, userType:string, strMsgType:string, message:string, agtName:string = ""){
        let me = this;
        me.lastMessage = { datMessage: nowDate, usrMessage: userType, strMsgType:strMsgType, strMessage: message };
        // Add agent name
        if(agtName){
            me.lastMessage['agtName'] = agtName;
        }
        let currentTimestamp = Date.now();
        let timestampDiff = currentTimestamp - this.lastTimestamp;
        me.chatStack.push(me.lastMessage);
        
        //console.log("timediff "  + timestampDiff);
        let validTopic = me.isValidTopic();
        if(timestampDiff > me.stampDiff && this.lastTimestamp > 0 && validTopic){
            me.emitData();
        } else {
            clearInterval(me.lastTimeout);
            //console.log("message in stack!");
            //console.log(me.chatStack);

            // Send stack
            if(validTopic) {
                me.lastTimeout = setTimeout(() => {
                    if(me.chatStack.length > 0){
                        me.emitData();
                    }
                }, me.stampDiff);
            }
        }
        me.lastTimestamp = currentTimestamp;
        return me.lastMessage;
    }

    /**
     * Registers the last stack of saved messages
     */
    emitData(){
        let me = this;
        ChatBL.updateChat(me.chatStack, this.chatTopic);
        //console.log("messages sent: " + me.chatStack.length)
        //console.log(me.chatStack);
        me.chatStack = [];
    }
}

export default ChatStack;