import React, { Fragment } from 'react';
import { LocaleBL } from '../../bl/locale/LocaleBL';
import ComponentCommon from "../../core/ComponentCommon";
import { Locale, LocaleTranslate } from '../../entity/locale/LocaleEntity';
import LocaleElementProp from '../../entity/locale/props/LocaleElementProp';
import LocaleElementState from '../../entity/locale/states/LocaleElementState';
import System from '../../helper/System';
import Alert from '../utils/Alert';
import LoaderBackdrop from '../utils/LoaderBackdrop';
import { _ } from '../../bl/admin/AdminLocaleBL';//../bl/admin/AdminLocaleBL


/**
 * Manage an element of static content and its translations
 * @class LocaleElement
 */
export class LocaleElement extends ComponentCommon<LocaleElementProp, LocaleElementState>{
    //Initial var to resize
    private _resizeWindow = null;
    
    /**
     * Initialize component
     * @param props Property
     */
    constructor(props: LocaleElementProp){
        super(props);
        this.state = new LocaleElementState();
    }

    /**
     * Component did mount
     */
    componentDidMount(){
        let me = this;
        me.setState({
            locale: me.props.locale
        });
        me.setState({
            width: window.innerWidth,
            height: window.innerHeight
        });
        this.resize();
    }

    /**
     * Component will unmount
     */
    componentWillUnmount(){
        window.removeEventListener("resize", this._resizeWindow);
    }

    /**
     * Add resize function to windows
     */
    private resize(){
        this._resizeWindow = (e) => {
            this.setState({
                width: window.innerWidth,
                height: window.innerHeight
            })
        }
        window.addEventListener("resize", this._resizeWindow);
    }

    /**
     * Expand the list of additional translations
     */
    private toggleExpand(){
        let me = this;
        me.setState({
            expand: !me.state.expand
        });
    }

    /**
     * Alternates the component to editable mode
     */
    private toggleEditable(){
        let me = this;
        me.setState({
            editable: !me.state.editable
        });
    }

    /**
     * Helper: Returns the value of the input according to your type
     * @param e event
     * @returns value
     */
    private getEventValue(e){
        if(e.target.type == "checkbox"){
            return e.target.checked ? 1 : 0;
        }
        return e.target.value;
    }

    /**
     * Change the status of the locale object
     * @param e Event
     */
    private setLocale(e) {
        let me = this;
        let locale = me.state.locale;

        let param = {};
        if(e) {
            param[e.target.name] = me.getEventValue(e);
            Object.assign(locale, param);
            locale.modified = true;
        }

        me.setState({
            locale: locale
        });
    }

    /**
     * Change the status of the locale translate object
     * @param e Event
     * @param localeTranslate Translate object
     */
    private setLocaleTranslate(e, localeTranslate: LocaleTranslate) {
        let me = this;
        let locale = me.state.locale;

        let have = false;
        let translates: Array<LocaleTranslate> = [];
        
        e && locale.translates.forEach( lt => {
            if(lt.language == localeTranslate.language){
                let param = {};
                param[e.target.name] = me.getEventValue(e);
                Object.assign(lt, param);
                lt.modified = true;
                translates.push(lt);
                have = true;
            }
        });

        if(!have){
            localeTranslate.modified = true;
        }

        me.setState({
            locale: locale,
        });
    }

    /**
     * Save the changes made in the database
     * @param locale 
     */
    private async saveLocale(locale: Locale){
        let me = this;
        let result = await Alert.confirm("Modificar contenido", `¿Confirmas aplicar los cambios realizados a '${locale.keyLocale}'?`)
        if(!result.isConfirmed){
            return;
        }

        me.setState({
            busy: true
        });
        await LocaleBL.updateLocale(locale);
        locale.modified = false;
        locale.translates.forEach(async (ut) => {
            if(ut.modified){
                await LocaleBL.updateLocaleTranslate(ut);
                ut.modified = false;
            }
        });

        // Redis flush command
        await System.redisFlushById('locale', locale.id);

        // Success message
        Alert.success();

        me.setState({
            locale: locale,
            editable: false,
            busy: false
        });
    }

    render(){
        let me = this;
        let locale = me.state.locale
        return ( 
            me.state.locale &&
            <>
            <tr className={ me.state.locale.modified ? "modified" : "" }>
                <td data-label="#">
                    {me.state.busy &&
                    <LoaderBackdrop visible={true}
                                    message={`Guardando cambios para '${locale.keyLocale.toUpperCase()}'`}/>}
                    {locale.id}
                </td>
                <td data-label="+">
                    {me.state.locale.translates.length > 0
                    && <i className={'mr-2 fa fa-' + (me.state.expand ? "minus" : "plus")}
                    onClick={ e => me.toggleExpand() }></i>}
                    {me.state.editable ? 
                        <i className={'fa fa-save'} onClick={ e => me.saveLocale(locale) }></i>
                    :
                        <i className={'fa fa-pencil'} onClick={ e => me.toggleEditable() }></i>
                    }
                </td>
                { me.state.width < 768 && me.state.expand && me.props.languages.map( langMain => {
                    if(langMain.code == "EN"){
                        return null;
                    }
                    let tra = new LocaleTranslate();
                    tra.language = langMain.code;
                    locale.translates.forEach(translate => {
                        if(translate.language == langMain.code){
                            tra = translate;
                        }
                    });
                    return(
                        <Fragment key={langMain.id}>
                            <td>{ tra.id }</td>
                            <td>{ langMain.label }</td>
                            <td>
                                {
                                    me.state.editable ? <input type="text"
                                                               className="form-control"
                                                               value={ tra.label }
                                                               name="label"
                                                               onChange={e => me.setLocaleTranslate(e, tra)}
                                                               id={`label_enabled_${me.props.locale.id}_${tra.id}`} />
                                                      : tra.label
                                }
                            </td>
                            <td>
                                {
                                    me.state.editable ? <input type="checkbox"
                                                               className="form-control"
                                                               checked={ tra.enabled > 0}
                                                               name="enabled"
                                                               onChange={ e => me.setLocaleTranslate(e, tra)}
                                                               id={`label_translate_enabled_${tra.id}`} />
                                                      : (tra.enabled ? "Habilitado" : "Deshabilitado")
                                }
                            </td>
                        </Fragment>
                    )
                })}
                <td data-label={_("key_locale_key")} className="key">   
                    { me.state.locale.keyLocale.toUpperCase() }          
                    {/*me.state.editable ? <input className="form-control" type="text" value={ locale.keyLocale } onChange={ e => me.setLocale(e) } name="keyLocale" id={`label_key_${me.props.locale.id}`}/> : locale.keyLocale*/} 
                </td>
                <td data-label={_("key_label")}>
                    {me.state.editable ? <input className="form-control" type="text" value={ locale.label } onChange={ e => me.setLocale(e) } name="label" id={`label_label_${me.props.locale.id}`}/> : locale.label }
                </td>
                <td data-label={_("key_state")}>
                    {me.state.editable ? <input className="form-control" type="checkbox" checked={ locale.enabled > 0 } onChange={ e => me.setLocale(e)} name="enabled" id={`label_enabled_${me.props.locale.id}`}/> : (locale.enabled ? "Habilitado" : "Deshabilitado")}
                </td>
            </tr>
            { me.state.width > 768 && me.state.expand && me.props.languages.map( langMain => {
                if(langMain.code == "EN") {
                    return null;
                }
                let tra = new LocaleTranslate();
                tra.language = langMain.code;
                locale.translates.forEach( translate => {
                    if(translate.language == langMain.code) {
                        tra = translate;
                    }
                });

                return (
                <tr key={langMain.id} className={ `locale-table__translate ${tra.modified ? "modified" : ""}` }>
                    <td>
                    
                    </td>
                    <td>{tra.id}</td>
                    <td>
                        {langMain.label}
                    </td>
                    <td>
                        {me.state.editable ? <input className="form-control"
                                                    type="text"
                                                    value={ tra.label }
                                                    name="label"
                                                    onChange={ e => me.setLocaleTranslate(e, tra)}
                                                    id={`label_enabled_${me.props.locale.id}_${tra.id}`}/> : tra.label } 
                    </td>
                    <td >
                        {me.state.editable ? <input className="form-control" type="checkbox" checked={ tra.enabled > 0 } name="enabled" onChange={ e => me.setLocaleTranslate(e, tra)} id={`label_translate_enabled_${tra.id}`}/> : (tra.enabled ? "Habilitado" : "Deshabilitado")}
                    </td>
                </tr>
                    );
                })}
            </>
        );
    }
}
export default LocaleElement;