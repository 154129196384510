import axios from 'axios';
import BaseBL from '../../core/BaseBL';
import { Channel } from '../../entity/channel/ChannelEntity';
import { Contact } from '../../entity/contact/ContactEntity';
import { PaginatedReponse, PaginatedRequest, Pagination } from '../../entity/control/ControlEntity';

/**
 * Perform operations in the contact API
 * @author Samael Fierro <sfierro@viajemos.com>
 * @class ContactBL
 * */
export class ContactBL extends BaseBL {
    /**
     * Get current system roles
     * @returns Groups
     */
    public static async getAllContacts(criteria: string): Promise<Contact[]>{
        let me = this;
        let data = {
            params: {
                criteria: criteria
            }
        };
        
        let response = await axios.get('/Home/ContactAPI/GetAllContacts', data);
        let result = me.getResponseData(response);
        result.data = me.parseEntityArray(result.data, Contact);
        return result;
    }

    /**
     * Get current system roles
     * @returns Groups
     */
    public static async getContact(paramsData: PaginatedRequest): Promise<PaginatedReponse<Contact>>{
        let me = this;
        let data = {
            params: paramsData
        };
        let response = await axios.get('/Home/ContactAPI/ListContact', data);
        let responseData = me.getResponseData(response);
        var result = me.parseEntity(responseData, PaginatedReponse);
        result.data = me.parseEntityArray(result.data, Contact);
        result.pagination = me.parseEntity(result.pagination, Pagination);
        return result;
    }

    /**
     * Take the action to save in the API.
     * @param group Entity object
     * @returns response
     */
     public static async updateContact(group: Contact) {
        let me = this;
        let formData = me.getEntityFormData(group);
        let response = await axios.post('/Home/ContactAPI/UpdateContact', formData);
        let responseData = me.getResponseData(response);
        let groupUpdated = me.parseEntity(responseData, Contact);
        return groupUpdated;
    }
}

export default ContactBL;