/**
 * Chat entity and options
 */
export class NavigationLogEntity {
	public id: number = 0;
	public trackUrl: string = "";
	public chatTopic: string = "";
	public checked: boolean = false;
    public createdDate: string = new Date().toString();
	public updatedDate: string|null = null;
	public isUpdated: boolean = false;
}