import AdminCookies from "../../../helper/AdminCookies";
import Mercure from "../../../helper/Mercure";

/**
 * Manage the topics of the latest open chats
 * @class ChatPersistence
 */
export class ChatPersistence {

    /**
     * Get current topics
     */
	public static get CurrentChats(): Array<string> {
		let topics = AdminCookies.getCookie("currentTopics");
		return topics ? topics.split("&") : [];
	}

    /**
     * Set current topics
     */
	public static set CurrentChats(topics: Array<string>) {
		AdminCookies.setCookie("currentTopics", topics.join("&"));
	}

    /**
     * Add custom topic (to cookie)
     * @param topic Topic
     */
    public static add(topic: string){
        let chats = this.CurrentChats;
        chats.push(topic);
        this.CurrentChats = chats;
    }

    /**
     * Remove custom topic from cookies
     */
    public static remove(topic: string){
        let chats = this.CurrentChats;
        chats = chats.filter(t => t !== topic);
        this.CurrentChats = chats;
    }
}

/**
 * Maneja eventos básicos del typing del user
 * @class ChatUserEvent
 */
 export class ChatUserEvent {
    // Eventos
    private events = {};
    private timeout_id = null;
    private off_timeout = 3000;
    private writting = false;
    private publish_route = "";
    private topic = null;
    private user = "";
    private message_type = "typeEvent";
    private debug = false;
    private static_write = false;

    /**
     * Constructor
     * @param topic Topic
     * @param user User
     */
    public constructor(topic: string, user: string) {
        let me = this;
        me.topic = topic;
        me.user = user;
    }

    /**
     * Publish mercure message
     * @param strUserType 
     * @param strMsgType 
     * @param strMessage 
     * @param strIntName 
     */
    private async publish(strUserType: string, strMsgType: string, strMessage: string, strIntName: string) {
        let me = this;
        let data = {
            userType: strUserType,
            strMsgType: strMsgType,
            message: strMessage,
            strIntName: strIntName
        };
        
        let dataString = JSON.stringify(data);
        await Mercure.publish(me.topic, dataString);
    }

    /**
     * For testing purposes
     */
    public setDebug() {
        this.debug = true;
    }

    /**
     * Set publish mercure URL
     * @param publicRoute New mercure publish url
     */
    public setPublishRoute(publicRoute: string) {
        this.publish_route = publicRoute;
    }

    /**
     * Set chat topic
     * @param topic New topic
     */
    public setTopic(topic: string) {
        this.topic = topic;
    }

    /**
     * Checks event source array event collection
     * @param type Event type
     */
    private checkEventSource(type) {
        let me = this;
        me.events[type] = me.events[type] ? me.events[type] : [];
    }

    /**
     * Add new event handler
     * @param type Event type
     * @param event Callback
     */
    public on(type: string, event: CallableFunction) {
        let me = this;
        me.checkEventSource(type);
        me.events[type].push(event);
    }

    /**
     * Call events from type
     * @param type Event type
     * @param params Params
     */
    public call(type: string, params) {
        let me = this;
        me.checkEventSource(type);
        me.events[type].forEach(ev => {
            ev(params);
        });
    }

    /**
     * Keep writting message, up to change
     * @param state Current state
     * @param write_type Event write type (optional)
     */
    public staticWrite(state: boolean, write_type: string = "normal") {
        let me = this;
        me.static_write = state;
        if (state) {
            me.callEventOn(write_type);
        } else {
            me.callEventOff(write_type);
        }
    }

    /**
     * Send the event to show the message "User typing..."
     * @param write_type 
     */
    public write(write_type: string = "normal") {
        let me = this;
        clearInterval(me.timeout_id);
        me.callEventOn(write_type);

        me.timeout_id = setTimeout(function () {
            me.callEventOff(write_type);
        }, me.off_timeout);
    }

    /**
     * Call event write by type
     * @param write_type 
     */
    private callEventOn(write_type: string) {
        let me = this;
        !me.writting && me.call("on_write_on", me);
        if (!me.writting) {
            me.publish(me.user, write_type, "writeOn", me.message_type);
        }
        me.writting = true;
    }

    /**
     * Call event write by type
     * @param write_type 
     */
    private callEventOff(write_type: string) {
        let me = this;
        me.writting && me.call("on_write_off", me);
        if (me.writting) {
            me.publish(me.user, write_type, "writeOff", me.message_type);
        }
        me.writting = false;
    }

    /**
     * Catch event from mercure messages
     * @param data 
     * @returns TRUE If it is a chatuserevent type event
     */
    public processEvent(data) {
        let me = this;
        let { userType, strMsgType, message, strIntName } = data;

        let userTypeValid = strIntName == me.message_type;
        if (userTypeValid && (userType !== me.user || me.debug)) {
            switch (message) {
                case "writeOn":
                    me.call("on_remote_write_on", data);
                    me.call("on_remote_write_on_" + strMsgType, data);
                    break;
                case "writeOff":
                    me.call("on_remote_write_off", data);
                    me.call("on_remote_write_off_" + strMsgType, data);
                    break;
                case "read":
                    me.call("on_read", data);
                    me.call("on_read_" + strMsgType, data);
                    break;
            }
        }
        return userTypeValid;
    }
}