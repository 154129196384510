import axios from "axios";
import BaseBL from "../../core/BaseBL";
import { MBTemplate } from "../../entity/chat/ChatEntity";

/**
 * Performs MessageBird Operations
 * @Class MBirdBL 
 * @author Samael Fierro <sfierro@viajemos.com>
*/
export class MBirdBL extends BaseBL {
	// Cache
	private static cache: MBTemplate[] = [];

	/**
	 * Get chats dispositions (API)
	 */
	 public static async getTemplates(): Promise<MBTemplate[]> {
		let me = this;
		if(me.cache.length > 0) {
			return me.cache;
		}
        let response = await axios.get('/Home/MBirdAPI/ListTemplates');
        let responseData = me.getResponseData(response);
        me.cache = me.parseEntityArray(responseData, MBTemplate);
        return me.cache;
	}
}

export default MBirdBL;