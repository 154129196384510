import React from "react";
import { LocaleBL } from "../../bl/locale/LocaleBL";
import ComponentCommon from "../../core/ComponentCommon";
import { LocationName, LocationNameTranslate } from "../../entity/location/LocationEntity";
import LocationNameElementProp from "../../entity/location/props/LocationNameElementProp";
import LocationNameElementState from "../../entity/location/states/LocationNameElementState";
import { FormGroup, Row, IconFa } from '../../../cuba/components/utils/reactstrap'
import { UserLanguage } from "../../entity/login/LoginEntity";
import { LocationNameBL } from "../../bl/location/LocationNameBL";
import { Button } from '../../../cuba/components/utils/reactstrap'
import { FormInputTranslate } from "../../core/Translate";
import Alert from "../utils/Alert";
import { _ } from "../../bl/admin/AdminLocaleBL";
/**
 * Performs updated and saved locationName data operations
 * @class LocationNoteElement
 * @author Samael Fierro <sfierro@viajemos.com>
 */
 export class LocationNameElement extends ComponentCommon<LocationNameElementProp, LocationNameElementState> {
    /**
     * Constructor
     * @param props Property 
     */
     public constructor(props){
        super(props);
        this.state = new LocationNameElementState();
    }

    /**
     * Component did mount
     */
    componentDidMount(){
        let me = this;
        me.prepare();
    }

    /**
     * Initialize component
     */
    private async prepare() {
        let me = this;

        var languages = await LocaleBL.getLanguages();
        var locationName = me.props.location.locationNameRef;

        if(locationName?.id < 1 || locationName == undefined) {
            locationName = new LocationName();
        }

        // Generate form inputs
        var inputs = FormInputTranslate.prepare(
            e => LocationNameBL.updateLocationName(e),
            e => LocationNameBL.updateLocationNameTranslate(e)
        ).generate(languages, locationName);

        me.setState({
            locationName: locationName,
            languages: languages,
            inputs: inputs
        });
    }


    /**
     * Handle sabe click
     */
     private async handleSave(){
        let me = this;
        
        var result = await Alert.confirm(_("key_locationname_save"), _("key_locationname_save_message"));
        if(result.isConfirmed) {
            me.save();
            Alert.success();
        }
    }

    /**
     * Save LocationNote and LocationNoteTranslates
     */
     public async save(){
        let me = this;

        var inputs = me.state.inputs;
        inputs = await FormInputTranslate.save(inputs);
        me.props.onChange && me.props.onChange();
        me.setState({
            inputs: inputs
        });
    }

    /**
     * Get identifier
     * @returns number
     */
     public getValue(): LocationName {
        let me = this;
        return FormInputTranslate.getParent(me.state.inputs).value;
    }

    render() {
        let me = this;
        return (
            me.state.locationName ? 
                <div className="mb-2">
                    {me.state.inputs.map( (input, i) => 
                        <div className="form-group form-control-md mb-3" key={ i }>
                            <h6 className="form-label">{input.label}</h6>
                            <input ref={ input.createRef("name") } required={true} defaultValue={ input.value["name"] } className="form-control" />
                        </div>
                    )}
                    <Button type="button" color="primary" onClick={ e => me.handleSave() }>
                        <IconFa icon="save"/>{_("key_save")}
                    </Button>
                </div>
            : null
        );
    }
}

export default LocationNameElement;