import React from "react";
import ComponentCommon from "../../core/ComponentCommon";
import ChatElementProp from "../../entity/chat/props/ChatElementProp";
import ChatElementState from "../../entity/chat/states/ChatElementState";
import Gravatar from 'react-gravatar';
import { _ } from "../../bl/admin/AdminLocaleBL";
import Rate from "../control/Rate";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { ADMIN_SITES } from "../../constants/data/sites";
import moment from "moment";

/**
 * Represents a chat with their respective indicators
 * @class ChatElement
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class ChatElement extends ComponentCommon<ChatElementProp, ChatElementState> {

    /**
     * Constructor
     * @param props Property
     */
    constructor(props: ChatElementProp){
        super(props);
        this.state = new ChatElementState();
    }

    private counter;

    componentDidMount(): void {
        let me = this;
        me.counter = setInterval(() => {
            me.setState({
                currentDate: new Date()
            })
        }, 1000)
    }

    componentWillUnmount(): void {
        clearInterval(this.counter);
    }

    private getCountDown() {
        let me = this;

        let lastMessage = null;
        me.props.chat.messages.forEach( (message, i) => {
            if(message.usrMessage == "chatbot") {
                lastMessage = me.props.chat.messages[i - 1];
            }
        })

        let chatDate = moment(me.props.chat.LastUserWaitingMessageDate);
        let nowDate = moment(me.state.currentDate);
        let difference = moment.duration(nowDate.diff(chatDate));
        let diffTime = moment.utc(difference.asMilliseconds());
        let totalMinutes = difference.asMinutes();
        let type = "success";

        if(totalMinutes < 3) {
            type = "success";
        } else if(totalMinutes < 5) {
            type = "warning";
        } else {
            type = "danger";
        }

        const timeFormat = Math.floor(difference.asHours()) > 0 ? "HH:mm:ss" : "mm:ss";
        let label = diffTime.format(timeFormat);

        return {
            label: label,
            type: type,
            active: true
        }
    }

    private about() {
        let me = this;
        let newCount = me.props.chat.NewCount;
        let siteCode = ADMIN_SITES.find( site => site.code == me.props.chat.siteCode );
        
        // Get countdown labels
        const countDown = me.getCountDown();

        return (
            <div className="about">
                <div className="name"><b>{_("key_chat_topic")}: </b> {me.props.chat.ChatTopic}</div>
                <div className="status">
                    { newCount > 0 && 
                        <b className="badge badge-pill badge-secondary">{newCount}</b>
                    }
                    {` ${me.props.chat.LastMessageText}`}<br/>
                    <b className="badge badge-pill badge-primary mr-2">{me.props.chat.language}</b>
                    {me.props.chat.session?.country && <i title={me.props.chat.session?.country} className={`flag-icon mr-2 flag-icon-` + me.props.chat.session?.country.toLowerCase()}></i>}
                    <i className="fa fa-calendar"></i> <i>{me.props.showFirstMessageDateInSummary?me.props.chat.FirstMessageDateText:me.props.chat.LastMessageDateText}</i>
                    {me.props.chat.chatAgent &&
                        <>
                            { me.props.chat.agent.userName &&
                                <div>
                                    <b><i className="fa fa-at"></i> {me.props.chat.agent.userName} </b>
                                </div>
                            }
                            <div>
                                <b><i className="fa fa-support"></i> {_("key_agent")}: </b>
                                {`${me.props.chat.agent.firstName || _("key_nameless")} ${me.props.chat.agent.lastName || ""}`}
                            </div>
                        </>
                    }
                    {me.props.chat.platform.length > 0 &&
                        <div>
                            <b><i className={`fa fa-${me.props.chat.platform}`}></i> {me.props.chat.platform}: </b>
                            {me.props.chat.profile}
                        </div>
                    }
                    {me.props.chat.idRate > 0 && 
                        <Rate readonly={true} title={_("key_rate")} value={me.props.chat.rate.rate} />
                    }
                    {siteCode && 
                        <div>
                            <b><i className={`fa fa-globe mr-1`}></i></b> {siteCode.name}
                        </div>
                    }
                    {me.props.showCounter &&
                        <div>
                            <b><i className={`fa fa-clock-o mr-1`}></i></b> 
                            <i className={`badge badge-pill badge-${countDown.type} text-white mr-2`}>{countDown.label}</i>
                        </div>
                    }
                </div>
            </div>
        );
    }

    render() {
        let me = this;
        return (
        <li className={`clearfix ${me.props.active ? 'active' : ''}`} onClick={ (e) => me.props.onClick(me.props.chat)} >
            {!me.props.minimize ?
                <>
                    <Gravatar email={ me.props.chat.chatTopic } className="rounded-circle user-image" />
                </>
                :
                <OverlayTrigger 
                trigger={['hover', 'focus']}
                    placement="right"
                    overlay={
                        <Popover id={"one"} className="popover popover--default | ph-L pv-S">
                            <div className="popover__content p-3">
                                {me.about()}
                            </div>
                        </Popover>
                    }
                    >
                    <Gravatar email={ me.props.chat.chatTopic } className="rounded-circle user-image" />
                </OverlayTrigger>
            }
            <div className={`status-circle ${me.props.chat.Online ? 'online' : 'offline'}`}></div>
            {!me.props.minimize &&
                me.about()
            }
        </li>
        );
    }
}

export default ChatElement;