import React from "react";
import NotificationPageProp from "../entity/notification/props/NotificationPageProp";
import NotificationPageState from "../entity/notification/states/NotificationPageState";
import { Card, CardBody, CardHeader, Form, Button, Row, Col, IconFa } from '../../cuba/components/utils/reactstrap'
import Paginator from "../component/control/Paginator";
import { PaginatedRequest, Pagination } from "../entity/control/ControlEntity";
import LoaderMini from "../component/utils/LoaderMini";
import { _ } from "../bl/admin/AdminLocaleBL";
import PageCommon from "../core/PageCommon";
import LoginBL from "../bl/login/LoginBL";
import NotifierBL from "../bl/admin/NotifierBL";
import { Notify } from "../entity/notification/NotificationEntity";
import { GET_NOTIFY_TEMPLATE } from "../constants/notification";
import MercureListener from "../helper/MercureListener";

/**
 * NotificationPage component
 * @class NotificationPage
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class NotificationPage extends PageCommon<NotificationPageProp, NotificationPageState> {
    //Paginator reference
    private _paginatorRef = null;
    // Notify handler
    private notifyHandler: CallableFunction;

    /**
     * Constructor
     * @param props Property 
     */
    constructor(props: NotificationPageProp) {
        super(props)
        this._paginatorRef = React.createRef();
        this.state = new NotificationPageState();
    }

    /**
     * Component did mount
     */
    componentDidMount(){
        let me = this;
        me.prepare();
    }

    /**
     * Component will unmount
     */
    componentWillUnmount(){
        let me = this;
        MercureListener.unsubscribeHandler(me.notifyHandler);
    }


    /**
     * Initialize componet
     */
    private prepare(){
        let me = this;

        // Handler
        me.notifyHandler = (data) => {
            me.searchResult();
        };

        // Subscribe to the notifications channel
        MercureListener.subscribe("notify_" + LoginBL.SessionData.id, me.notifyHandler);

        // Load notifications
        me.searchResult();
    }

    /**
     * Handle form submit
     * @param e Event
     */
    private handleFormSubmit(e){
        e.preventDefault();
        let me = this;
        me.searchResult(true);
    }

    /**
     * Make the query by page
     */
    private async searchResult(resetCount: boolean = false){
        let me = this;

        let request = new PaginatedRequest<any>();
        request.pagination = me.state.pagination;
        request.data = {
            id: LoginBL.SessionData.id
        };

        me.setState({busy: true});
        let data = await NotifierBL.listNotification(request);

        me.setState({
            busy: false,
            notifications: data.data,
            pagination: resetCount ? data.pagination.setCurrentPage(1) : data.pagination
        }, () => {
            me._paginatorRef?.current?.update()
        });
    }
    
    /**
     * Clear notifications
     * @param notify 
     */
     private async clearNotifications(){
        let me = this;
        var notifications = me.state.notifications.map( n => {
            n.state = 1;
            n.push = false;
            return n;
        });
        me.setState({
            notifications: notifications
        });
        var notificationsCount = await NotifierBL.clearNotifications();
    }

    /**
     * Handle notification click
     * @param e Event
     */
     private async clearNotification(notify: Notify) {
        let me = this;

        var notifications = me.state.notifications.map( n => {
            if(n.id == notify.id) {
                n.state = 1;
                n.push = false;
            }
            return n;
        });
        me.setState({
            notifications: notifications
        });
        var result = await NotifierBL.clearNotification(notify.id);
    }

    /**
     * Get notification template
     * @param notify 
     * @returns Component
     */
    private getNotify(notify: Notify){
        let me = this;
        var Component = GET_NOTIFY_TEMPLATE(notify).Component;
        return <li onClick={ e => me.clearNotification(notify) } key={notify.id}><Component notify={notify}/></li>
    }

    /**
     * Update pagination state
     * @param pagination Pagination object
     */
    public passPage(pagination: Pagination){
        let me = this;
        me.setState({
            pagination: pagination
        }, () => {
            me.searchResult();
        });
    }

    render(){
        let me = this;

        return (
            <Card>
                <CardHeader>
                    <Row>
                        <Col md="6">
                            <h5>{_("key_my_notifications")}</h5> 
                        </Col>
                        <Col md="6">
                            <Button color="primary" className="float-right mt-3 mt-md-0" onClick={ e => me.clearNotifications() }>
                                <IconFa icon="bell"/> {_("key_mark_as_read")}
                            </Button>
                        </Col>
                    </Row>  
                </CardHeader>
                <CardBody> 
                    {me.state.busy && <LoaderMini message={_("key_search_loading_results")}/>}
                    <table className="table list-unstyled">
                        <tbody>
                            {!me.state.busy && me.state.notifications.map( (notification: Notify) => 
                                <tr key={notification.id}>
                                    <td>
                                        {me.getNotify(notification)}
                                    </td>
                                </tr>
                            )} 
                        </tbody>
                    </table>
                    <Paginator 
                        visible={!me.state.busy && me.state.notifications.length > 0} 
                        ref={ me._paginatorRef } 
                        pagination={me.state.pagination} 
                        onChange={ pagination => me.passPage(pagination) } />
                </CardBody>
            </Card>
        );
    }
}
