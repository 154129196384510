import React from 'react';
import ComponentCommon from "../../core/ComponentCommon";
import { Card, CardHeader, CardBody, FormGroup, IconFa, Row, Col, AlertBar } from '../../../cuba/components/utils/reactstrap';
import { _ } from '../../bl/admin/AdminLocaleBL';
import AuditorLogList from '../auditor/AuditorLogList';
import { AuditorConfig } from '../../entity/auditor/AuditorEntity';
import LoaderBackdrop from '../utils/LoaderBackdrop';
//import { REQUEST_PLATFORM } from '../../constants/data/request';
import LanguageFormProp from '../../entity/language/props/LanguageFormProp';
import LanguageFormState from '../../entity/language/states/LanguageFormState';
import 'react-intl-tel-input/dist/main.css';
import UserScheduleList from '../user/UserScheduleList';
import LanguageScheduleList from './LanguageScheduleList';

/**
 * Update chat contacts
 * @class ContactForm
 * @author Eduardo Escallon <eescallon@viajemos.com>
 */
export class LanguageForm extends ComponentCommon<LanguageFormProp, LanguageFormState> {
    private _auditorLog = null;
    private _auditorLogConfig: AuditorConfig = null;
    private _phoneRef = null;

    /**
     * Constructor
     * @param props Property
     */
    constructor(props: LanguageFormProp){
        super(props);
        let me = this;
        me.state = new LanguageFormState();  
        me._auditorLog = React.createRef();
        me._auditorLogConfig = new AuditorConfig();
        me._phoneRef = React.createRef();
    }
    
    /**
     * Component did mount
     */
    componentDidMount(){
        let me = this;
        me.prepare();
    }

    /**
     * Initialize componente
     */
    private async prepare(){
        let me = this;
        me.setState({
            language: me.props.language
        });
    }

    /**
     * Handle form submit
     * @param e Event
     */
    private async handleSubmit(e) {
        e.preventDefault();
        // let me = this;
        // let channel = me.state.language;

        // // Check number
        // if(!me.checkCurrentPhone()){
        //     Alert.error(_("key_phone_invalid"));
        //     return;
        // }
         
        // me.setState({busy: true});

        // // Save changes...
        // channel = await ContactBL.updateContact(channel);

        // me.props.onChange && me.props.onChange(channel);
        // Alert.success();
        // me.setState({
        //     busy: false,
        //     language: channel
        // });

        // // Update auditor
        // me._auditorLog.current?.update();
    }

    /**
     * Event handler for inputs
     * @param event Event
     */
     private handleChange(event){
        let me = this;
        let dom = event.target;
        let value = dom.value;
        if(dom.type == "checkbox"){
            value = dom.checked;
        }
        let prop = dom.name;
        let channelState = me.state.language;
        channelState[prop] = value;
        me.setState({
            language: channelState
        });
    }

    /**
     * Handle changes in the components
     */
    private handleChanges(){
        let me = this;
        me._auditorLog.current?.update();
    }

    /**
     * Handle on close
     */
    private handleClose(){
        let me = this;
        me.props.onClose && me.props.onClose();
    }

    render(){
        let me = this;

        me._auditorLogConfig.clear();
        me._auditorLogConfig.addCriteria({
            module: "Language", identifier: me.state.language?.id
        });

        if(!me.state.language){
            return <></>
        }

        return (
            <>
                <LoaderBackdrop visible={me.state.busy} message={_("key_data_saving")} />
                <form onSubmit={ e => me.handleSubmit(e) }>
                    <Card> 
                        <CardHeader>
                            <Row>
                                <Col md="6" className="p-0">
                                    <h5 className="card-title mb-0">{_("key_edit")} {me.state.language?.name && <span>"{me.state.language.name}"</span>}</h5>
                                </Col>
                                <Col md="6" className="p-0">
                                    <button className="btn btn-primary my-2 mx-md-2 float-right m-2" type="submit">
                                        <IconFa icon="save"/> {_("key_save")}
                                    </button>
                                    <button onClick={ () => me.handleClose() } className="btn btn-info my-2 mx-md-2 float-right m-2">
                                        <IconFa icon="close"/> {_("key_return")}
                                    </button>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody className="p-5">
                            <Row>
                                <Col sm="12" md="6">
                                    <FormGroup>
                                        <h6 className="form-label">{_("key_name")}</h6>
                                        <input type="text" required={true} onChange={ e => me.handleChange(e) } name="name" value={ me.state.language.name } className="form-control" />
                                    </FormGroup>
                                </Col>
                                <Col sm="12" md="6">
                                    <FormGroup>
                                        <h6 className="form-label">{_("key_code")}</h6>
                                        <input type="text" required={true} onChange={ e => me.handleChange(e) } name="code" value={ me.state.language.code } className="form-control" />
                                    </FormGroup>
                                </Col>
                                <Col sm="12" md="6">
                                    <FormGroup>
                                        <h6 className="form-label">{_("key_label")}</h6>
                                        <input type="text" required={true} onChange={ e => me.handleChange(e) } name="label" value={ me.state.language.label } className="form-control" />
                                    </FormGroup>
                                </Col>
                                <Col sm="12">
                                <Card>
                                    <AlertBar header={true}>{_("key_user_schedule")}</AlertBar>
                                    <div className="m-4">
                                        <LanguageScheduleList onChange={ e => me.handleChanges() }  language={me.state.language} />
                                    </div>
                                </Card>
                            </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </form> 
                { me.state.language.id > 0 && <AuditorLogList ref={ me._auditorLog } config={ me._auditorLogConfig }/> }
            </>
        );
    }
}

export default LanguageForm;