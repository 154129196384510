import IState from "../../../core/IState";
import { Pagination } from "../../control/ControlEntity";
import { GroupUser } from "../../login/LoginEntity";
import { Role } from "../SecurityEntity";

export default class GroupPageState implements IState {
    public pagination: Pagination = new Pagination();
    public criteria: string = "";

    public group: GroupUser = null;
    public groups: GroupUser[] = [];
    public loadingGroups: boolean = false;
}