import React from "react";
import ComponentCommon from "../../core/ComponentCommon";
import SwitchProp from "../../entity/control/props/SwitchProp";
import SwitchState from "../../entity/control/states/SwitchState";

/**
 * Switch component
 * @class Switch
 * @author Samael Fierro <sfierro@viajemos.com>
 */
 export class Switch extends ComponentCommon<SwitchProp, SwitchState> {
    /**
     * Constructor
     * @param props Property
     */
    constructor(props: SwitchProp) {
        super(props);
        this.state = new SwitchState();
    }

    /**
     * On did mount
     */
    componentDidMount() {
        let me = this;
        me.prepare();
    }

    /**
     * Initialize component
     */
    private prepare() {
        let me = this;
        me.setState({
            checked: me.props.checked
        })
    }

    /**
     * Handle click event
     */
    private handleClick(e){
        let me = this;
        me.setState({
            checked: !me.state.checked
        })
    }

    /**
     * Handle on change event
     * @param e Event
     */
    private handleChange(e){
        let me = this;
        e.target.checked = me.state.checked;
        me.props.onChange && me.props.onChange(e);
    }

    /**
     * Get value
     * @returns switch state value
     */
    public getValue() {
        let me = this;
        return me.state.checked;
    }

    render() {
        let me = this;
        var color = me.state.checked ? (me.props.color ?? "primary") : "";
        var sizeClass = me.props.size ? `switch-${me.props.size}` : ""; 
        var outline = me.props.outline == true;
        var labelClass = "";
        
        switch (me.props.size) {
            case "sm":
                labelClass = "h5 mt-2";
                break;
            default:
                labelClass = "h2 mt-2";
                break;
        }
 

        return (                            
            <div className="media mb-2">
                <div className={`text-end ${outline ? "switch-outline" : ""} ${sizeClass}`}>
                    <label className={`switch`}>
                        <input 
                            name={me.props.name} 
                            //ref={ ref } 
                            type="checkbox" 
                            onChange={ e => this.handleChange(e) } 
                            checked={ me.state.checked } 
                            title={me.props.title}/>

                        <span onClick={ e => me.handleClick(e) } className={`switch-state bg-${color}`}></span>
                    </label>
                </div>
                {me.props.title && <label onClick={ e => me.handleClick(e) } className={`${labelClass} ml-3 text-${color}`}>{me.props.title}</label>}
            </div>
        );
    }
}

export default Switch;