import { _ } from "../../bl/admin/AdminLocaleBL";

/**
 * It represents a category of reports
 */
export class ReportCategory {
    constructor(
        {name, title, reports}
        :
        {name: string, title: string, reports: Report[]}
    ){
        let me = this;
        me.title = title;
        me.reports = reports;
        me.name = name;
    }

    public name: string;
    public title: string;
    public reports: Report[] = [];

    public get Title() {
        return _(this.title);
    }
}

/**
 * Represents a report.
 */
export class Report {
    constructor(
        {report, name, form, result}
        :
        {report: string, name: string, form: any, result: any}
    ){
        let me = this;
        me.report = report;
        me.name = name;
        me.form = form;
        me.result = result;
    }

    public report: string;
    public name: string;
    public form: any;
    public result: any;

    public get Name() {
        return _(this.name);
    }
}