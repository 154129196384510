import React from "react";
import AuditorLogBL from "../../bl/auditor/AuditorLogBL";
import { LocationBL } from "../../bl/location/LocationBL";
import ComponentCommon from "../../core/ComponentCommon";
import AuditorLogListProp from "../../entity/auditor/props/AuditorLogListProp";
import { AuditorLog as AuditorLogE} from "../../entity/auditor/AuditorEntity";
import AuditorLogListState from "../../entity/auditor/states/AuditorLogListState";
import { PaginatedRequest, Pagination } from "../../entity/control/ControlEntity";
import { IconFa, Card, Button, AlertBar } from '../../../cuba/components/utils/reactstrap';
import Paginator from "../control/Paginator";
import AuditorLogElement from "./AuditorLogElement";
import LoginBL from "../../bl/login/LoginBL";
import { Empty } from "../utils/Empty";
import LoaderMini from "../utils/LoaderMini";
import { _ } from "../../bl/admin/AdminLocaleBL";

/**
 * Represents an Audit Log of Changes
 * @class AuditorLog
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class AuditorLogList extends ComponentCommon<AuditorLogListProp, AuditorLogListState> {

    // Paginator ref
    private _paginatorRef = null;
    private _timeout = null;

    /**
     * Constructor
     * @param props Property
     */
    public constructor(props){
        super(props);
        this.state = new AuditorLogListState();
        this._paginatorRef = React.createRef();
    }

    /**
     * Component did mount
     */
    componentDidMount() {
        let me = this;
        me.prepare();
    }

    /**
     * Component will unmount
     */
    componentWillUnmount(){
        let me = this;
        clearTimeout(me._timeout);
    }

    /**
     * Initialize component
     */
    private prepare() {
        let me = this;
        var config = me.props.config;

        if(config.isEmpty()) {
            me._timeout = setTimeout(function(){
                me.searchResult();
            }, config.timeout);
        } else {
            me.searchResult();
        }
    }

    /**
	 * Realiza la solicitud de búsqueda a la API
	 */
    public async searchResult(loader: boolean = true) {
        let me = this;
        me.setState({
            busy: loader
        })

        var config = me.props.config;
        config.update();
        let data = config.Criteria;

        // Prepare request!
        let request = new PaginatedRequest<any>();
        request.data = data;
        request.pagination = me.state.pagination;
        let results = await AuditorLogBL.getAuditorLogs(request);
    
        me.setState({
            pagination: results.pagination,
            busy: false,
            logList: results.data
        }, () => {
            // Update paginator
            me._paginatorRef?.current?.update();
        });
    }

    /**
     * Update pagination state
     * @param pagination Pagination object
     */
     public passPage(pagination: Pagination){
        let me = this;
        me.setState({
            pagination: pagination
        }, () => {
            me.searchResult();
        });
    }

    /**
     * Set selected log
     * @param log AuditorLog element
     */
    private setCurrentLog(log: AuditorLogE) {
        let me = this;
        me.setState({
            currentLog: log
        });
    }

    public update() {
        let me = this;
        me.searchResult();
    }
    
    render(){
        let me = this;
        return (
            <Card>
                <AlertBar header={true}>
                    <Button onClick={ e => me.searchResult() } className="float-right" color="secondary">
                        <IconFa icon="refresh"/> {_("key_refresh")}
                    </Button>
                    {_("key_changes_history")}
                </AlertBar>
                <div className="p-3">
                    <LoaderMini message={_("key_loading_history")} visible={me.state.busy} />
                    {!me.state.busy && 
                        <ul className="log-container custom-scrollbar">                               
                            {me.state.logList.map( (log) => 
                                <AuditorLogElement 
                                    onClick={ e => me.setCurrentLog(log) } 
                                    key={log.id} 
                                    labels={me.props.config.labels}
                                    active={log.id == me.state.currentLog?.id} 
                                    log={log} />
                            )}
                        </ul>
                    }
                    <Empty visible={!me.state.busy && me.state.logList.length < 1}/>
                    <Paginator visible={me.state.logList.length > 0} ref={ me._paginatorRef } pagination={me.state.pagination} onChange={ pagination => me.passPage(pagination) }/>
                </div>
            </Card>
        );
    }
}

export default AuditorLogList;