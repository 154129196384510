import { _ } from "../bl/admin/AdminLocaleBL";
import { ChatNewNotify, ChatNotify, ChatTransferNotify, RequestNotify } from "../component/notification/Notifications";
import { NotificationTemplate, Notify } from "../entity/notification/NotificationEntity";
import GeneralFunctions from "../helper/GeneralFunctions";
import ChatPage from "../page/ChatPage";
import RequestPage from "../page/RequestPage";

/**
 * Related properties
 * These properties describe the titles and messages generated in notifications
 */
 export const NOTIFICATIONS = {
    Chat: new NotificationTemplate({
        action: "/chat/Dubai",
        titleKey: "key_new_message",
        messageKey: "key_notify_chat",
        component: ChatNotify,
        page: ChatPage,
        message: function() { return _(this.messageKey) + ": " + GeneralFunctions.sliceText(this.related.Data?.strMessage) }
    }),
    Request: new NotificationTemplate({
        action: "/request/Dubai",
        titleKey: "key_new_request",
        messageKey: "key_notify_request",
        component: RequestNotify,
        page: RequestPage,
        message: function() { return _(this.messageKey) + ": " + this.related.Data?.usrName }
    }),
    ChatNew: new NotificationTemplate({
        action: "/chat/Dubai",
        titleKey: "key_new_chat",
        messageKey: "key_notify_chat_new",
        component: ChatNewNotify,
        page: ChatPage,
        message: function() { return _(this.messageKey) + ": " + (this.related.Data?.topic?.split("_")[1] ?? "") }
    }),
    ChatTransfer: new NotificationTemplate({
        action: "/chat/Dubai",
        titleKey: "key_chat_transfer",
        messageKey: "key_notify_chat_transfer",
        component: ChatTransferNotify,
        page: ChatPage,
        message: function() { return _(this.messageKey) }
    })
};

/**
 * Get notifications configuration templating
 * @param notify 
 * @returns Notification properties
 */
 export const GET_NOTIFY_TEMPLATE = (notify: Notify) => {
    var properties = NOTIFICATIONS[notify.type] as NotificationTemplate;
    properties.Related = notify;
    return properties;
}