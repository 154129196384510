/**
 * Notification helper
 * @class Notifier
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class Notifier {
    /**
     * Initialize
     */
    public static prepare() {
        let me = this;
        // Request permission
        me.requestPermission()
    }

    /**
     * Show new notification
     * @param title Title of the notification
     * @param options Options
     * @returns Notification
     */
     public static show(title: string, options: NotificationOptions = null): Notification{
        return new Notification(title, options);
    }

    /**
     * Request permissión
     */
    public static async requestPermission(): Promise<string> {
        Notification?.requestPermission();
        
        // Comprobamos si el navegador soporta las notificaciones
        if (!("Notification" in window)) {
            return null;
        }
        return (await Notification.requestPermission());
    }

    /**
     * Get current notification permission
     * @returns 
     */
    public static getCurrentPermission(): string {
        if(Notification?.permission){
            return Notification.permission;
        }
        return null;
    }
}

export default Notifier;
