import React, { Fragment } from 'react';
//import { FileText, LogIn, Mail, User as UserIcon, Edit } from 'react-feather';
import { Card, CardBody, CardHeader, Form, Button, Row, Col, IconFa, Nav } from '../../cuba/components/utils/reactstrap'
import UserPageProp from '../entity/user/props/UserPageProp';
import UserPageState from '../entity/user/states/UserPageState';
import UserBL from '../bl/user/UserBL'
import { User } from '../entity/login/LoginEntity';
import { UserForm } from '../component/user/UserForm';
import PageCommon from '../core/PageCommon';
import Paginator from '../component/control/Paginator';
import { _ } from '../bl/admin/AdminLocaleBL';
import { PaginatedRequest, Pagination, PaginatedWithIdRequest } from '../entity/control/ControlEntity';
import LoaderMini from '../component/utils/LoaderMini';

/**
 * UserPage (User admin)
 * @class UserPage
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export default class UserPage extends PageCommon<UserPageProp, UserPageState> {
    //Paginator reference
    private _paginatorRef = null;

    public constructor(props: UserPageProp){
        super(props);
        this.state = new UserPageState();
    }

    componentDidMount() {
        let me = this;
        me._paginatorRef = React.createRef();
        me.getGroups()
        me.searchResult();
    }

    /**
     * Select a user and displays the preview
     * @param user 
     */
    private selectUser(user: User){
        let me = this;
        me.setState({
            selectedUser: user,
            editUser: true
        });
    }

    /**
     * Exchange the preview and the editor
     * @param edit Editor state
     */
    private editUser(edit:boolean = false){
        let me = this;
        me.setState({
            editUser: edit
        });
    }

    /**
     * Create a new user
     */
    private createUser(){
        let me = this;

        // Destroy component
        me.setState({
            selectedUser: null
        }, () => {
            // Update component
            me.setState({
                selectedUser: new User(),
                editUser: true
            })
        })
    }

    /**
     * On close form handler
     * @param user User updated
     */
    private onCloseHandler (user: User) {
        let me = this;
        me.setState({
            selectedUser: user,
            editUser: false
        });
        me.searchResult();
    }

    /**
     * Make the query by page
     */
     private async searchResult(resetCount: boolean = false){
        let me = this;

        let da = {
            criteria: me.state.criteria,
            id: +me.state.currentGroup,
            enabled: +me.state.filterEnabled
        }

        let request = new PaginatedWithIdRequest();
        request.pagination = me.state.pagination;
        request.data = da

        me.setState({loadingUsers:true});
        let data = await UserBL.getUsers(request);
        
        me.setState({
            loadingUsers: false,
            currentUsers: data.data,
            pagination: resetCount ? data.pagination.setCurrentPage(1) : data.pagination
        }, () => {
            me._paginatorRef?.current?.update()
        });
    }

    /**
     * Update pagination state
     * @param pagination Pagination object
     */
     public passPage(pagination: Pagination){
        let me = this;
        me.setState({
            pagination: pagination
        }, () => {
            me.searchResult();
        });
    }

    private handleFormSubmit(e){
        e.preventDefault();
        let me = this;
        me.searchResult(true);
    }

    private async getGroups() {
        let me = this
        let groups = await UserBL.getGroups()
         me.setState({
            groups: groups
        })
    }

    render() {
        let me = this;
        return (
                <Fragment>
                    { !me.state.editUser ? 
                        <Card>
                            <CardHeader>
                                <Row>
                                    <Col md="6">
                                        <h5>{_("key_users_registered")} ({me.state.currentUsers.length})</h5> 
                                    </Col>
                                    <Col md="6">
                                        <Button color="success" className="float-right mt-3 mt-md-0" onClick={ e => me.createUser() }>
                                            <IconFa icon="plus"/> {_("key_user_create")}
                                        </Button>
                                    </Col>
                                </Row>                                
                            </CardHeader>
                            <CardBody> 
                                <Form onSubmit={ e => me.handleFormSubmit(e) } className="mb-3">
                                    <Row>
                                        <Col md="4">
                                            <input id="criteria_input" className="form-control" value={me.state.criteria} onChange={ e => me.setState({criteria: e.target.value })} placeholder={_("key_search_criteria")}/>
                                        </Col>
                                        <Col md="4">
                                            <select value={me.state.currentGroup} onChange={ e => me.setState({currentGroup: e.target.value })} className="form-control">
                                                <option value="0">{_("key_role_all")}</option>
                                                {me.state.groups.map( group => 
                                                    <option key={group.id} value={group.id}>{ group.name }</option>
                                                )}
                                            </select>
                                        </Col>
                                        <Col md="2">
                                            <select value={me.state.filterEnabled} onChange={ e => me.setState({filterEnabled: e.target.value })} className="form-control">
                                                <option value="1">{_("key_enabled")}</option>
                                                <option value="0">{_("key_disabled")}</option>
                                            </select>
                                        </Col>
                                        <Col md="2">
                                            <button className="btn btn-primary mt-3 mt-md-0 float-right" type="submit">
                                                <IconFa icon="search"/> {_("key_filter_results")}
                                            </button>
                                        </Col>
                                    </Row>
                                </Form>
                                <table className="table table-m-2">
                                    <thead>
                                        <tr>
                                            <th scope="col">{_("key_image")}</th>
                                            <th scope="col">{_("key_name")}</th>
                                            <th className="hide-md" scope="col">{_("key_lastname")}</th>
                                            <th className="hide-md" scope="col">{_("key_email")}</th>
                                            <th className="hide-md" scope="col">{_("key_role")}</th>
                                            <th scope="col">{_("key_actions")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {me.state.loadingUsers &&
                                        <tr>
                                            <td colSpan={6}>
                                                <LoaderMini message={_("key_search_loading_results")}/>     
                                            </td>
                                        </tr>
                                    }
                                    {me.state.currentUsers.map( (user: User) => 
                                        <tr key={user.id}>
                                            <td>
                                                <img className="img-40" src={ user.Avatar }alt=""/>
                                            </td>
                                            <td>{user.firstName}</td>
                                            <td className="hide-md">{user.lastName}</td>
                                            <td className="hide-md">{user.userName}</td>
                                            <td className="hide-md">{user.groupUser.name}</td>
                                            <td className="p-2">
                                                <Button className="p-x-md-0" onClick={ e =>  me.selectUser(user)} size="sm">
                                                    <IconFa icon="pencil"/> {_("key_edit")}
                                                </Button>
                                            </td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>                    
                                <Paginator visible={me.state.currentUsers.length > 0} ref={ me._paginatorRef } pagination={me.state.pagination} onChange={ pagination => me.passPage(pagination) }/>
                            </CardBody>
                        </Card>
                    :
                        <UserForm onSave={ data => me.searchResult() } onClose={ user => me.onCloseHandler(user) } user={me.state.selectedUser} /> 
                    }
                </Fragment>
        );
    }
}