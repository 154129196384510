import React from "react";
import { _ } from "../../bl/admin/AdminLocaleBL";
import ComponentCommon from "../../core/ComponentCommon";
import { CheckListElement } from "../../entity/control/ControlEntity";
import CheckListProp from "../../entity/control/props/CheckListProp";
import CheckListState from "../../entity/control/states/CheckListState";
import GeneralFunctions from "../../helper/GeneralFunctions";

/**
 * CheckList component
 * @class CheckList
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class CheckList extends ComponentCommon<CheckListProp, CheckListState>{
    // Control identifier
    private _token: string = "";

    /**
     * Contructor
     * @param props Property 
     */
    constructor(props: CheckListProp) {
        super(props);
        this._token = GeneralFunctions.generateRoken();
        this.state = new CheckListState();
    }

    /**
     * On did mount
     */
    componentDidMount() {
        let me = this;
        me.setState({
            values: me.props.values
        });
    }

    /**
     * Handle onchange event
     * @param element Current changed element
     */
    private onChange(element: CheckListElement){
        let me = this;

        var elements = me.state.values.map( el => {
            if(el.value == element.value){
                el.checked = !element.checked;
            }
            return el;
        }); 

        me.setState({
            values: elements
        });

        me.props.onChange(elements);
    }

    /**
     * Control name
     */
    public get Name(){
        return this._token + "_" + (this.props.name ?? "");
    }

    render() {
        let me = this;
        return (
            <div className="checklist-container form-control mb-2">
                {me.props.values.map((item: CheckListElement, index) => 
                    <div key={index} className="media">
                        <div className={`text-end ${me.props.outline ? "switch-outline" : ""}`}>
                            <span className="d-block">
                                <input className="checkbox_animated" 
                                    type="checkbox"
                                    name={ me.Name + "_" + index + "_" + item.value }
                                    onChange={ e => me.onChange(item)}
                                    checked={ item.checked }
                                    />                
                            </span>
                        </div>
                        <label htmlFor={ me.Name + "_" + index + "_" + item.value  } onClick={ e => me.onChange(item)}>{item.label}</label>
                    </div>
                )}
                {me.props.values.length < 1 &&
                    <div className="text-center">
                        <span className="text-muted">{_("key_without_results")}</span>
                    </div>
                }
            </div>
        );
    }
}

export default CheckList;
