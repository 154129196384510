import React from 'react';
import ComponentCommon from "../../core/ComponentCommon";
import RequestFormProp from '../../entity/request/Props/RequestFormProp';
import RequestFormState from '../../entity/request/States/RequestFormState';
import { IconFa, Row, Col, Button, Card, CardBody, AlertBar } from '../../../cuba/components/utils/reactstrap';
import { RequestTraceElement } from './RequestTraceElement';
import LoginBL from '../../bl/login/LoginBL';
import { RequestState, RequestTrace } from '../../entity/request/RequestEntity';
import moment from 'moment';
import { User } from '../../entity/login/LoginEntity';
import RequestBL from '../../bl/request/RequestBL';
import Alert from '../utils/Alert';
import LoaderMini from '../utils/LoaderMini';
import { Empty } from '../utils/Empty';
import { _ } from '../../bl/admin/AdminLocaleBL';

/**
 * Manage requests from users
 * @class RequestForm
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class RequestForm extends ComponentCommon<RequestFormProp, RequestFormState> {

    /**
     * Constructor
     * @param props Property 
     */
    constructor(props: RequestFormProp){
        super(props);
        this.state = new RequestFormState();
    }

    /**
     * Component did mount
     */
    componentDidMount(){
        let me = this;
        me.setState({
            traces: me.props.request.trace,
            currentState: me.props.request.idRequestState
        });
        me.checkClosedState(me.props.request.state);
        me.loadStates();
    }

    /**
     * Get the user who has logged in
     */
    private get CurrentAgent(): User {
        return LoginBL.SessionData;
    }

    /**
     * Load the listing of request states available
     */
    private async loadStates(){
        let me = this;
        let states = await RequestBL.getRequestState();
        let values = {
            states: states
        };
        // Default state
        if(!me.state.currentState && states.length > 0){
            // Find default state: 0=Open
            states.forEach( state => {
                state.state == 0 && (values["currentState"] = states[0].id);
            });
        }
        me.setState(values);
    }

    /**
     * Verifica si el esta selecciona correponde a "cerrado" y poner en modo solo lectura
     * @param id 
     */
    private checkClosedState(state: RequestState){
        let me = this;

        if(state?.state == 1 || me.props.readOnly == true){
            me.setState({
                readOnly: true
            })
        }
        me.setState({
            currentState: state?.id
        });
    }

    /**
     * Change the status of the application
     * @param state State
     */
    private async setRequestState(state: RequestState){
        let me = this;
        let result = await Alert.confirm(_("key_request_state"), _("key_request_state_message"));
        if(result.isConfirmed){
            let request = await RequestBL.setRequestState(this.props.request, state);
            me.checkClosedState(request.state);
            // Success message
            Alert.success();
            // Notify parent
            me.props.onChange && me.props.onChange(me.props.request);
        }
    }

    /**
     * Search the closing state, and closes request
     */
    private closeRequest(){
        let me = this;
        let closeStateElement = null;
        me.state.states.forEach(state => {
            state.state == 1 && (closeStateElement = state);
        });
        closeStateElement && me.setRequestState(closeStateElement);
    }

    /**
     * Update traces
     */
    private async updateTraces(){
        let me = this;
        me.setState({busy: true});
        let traces = await RequestBL.getRequestTrace(me.props.request);
        me.setState({
            traces: traces,
            busy: false,
            traceContent: ""
        });
    }

    /**
     * Handle form submit
     * @param e Event
     */
    private async handleForm(e){
        let me = this;
        e.preventDefault();
        let result = await Alert.confirm(_("key_request_trace_save"), _("key_request_trace_save_message"));
        if(result.isConfirmed){
            let trace = new RequestTrace();
            trace.content = me.state.traceContent;
            trace.idUserAgent = me.CurrentAgent.id;
            trace.idChatsUsersInfo = me.props.request.id;
            trace = await RequestBL.updateRequestTrace(trace);
            me.updateTraces();
            Alert.success();
        }
    }

    /**
     * On close component
     */
    private handleClose(){
        let me = this;
        me.props.onClose && me.props.onClose();
    }

    render() {
        let me = this;
        return (
            <Card>
                <CardBody>
                    <Button onClick={ () => me.handleClose() } color="info" className="float-right ml-2">
                        {_("key_return")}
                    </Button>
                    { !me.state.readOnly &&
                        <Button onClick={ () => me.closeRequest() } color="primary" className="float-right">
                            <IconFa icon="close"/> {_("key_request_trace_close")}
                        </Button>
                    }
                    <h1>{me.props.request.userName}</h1>
                    <hr/>
                    <p>
                        {me.props.request.userRequest}
                    </p>
                    <hr/>
                    <Row className="text-center">
                        <Col sm="12" md="2">
                            <IconFa icon="phone"/> {me.props.request.userPhone}
                        </Col>
                        <Col sm="12" md="3">
                            <IconFa icon="envelope"/> {me.props.request.userEmail}
                        </Col>
                        <Col sm="12" md="3">
                            <IconFa icon="calendar"/> {me.props.request.CreatedDateText}
                        </Col>
                        <Col sm="12" md="2">
                            <a href={`/history/Dubai#id=${me.props.request.ChatTopic}`}><IconFa icon="comments"/> {me.props.request.ChatTopic}</a>
                        </Col>
                        {me.props.request.platform.length > 0 &&
                            <Col sm="12" md="2">
                                <b><IconFa icon={me.props.request.platform}/> {me.props.request.platform}: {me.props.request.profile}</b>
                            </Col>
                        }
                    </Row>
                    <hr/>
                    <div className="social-chat">
                        {me.state.traces.map( trace => 
                            <RequestTraceElement key={trace.id} trace={trace} />
                        )}
                        <Empty visible={me.state.traces.length < 1} message={_("key_request_trace_empty")}/>
                    </div>
                    <LoaderMini visible={me.state.busy} message={_("key_search_loading_results")}/>
                    <hr/>
                    {!me.state.readOnly &&
                    <form onSubmit={ e => me.handleForm(e) } className="comments-box">
                        <div className="media">
                            <img className="img-50 img-fluid m-r-20 rounded-circle" alt="" src={me.CurrentAgent.Avatar}/>
                            <div className="media-body">
                                {_("key_request_trace_message")}
                                <div className="input-group text-box">
                                    <input required={true} value={me.state.traceContent} onChange={ e => me.setState({traceContent: e.target.value}) }  className="form-control input-txt-bx" type="text" name="message-to-send" placeholder={_("key_request_trace_comment")}/>
                                </div>
                                <div className="input-group-append mt-2">
                                    <button className="btn btn-success" type="submit"><i className="fa fa-paper-plane"></i> {_("key_register")}</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    } 
                </CardBody>
                {/** CHAT DISPOSITIONS */}
                {/* !me.state.readOnly && 
                    <>
                    <hr/>
                    <div className="m-checkbox-inline custom-radio-ml">
                        <h4 className="text-primary form-check-inline ml-2">Estado de la solicitud: </h4>
                        { me.state.states && me.state.states.map( (state: RequestState) => 
                            <div key={state.id} className={`form-check form-check-inline radio radio-${state.id == me.state.currentState ? 'success': "primary"}`}>
                                <input onChange={ e => me.setRequestState(state) } 
                                checked={state.id == me.state.currentState} 
                                className="form-check-input" 
                                id={`label_disp_${state.id}_${me.props.request.id}`} 
                                type="radio" 
                                name={`chat_dispositions_${state.id}_${me.props.request.id}`}/>
                                <label className="form-check-label mb-0" htmlFor={`label_disp_${state.id}_${me.props.request.id}`}>{state.name}</label>
                            </div>
                        )} 
                    </div>
                    </>
                */}
            </Card>
        );
    }
}