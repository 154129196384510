import axios from "axios";
import React from "react";
import { _ } from "../../bl/admin/AdminLocaleBL";
import ComponentCommon from "../../core/ComponentCommon";
import { APIResponse } from "../../entity/common/CommonEntity";
import InputSearchProp from "../../entity/control/props/InputSearchProp";
import InputSearchState from "../../entity/control/states/InputSearchState";
import Reflection from "../../helper/Reflection";
import Alert from "../utils/Alert";
import { Empty } from "../utils/Empty";

/**
 * Control for Relationship Searches
 * @class InputSelector
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class InputSearch extends ComponentCommon<InputSearchProp, InputSearchState> {

    private _timeoutID = null;

    /**
     * Constructor
     * @param props Property
     */
    public constructor(props) {
        super(props);
        this.state = new InputSearchState();
    }

    /**
     * On did mount
     */
     componentDidMount() {
        let me = this;
        me.prepare();
    }

    /**
     * On unmount
     */
    componentWillUnmount(){
        let me = this;
        clearTimeout(me._timeoutID);
    }

    /**
     * Input search config
     */
    public get Config(): InputSearchConfig {
        let me = this;
        return me.props.config;
    }

    /**
     * Component for each element of the list (default ISDefault) 
     */
     public get Component() {
        let me = this;
        return me.props.component ? me.props.component : ISDefault;
    }

    /**
     * Entity for each element of the list (default ISDefault) 
     */
    public get Entity() {
        let me = this;
        return me.props.entity ? me.props.entity : ISData;
    }

    /**
     * Prepare default values
     */
    private async prepare() {
        let me = this;
        
        // Set default value
        var value = me.props.value;
        me.setValue(value);
    }

    /**
     * Get the search results through the specified configuration
     * @param id identifier (optional)
     * @returns List of results
     */
    public async getResults(id: number = 0): Promise<any[]> {
        let me = this;
        var config = me.Config;

        // Se parameters
        var params = {};
        
        if(id > 0){
            params["id"] = id;
        } else {
            params[config.parameter] = me.state.criteria;
        }
        
        var data = {
            params
        };

        var results = await axios.get(config.searchUrl, data)
        var response = results.data as APIResponse;
        if(!response.result && !response?.data?.status) {
            Alert.error(_("key_error_results"));
            return;
        }

        var entity = me.Entity;
        var arrayData = response.data.response;
        var elements = Reflection.parseEntityArray(arrayData, entity, (e) => {
            return me.setDefaults(e);
        });
        me.setState({
            results: elements,
            busy: false
        })
        return elements;
    }

    /**
     * Configure the default values for the component of the
     * results.
     * @param e 
     * @returns 
     */
    private setDefaults(e: any){
        let me = this;
        var config = me.Config;
        var options = config.defaultLabels;

        options.forEach( o => {
            e[o] && (e.label = e[o]);
        })

        e.code == "" && e.id > 0 && (e.code = "" + e.id);
        return e;
    }

    /**
     * Gets the identifier of the selected element
     * @returns 
     */
    public getValue(): number {
        let me = this;
        return me.state.selected?.id;
    }

    /**
     * Sets selected element
     * @returns 
     */
    public async setValue(value: number) {
        let me = this;
        if(value > 0){
            var result = await me.getResults(value);
            if(result.length == 1){
                me.setState({
                    selected: result[0]
                });
            }
        }
        me.props.onChange && me.props.onChange(value);
    }

    /**
     * Set the selected elements
     * @param item 
     */
    private handleSelect(item: any) {
        let me = this;
        me.setState({
            selected: item
        }, () => {
            me.props.onChange && me.props.onChange(me.getValue());
        });
    }

    /**
     * Control the results search events when the user 
     * is writing in the criteria field
     * @param e 
     */
    private handleChange(e){
        let me = this;
        var config = me.Config;

        me.setState({ 
            criteria: e.target.value,
            busy: true
        });

        clearTimeout(me._timeoutID);

        me._timeoutID = setTimeout(function() {
           if(me.state.criteria.length < 4) {
               me.setState({busy: false});
               return;
           } 
           me.getResults();
        }, config.timeout);
    }

    render() {
        let me = this;
        var Component = me.Component;
        return (
            <div className="inputsearch">
                <div className="mb-3">
                    {!me.state.selected ? <input 
                        onChange={ e => me.handleChange(e) } 
                        value={ me.state.criteria } 
                        className="form-control" 
                        type="text" 
                        placeholder={_("key_search_criteria")}
                        />
                    :
                        <div>
                            <div onClick={e=>me.handleSelect(null)} className="float-right input-search-close">
                                <i className="icon-close"></i>
                            </div>
                            <div className="inputsearch-result">
                                <Component data={me.state.selected} />
                            </div>
                        </div>
                    }
                </div>
                
                {!me.state.selected && me.state.criteria.length > 3 &&
                    <> 
                        <div className="input-search-list">
                            {me.state.busy &&
                                <div className="text-center">
                                    {_("key_search_loading_results")}
                                </div>
                            }
                            {me.state.results.map( (item, i) => 
                                <div key={ i } onClick={ e => me.handleSelect(item) } className="inputsearch-result">
                                    <Component data={item} />
                                </div>
                            )}
                            {!me.state.busy && me.state.results.length < 1 &&
                                <Empty message={_("key_without_results")}/>
                            } 
                        </div>
                    </>
                }
            </div>
        );
    }
}

/**
 * Default listing element of the component
 */
export const ISDefault = (props) => {
    var data = props.data as ISData;
    return (
        <div className={`alert`}>
            <b>{data?.code}</b> {data?.label}
        </div>
    );
}

/**
 * Default data format for the component
 */
export class ISData {
    public id: number = 0;
    public code: string = "";
    public label: string = "";
}

/**
 * Search settings for the component
 */
export class InputSearchConfig {
    public constructor(searchUrl: string, parameter: string = "criteria"){
        let me = this;
        me.searchUrl = searchUrl;
        me.parameter = parameter;
    }
    public defaultLabels = ["name", "note", "text", "content"];
    public searchUrl: string = "";
    public parameter: string = "criteria";
    public timeout: 4000;
}

export default InputSearch;