import React from 'react';
import Gravatar from 'react-gravatar';
import LoginBL from '../../bl/login/LoginBL';
import ChatStack from '../../core/component/chat/ChatStack';
import { ChatPersistence, ChatUserEvent } from '../../core/component/chat/ChatUtils';
import Config from '../../core/component/chat/config/Config';
import ComponentCommon from "../../core/ComponentCommon";
import { IconFa, Button, Row, Col } from '../../../cuba/components/utils/reactstrap'
import { ChatDisposition, ChatSuggestion, MBTemplate, MBTemplateComponent, UserInfo } from '../../entity/chat/ChatEntity';
import ChatBoxProp from '../../entity/chat/props/ChatBoxProp';
import ChatBoxState from '../../entity/chat/states/ChatBoxState';
import GeneralFunctions from '../../helper/GeneralFunctions';
import ChatTextBox from './ChatTextBox';
import ChatSuggestions from './ChatSuggestions';
import Alert from '../utils/Alert';
import moment from 'moment';
import ChatBL from '../../bl/chat/ChatBL';
import Mercure from '../../helper/Mercure';
import MercureListener from '../../helper/MercureListener';
import { _ } from '../../bl/admin/AdminLocaleBL';
import { Empty } from '../utils/Empty';
import Rate from '../control/Rate';
import MBirdBL from '../../bl/chat/MBirdBL';
import SecurityBL from '../../bl/security/SecurityBL';
import ChatTransfer from './ChatTransfer';
import { User } from '../../entity/login/LoginEntity';
import { ExpandPlugin } from '../control/textbox/plugins/ExpandPlugin';
import { MilesContext } from '../../context/MilesContext';
import Swal, { SweetAlertResult } from 'sweetalert2'
/**
 * ChatBox Main control: Conversations and History
 * @class ChatBox
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class ChatBox extends ComponentCommon<ChatBoxProp, ChatBoxState> {
    private _config: Config = null;
    private _chatStack: ChatStack = null;
    private _chatUserEvent: ChatUserEvent = null;
    private _eventSource: EventSource = null;
    private _baseUrlMercure: string = "";
    private _refChatContainer = null;
    private _refChatSearch = null;
    private _refChatTextbox = null;
    
    // Conversation start divider with agent
    private _agentPreMessage: any = null;
    private _agentStart: boolean = false;
    private _agentDelimiter: boolean = false;

    // Conversation start divider with agent tranference
    private _agentTransferPreMessage: any = null;
    private _agentTransferDelimiter: boolean = false;

    private static _dispositions: Array<ChatDisposition> = null;
    static contextType = MilesContext;
    private _expandState = null;
    private _dateAgentConnect = null

    /**
     * Constructor
     * @param prop 
     */
    constructor(prop: ChatBoxProp) {
        super(prop);
        let me = this;
        me.state = new ChatBoxState();
        me.prepare();
        me._expandState = React.createRef();
    }

    componentDidMount(){
        let me = this;
        me.setState({
            messages: me.props.chat.messages,
            closed: me.props.chat.IsClosed
        });

        me.notifyMessageChange();
        me.loadDispositions();
        me.listenMessages();
        me.loadTemplates();

        // Scroll to start
        setTimeout(function(){
            me.autoScrollToBottom();
        }, 10);

        setTimeout(function(){
            me.toggleExpand(false);
        }, 10);
    }

    componentWillUnmount(){
        let me = this;
        me.closeMercure();
        me.context.chat.Expand = false;
        me.resizeObserver?.disconnect();
    }

    private resizeObserver: ResizeObserver = null;

    /**
     * Observe columns (expand)
     */
    private observeColumns(){
        let me = this;
        const toObserve = document.getElementById("expand_container");
        if(toObserve){
            me.resizeObserver = new ResizeObserver(me.handleResize.bind(this));
            me.resizeObserver.observe(toObserve)
        }
       //window.addEventListener("resize", me.handleResize.bind(this));
    }

    /**
     * Unsubscribe
     */
    private closeMercure(){
        let me = this;
        let topic = me.props.chat.chatTopic;
        MercureListener.unsubscribe(topic);
    }

    /**
     * Gets the character count without HTML labels
     */
    private get TextareaLength(): number {
        let me = this;
        let div = document.createElement("div");
        div.innerHTML = me.state.message;
        return div.innerText.length;
    }

    /**
     * Get current agent data
     */
    private get CurrentAgent(){
        let me = this;
        let prop_agent = me.props.chat.agent;
        return prop_agent ? prop_agent : LoginBL.SessionData;
    }

    /**
     * Check if the current chat can be closed in the current session
     */
    private get CanClose(): boolean {
        let me = this;
        if(!me.props.readOnly) {
            return true;
        }

        // The supervisor can only close past chats 24 hours
        let minDate = moment().add(-24, 'hours').toDate();
        return minDate > me.props.chat.LastMessageDate;
    }

    /**
     * Prepare Chatbox component
     */
    private prepare(){
        let me = this;
        let topic = me.props.chat.chatTopic;
        me._config = Config.configuration;
        me._refChatContainer = React.createRef();    
        me._refChatSearch = React.createRef();    
        me._refChatTextbox = React.createRef();    
		me._baseUrlMercure = me._config.baseUrlMercure;
		me._chatStack = new ChatStack(topic, me._config.baseUrl);
		me._chatUserEvent = new ChatUserEvent(topic, LoginBL.SessionData.userName);
		me._chatUserEvent.on("on_read", e => {
            var messages = me.state.messages as any[];

            messages.forEach(m => {
                if(m["usrMessage"] !== "customer") {
                    m["datRead"] = GeneralFunctions.defineActualDateTime(); 
                }
            });

            me.setState({
                messages: messages
            })
		});
		me._chatUserEvent.on("on_remote_write_on", e => {
            me.setState({
                chatWritting: true
            })
		});
		me._chatUserEvent.on("on_remote_write_off", e => {
            me.setState({
                chatWritting: false
            })
		});

        // Handle expand event
        ExpandPlugin.addOnClickExpand(me.props.chat.chatTopic, function(){
            me.toggleExpand();
        });
    }

    /**
     * Show related rate comment
     */
    private showRateComment(){
        let me = this;
        Alert.show(_("key_related_comments"), me.props.chat.rate.comment, "info");
    }
    
    /**
     * Verify the beginning of the start of the agent conversation
     * @param message Current message
     * @returns TRUE: Show divider
     */
     private checkAgentDelimiter(message: any): boolean {        
        let me = this;
        if(me.state.messages[0] == message){
            me._agentPreMessage = null;
            me._agentStart = false;
            me._agentDelimiter = false;
        }

        let user_name = message["usrMessage"];

        // Detect when you start the Agent Conversation
        if(me._agentStart){
            me._agentDelimiter = false;
        }
        if(!me._agentStart && !("customer|chatbot".includes(user_name))) {
            me._agentStart = true;
            me._agentDelimiter = true;
        }
        
        if(!me._agentStart){
            me._agentPreMessage = message;
        }

        return me._agentStart && me._agentDelimiter;
    }

    /**
     * Verify the transfer of agent conversation
     * @param message Current message
     * @returns TRUE: Show divider
     */
    private checkTransferDelimiter(message: any): boolean {        
        let me = this;   
        if(me.state.messages[0] == message){
            me._agentTransferPreMessage = null;
            me._agentTransferDelimiter = false;
        }

        // Filter only agent messages
        let user_name = message["agtName"];
        let user_type = message["usrMessage"];
        if("customer|chatbot".includes(user_type)){
            return;
        }

        // Get last agent name (previus message)
        let pre_user_name = null;
        if(me._agentTransferPreMessage != null){
            pre_user_name = me._agentTransferPreMessage["agtName"];    
        }

        // Detect when you start the Agent Conversation
        if(me._agentTransferDelimiter){
            me._agentTransferDelimiter = false;
        }

        // Check agent change
        if(pre_user_name && pre_user_name != user_name) {
            me._agentTransferDelimiter = true;
            message["agtNameBefore"] = pre_user_name;
        }
        
        me._agentTransferPreMessage = message;

        return me._agentTransferDelimiter;
    }

    /**
     * Notifiy new message event!
     */
     private notifyMessageChange() {
        let me = this;
        let chat = me.props.chat;
        //chat = me.markAsRead(chat);
        me.props.onMessage && me.props.onMessage(me.props.chat);
    }

    /**
     * Get the list of dispositions (first time)
     */
    private async loadDispositions(){
        let me = this;
        if(!ChatBox._dispositions) {
            let dispositions = await ChatBL.getChatDispositions();
            ChatBox._dispositions = dispositions;
        } 
        let filtDisp = ChatBox._dispositions.filter( disp => disp.id == me.props.chat.disposition);
        let selectedDisposition = filtDisp.length > 0 ? filtDisp[0] : new ChatDisposition();

        me.setState({
            disposition: selectedDisposition,
            dispositions: ChatBox._dispositions
        })
    }

    /**
     * Get the list of dispositions (first time)
     */
    private async loadTemplates(){
        let me = this;

        let templates = await MBirdBL.getTemplates();
        me.setState({
            templates: templates,
            template: templates[0]
        })
    }

    /**
     * add New Message To Chat
     */
    private listenMessages() {
        let me = this;

        let topic = me.props.chat.chatTopic;

        MercureListener.subscribe(topic, data => {
            // data Destructuring
            let data_encoded = JSON.parse(data);
            let { userType, message, strIntName } = data_encoded;
            if(strIntName !== 'navigation'){
                // define actual Time
                let nowDate = GeneralFunctions.defineActualDateTime();
                // User type
                if (me._chatUserEvent.processEvent(data_encoded)) {
                    return;
                }
                let isCustomer = userType !== "agent";
                (isCustomer || me.props.readOnly) && me.addChatMSG(nowDate, userType, message);
            }
        });
    }

    /**
     * Add chat message (container)
     * @param nowDate Current date
     * @param usrMessage UserType
     * @param usrRequest Message
     */
    private addChatMSG(nowDate: string, usrMessage: string, usrRequest) {
        let me = this;
        let data = {
            datMessage: nowDate,
            usrMessage: usrMessage,
            strMessage: usrRequest
        }

        let messages = me.state.messages;
        messages.push(data);
        me.setState({
            messages: messages
        })

        me.notifyMessageChange();
        me.autoScrollToBottom();
    }

    /**
     * auto Scroll To Bottom
     */
    private autoScrollToBottom() {
        let me = this;
        let chatPanelContainer = $(me._refChatContainer.current);
        chatPanelContainer.scrollTop(chatPanelContainer.prop("scrollHeight") - 250);
	}

    /**
     * Display writting message, handler
     * @param text Text changed
     */
    private onChatWritting(text){
        let me = this;
        me._chatUserEvent.write();
    }

    /**
     * Display writting message, handler
     * @param text Text changed
     */
    private onChatChange(text){
        let me = this;
        me.setState({message: text })
    }

    /**
     * Mercure: Send message and change state
     * @param msg Message
     */
    private sendCurrentMessage(msg: string){
        let me = this;

        // Remove open tags (fix html)
        let container = document.createElement("div");
        container.innerHTML = msg;
        msg = container.innerHTML;

        me.sendRequestResponse(msg, "");

        // Empty textbox
        me.setState({
            message: ""
        })
    }

    /**
     * Close Topic
     */
    private async closeChatSession(disposition: any){
        let me = this;
        
        // if (formValues) {
        //     Swal.fire(JSON.stringify(formValues));
        // }
        
        let result = await Alert.typeSelect(disposition);
        
        // validate Finish Session
        if (result.isConfirmed) {
            let codeDisposition = result.value.dispositionText
            let ReservationNumber = result.value.reservationText
            await ChatBL.setChatDisposition(me.props.chat.chatTopic, codeDisposition, ReservationNumber);
            // Hide chatbox
            me.setState({
                visible: false,
                closed: true
            });

            // Stop eventSource
            me.closeMercure();

            // send Request Response
            me.sendRequestResponse("CloseSession", "intnet_closesession");

            // remove cookie
            ChatPersistence.remove(me.props.chat.chatTopic);

            // Call parent
            me.props.onClose && me.props.onClose(me.props.chat);
        }
    }

    /**
     * Transfer chat action
     */
    private transferChat(){
        let me = this;

        me.setState({
            chatTransfer: true
        });
    }

    /**
     * Download chat action
     */
    private downloadChat(){
        let me = this;
        ChatBL.downloadChat(me.props.chat.chatTopic);
    }

    /**
     * send Request Response
     */
    private sendRequestResponse(usrRequest: string, usrIntName: string = "") {

        // default Vars
        let me = this;
        let actChat = "";
        let usrMessage: string = "agent";
        let topic = me.props.chat.chatTopic;

        // validate Topic Chat And User String Length
        if (topic != "" && usrRequest.trim().length > 1) {				
            let nowDate = GeneralFunctions.defineActualDateTime();
            me.saveChatHistory(nowDate, usrMessage, usrRequest, false);
            Mercure.message(topic, usrMessage, usrRequest, usrIntName);
        }
    }

    /**
     * Display search-box for chat history
     * @param state State
     */
    private setChatFindState(state: boolean){
        let me = this;
        me.setState({
            chatFindState: state
        }, () => {
            state && me._refChatSearch.current.focus();
        });
    }
    
    /**
     * Set filter text for chat history
     * @param search Criteria
     */
    private filterChatMessages(search: string){
        let me = this;
        me.setState({
            chatFindText: search
        });
    }

    /**
     * save Chat History
     */
    private saveChatHistory(nowDate: string, userType: string, message: string, goToAgent: boolean) {
        let me = this;
        var agentName = LoginBL.SessionData.FullName;
        let messagesHistory = me._chatStack.registerChat(nowDate, userType, "standard", message, agentName);

        // add Object Message
        let messages = me.state.messages;
        messages.push(messagesHistory);
        me.setState({
            messages: messages
        });

        me.notifyMessageChange();
        me.autoScrollToBottom();
    }

    /**
     * Handle chat suggestion event
     * @param e ChatSuggestion
     */
    private handleChatSuggestion(e: ChatSuggestion) {
        let me = this;
        let chatTextBox = me._refChatTextbox.current;
        chatTextBox.setValue(e.suggestion);
    }

    /**
     * Handle chat transfer event
     * @param user 
     */
    private handleChatTransfer(user: User){
        let me = this;
        me.setState({
            chatTransfer: false
        });
    }

    /**
     * Handle chat start message
     */
    private sendStartMessage(){
        let me = this;
        let message = _("key_chat_template_default");
        let currentTemplate = me.state.template;

        if(currentTemplate == null){
            Alert.error("Selecciona una plantilla primero");
            return;
        }

        // Default body
        var body = currentTemplate.components.filter( t => t.type == "BODY").pop();
        message = body ? body.text : message;

        message = `[template=${currentTemplate.name}] ${message}`;

        // Send message
        me.sendCurrentMessage(message);
    }

    /**
     * Message list length
     */
    private get MessageLength(): number {
        return this.state.messages.length;
    }

    /**
     * Chatbox container reference
     */
    public get ChatBoxContainer(): any {
        let me = this;
        return document.getElementById(`chatbox_${me.props.chat.chatTopic}`);
    }

    /**
     * Toggle expand state
     */
    private toggleExpand(ignoreToggle = null){
        let me = this;
        
        let historyDom: any = me.ChatBoxContainer.querySelector(".chat-msg-box");
        // Ignore expand
        if(me.props.readOnly){
            historyDom.style.height= "525px";
            return;
        }

        const expand = ignoreToggle !== null ? ignoreToggle : !me.context.chat.Expand;
        let toxEditor = me.ChatBoxContainer.querySelector(".tox-tinymce") as HTMLDivElement;
        const floatingChat = me.ChatBoxContainer.querySelectorAll(".floating-chat-section")[0] as HTMLDivElement;
        const cardChat = document.querySelector(".chat-container-agent") as HTMLDivElement;

        me.context.chat.Expand = expand;
        me._expandState.current = expand;
        if(expand) {
            toxEditor.style.height = (cardChat.clientHeight - 70) + "px";
            floatingChat.style.height = cardChat.clientHeight + "px";
            me.handleResize();
            me.observeColumns();
        } else {
            toxEditor.style.height = "202px";
            floatingChat.style.width = null;
            floatingChat.style.left = null;
            floatingChat.style.height = null
            me.resizeObserver?.disconnect();
        }

        let heightHistory=0;
        me.ChatBoxContainer.childNodes.forEach((e:any) => {
            if(!(e == historyDom || (e == floatingChat && expand))) {
                heightHistory += e.getBoundingClientRect().height;
            }
        });

        let historyDomHeight = 0;
        if(heightHistory > 0){
            historyDomHeight = (cardChat.getBoundingClientRect().height - heightHistory)
        }else {
            historyDomHeight = 300;
        }

        historyDom.style.height= historyDomHeight + "px";
    }

    /** Handle resize event */
    private handleResize() {
        let me = this;
        if(me.context.chat.Expand){
            let float = me.ChatBoxContainer.querySelector(".floating-chat-section") as any;
            let expand = document.getElementById("expand_container");
            let data = expand.getBoundingClientRect();
            float.style.left=data.x+"px";
            float.style.width=data.width+"px";
        }
        if(me.context.chat.Expand !== me._expandState.current){
            me.toggleExpand(false);
        }
    }

    // private formatDateConnectAgent() {
    //     let me = this;
    //     let date = GeneralFunctions.convertUTCDateToLocalDate(moment(me.props.chat.dateAgentConnect, "DD/MM/YYYY hh:mm:ss").toDate());
    //     me._dateAgentConnect = date;
    // }
    
    render(){
        let me = this;
        let dateAgentConnect = null;
        if (me.props.chat.dateAgentConnect != null) {
            dateAgentConnect = new Date(me.props.chat.dateAgentConnect)
        }

        return (
            me.state.visible ? <>
            <div className="chat" id={`chatbox_${me.props.chat.chatTopic}`}>
                <div className="chat-header clearfix">
                    <Gravatar email={ me.props.chat.chatTopic } />
                    <div className="about">
                        <div className="name">
                            <b>{_("key_chat_topic")}: </b>{me.props.chat.ChatTopic} 
                            {me.state.chatFindText.length > 0 && 
                                <span className="ml-2"> 
                                    <i onClick={ () => me.filterChatMessages("") } className="fa fa-close text-danger"></i>
                                    <span className="text-info ml-2">{_("key_filter_by")} </span>"{me.state.chatFindText}"
                                </span>
                            }
                            {me.props.chat.rate?.comment?.length > 0 &&
                                <a href="#" className="font-primary ml-2" onClick={ () => me.showRateComment() }>{_("key_see_comments")}</a>
                            }
                        </div>
                        <div className="status digits" >
                            <b className="badge badge-pill badge-primary">{me.props.chat.language}</b>
                            {me.props.chat.Online ? <b className="badge badge-pill badge-success">{_("key_online")}</b> : <span className="ml-2 mr-2">{me.props.chat.FirstMessageDateText}</span>}
                        </div>
                        {me.props.chat.idRate > 0 &&
                            <Rate readonly={true} title={_("key_rate")} value={me.props.chat.rate.rate} />
                        }
                    </div> 
                    <ul className="list-inline float-left float-sm-right chat-menu-icons">
                        {/* CHAT DOWNLOAD */}
                        {!me.state.chatFindState && SecurityBL.checkGranted("ChatDownload") && 
                            <li className="list-inline-item">
                                <a onClick={ () => me.downloadChat() } title={_("key_chat_download")}><i className="fa fa-download"></i></a>
                            </li>
                        }
                        {/* CHAT TRANSFER */}
                        {!me.state.chatFindState && !me.state.closed && SecurityBL.checkGranted("ChatTransfer") && 
                            <li className="list-inline-item">
                                <a onClick={ () => me.transferChat() } title={_("key_chat_transfer")}><i className="fa fa-users"></i></a>
                            </li>
                        }
                        {/* SEARCH MESSAGES */}
                        <li className="list-inline-item">
                            {!me.state.chatFindState ? 
                                <a onClick={ () => me.setChatFindState(true) }><i className="icon-search"></i></a>
                                : 
                                <input value={ me.state.chatFindText } onChange={ e => me.filterChatMessages(e.target.value) } ref={ me._refChatSearch } onBlur={ () => me.setChatFindState(false) } className="form-control" type="text" placeholder={_("key_chat_find_conversation")} data-bs-original-title="" title=""/>
                            }
                        </li>
                        {/*<li className="list-inline-item"><a href="#javascript"><i className="icon-clip"></i></a></li>
                        <li className="list-inline-item"><a href="#javascript"><i className="icon-headphone-alt"></i></a></li>
                        <li className="list-inline-item"><a href="#javascript"><i className="icon-video-camera"></i></a></li>*/}
                        {!me.state.chatFindState && me.CanClose && 
                            <li className="list-inline-item">
                                <a onClick={ () => me.closeChatSession(me.state.dispositions) }><i className="icon-close"></i></a>
                            </li>
                        }
                        <li className="list-inline-item toogle-bar" onClick={() => {} /*chatMenuToggle()*/ }><a href="#javascript"><i className="icon-menu"></i></a></li>
                    </ul>
                </div>
                <div className="pl-3">                                             
                    {me.props.chat.session?.country && <i title={me.props.chat.session?.country} className={`flag-icon ml-2 mr-2 flag-icon-` + me.props.chat.session?.country.toLowerCase()}></i>}
                    {me.props.chat.session?.location && <><i className="fa fa-map-marker"></i> {me.props.chat.session?.location}</>}
                    {me.props.chat.platform.length > 0 &&
                        <>
                            <b><i className={`fa ml-1 fa-${me.props.chat.platform}`}></i> {me.props.chat.platform}: </b>
                            {me.props.chat.profile}
                        </>
                    }
                    {me.props.chat.session?.device && <><i className="fa ml-1 fa-laptop"></i> {me.props.chat.session?.device} </>}
                    {me.props.chat.session?.browser && <><i className="fa ml-1 fa-window-restore"></i> {me.props.chat.session?.browser} </>}
                    {me.props.chat.session?.os && <><i className="fa ml-1 fa-server"></i> {me.props.chat.session?.os} </>}
                    {me.props.chat.session?.domain && <><i className="fa ml-1 fa-globe"></i> {me.props.chat.session?.domain} </>}
                </div>
                {/** CHAT DISPOSITIONS */}
                    { me.props.readOnly && me.state.disposition && me.state.disposition.name &&
                        <div className="m-checkbox-inline custom-radio-ml mb-2 mt-1">
                            <b className="form-check form-check-inline mb-2">{_("key_chat_disposition")}: </b>
                            <label className="form-check-label mb-0" >{me.state.disposition.name}</label>
                        </div>
                    }
                <div ref={me._refChatContainer} className={`chat-history chat-msg-box ${me.props.readOnly /*|| me.state.expand*/ ? "chat-msg-box-readonly" : ""} custom-scrollbar`}>
                    <ul>
                        {/** CHAT USER INFO (REQUESTS FORM) */}
                            {me.props.chat.info.map((userInfo: UserInfo) => {
                                let createdDate = userInfo.CreatedDateText;
                                return (<li className="card p-2 mb-1 browser-widget" key={userInfo.id}>
                                    <div className="media-body align-self-center">
                                        <div>
                                            <a href={`/request/Dubai#id=${userInfo.id}`} >
                                                <b><IconFa icon="hashtag"/>: </b> {userInfo.id}
                                            </a>
                                            <div><b><IconFa icon="calendar" /> {_("key_created")}: </b> {createdDate}</div>
                                        </div>
                                        <div>
                                            <div><b><IconFa icon="user" /> {_("key_username")}: </b> {userInfo.userName}</div>
                                            <div><b><IconFa icon="phone" /> {_("key_phone")}: </b> {userInfo.userPhone}</div>
                                        </div>
                                        <div>
                                            <div><b><IconFa icon="envelope" /> {_("key_message")}: </b> {userInfo.userRequest}</div>
                                        </div>
                                    </div>
                                </li>);
                            }
                        )}
                        {me.MessageLength < 1 &&
                            <>
                                <h5 className="text-center">{_("key_first_message")}</h5>
                                <Row>
                                    <Col sm="12" md="3"></Col>
                                    <Col sm="12" md="6" className="text-center">
                                        <select 
                                            value={me.state.template?.id}
                                            className="form-control mb-2" 
                                            onChange={e => me.setState({template: me.state.templates.filter( t => t.id == e.target.value ).pop() })}>
                                            {me.state.templates.map( (template: MBTemplate) =>
                                                <option key={template.id} value={template.id}>[{template.language}] {template.name}</option>
                                            )}
                                        </select>
                                        <Button onClick={ () => me.sendStartMessage() }>
                                            <IconFa icon="send mr-2"/> {_("key_send_template")}
                                        </Button>
                                    </Col>
                                    <Col sm="12" md="3"></Col>
                                </Row>
                                {me.state.template &&
                                    <div className="cal-desc text-center card-body">
                                        <h6 className="f-w-600">{me.state.template.name.toUpperCase()}</h6>
                                        {me.state.template?.components.map( (component: MBTemplateComponent, i) =>
                                            <p key={i} className="text-muted mt-3 mb-0">
                                                <b>{component.type}:</b> {component.text}
                                            </p>
                                        )}
                                    </div>
                                }
                            </>
                        }
                        {me.state.messages.map(e => {                            
                            let user_name = e["usrMessage"];
                            let is_customer = user_name === "customer";
                            let chat_agent = me.CurrentAgent;
                            user_name = user_name === "agent" ? (chat_agent ? chat_agent.firstName : user_name) : user_name;
                            
                            // Stored agent user name
                            if(e["agtName"]) {
                                user_name = e["agtName"];
                            }
                            let before_user_name = e["agtNameBefore"];

                            // Styles
                            let box_class = `message my-message${is_customer ? '-client' : ''} ${is_customer ? '' : 'float-right'}`;

                            // Filter messages
                            let filter_hide = me.state.chatFindText.length > 2 && !(e.strMessage.toLowerCase().includes(me.state.chatFindText.toLowerCase()));
                            
                            // datRead (convert from utc)
                            let datMessage = GeneralFunctions.convertUTCDateToLocalDate(moment(e["datMessage"], "DD/MM/YYYY hh:mm:ss").toDate());
                            // Read date
                            let dateMessage = moment(datMessage);

                            // datRead (convert from utc)
                            let datRead = GeneralFunctions.convertUTCDateToLocalDate(moment(e["datRead"], "DD/MM/YYYY hh:mm:ss").toDate());
                            // Read date
                            let readDate = moment(datRead);

                            let index = me.state.messages.indexOf(e);
                            let nextMessage = me.state.messages[index + 1];
                            let transfer = me.props.chat.transfer.filter( t => {
                                if(nextMessage){
                                    let datMessage2 = GeneralFunctions.convertUTCDateToLocalDate(moment(nextMessage["datMessage"], "DD/MM/YYYY hh:mm:ss").toDate());
                                    return datMessage < t.CreatedDateDate && datMessage2 > t.CreatedDateDate;
                                }
                                return datMessage < t.CreatedDateDate;
                            }); 
                            return (
                                <React.Fragment key={Math.random()}>
                                    {/* Only show in realtime mode */}
                                    {me.checkAgentDelimiter(e) &&
                                        <div className="h5 m-4 text-center">
                                            <span className="badge badge-pill badge-info">{_("key_connect_to_agent")} {dateAgentConnect != null && moment(dateAgentConnect).format("DD/MM/YYYY H:mm:ss")}</span>
                                        </div>
                                    }
                                    {!me.props.readOnly && me.checkTransferDelimiter(e) &&
                                        <div className="h5 m-4 text-center">
                                            <span className="badge badge-pill badge-success">{_("key_agent_transfer", {agent_source: before_user_name, agent_target: user_name})}</span>
                                        </div>
                                    }
                                    <li className={`clearfix ${filter_hide ? 'd-none' : '' }`}>
                                        <div className={box_class}>
                                            {!is_customer && 
                                                <div className={`message-data text-${is_customer ? "left" : "right"}`}>
                                                    <b className={`chat-message-${e["usrMessage"]}`}>{user_name}: </b> 
                                                </div>
                                            }
                                            {e["strMessage"] == 'standard_form_contact_chat' &&
                                              <span className="message-data-text"
                                                    dangerouslySetInnerHTML={{__html: _("key_form_contact_sent")}}></span>
                                              ||
                                              <span className="message-data-text"
                                                    dangerouslySetInnerHTML={{__html: e["strMessage"]}}></span>
                                            }
                                            <span className="message-data-time">
                                                {dateMessage.format("DD/MM/YYYY H:mm:ss")}
                                                {!is_customer && e["datRead"] && readDate.year() > 1 &&
                                                    <span className="ml-2 text-primary" title={_("key_reading_check") + ": " + readDate.format("DD/MM/YYYY H:mm:ss")}>
                                                        <IconFa icon="check"/>
                                                    </span>
                                                }
                                            </span>
                                        </div>
                                    </li>
                                    {/* Show only in reading mode */}
                                    {me.props.readOnly && transfer.map(t => 
                                        <div className="h5 m-4 text-center">
                                            {t.sourceName ?
                                                <span title={t.CreatedDateText} className="badge badge-pill badge-success">{_("key_agent_transfer", {agent_source: t.sourceName, agent_target: t.destinationName})}</span>
                                                : 
                                                <>{/*<span className="badge badge-pill badge-info">{_("key_connect_to_agent")}</span>*/}</>
                                             }
                                        </div>
                                    )}
                                </React.Fragment>
                            )
                        })}
                        {/* WAITING RESPONSE MESSAGE */}
                        {me.MessageLength == 1 &&
                            <div className="h5 m-4 text-center">
                                <span className="badge badge-pill badge-info">{_("key_chat_waiting_response")}</span>
                            </div>
                        }
                    </ul>
                    {me.props.readOnly && me.state.chatWritting && <div className="chat_writting"><b>{_("key_chat_user_writting")}</b></div>}
                </div>
                <div className={`${me.props.readOnly?"":"chat-message"} clearfix floating-chat-section ${me.context.chat.Expand ? "floating-chat" : ""}`}>
                    {!me.state.closed && me.state.chatTransfer && 
                        <ChatTransfer 
                            chat={me.props.chat} 
                            onClose={ () => me.setState({chatTransfer: false}) } 
                            onChange={ agent => me.handleChatTransfer(agent)}
                            />
                    }
                    {!(me.state.closed || me.props.readOnly) && me.MessageLength > 1 && 
                    <div className="chat_contenedor_input">
                        <ChatSuggestions chat={me.props.chat} onSelect={ e => me.handleChatSuggestion(e) } language={ me.props.chat.language } />
                        <form name="form_chat">
                            {me.state.chatWritting && <div className="chat_writting"><b>{_("key_chat_user_writting")}</b></div>}
                            <ChatTextBox 
                                id={me.props.chat.chatTopic} 
                                ref={ me._refChatTextbox } 
                                onEnter={ msg => { me.sendCurrentMessage(msg) } } 
                                onKeyPress={ msg => me.onChatWritting(msg) } 
                                onChange={ msg => me.onChatChange(msg) }
                                spellLanguage={me.props.chat.language}
                                >
                            </ChatTextBox>
                        </form>
                        <div className="chat_contenedor_input">
                            <span className="spn_topic">{_("key_chat_chars_count")}: {me.TextareaLength} {_("key_chat_chars")}</span>
                        </div>
                    </div>
                    }
                </div>
            </div>
            </>
            : <></>
        );
    }
}

export default ChatBox;