import React from 'react';
import { /*withRouter ,*/ RouteComponentProps, withRouter } from "react-router-dom";
import ComponentCommon from "../../core/ComponentCommon";
import ResetPasswordProp from "../../entity/login/props/ResetPasswordProp";
import ResetPasswordState from "../../entity/login/states/ResetPasswordState";
import {MilesLogo,} from '../../constants/common';
import {Form,Row,Col,Button,TabPane,TabContent} from '../../../cuba/components/utils/reactstrap'
import LoginBL from '../../bl/login/LoginBL';
import SecurityBL from '../../bl/security/SecurityBL';
import { _ } from '../../bl/admin/AdminLocaleBL';

/**
 * Displays the application box to update the password for a safer
 * @class ResetPassword
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class ResetPassword extends ComponentCommon<ResetPasswordProp & RouteComponentProps, ResetPasswordState>{

    public constructor(prop: ResetPasswordProp & RouteComponentProps){
        super(prop);
        this.state = new ResetPasswordState();
    }

    /**
     * Gets the current password rating
     */
    private get PasswordRate(): number {
        let me = this;
        return SecurityBL.getPasswordStrongLevel(me.state.password);
    }

    /**
     * Get the labels for the password rating
     */
    private get PasswordLevel(){
        let messages = {
            0: {
                style: "info",
                message: _("key_password_different")
            },
            1: {
                style: "danger",
                message: _("key_password_level_1")
            },
            2: {
                style: "warning",
                message: _("key_password_level_2")
            },
            3: {
                style: "success",
                message: _("key_password_level_3")
            }
        }
        return messages;
    }

    /**
     * Verify that if you have pressed the ENTER key
     * @param event Event handler
     */
    private checkEnter(event){
        let me = this;
        if(event.key === 'Enter'){
            me.changePassword();
        }
    }

    /**
     * Displays the current error message
     * @param message Error message
     */
    private setError(message: string){
        let me = this;
        me.setState({
            error: message
        })
    }

    /**
     * Updates the data of the two passwords in the state and get tags
     * @param event Event handler
     * @param name Input name
     */
    private updatePassword(event, name){
        let me = this;
        let options = {};
        let value = event.target.value;
        
        // Update state
        options[name] = value;

        me.setState(options, () => {
            // Get password rate
            let passwordRate = me.PasswordRate + 1;
            passwordRate = me.state.password == me.state.passwordConfirm ? passwordRate : 0;
            me.setState({
                passwordLevel: me.PasswordLevel[passwordRate]
            })
        });
    }

    /**
     * Take the request to the API to change the password.
     * @param e Event handler (optional)
     */
    private async changePassword(e = null){
        let me = this;
        e && e.preventDefault();

        // Email URL recovery
        let currentUserId = me.props.match.params["id"] ?? 0;

        if(me.state.password == me.state.passwordConfirm && me.PasswordRate > 0){
            // Reset password
            let resultChangePass = null;
            me.setState({busy: true});
            // If email URL recovery (id)
            if(currentUserId > 0){
                resultChangePass =  await LoginBL.changePassword(currentUserId, me.state.password);
            } else {
                resultChangePass =  await LoginBL.resetPassword(me.state.password);
            }

            if(!resultChangePass) {
                me.setError(_("key_password_error"));
            } else if(me.props.onClose) {
                // Call parent
                me.props.onClose();
            } else {
                // For email URL recovery
                me.setState({
                    error: "",
                    password: "",
                    passwordConfirm: "",
                    canLogin: currentUserId > 0,
                    passwordLevel: null,
                    success: true
                });
            }
            me.setState({busy: false});
        }
    }

    render(){
        let me = this;
        return (
            <Row className="m-0">
                <Col xs="12" className="p-0">     
                    <div className="login-card">
                    <div>
                        <div>
                        <a className="logo">
                            <img className="img-fluid for-light" src={MilesLogo} alt=""/>
                            <img className="img-fluid for-dark" src={MilesLogo} alt=""/>
                        </a>
                        </div>
                        <div className="login-main login-tab"> 
                            <TabContent activeTab={"login"} className="content-login">
                                <TabPane  className="fade show" tabId={"login"}>
                                    <Form disabled={me.state.busy} onSubmit={ (e) => this.changePassword(e) } className="theme-form">
                                        <h4>{_("key_password_change")}</h4>
                                        <p>{_("key_password_description")}</p>
                                        <div className="form-group">
                                            <label className="col-form-label">{_("key_new_password")}</label>
                                            <input disabled={me.state.success} onKeyPress={ e => me.checkEnter(e) } onChange={ e => { me.updatePassword(e, 'password') } } required={true} type="password" className="form-control form-control"/>
                                        </div>
                                        <div className="form-group">
                                            <label className="col-form-label">{_("key_password_confirm")}</label>
                                            <input disabled={me.state.success} onKeyPress={ e => me.checkEnter(e) }  onChange={ e => { me.updatePassword(e, 'passwordConfirm') } } required={true} type="password" className="form-control form-control"/>
                                        </div>
                                        <div className="form-group mb-0">
                                            <div>
                                                <Button disabled={me.state.busy} type="submit" color="primary" className="btn-block" >{_("key_password_change")}</Button>
                                            </div>
                                            {me.state.canLogin &&
                                                <div>
                                                    <a  href="/" className="btn-block btn btn-secondary mt-2" >{_("key_login")}</a>
                                                </div>
                                            }
                                        </div>

                                        {me.state.passwordLevel &&
                                            <div>
                                                <h5 className={`text-${me.state.passwordLevel["style"]} text-center m-2`}>{me.state.passwordLevel["message"]}</h5>
                                            </div>
                                        }

                                        {me.state.success &&
                                            <div>
                                                <h5 className="text-success text-center m-2">{_("key_alert_success_message")}</h5>
                                            </div>
                                        }

                                        {me.state.error.length > 0 && 
                                            <div>
                                                <h5 className="text-danger text-center m-2">{me.state.error}</h5>
                                            </div>
                                        }
                                    </Form>
                                </TabPane>
                            </TabContent>
                        </div>
                    </div>
                    </div>
                </Col>
            </Row>
        );
    }
}

export default withRouter(ResetPassword);