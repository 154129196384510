import React, { Fragment } from 'react';
import { Container, Row, Col, Button } from 'reactstrap'
import { Link } from 'react-router-dom'
const Footer = (props) => {
  return (
    <Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md="12" className="footer-copyright text-center">
              <p className="mb-0">{"Copyright 2020 © Cuba theme by pixelstrap."}</p>
              {/*<Link to={`/login`}><Button>Aca</Button></Link>
              <Link to={`/chat/Dubai`}><Button>CHAT</Button></Link>*/}
            </Col>
          </Row>
        </Container>
      </footer>
    </Fragment>
  );
}

export default Footer;