import axios from 'axios';
import BaseBL from '../../core/BaseBL';
import { PaginatedReponse, PaginatedRequest, Pagination } from '../../entity/control/ControlEntity';
import { Locale, LocaleTranslate } from '../../entity/locale/LocaleEntity';
import { UserLanguage } from '../../entity/login/LoginEntity';

/**
 * Get and manage translations
 * @class LocaleBL
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class LocaleBL extends BaseBL {
    private static _languages: Array<UserLanguage> = [];

    /**
     * Stored languages
     */
    public static get StoredLanguages(): UserLanguage[] {
        let me = this;
        return me._languages;
    }

    /**
     * Gets a listing page of translations
     * @param criteria Criterio de búsqueda
     * @returns 
     */
    public static async getLocale(paramsData: PaginatedRequest){
        let me = this;
        let data = {
            params: paramsData
        };
        let response = await axios.get('/Home/LocaleAPI/ListLocale', data);
        let responseData = me.getResponseData(response);
        let result = me.parseEntity(responseData, PaginatedReponse);

        result.data = me.parseEntityArray(result.data, Locale, (locale: Locale) => {
            locale.translates = me.parseEntityArray(locale.translates, LocaleTranslate);
            return locale;
        });
        result.pagination = me.parseEntity(result.pagination, Pagination);
        return result;
    }

    /**
     * Get List of Languages
     * @returns UserLanguage Languages
     */
    public static async getLanguages(){
        let me = this;
        if(me._languages.length > 0){
            return me._languages;
        }
        let response = await axios.get('/Home/LocaleAPI/GetLanguages');
        let responseData = me.getResponseData(response);
        let languageList: Array<UserLanguage> = [];
        languageList = me.parseEntityArray(responseData, UserLanguage);
        me._languages = languageList;
        return languageList;
    }

    /**
     * Save the specified static content
     * @param locale Translation
     * @returns Updated object
     */
    public static async updateLocale(locale: Locale){
        let me = this;
        let formData = me.getEntityFormData(locale);
        let response = await axios.post('/Home/LocaleAPI/UpdateLocale', formData);
        let responseData = me.getResponseData(response);
        let result = me.parseEntity(responseData, Locale);
        return result;
    }

    /**
     * Save the specified translation
     * @param localeTranslate Translation of static content
     * @returns Updated object of the translation
     */
    public static async updateLocaleTranslate(localeTranslate: LocaleTranslate){
        let me = this;
        let formData = me.getEntityFormData(localeTranslate);
        let response = await axios.post('/Home/LocaleAPI/UpdateLocaleTranslate', formData);
        let responseData = me.getResponseData(response);
        let result = me.parseEntity(responseData, LocaleTranslate);
        return result;
    }
}