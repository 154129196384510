import React, { Fragment } from 'react';
import { Card, CardBody, CardHeader, Form, Button, Row, Col, IconFa } from '../../cuba/components/utils/reactstrap'
import PageCommon from '../core/PageCommon';
import Paginator from '../component/control/Paginator';
import { _ } from '../bl/admin/AdminLocaleBL';
import { PaginatedRequest, Pagination } from '../entity/control/ControlEntity';
import LoaderMini from '../component/utils/LoaderMini';
import ChannelPageProp from '../entity/channel/props/ChannelPageProp';
import ChannelPageState from '../entity/channel/states/ChannelPageState';
import { Channel } from '../entity/channel/ChannelEntity';
import ChannelForm from '../component/channel/ChannelForm';
import ChannelBL from '../bl/channel/ChannelBL';

/**
 * ChannelPage (User admin)
 * @class ChannelPage
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class ChannelPage extends PageCommon<ChannelPageProp, ChannelPageState> {
    //Paginator reference
    private _paginatorRef = null;

    /**
     * Constructor
     * @param props Property 
     */
    public constructor(props: ChannelPageProp){
        super(props);
        this.state = new ChannelPageState();
    }

    /**
     * Component did mount
     */
    componentDidMount() {
        let me = this;
        me._paginatorRef = React.createRef();
        me.searchResult();
    }

    /**
     * Select a channel and displays the preview
     * @param channel 
     */
    private selectChannel(channel: Channel){
        let me = this;
        me.setState({
            channel: channel
        });
    }

    /**
     * Make the query by page
     */
     private async searchResult(resetCount: boolean = false){
        let me = this;

        let request = new PaginatedRequest<any>();
        request.pagination = me.state.pagination;
        request.data.criteria = me.state.criteria;

        me.setState({loadingChannels:true});
        let data = await ChannelBL.getChannel(request);
        
        me.setState({
            loadingChannels: false,
            channels: data.data,
            pagination: resetCount ? data.pagination.setCurrentPage(1) : data.pagination
        }, () => {
            me._paginatorRef?.current?.update()
        });
    }

    /**
     * Update pagination state
     * @param pagination Pagination object
     */
     public passPage(pagination: Pagination){
        let me = this;
        me.setState({
            pagination: pagination
        }, () => {
            me.searchResult();
        });
    }

    /**
     * Handle form submit
     * @param e Event
     */
    private handleFormSubmit(e){
        e.preventDefault();
        let me = this;
        me.searchResult(true);
    }

    /**
     * Handle form close
     */
    private handleClose(){
        let me = this;

        me.setState({
            channel: null
        });
        me.searchResult();
    }

    /**
     * Create group
     */
    private createGroup(){
        let me = this;
        let newChannel = new Channel();
        me.setState({
            channel: newChannel
        })
    }

    render() {
        let me = this;
        return (
            <Fragment>
                <Row>
                    <Col sm={12} md={1}></Col>
                    <Col sm={12} md={10}>
                        { !me.state.channel ? 
                            <Card>
                                <CardHeader>
                                    
                                    <Row>
                                        <Col md="6">
                                            <h5>{_("key_mod_page_channels")}</h5> 
                                        </Col>
                                        <Col md="6">
                                            <Button color="success" className="float-right mt-3 mt-md-0" onClick={ e => me.createGroup() }>
                                                <IconFa icon="plus"/> {_("key_create_new")}
                                            </Button>
                                        </Col>
                                    </Row>               
                                </CardHeader>
                                <CardBody> 
                                    <Form onSubmit={ e => me.handleFormSubmit(e) } className="mb-3">
                                        <Row>
                                            <Col md="6">
                                                <input id="criteria_input" className="form-control" value={me.state.criteria} onChange={ e => me.setState({criteria: e.target.value })} placeholder={_("key_search_criteria")}/>
                                            </Col>
                                            <Col md="6">
                                                <button className="btn btn-primary mt-3 mt-md-0 float-right" type="submit">
                                                    <IconFa icon="search"/> {_("key_filter_results")}
                                                </button>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <table className="table table-m-2">
                                        <thead>
                                            <tr>
                                                <th scope="col">{_("key_name")}</th>
                                                <th scope="col">{_("key_profile")}</th>
                                                <th scope="col">{_("key_platform")}</th>
                                                <th scope="col">{_("key_site")}</th>
                                                <th scope="col">{_("key_state")}</th>
                                                <th scope="col">{_("key_actions")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {me.state.loadingChannels &&
                                            <tr>
                                                <td colSpan={6}>
                                                    <LoaderMini message={_("key_search_loading_results")}/>     
                                                </td>
                                            </tr>
                                        }
                                        {!me.state.loadingChannels && me.state.channels.map( (channel: Channel) => 
                                            <tr key={channel.id}>
                                                <td>{channel.name}</td>
                                                <td>{channel.profile}</td>
                                                <td>{channel.platform}</td>
                                                <td>{channel.siteCode}</td>
                                                <td>{channel.enabled ? _("key_enabled") : _("key_disabled")}</td>
                                                <td className="p-2 actions">
                                                    <Button className="p-x-md-0" onClick={ e =>  me.selectChannel(channel)} size="sm">
                                                        <IconFa icon="pencil"/> {_("key_edit")}
                                                    </Button>
                                                </td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>                    
                                    <Paginator 
                                        visible={!me.state.loadingChannels && me.state.channels.length > 0} 
                                        ref={ me._paginatorRef } 
                                        pagination={me.state.pagination} 
                                        onChange={ pagination => me.passPage(pagination) } />
                                </CardBody>
                            </Card>
                        :
                            <ChannelForm onClose={ e => me.handleClose() } channel={me.state.channel} />
                        }
                    </Col>
                    <Col sm={12} md={1}></Col>
                </Row>
            </Fragment>
        );
    }
}

export default ChannelPage;
