import ComponentCommon from "../../../core/ComponentCommon";
import ApiTinyTokenFormProp from "../../../entity/api/tiny/props/ApiTinyTokenFormProp";
import ApiTinyTokenFormState from "../../../entity/api/tiny/states/ApiTinyTokenFormState";
import React from "react";
import LoaderBackdrop from "../../utils/LoaderBackdrop";
import {_} from "../../../bl/admin/AdminLocaleBL";
import { Card, CardHeader, CardBody, FormGroup, IconFa, Row, Col } from '../../../../cuba/components/utils/reactstrap';
import Alert from "../../utils/Alert";
import ApiTinyBL from "../../../bl/api/tiny/ApiTinyBL";
import Switch from "../../control/Switch";

/**
 * Update API Tiny Token
 * @class ApiTinyTokenForm
 * @author Juan Serrano <jserrano@viajemos.com>
 */
export default class ApiTinyTokenForm extends ComponentCommon<ApiTinyTokenFormProp, ApiTinyTokenFormState> {

    /**
     * Constructor
     * @param props Property
     */
    constructor(props: ApiTinyTokenFormProp){
        super(props);
        let me = this;
        me.state = new ApiTinyTokenFormState();
    }
    
    /**
     * Component did mount
     */
    public componentDidMount(){
        const me = this;
        me.prepare();
    }

    /**
     * Initialize Component
     */
    private prepare(){
        let me = this;
        me.setState({
            apiTinyToken: me.props.apiTinyToken
        });
    }

    /**
     * Handle form submit
     * @param e React.FormEvent<HTMLFormElement>
     */
    private async handleSubmit(e:  React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        let me = this;
        let apiTinyToken = me.state.apiTinyToken;

        me.setState({busy: true});

        // Save changes...
        apiTinyToken = await ApiTinyBL.updateToken(apiTinyToken);

        me.props.onChange && me.props.onChange(apiTinyToken);
        Alert.success();
        me.setState({
            busy: false,
            apiTinyToken
        });
    }

    /**
     * Event handler for inputs
     * @param event React.ChangeEvent<HTMLInputElement>
     */
     private handleChange(event:  React.ChangeEvent<HTMLInputElement>){
        const me = this;
        const element = event.target;
        const value = element.type === "checkbox" ? +element.checked : element.value;
        const prop = element.name;
        const apiTinyToken = me.state.apiTinyToken;
        apiTinyToken[prop] = value;
        me.setState({apiTinyToken});
    }

    /**
     * Handle on close
     */
    private handleClose(){
        let me = this;
        me.props.onClose && me.props.onClose();
    }

    render(){
        const me = this;
        if(!me.state.apiTinyToken) return <></>;

        return (
            <>
                <LoaderBackdrop visible={me.state.busy} message={_("key_data_saving")} />
                <form onSubmit={ e => me.handleSubmit(e) }>
                    <Card> 
                        <CardHeader>
                            <Row>
                                <Col md="6" className="p-0">
                                    <h5 className="card-title mb-0">{_("key_edit")} {me.state.apiTinyToken?.token && <span>"{me.state.apiTinyToken.token}"</span>}</h5>
                                </Col>
                                <Col md="6" className="p-0">
                                    <button className="btn btn-primary my-2 mx-md-2 float-right m-2" type="submit">
                                        <IconFa icon="save"/> {_("key_save")}
                                    </button>
                                    <button onClick={ () => me.handleClose() } className="btn btn-info my-2 mx-md-2 float-right m-2">
                                        <IconFa icon="close"/> {_("key_return")}
                                    </button>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody className="p-5">
                            <Row>
                                <Col sm="12" md="6">
                                    <FormGroup>
                                        <h6 className="form-label">{_("key_token")}</h6>
                                        <input type="text" required={true} onChange={ e => me.handleChange(e) } name="token" value={ me.state.apiTinyToken.token } className="form-control" />
                                    </FormGroup>
                                </Col>
                                <Col sm="12" md="6">
                                    <FormGroup>
                                        <h6 className="form-label">{_("key_times_used")}</h6>
                                        <input type="number" required={true} onChange={ e => me.handleChange(e) } name="numberTimesUsed" value={ me.state.apiTinyToken.numberTimesUsed } className="form-control" />
                                    </FormGroup>
                                </Col>
                                <Col sm="12" md="6">
                                    <FormGroup>
                                        <h6 className="form-label">{_("key_status")}</h6>
                                        <Switch size="sm" title={_("key_enabled")} onChange={ (e: any) => me.handleChange(e) } name="enabled" checked={me.state.apiTinyToken.enabled}/>
                                    </FormGroup>
                                </Col>
                                <Col sm="12" md="6">
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </form>
            </>
        );
    }
}