import React from "react";
import PageCommon from "../core/PageCommon";
import ProfilePageProp from "../entity/profile/props/ProfilePageProp";
import ProfilePageState from "../entity/profile/states/ProfilePageState";
import { Card, CardBody, CardHeader, Form, FormGroup, Label, Row, Col, IconFa } from '../../cuba/components/utils/reactstrap'
import UserBL from "../bl/user/UserBL";
import LoginBL from "../bl/login/LoginBL";
import LoaderMini from "../component/utils/LoaderMini";
import { _ } from "../bl/admin/AdminLocaleBL";
import LoaderBackdrop from "../component/utils/LoaderBackdrop";
import FileUploader from "../helper/FileUploader";
import Alert from "../component/utils/Alert";
import ProfilePassword from "../component/profile/ProfilePassword";

/**
 * ProfilePage
 * @class ProfilePage
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class ProfilePage extends PageCommon<ProfilePageProp, ProfilePageState> {
    /**
     * Constructor
     * @param props Property 
     */
    constructor(props: ProfilePageProp) {
        super(props);
        this.state = new ProfilePageState();
    }

    /**
     * Component did mount
     */
    componentDidMount() {
        let me = this;
        me.prepare();
    }

    /**
     * Initialize component
     */
    private async prepare(){
        let me = this;

        let userId = LoginBL.SessionData.id;

        me.setState({busy: true});
        var user = await UserBL.getProfile(userId);

        me.setState({
            user: user,
            busy: false
        });
    }

    /**
     * Event handler for inputs
     * @param event Event
     */
     private handleChange(event){
        let me = this;
        let dom = event.target;
        let value = dom.value;
        if(dom.type == "checkbox"){
            value = dom.checked;
        }
        let prop = dom.name;
        let user = me.state.user;
        user[prop] = value;
        me.setState({
            user: user
        });
    }

    /**
     * Cargar la imagen de perfil
     * @param e 
     */
    private uploadImage(e){
        let me = this;
        var upload = new FileUploader(e);
        
        var file = e.target.files[0];
        me.setState({
            loadingImage: true
        });
        upload.upload(file, e => {
            var user = me.state.user;
            if(e.file !== null){
                user.image = e.file.url;
                me.setState({
                    user: user,
                    loadingImage: false
                })
            }   
        });
    }
    
    /**
     * Handle form submit
     * @param e Event
     */
    private async handleSubmit(e) {
        e.preventDefault();
        let me = this;

        var result = await Alert.confirm(_("key_save_changes"), _("key_save_changes_message"));
        if(result.isConfirmed){
            me.setState({savingProfile: true});
            var updatedUser = await UserBL.updateProfile(me.state.user);
            Alert.success();
            me.setState({
                //user: updatedUser,
                savingProfile: false
            });
        }
    }

    render() {
        let me = this;
        
        return (
            <>
                <Row>
                    <Col sm={12} md={8}>
                        <Card>
                            <Form onSubmit={ e => me.handleSubmit(e) } className="mb-3">
                                <LoaderBackdrop visible={me.state.savingProfile} message={_("key_data_saving")}/>
                                {me.state.user && 
                                    <CardHeader className="p-3">
                                        <LoaderBackdrop visible={ me.state.loadingImage } message={_("key_user_loading_image")} />
                                        <Row>
                                            <div className="col-auto">
                                                <img className="img-70 rounded-circle update_img_0" src={ me.state.user.Avatar } alt=""/>
                                                <input className="updateimg" type="file" name="img" onChange={ e => me.uploadImage(e) } data-bs-original-title="" title=""/>
                                            </div>
                                            <Col>
                                                <button className="btn btn-primary my-2 mx-md-2 float-right m-2" type="submit">
                                                    <IconFa icon="save"/> {_("key_save")}
                                                </button>
                                                <h3 className="mb-1">{ me.state.user.firstName } { me.state.user.lastName }</h3>
                                                <p className="mb-4">{ me.state.user.userName }</p>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                }
                                <CardBody> 
                                    {me.state.busy ?
                                        <LoaderMini message={_("key_loading")} />
                                    :
                                        <Row>
                                            {/*<Col sm="12" md="6">
                                                <FormGroup>
                                                    <h6 className="form-label">{_("key_username")}</h6>
                                                    <input type="email" required={true} readOnly={true} name="userName" defaultValue={ me.state.user.userName } className="form-control" />
                                                </FormGroup>
                                            </Col>*/}
                                            <Col sm="12" md="6">
                                                <FormGroup>
                                                    <Label className="form-label">{_("key_email_alternative")}</Label>
                                                    <input required={false} type="email" onChange={ e => me.handleChange(e) } name="emailAlternative" value={ me.state.user.emailAlternative } className="form-control" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm="12" md="6">
                                                <FormGroup>
                                                    <Label className="form-label">{_("key_name")}</Label>
                                                    <input required={true} onChange={ e => me.handleChange(e) } name="firstName" value={ me.state.user.firstName } className="form-control" type="text" placeholder="First name" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm="12" md="6">
                                                <FormGroup>
                                                    <Label className="form-label">{_("key_lastname")}</Label>
                                                    <input required={ true } onChange={ e => me.handleChange(e) } name="lastName" value={ me.state.user.lastName } className="form-control" type="text" placeholder="Last Name" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm="12" md="6">
                                                <FormGroup>
                                                    <Label className="form-label">{_("key_phone")}</Label>
                                                    <input required={true} onChange={ e => me.handleChange(e) } name="phone" value={ me.state.user.phone } className="form-control" type="text" placeholder="Teléfono" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm="12" md="6">
                                                <FormGroup>
                                                    <Label className="form-label">{_("key_city")}</Label>
                                                    <input required={true} onChange={ e => me.handleChange(e) } name="city" value={ me.state.user.city } className="form-control" type="text" placeholder="City" />
                                                </FormGroup>
                                            </Col>
                                            <Col sm="12" md="6">
                                                <FormGroup>
                                                    <Label className="form-label">{_("key_country")}</Label>
                                                    <input required={true} onChange={ e => me.handleChange(e) } name="country" value={ me.state.user.country } className="form-control btn-square"/>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    }
                                </CardBody>
                            </Form>
                        </Card>
                    </Col>
                    <Col sm={12} md={4}>
                        <ProfilePassword />
                    </Col>
                </Row>
            </>
        );
    }
}

export default ProfilePage;
