import React from 'react';
import ComponentCommon from "../../core/ComponentCommon";
import { Card, CardHeader, CardBody, FormGroup, IconFa, Row, Col } from '../../../cuba/components/utils/reactstrap';
import Alert from '../utils/Alert';
import { _ } from '../../bl/admin/AdminLocaleBL';
import GroupFormProp from '../../entity/security/props/GroupFormProp';
import GroupFormState from '../../entity/security/states/GroupFormState';
import GroupBL from '../../bl/security/GroupBL';
import AuditorLogList from '../auditor/AuditorLogList';
import { AuditorConfig } from '../../entity/auditor/AuditorEntity';
import PermissionList from './PermissionList';
import LoaderBackdrop from '../utils/LoaderBackdrop';
import Switch from '../control/Switch';
import { NotificationList } from '../notification/NotificationList';

/**
 * Update chat suggestions
 * @class SuggestionForm
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class GroupForm extends ComponentCommon<GroupFormProp, GroupFormState> {
    private _auditorLog = null;
    private _auditorLogConfig: AuditorConfig = null;
    private _permissionListRef = null;
    private _notificationListRef = null;

    /**
     * Constructor
     * @param props Property
     */
    constructor(props: GroupFormProp){
        super(props);
        let me = this;
        me.state = new GroupFormState();  
        me._permissionListRef = React.createRef();
        me._notificationListRef = React.createRef();
        me._auditorLog = React.createRef();
        me._auditorLogConfig = new AuditorConfig();
    }
    
    /**
     * Component did mount
     */
    componentDidMount(){
        let me = this;
        me.prepare();
    }

    /**
     * Initialize componente
     */
    private async prepare(){
        let me = this;
        me.setState({
            group: me.props.group
        });
    }

    /**
     * Handle form submit
     * @param e Event
     */
    private async handleSubmit(e) {
        e.preventDefault();
        let me = this;
        let group = me.state.group;
        me.setState({busy: true});

        // Save changes...
        group = await GroupBL.updateGroup(group);
        await me._permissionListRef.current?.save();
        await me._notificationListRef.current?.save();

        me.props.onChange && me.props.onChange(group);
        Alert.success();
        me.setState({
            busy: false,
            group: group
        });

        // Update auditor
        me._auditorLog.current?.update();
    }

    /**
     * Event handler for inputs
     * @param event Event
     */
     private handleChange(event){
        let me = this;
        let dom = event.target;
        let value = dom.value;
        if(dom.type == "checkbox"){
            value = dom.checked;
        }
        let prop = dom.name;
        let group = me.state.group;
        group[prop] = value;
        me.setState({
            group: group
        });
    }

    /**
     * Handle on close
     */
    private handleClose(){
        let me = this;
        me.props.onClose && me.props.onClose();
    }

    render(){
        let me = this;

        me._auditorLogConfig.clear();
        me._auditorLogConfig.addCriteria({
            module: "User", table: "Group", identifier: me.state.group?.id
        });
        me._auditorLogConfig.addCriteria({
            module: "User", table: "GroupPermission", identifier: me.state.group?.id
        });
        me._auditorLogConfig.addCriteria({
            module: "Notify", table: "UserNotification", identifier: me.state.group?.id
        });

        if(!me.state.group){
            return <></>
        }

        return (
            <>
                <LoaderBackdrop visible={me.state.busy} message={_("key_data_saving")} />
                <form onSubmit={ e => me.handleSubmit(e) }>
                    <Card> 
                        <CardHeader>
                            <Row>
                                <Col md="6" className="p-0">
                                    <h5 className="card-title mb-0">{_("key_edit")} {me.state.group?.name && <span>"{me.state.group.name}"</span>}</h5>
                                </Col>
                                <Col md="6" className="p-0">
                                    <button className="btn btn-primary my-2 mx-md-2 float-right m-2" type="submit">
                                        <IconFa icon="save"/> {_("key_save")}
                                    </button>
                                    <button onClick={ () => me.handleClose() } className="btn btn-info my-2 mx-md-2 float-right m-2">
                                        <IconFa icon="close"/> {_("key_return")}
                                    </button>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody className="p-5">
                                <Row>
                                    <Col sm="12" md="6">
                                        <FormGroup>
                                            <input type="name" required={true} onChange={ e => me.handleChange(e) } name="name" value={ me.state.group.name } className="form-control" />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="12" md="6">
                                        <Switch size="sm" title={_("key_admin")} onChange={ e => me.handleChange(e) } name="isAdmin" checked={me.state.group.isAdmin}/>
                                    </Col>
                                </Row>
                        </CardBody>
                    </Card>
                </form>
                {me.state.group.id > 0 ?
                    <>
                        <NotificationList ref={me._notificationListRef} group={me.state.group} />
                        { !me.state.group.isAdmin &&
                            <PermissionList ref={me._permissionListRef} group={me.state.group}/>
                        }
                    </>
                :
                    <Card>
                        <CardBody>
                            <h3>{_("key_group_before_message")}</h3>
                        </CardBody>
                    </Card>
                }
                { me.state.group.id > 0 && <AuditorLogList ref={ me._auditorLog } config={ me._auditorLogConfig }/> }
            </>
        );
    }
}

export default GroupForm;