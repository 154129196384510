import axios from "axios";
import BaseBL from "../../core/BaseBL";
import PageCommon from "../../core/PageCommon";
import { PaginatedReponse, PaginatedRequest, Pagination } from "../../entity/control/ControlEntity";
import { Notify } from "../../entity/notification/NotificationEntity";
import Security from "../../helper/Security";
import LoginBL from "../login/LoginBL";

/**
 * Performs Notify Operations (Mongo)
 * @Class NotifyBL 
 * @author Samael Fierro <sfierro@viajemos.com>
*/
export class NotifierBL extends BaseBL {
    // Token
    private static token: string = null;
    // Section tracking
    private static sections: any = {};
    // Tab listener
    private static tabInterval = null;
    // Tabs control
    private static lastKeys: string = null;

    /**
     * Prepare token
     */
    public static prepare(){
        let me = this;

        if(!me.token){
            me.token = Security.genToken();

            // Check user data
            LoginBL.on("check", () => me.prepareTabs());
            // Check login
            LoginBL.on("login", () => me.prepareTabs());
        }
    }

    /**
     * Prepare tabs monitoring
     */
    private static prepareTabs() {
        let me = this;
        // Set new token
        let hash = me.token;
        sessionStorage.setItem('TabHash',hash);
        let tabs = JSON.parse(localStorage.getItem('TabsOpen')||'{}');
        tabs[hash] = true;
        localStorage.setItem('TabsOpen',JSON.stringify(tabs));

        // On close tab
        document.body['onunload'] = () => {
            let hash= sessionStorage.getItem('TabHash');
            let tabs = JSON.parse(localStorage.getItem('TabsOpen')||'{}');
            delete tabs[hash];
            localStorage.setItem('TabsOpen',JSON.stringify(tabs));
            clearInterval(me.tabInterval);
        };

        // Watch tabs
        me.tabInterval = setInterval(() => {
            me.checkTabs();
        }, 1000);

        // Check tabs
        me.checkTabs();
    }

    /**
     * Check tabs status
     */
    private static checkTabs(){
        let me = this;
        var currentTabKeys = me.getTokens();
        if(currentTabKeys != me.lastKeys) {
            me.lastKeys = currentTabKeys;
            me.setSessions();
        }
    }

    /**
     * Get stored tokens
     */
    private static getTokens(): string {
        let tabs = JSON.parse(localStorage.getItem('TabsOpen')||'{}');
        return Object.keys(tabs).join(',');
    }

	/**
	 * Get notifications (API)
	 */
	public static async getNotifications(): Promise<Notify[]> {
        let me = this;
        let data = {
            params: {
                id: LoginBL.SessionData.id
            }
        };
        let response = await axios.get('/Home/NotifierAPI/GetNotifications', data);
        let responseData = me.getResponseData(response);
        var notifications = me.parseEntityArray(responseData, Notify);
        return notifications;
	}

    /**
	 * Get notifications list (API)
     */
    public static async listNotification(paramsData: PaginatedRequest): Promise<PaginatedReponse<Notify>> {
        let me = this;
        let data = {
            params: paramsData
        };
        let response = await axios.get('/Home/NotifierAPI/ListNotification', data);
        let responseData = me.getResponseData(response);
        let result = me.parseEntity(responseData, PaginatedReponse);

        result.data = me.parseEntityArray(result.data, Notify);
        result.pagination = me.parseEntity(result.pagination, Pagination);
        return result;
    }

	/**
	 * Change notification state (API)
	 */
     public static async clearNotifications(): Promise<number> {
        let me = this;
        let data = {
            params: {
                id: LoginBL.SessionData.id
            }
        };
        let response = await axios.get('/Home/NotifierAPI/ClearNotifications', data);
        let responseData = me.getResponseData(response);
        return parseInt(responseData);
	}

	/**
	 * Clear notification state (API)
	 */
     public static async clearNotification(id: string): Promise<number> {
        let me = this;
        let data = {
            params: {
                id: id
            }
        };
        let response = await axios.get('/Home/NotifierAPI/ClearNotification', data);
        let responseData = me.getResponseData(response);
        return parseInt(responseData);
	}

	/**
	 * Change notification state (API)
     * @param name PageCommon page
     * @returns number
	 */
	public static async setSection(page: PageCommon<any, any>): Promise<number> {
        let me = this;

        // Get page name
        var name = page["constructor"]["name"];
        var section = name.replace("Page", "");
        // Track page
        me.sections[name] = page;
        let data = {
            params: {
                user: LoginBL.SessionData.id,
                token: me.token,
                section: section
            }
        };
        let response = await axios.get('/Home/NotifierAPI/SetSection', data);
        let responseData = me.getResponseData(response);
        return responseData;
	}

	/**
	 * Change notification state (API)
     * @param name PageCommon page
     * @returns number
	 */
	public static async setSessions(): Promise<number> {
        let me = this;

        let data = {
            params: {
                user: LoginBL.SessionData?.id,
                tokens: me.getTokens()
            }
        };

        let response = await axios.get('/Home/NotifierAPI/SetSessions', data);
        let responseData = me.getResponseData(response);
        return responseData;
	}

    /**
     * Get related section
     * @param name Page name
     * @returns PageCommon
     */
    public static getSection(name: string): PageCommon<any, any> {
        let me = this;

        var component: PageCommon<any, any> = me.sections[name];
        if(!component?.Mounted) {
            return null;
        }

        return component;
    }
}

NotifierBL.prepare();
export default NotifierBL;
