import React from "react";
import ComponentCommon from "../../core/ComponentCommon";
import AuditorLogElementProp from "../../entity/auditor/props/AuditorLogElementProp";
import AuditorLogElementState from "../../entity/auditor/states/AuditorLogElementState";
import { IconFa, Col, Row, Form } from '../../../cuba/components/utils/reactstrap';
import { _ } from "../../bl/admin/AdminLocaleBL";

/**
 * Represents an Audit Log 
 * @class AuditorLogElement
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class AuditorLogElement extends ComponentCommon<AuditorLogElementProp, AuditorLogElementState> {
    /**
     * Constructor
     * @param props Property 
     */
    public constructor(props) {
        super(props);
        this.state = new AuditorLogElementState();
    }

    private handleClick(e){
        let me = this;
        me.props.onClick && me.props.onClick(e);
    }

    render(){
        let me = this;
        var device = me.props.log.device;
        var changes = me.props.log.affectedColumns;
        var labels = me.props.labels;

        return (
            <li className={`media p-2 ${me.props.active ? 'active' : ''}`} onClick={ e => me.handleClick(e) }>
                <div className="media-body">
                    <Row>
                        <Col sm="12" md="5">
                            <div>
                                <IconFa icon="calendar-check"/> {me.props.log.action}
                                <IconFa icon="calendar ml-2"/> {me.props.log.DateTimeText}
                            </div>
                            {labels &&
                                <div>
                                    <IconFa icon="folder-open"/> {labels[me.props.log.module]} 
                                    <IconFa icon="arrow-right ml-2"/> {labels[me.props.log.table]}
                                </div>
                            }
                        </Col>
                        <Col sm="12" md="3">
                            <div>
                                <IconFa icon="user"/> {me.props.log.user.firstName} {me.props.log.user.lastName}
                            </div>
                        </Col>
                        <Col sm="12" md="4">
                            <div onClick={ e => me.setState({openDevice: !me.state.openDevice})}>
                                <IconFa icon={me.state.openDevice ? "minus" : "plus"}/> {_("key_device")}
                            </div>
                            {me.state.openDevice &&
                                <div className="ml-3">
                                    {device.browser && 
                                        <><IconFa icon="chrome"/> {device.browser} </>
                                    }
                                    {device.brand && 
                                        <><IconFa icon="copyrigth"/> {device.brand} </>
                                    }
                                    {device.device && 
                                        <><IconFa icon="laptop"/> {device.device} </>
                                    }
                                    {device.ip && 
                                        <><IconFa icon="server"/> {device.ip} </>
                                    }
                                    {device.model && 
                                        <><IconFa icon="chrome"/> {device.model} </>
                                    }
                                    {device.os && 
                                        <><IconFa icon="windows"/> {device.os} </>
                                    }
                                </div>
                            }
                            <div onClick={ e => me.setState({openAffected: !me.state.openAffected})}>
                                <IconFa icon={me.state.openAffected ? "minus" : "plus"}/> {_("key_changes")} ({Object.keys(me.props.log.affectedColumns).length})
                            </div>
                        </Col>
                    </Row>
                    {me.state.openAffected &&
                        Object.entries(changes).map( ([keyColumn, valueColumn], i) => 
                            <Row className="mt-2" key={ i }>
                                <Col sm="12" md="2">
                                    <IconFa icon="table"/> {keyColumn} 
                                </Col>
                                <Col sm="12" md="10">
                                    {Object.entries(valueColumn).map(([keyType,valueType]) => 
                                        <div key={keyType}>
                                            {keyType.length == 2 && 
                                                <i className={`mr-2 flag-icon flag-icon-${keyType}`}></i>
                                            }
                                            {valueType.oldValue ? valueType.oldValue : "(NULL)"} <IconFa icon="arrow-right"/> {valueType.newValue}
                                        </div>
                                    )}
                                </Col>                     
                            </Row>
                        )
                    }
                </div>
            </li>
        );
    }
}

export default AuditorLogElement;