import axios from "axios";
import BaseBL from "../../core/BaseBL";
import { Place } from "../../entity/place/PlaceEntity";

/**
 * Perform operations in the place API
 * @class PlaceBL
 * @author Samael Fierro <sfierro@viajemos.com>
 * */
export class PlaceBL extends BaseBL {
    // Country ids
    private static countryStates = [113222,113178,113181,113180,113198,113311,113397,113269,113323,113322,113329,113402];

    /**
     * Verify if a country has states
     * @param idPlace 
     */
    public static isState(idPlace: number){
        let me = this;
        // Countries with States
        return me.countryStates.includes(idPlace);
    }
    
    /**
     * Perform the search for places from the API
     * @param typePlace Location Type (1: Country, 2: State, 3: City)
     * @param idPlace Id Place
     * @param idParent Id Parent (optional)
     * @returns 
     */
    public static async findPlace(typePlace: number, idPlace: number = 0, idParent: number = 0): Promise<Place[]> {
        let me = this;

        var data = {
            params: {
                typePlace,
                idPlace,
                idParent
            }
        };
        
        let response = await axios.get('/Home/PlaceAPI/FindPlace', data);
        let responseData = me.getResponseData(response);
        let places = me.parseEntityArray(responseData, Place) as Place[];
        return places;
    }
}