import React from 'react';
import ComponentCommon from '../../core/ComponentCommon';
import UserLanguageListProp from '../../entity/user/props/UserLanguageListProp';
import UserLanguageListState from '../../entity/user/states/UserLanguageListState';
import UserBL from '../../bl/user/UserBL';
import { UserLanguage } from '../../entity/login/LoginEntity';
import Switch from '../control/Switch';

/**
 * Manage the languages assigned to agents
 * @class UserLanguageList
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class UserLanguageList extends ComponentCommon<UserLanguageListProp, UserLanguageListState> {
    /**
     * Constructor
     * @param props Property
     */
    constructor(props: UserLanguageListProp){
        super(props);
        this.state = new UserLanguageListState();
    }

    /**
     * Component did mount
     */
    componentDidMount(){
        let me = this;
        me.loadUserLanguageLists();
    }
    
    /**
     * Load the UserLanguageLists to show them in the form of the form
     */
    private async loadUserLanguageLists(){
        let me = this;
        
        // Set loader
        me.setState({ busy: true });

        let userLanguageLists = await UserBL.getLanguages(me.props.user);
        
        me.setState({
            busy: false,
            userLanguages: userLanguageLists
        });
    }

    /**
     * Assign a language to the specified user and update the interface
     * @param lang LanguageState element
     * @param state State
     */
    private async setLanguageState(lang: UserLanguage, state){
        let me = this;
        let result = await UserBL.assignLanguage(me.props.user, lang.code, state);

        let languageList = me.state.userLanguages;
        languageList.forEach( currLang => {
            if(lang.code == currLang.code){
                currLang.state = state;
            }
        });
        me.setState({
            userLanguages: languageList
        });

        me.props.onChange && me.props.onChange();
    }

    render(){
        let me = this;
        return (
            <>
                { me.state.busy && <div>Cargando listado...</div>}
                { me.state.userLanguages.map( lang => 
                    <Switch size="sm" outline={ true } key={ lang.id } title={lang.name} checked={lang.state} onChange={ evt => me.setLanguageState(lang, evt.target.checked)} /> 
                )}
            </>
        );
    }
}

export default UserLanguageList;