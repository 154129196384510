import React from 'react';
import ComponentCommon from '../../core/ComponentCommon';
import { Row, Col, Card, CardBody, Switch, SwitchDsn, CardHeader, Form, FormGroup, Label, AlertBar } from '../../../cuba/components/utils/reactstrap'
import LoaderBackdrop from '../utils/LoaderBackdrop';
import Alert from '../utils/Alert';
import LocationFormProp from '../../entity/location/props/LocationFormProp';
import LocationFormState from '../../entity/location/states/LocationFormState';
import TimeRange from '../control/TimeRange';
import { LocationBL } from '../../bl/location/LocationBL';
import Reflection from '../../helper/Reflection';
import { LocationNoteList } from './LocationNoteList';
import { LOCATION_TYPES } from '../../constants/data/location';
import { DAYS } from '../../constants/data/general';
import LocationNameElement from './LocationNameElement';
import { Location, LocationName, LocationNote } from '../../entity/location/LocationEntity';
import InputSearch, { InputSearchConfig } from '../control/InputSearch';
import { AuditorConfig } from '../../entity/auditor/AuditorEntity';
import AuditorLogList from '../auditor/AuditorLogList';
import { _ } from '../../bl/admin/AdminLocaleBL';
import { ISPlace } from '../landing/ISPlace';
import GeneralFunctions from '../../helper/GeneralFunctions';
import { faCheckDouble } from '../../../../../../../wwwroot/lib/font-awesome/js-packages/@fortawesome/free-solid-svg-icons/faCheckDouble';

/**
 * Performs updated and saved location data operations
 * @class LocationForm
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class LocationForm extends ComponentCommon<LocationFormProp, LocationFormState>{

    private _locationName = null;
    private _locationNote = null;
    private _locationPlace = null;
    private _auditorLog = null;
    private _auditorLogConfig: AuditorConfig = null;
    private _searchPlaceConfig: InputSearchConfig = null;

    /**
     * Constructor
     * @param props 
     */
    public constructor(props: LocationFormProp){
        super(props);
        this.state = new LocationFormState();
        this._searchPlaceConfig = new InputSearchConfig("/Home/PlaceAPI/GetPlaces");
        this._auditorLogConfig = new AuditorConfig();
        this._auditorLogConfig.setLabels({
            "Location": _("key_location"),
            "LocationName": _("key_location_name"),
            "LocationNameTranslate": _("key_locationname_translate"),
            "LocationNote": _("key_location_note"),
            "LocationNoteTranslate": _("key_locationnote_translate")
        });
    }
    
    /**
     * Component did mount
     */
    componentDidMount() {
        let me = this;
        me.prepare();
    }

    /**
     * Initialize component
     */
    private async prepare(){
        let me = this;
        me._locationName = React.createRef();
        me._locationNote = React.createRef();
        me._locationPlace = React.createRef();
        me._auditorLog = React.createRef();
        let agencies = await LocationBL.getAgencies();
        let location = me.props.location;
        me.setState({
            location: location,
            agencies: agencies
        });

        var config = me._auditorLogConfig;
        config.setContext(() => {
            config.clear();
            config.addCriteria({module: "Location", table: "Location", id: me.state.location.id});
    
            // Location name refs
            var locationName = me._locationName.current?.getValue() as LocationName;
            
            if(locationName?.id > 0){
                config.addCriteria({module: "Location", table: "LocationName", id: locationName.id});
                locationName.translates.forEach( e => {
                    config.addCriteria({module: "Location", table: "LocationNameTranslate", id: e.id});
                });
            }
    
            // Location notes
            var locationNotes = me._locationNote.current?.getValues();
            
            if(locationNotes) {
                (locationNotes as LocationNote[]).forEach( note => {
                    config.addCriteria({module: "Location", table: "LocationNote", id: note.id});
                    note.translates.forEach( e => {
                        config.addCriteria({module: "Location", table: "LocationNoteTranslate", id: e.id});
                    });
                })
            };
        });
    }

    /**
     * Event handler for inputs
     * @param event Event
     */
    private handleChange(event){
        let me = this;
        let dom = event.target;
        let value = dom.value;
        if(dom.type == "checkbox"){
            value = dom.checked;
        }
        let prop = dom.name;
        let location = me.state.location;
        location[prop] = value;
        me.setState({
            location: location
        });
    }
    
    /**
     * Take the request to the API to save or update the data.
     * @param e Event
     */
    private async processForm(e){
        e.preventDefault();
        let me = this;
        var location = me.state.location;
        
        // Validate fields
        var locationNameId = me._locationName.current.getValue()?.id;
        if(locationNameId < 1){
            Alert.error(_("key_location_locationame_required"));
            return;
        }
        var idPlace = me._locationPlace.current.getValue();
        if(idPlace < 1){
            Alert.error(_("key_location_place_required"));
            return;
        }

        var idAgency = me.state.location.idAgency;
        if(idAgency < 1){
            Alert.error(_("key_location_agency_required"));
            return;
        }
        location.idLocationName = locationNameId;
        location.idAgency = idAgency;
        location.idPlace = idPlace;
        location =  Reflection.clearNulls<Location>(location);

        var result = await Alert.confirm(_("key_location_save"), _("key_location_save_message"));
        if(result.isConfirmed){
            me.setState({busy: true});
            let locationUpdated = await LocationBL.updateLocation(location);
            if(locationUpdated?.id > 0) {
                Alert.show(_("key_user_save"), _("key_user_save_message"), "success");
                me.setState({
                    location: locationUpdated,
                    busy: false
                })
                me._auditorLog.current?.update();
                me.props.onSave && me.props.onSave(locationUpdated);
            } else {
                me.setState({
                    busy: false
                });
                Alert.error();
            }
        }
    }

    /**
     * Close the form, call the event to notify the parent component.
     */
    private closeForm() {
        let me = this;
        me.props.onClose && me.props.onClose(me.state.location);
    }

    /**
     * Capture the time range change event
     * @param value timerange value
     * @param label form identifier
     */
    private handleTimeRange(value: string, label: string) {
        let me = this;
        var location = me.props.location;
        location[label] = value;
        me.setState({
            location: location
        });
    }

    // On location name change
    private handleCompChange() {
        let me = this;
        me._auditorLog.current?.update();
    }

    /**
     * Prevent invalid chars
     * @param event 
     */
    private checkDouble(event){
        let input = event.target;
        // Future value
        var value = input.value,
        idx = input.selectionStart,
        key = event.key;
        value = value.slice(0, idx) + key + value.slice(idx + Math.abs(0));
        
        let pattern = input.pattern;
        let regex = new RegExp(pattern);
        let invalid = !regex.test(value);
        invalid = invalid || value.split("-").length > 2;
        invalid = invalid || value.split(".").length > 2;
        invalid && event.preventDefault();
    }

    render() {
        let me = this;

        if(!me.state.location){
            return <></>
        }

        return (
            <div className="p-md-5">
                <LoaderBackdrop visible={ me.state.busy } message={_("key_data_saving")}/>
                
                <Form onSubmit={ e => me.processForm(e) }>
                    <Row>
                        <Col md="3">
                            <Card className="card-location-name">
                                <AlertBar header={true}>{_("key_name")}</AlertBar>
                                <CardBody className="p-3">
                                    <LocationNameElement onChange={ e => me.handleCompChange() } ref={ me._locationName } location={me.state.location}/>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="9">
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <div className="col-md-5">
                                            <h2>{_("key_location_general_data")}</h2>
                                        </div>
                                        <div className="col-md-7">
                                            <button type="button"
                                                    onClick={ () => me.closeForm() }
                                                    className="btn btn-info m-2 float-right">{_("key_return")}</button>
                                            <button className="btn btn-primary m-2 float-right"
                                                    type="submit">{_("key_save")}</button>
                                        </div>                                    
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        {/* IDENTIFIERS */}
                                        <Col sm="6" md="4">
                                            <Label className="form-label">{_("key_place")}</Label>
                                            <InputSearch 
                                                component={ISPlace} 
                                                ref={ me._locationPlace } 
                                                value={ me.state.location.idPlace } 
                                                config={me._searchPlaceConfig} />
                                        </Col>
                                        <Col sm="6" md="4">
                                            <FormGroup>
                                                <Label className="form-label">{_("key_agency")}</Label>
                                                <select required={ true } onChange={ e => me.handleChange(e) } name="idAgency" value={ me.state.location.idAgency } className="form-control">
                                                    {me.state.agencies.map( (agency) => {
                                                        return <option key={ agency.id } value={agency.id}>{agency.code}: {agency.name}</option>
                                                    })}
                                                </select>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6" md="4">
                                            <FormGroup>
                                                <h6 className="form-label">{_("key_location_name")}</h6>
                                                <input type="text" required={true} onChange={ e => me.handleChange(e) } name="locationName" value={ me.state.location.locationName } className="form-control" />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6" md="4">
                                            <FormGroup>
                                                <h6 className="form-label">{_("key_location_oag_code")}</h6>
                                                <input type="text" required={true} onChange={ e => me.handleChange(e) } name="oagCode" value={ me.state.location.oagCode } className="form-control" maxLength={10}/>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6" md="4">
                                            <FormGroup>
                                                <h6 className="form-label">{_("key_location_agency_code")}</h6>
                                                <input type="text" required={true} onChange={ e => me.handleChange(e) } name="agencyCode" value={ me.state.location.agencyCode } className="form-control" maxLength={10}/>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6" md="4">
                                            <FormGroup>
                                                <Label className="form-label">{_("key_location_type")}</Label>
                                                <select required={ true } onChange={ e => me.handleChange(e) } name="locationType" value={ me.state.location.locationType } className="form-control">
                                                    {LOCATION_TYPES.map( (locationType, i) => {
                                                        return <option key={ i } value={i + 1}>{locationType}</option>
                                                    })}
                                                </select>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6" md="4">
                                            <FormGroup>
                                                <h6 className="form-label">{_("key_zip_code")}</h6>
                                                <input type="text" required={true} onChange={ e => me.handleChange(e) } name="zipCode" value={ me.state.location.zipCode } className="form-control" maxLength={20}/>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6" md="4">
                                            <FormGroup>
                                                <h6 className="form-label">{_("key_address") + " 1"}</h6>
                                                <input type="text" required={true} onChange={ e => me.handleChange(e) } name="address1" value={ me.state.location.address1 } className="form-control" />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6" md="4">
                                            <FormGroup>
                                                <h6 className="form-label">{_("key_address") + " 2"}</h6>
                                                <input type="text" onChange={ e => me.handleChange(e) } name="address2" value={ me.state.location.address2 } className="form-control" />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6" md="4">
                                            <FormGroup>
                                                <h6 className="form-label">{_("key_phone")}</h6>
                                                <input type="text" required={true} onChange={ e => me.handleChange(e) } name="phone" value={ me.state.location.phone } className="form-control" />
                                            </FormGroup>
                                        </Col>

                                        {/* Geolocation */}
                                        <Col sm="6" md="4">
                                            <FormGroup>
                                                <h6 className="form-label">{_("key_latitude")}</h6>
                                                <input type="text" pattern="^[-0-9]+([\.][0-9]+)$" required={true} onKeyPress={ e => me.checkDouble(e) } onChange={ e => me.handleChange(e) } name="latitude" value={ me.state.location.latitude } className="form-control" />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6" md="4">
                                            <FormGroup>
                                                <h6 className="form-label">{_("key_longitude")}</h6>
                                                <input type="text" pattern="^[-0-9]+([\.][0-9]+)$" required={true} onKeyPress={ e => me.checkDouble(e) } onChange={ e => me.handleChange(e) } name="longitude" value={ me.state.location.longitude } className="form-control" />
                                            </FormGroup>
                                        </Col>
                                        {/* Space for schedules */}

                                        {/* Other */}
                                        <Col sm="6" md="4">
                                            <FormGroup>
                                                <h6 className="form-label">{_("key_keywords")}</h6>
                                                <input type="text" onChange={ e => me.handleChange(e) } name="keywords" value={ me.state.location.keywords } className="form-control" />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="3" md="4">
                                            <SwitchDsn size="sm"
                                                    name="enabled"
                                                    title={_("key_enabled")}
                                                    checked={ me.state.location.enabled }
                                                    onChange={ e => me.handleChange(e) } />
                                            {/* <SwitchDsn size="sm" name="enabled" title="Enabled" checked={ me.state.user.enabled } onChange={ e => me.handleChange(e) }/> */}
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col md="7">
                            <Card>
                                <AlertBar header={true}>{_("key_schedule")}</AlertBar>
                                <CardBody>
                                    {DAYS.map( (day, i) => 
                                        <TimeRange key={ i }
                                                    value={ me.state.location[day] }
                                                    onChange={ time => me.handleTimeRange(time, day) }
                                                    label={_(`key_${day}`)}/> 
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="5">
                            {me.state.location.id > 0 ?
                                <Card>
                                    <AlertBar header={true}>{_("key_location_related_notes")}</AlertBar>
                                    <CardBody>
                                        <LocationNoteList onChange={ e => me.handleCompChange() } ref={ me._locationNote } location={me.state.location} />
                                    </CardBody>
                                </Card>
                            :
                                <Card>
                                    <CardBody>
                                        <h3>{_("key_location_related_notes_message")}</h3>
                                    </CardBody>
                                </Card>
                            }
                        </Col>
                    </Row>
                </Form>
                { me.state.location.id > 0 && 
                    <AuditorLogList ref={ me._auditorLog } 
                        config={ me._auditorLogConfig }
                        /> 
                }
            </div>
        )
    }
}

export default LocationForm;