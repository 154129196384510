import axios from 'axios';
import React from 'react';
import BaseBL from '../../core/BaseBL';
import Cookies from '../../helper/Cookies';

// DateTime picker languages
import { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import pt from 'date-fns/locale/pt';
import en from 'date-fns/locale/en-US';
import { BaseTranslate } from '../../core/Translate';
registerLocale('es', es)
registerLocale('en', en)
registerLocale('pt', pt)

/**
 * Get and manage translations
 * @class AdminLocaleBL
 * @author Eduardo Escallon <eescallon@viajemos.com>
 */
export class AdminLocaleBL extends BaseBL {
    private static _locale: any[] = [];
    private static _components: React.Component<any, any>[] = [];
    private static _handlers: CallableFunction[] = [];
    private static _debug: any = {};

    /**
     * Subscribe components handler to update them later
     * @param component component
     */
     public static subscribeHandler(handler: CallableFunction){
        let me = this;
        me._handlers.push(handler);
    }   

    /**
     * Subscribe components to update them later
     * @param component component
     */
     public static subscribeComponent(component: React.Component<any, any>){
        let me = this;
        me._components.push(component);
    }   

    /**
     * Force to update a component
     */
    public static updateComponents(){
        this._components.map( c => c.forceUpdate());
        this._handlers.map( h => h());
    }

    /**
     * Get current language
     */
    public static get Language(): string {
        return Cookies.get("_miles_lang", "ES");
    }

    /**
     * Set current language
     */
    public static set Language(lang: string) {
        let me = this;
        lang = lang.toUpperCase().trim();

        me.load(lang).then(() => {
            Cookies.set("_miles_lang", lang);
            me.updateComponents();
        });
    }

    /**
     * Load in the browser The list of translations
     */
    public static async load(language: string  = null){
        let me = this;
        
        if(language == null) {
            language = me.Language;
        }

        // Check if exists
        if(me._locale[language] == undefined){
            me._locale[language] = {};
        }

        // Check if the current language is already downloaded
        if(Object.keys(me._locale[language]).length > 0){
            return;
        }

        var data = {
            params: {
                language
            }
        }

        // Get translates
        let response = await axios.get('/Home/AdminLocaleAPI/GetLocale', data);
        let responseData = me.getResponseData(response);
        me._locale[language] = responseData;
    }

    /**
     * Return a translation by key
     * @param key translate key
     * @param parameters parameters (optional)
     * @returns translate
     */
    public static getLocaleByKey(key: string, parameters: any = {}): string {
        let me = this;
        
        var lang = me.Language;

        var locale = me._locale[lang];
        if(locale == undefined) {
            return "{NOT_LOADED}";
        }

        var label = locale[key];
        if(locale[key] == undefined) {
            label = "{NOT_EXISTS}";
        }
        me._debug[key] = label;

        for(var x in parameters) {
            var value = parameters[x];
            label = label.split(`{${x}}`).join(String(value));
        }

        return label;
    }

    /**
     * List all the tags requested
     * @returns List
     */
    public static getDebug(): any {
        let me = this;
        return me._debug;
    }

    /**
     * Gets a translation of a BaseTranslate type object
     * @param entity BaseTranslate entity
     * @param field Field to display
     * @returns String
     */
    public static getEntityTranslate(entity: BaseTranslate, field: string = "name"): string {
        let me = this;
        var currentLanguage = me.Language;
        if(currentLanguage == "EN"){
            return entity[field];
        }
        for(var x in entity.translates){
            var translation = entity.translates[x];
            if(translation.language == currentLanguage){
                return translation[field];
            }
        }
        return "{NOT_EXISTS}";
    }
}

export default AdminLocaleBL;

/**
 * Return a translation by key
 * @param key translate key
 * @param parameters parameters (optional)
 * @returns translate
 */
export const Lang =  (key: string, parameters: any = {}): string => {
    return AdminLocaleBL.getLocaleByKey(key, parameters);
}

/**
 * Gets a translation of a BaseTranslate type object
 * @param entity BaseTranslate entity
 * @param field Field to display
 * @returns String
 */
export const LangEntity = (entity: BaseTranslate, field: string = "name"): string => {
    return AdminLocaleBL.getEntityTranslate(entity, field);
}

export { Lang as _ };
export { LangEntity as __ };
window["_Locale"] = AdminLocaleBL;
