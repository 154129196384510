import React from "react";

/**
 * Represents entity-component
 * It arises as a specific solution by maintaining the component-entity 
 * relationship in which the entity data is updated recurrently
 * Or keep the relationship between entity and component separate
 */
 export class Pair<E = any, C = any> {
    public entity: E = null;
    public component: C = null;
    public active: boolean = true;
    public visible: boolean = true;

    public static fromArray<E, C>(list: Array<E>, componentCreator: CallableFunction): PairList<E, C> {
        let me = this;
        let pairList: PairList<E, C> = [];
        list.forEach( e => {
            let pair = me.create<E, C>(e, componentCreator);
            pairList.push(pair);
        });
        return pairList;
    }

    public static create<E, C>(entity: E, componentCreator: CallableFunction): Pair<E, C> {
        let pair: Pair<E, C> = new Pair<E, C>();
        pair.entity = entity;
        pair.component = componentCreator(pair);
        return pair;
    }
}

/**
 * Pair entity-component helper
 */
export class PairList<E, C> extends Array<Pair<E, C>> {
    /**
     * Render the list of components by preserving its states
     * @param id Status key
     * @returns Componentes
     */
    public static render?(list: PairList<any, any>) {
        return list
        .map((element, i) => 
            <div key={i} className={ element.active ? "" : "d-none" }>
                {element.component}
            </div>
        );
    }

    /**
     * Compare the keys of the entities and visualize only the coincidences
     * @param list Ready to compare.
     * @param entity Entity to compare.
     * @param key Property Key
     */
    public static renderByKey(list: PairList<any, any>, entity = null, key: string = "id"){
        let me = this;
        list.forEach( l => {
            l.active = entity !== null && entity[key] == l.entity[key];
        });
        return me.render(list);
    }
}