import React from 'react';
import SuggestionsPageProp from "../entity/suggestion/props/SuggestionsPageProp";
import SuggestionsPageState from "../entity/suggestion/states/SuggestionsPageState";
import { Card, CardBody, Row, Col, Form, Button, IconFa } from '../../cuba/components/utils/reactstrap';
import { PaginatedRequest, Pagination } from '../entity/control/ControlEntity';
import ChatSuggestionBL from '../bl/chat/ChatSuggestionBL';
import Paginator from '../component/control/Paginator';
import LoaderMini from '../component/utils/LoaderMini';
import SuggestionForm from '../component/suggestion/SuggestionForm';
import { ChatSuggestion } from '../entity/chat/ChatEntity';
import PageCommon from '../core/PageCommon';
import { Empty } from '../component/utils/Empty';
import { Pair } from '../helper/Pair';
import SuggestionElement from '../component/suggestion/SuggestionElement';
import { LocaleBL } from '../bl/locale/LocaleBL';
import { UserLanguage } from '../entity/login/LoginEntity';
import { _ } from '../bl/admin/AdminLocaleBL';
import { ADMIN_SITES } from '../constants/data/sites';

/**
 * Manage chat suggestions
 * @class SuggestionsPage
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class SuggestionsPage extends PageCommon<SuggestionsPageProp, SuggestionsPageState> {

    private _paginatorRef = null;

    /**
     * Constructor
     * @param props Property 
     */
    constructor(props: SuggestionsPageProp){
        super(props);
        let me = this;
        me.state = new SuggestionsPageState();
        me._paginatorRef = React.createRef();
    }

    /**
     * On component did mount
     */
    componentDidMount(){
        let me = this;
        me.prepare();
    }

    /**
     * Initialize component
     */
    private async prepare() {
        let me = this;
        me.searchResult();
        
        let languages = await LocaleBL.getLanguages();
        me.setState({
            languages: languages
        })
    }

    /**
     * Handle SuggestionForm changes
     * @param suggestion ChatSuggestion suggestion 
     */
    private handleChange(suggestion: ChatSuggestion){
        let me = this;
        me.searchResult();
    }

    /**
     * Create new SuggestionForm
     * @param suggestion ChatSuggestion object
     * @returns SuggestionForm Component
     */
    private createComponent(pair: Pair<ChatSuggestion>){
        let me = this;
        pair.active = false;
        return <SuggestionForm onChange={ e => me.handleChange(e) } suggestion={pair.entity} />;
    }

    /**
     * Make the query by page
     */
    private async searchResult(){
        let me = this;

        let request = new PaginatedRequest<any>();
        
        request.data = {
            criteria: me.state.criteria,
            languages: me.state.language,
            siteCode: me.state.siteCode
        };
        request.pagination = me.state.pagination;

        me.setState({busy: true});
        let data = await ChatSuggestionBL.getSuggestions(request);
        me.setState({busy: false});

        // Create pair: Entity-Component
        let suggestions = Pair.fromArray<ChatSuggestion, SuggestionForm>(data.data, e => me.createComponent(e) );

        me.setState({
            suggestions: suggestions,
            pagination: data.pagination
        }, () => {
            me._paginatorRef.current.update();
        });
    }

    /**
     * Mark as active
     * @param pair Pair Entity-Component
     */
    private setActive(pair: Pair<ChatSuggestion, SuggestionForm>){
        let me = this;
        let suggestions = me.state.suggestions;
        suggestions.forEach( sugPair => {
            sugPair.active = pair.entity.id == sugPair.entity.id;
        });
        me.setState({
            suggestions: suggestions
        });
    }

    /**
     * Update pagination state
     * @param pagination Pagination object
     */
     public passPage(pagination: Pagination){
        let me = this;
        me.setState({
            pagination: pagination
        }, () => {
            me.searchResult();
        });
    }

    /**
     * Handle form submit event
     * @param e Event
     */
    private handleFormSubmit(e){
        e.preventDefault();
        let me = this;
        me.searchResult();
    }

    /**
     * Edit suggestion
     * @param suggestion Suggestion object 
     */
    private editSuggestion(suggestion: Pair<ChatSuggestion, SuggestionForm>){
        let me = this;
        me.setState({
            // Delete component
            suggestion: null
        }, () => {
            me.setActive(suggestion);
            me.setState({
                suggestion: suggestion
            });
        });
    }

    /**
     * Create new suggestion
     */
    private async createSuggestion(){
        let me = this;
        let suggestion = new ChatSuggestion();
        let pair = Pair.create<ChatSuggestion, SuggestionForm>(suggestion, pair => me.createComponent(pair));
        me.editSuggestion(pair);
    }

    /**
     * Change the selected language
     * @param language UserLanguage
     */
    private toggleLanguage(language: UserLanguage){
        let me = this;
        let languages: Array<string> = me.state.language;
        if(languages.includes(language.code)){
            languages = languages.filter( l => l != language.code);
        } else {
            languages.push(language.code);
        }
        me.setState({
            language: languages
        });
    }

    render(){
        let me = this;
        return (
            <Row>
                <Col sm="4" className="call-chat-sidebar">
                    <Card>
                        <CardBody className="chat-body">
                            <div className="chat-box">
                                <div className="chat-left-aside">
                                    <div className="people-list">
                                        <div className="search">
                                            <Form onSubmit={ e => me.handleFormSubmit(e) } className="theme-form">
                                                <div className="mb-1">
                                                    <input 
                                                        onChange={ e => me.setState({ criteria: e.target.value }) } 
                                                        value={ me.state.criteria } 
                                                        className="form-control" 
                                                        type="text" 
                                                        placeholder={_("key_search_criteria")}
                                                        />
                                                    <i className="fa fa-search search-icon"></i>
                                                </div>
                                                <label className="mt-3">{_("key_sites")}: </label><br/>
                                                <select onChange={ e => me.setState({ siteCode: e.target.value }) } name="siteCode" value={ me.state.siteCode } className="form-control mb-3">
                                                    <option value={""}>{_("key_sites_all")}</option>
                                                    {ADMIN_SITES.map( (rp) => {
                                                        return <option key={ rp.code } value={rp.code}>{rp.name}</option>
                                                    })}
                                                </select>
                                                <label>{_("key_language")}: </label>
                                                <div className="text-center">
                                                    {me.state.languages.map( lang => {
                                                        let active = me.state.language.includes(lang.code);
                                                        return(
                                                            <button type="button"
                                                                    key={lang.id}
                                                                    onClick={ () => me.toggleLanguage(lang) }
                                                                    className={`btn btn-${active ? 'primary text-white ': 'light'} m-2`}>
                                                                {active && <IconFa icon="check text-white mr-2"></IconFa>}
                                                                {lang.name}
                                                            </button>
                                                        );
                                                    })}
                                                </div>
                                                <div className="text-center p-2">
                                                    <Button type="submit"
                                                            color="primary"
                                                            className="m-2">{_("key_search")}
                                                    </Button>
                                                    <Button className="m-2"
                                                            color="success"
                                                            onClick={ e => me.createSuggestion() }
                                                            type="button"> {_("key_create_new")}
                                                    </Button>
                                                </div>
                                            </Form>
                                        </div>
                                        <LoaderMini visible={me.state.busy} message={_("key_search_loading_results")}/>
                                        <ul className="list suggestions-container custom-scrollbar">
                                            { !me.state.busy && me.state.suggestions.length > 0 ? me.state.suggestions.map( (sug, i) =>
                                                <SuggestionElement key={i} active={sug.active} suggestion={sug.entity} onClick={ () => me.editSuggestion(sug) }/>
                                            ) : 
                                                <Empty visible={!me.state.busy}/>
                                            }
                                        </ul>
                                        <Paginator showPageSizes={false}  ref={ me._paginatorRef } visible={ me.state.suggestions.length > 0 } pagination={ me.state.pagination } onChange={ pagination => me.passPage(pagination) } />
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col className="call-chat-body">
                    {me.state.suggestion?.component}
                    {!me.state.busy && me.state.suggestion == null && 
                        <Card>
                            <CardBody className="p-5">
                                <Empty />
                            </CardBody>
                        </Card>
                    }
                </Col>
            </Row>
        )
    }
}

export default SuggestionsPage;