import React, {Fragment} from 'react';
import {Card, CardBody, CardHeader, Form, Button, Row, Col, IconFa} from '../../cuba/components/utils/reactstrap'
import PageCommon from '../core/PageCommon';
import Paginator from '../component/control/Paginator';
import {_} from '../bl/admin/AdminLocaleBL';
import {PaginatedRequest, Pagination} from '../entity/control/ControlEntity';
import LoaderMini from '../component/utils/LoaderMini';
import ApiTinyTokenPageProp from "../entity/api/tiny/props/ApiTinyTokenPageProp";
import ApiTinyTokenPageState from "../entity/api/tiny/states/ApiTinyTokenPageState";
import ApiTinyToken from "../entity/api/tiny/ApiTinyTokenEntity";
import ApiTinyBL from "../bl/api/tiny/ApiTinyBL";
import ApiTinyTokenForm from "../component/api/tiny/ApiTinyTokenForm";

/**
 * ApiTinyPage (Admin)
 * @class ApiTinyTokenPage
 * @author Juan Serrano <jserrano@viajemos.com>
 */
export default class ApiTinyTokenPage extends PageCommon<ApiTinyTokenPageProp, ApiTinyTokenPageState> {
    //Paginator reference
    private _paginatorRef = null;

    /**
     * Constructor
     * @param props Property
     */
    public constructor(props: ApiTinyTokenPageProp) {
        super(props);
        this.state = new ApiTinyTokenPageState();
    }

    /**
     * Component Did Mount
     */
    public async componentDidMount() {
        const me = this;
        me._paginatorRef = React.createRef();
        await me.searchResult();
    }

    /**
     * Select a Token of the Api Tiny Token
     * @param apiTinyToken
     */
    private selectToken(apiTinyToken: ApiTinyToken) {
        const me = this;
        me.setState({
            apiTinyToken
        });
    }

    /**
     * Make the query by page
     */
    private async searchResult(resetCount: boolean = false) {
        const me = this;

        const request = new PaginatedRequest<any>();
        request.pagination = me.state.pagination;
        request.data.criteria = me.state.criteria;

        me.setState({loadingTokens: true});
        const data = await ApiTinyBL.getAllTokens(request);

        me.setState({
            loadingTokens: false,
            apiTinyTokens: data.data,
            pagination: resetCount ? data.pagination.setCurrentPage(1) : data.pagination
        }, () => {
            me._paginatorRef?.current?.update()
        });
    }

    /**
     * Update pagination state
     * @param pagination Pagination object
     */
    public passPage(pagination: Pagination) {
        const me = this;
        me.setState({
            pagination: pagination
        }, async() => {
            await me.searchResult();
        });
    }

    /**
     * Handle form submit
     * @param e Event
     */
    private async handleFormSubmit(e: Event) {
        e.preventDefault();
        let me = this;
        await me.searchResult(true);
    }

    /**
     * Handle form close
     */
    private async handleClose() {
        const me = this;

        me.setState({
            apiTinyToken: null
        });
        await me.searchResult();
    }

    private formatDate(dateTime: string) {
        const date = new Date(dateTime);
        return date.getDate()+"-"+date.getMonth()+"-"+date.getFullYear()+" "+date.getUTCHours()+":"+date.getUTCMinutes()+":"+date.getUTCSeconds();
    }

    /**
     * Create group
     */
    private createApiTinyToken() {
        const me = this;
        const apiTinyToken = new ApiTinyToken();
        me.setState({
            apiTinyToken
        });
    }

    render() {
        let me = this;
        return (
            <Fragment>
                <Row className={"justify-content-center"}>
                    <Col sm={12} md={10}>
                        {!me.state.apiTinyToken ?
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col md="6">
                                            <h5>{_("key_mod_page_api_tiny")}</h5>
                                        </Col>
                                        <Col md="6">
                                            <Button color="success" className="float-right mt-3 mt-md-0"
                                                    onClick={() => me.createApiTinyToken()}>
                                                <IconFa icon="plus"/> {_("key_create_new")}
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Form onSubmit={(e: Event) => me.handleFormSubmit(e)} className="mb-3">
                                        <Row>
                                            <Col md="6">
                                                <input id="criteria_input" className="form-control"
                                                       value={me.state.criteria}
                                                       onChange={e => me.setState({criteria: e.target.value})}
                                                       placeholder={_("key_search_criteria")}/>
                                            </Col>
                                            <Col md="6">
                                                <button className="btn btn-primary mt-3 mt-md-0 float-right"
                                                        type="submit">
                                                    <IconFa icon="search"/> {_("key_filter_results")}
                                                </button>
                                            </Col>
                                        </Row>
                                    </Form>
                                    <table className="table table-m-2">
                                        <thead>
                                        <tr>
                                            <th scope="col">{_("key_token")}</th>
                                            <th scope="col">{_("key_times_used")}</th>
                                            <th scope="col">{_("key_status")}</th>
                                            <th scope="col">{_("key_create_date")}</th>
                                            <th scope="col">{_("key_usage_renewal_date")}</th>
                                            <th scope="col">{_("key_updated_date")}</th>
                                            <th scope="col">{_("key_actions")}</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {me.state.loadingTokens &&
                                            <tr>
                                                <td colSpan={6}>
                                                    <LoaderMini message={_("key_search_loading_results")}/>
                                                </td>
                                            </tr>
                                        }
                                        {!me.state.loadingTokens && me.state.apiTinyTokens.map((apiTinyToken: ApiTinyToken) =>
                                            <tr key={apiTinyToken.id}>
                                                <td>{apiTinyToken.token}</td>
                                                <td>{apiTinyToken.numberTimesUsed}</td>
                                                <td>{apiTinyToken.enabled}</td>
                                                <td>{me.formatDate(apiTinyToken.createDate.toString())}</td>
                                                <td>{me.formatDate(apiTinyToken.usageRenewalDate.toString())}</td>
                                                <td>{me.formatDate(apiTinyToken.updateDate.toString())}</td>
                                                <td className="p-2 actions">
                                                    <Button className="p-x-md-0"
                                                            onClick={() => me.selectToken(apiTinyToken)} size="sm">
                                                        <IconFa icon="pencil"/> {_("key_edit")}
                                                    </Button>
                                                </td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                    <Paginator
                                        visible={!me.state.loadingTokens && me.state.apiTinyTokens.length > 0}
                                        ref={me._paginatorRef}
                                        pagination={me.state.pagination}
                                        onChange={(pagination: Pagination) => me.passPage(pagination)}/>
                                </CardBody>
                            </Card>
                            :
                            <ApiTinyTokenForm onClose={() => me.handleClose()} apiTinyToken={me.state.apiTinyToken}/>
                        }
                    </Col>
                </Row>
            </Fragment>
        );
    }
}