import IState from "../../../core/IState";
import { Pagination } from "../../control/ControlEntity";
import { UserLanguage } from "../../login/LoginEntity";
import { Customer, CustomerSite, CustomerSiteLandingPage } from "../LandingEntity";

export default class LandingPageState implements IState {
    public pagination: Pagination = new Pagination();
    public customers: Array<Customer> = [];
    public busy: boolean = false;
    public currentCustomer: string = ""
    public customerSite: Array<CustomerSite> = []
    public currentCustomerSite: string = "";
    public itemsLandingPage: Array<CustomerSiteLandingPage> = [];
    public currentItemLandingPage: CustomerSiteLandingPage = null;
    public criteria: string = "";
    public busySaving: boolean = false;
}