import IState from "../../../core/IState";
import { Pagination } from "../../control/ControlEntity";
import { Channel } from "../ChannelEntity";

export default class ChannelPageState implements IState { 
    public pagination: Pagination = new Pagination();
    public loadingChannels: boolean = false;
    public channels: Channel[] = [];
    public criteria: string = "";
    public channel: Channel;
}
