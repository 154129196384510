import React from 'react';
import ComponentCommon from "../../core/ComponentCommon";
import NavigationLogState, {INewNavigation} from "../../entity/navigation/states/NavigationLogState";
import {MilesContext} from "../../context/MilesContext";
import {NavigationLogEntity} from "../../entity/navigation/NavigationLogEntity";
import { NavigationLogBL } from '../../bl/navigation/NavigationLogBL';
import MercureListener from "../../helper/MercureListener";
import Reflection from "../../helper/Reflection";
import NavigationLogProp from "../../entity/navigation/props/NavigationLogProp";

/**
 * NavigationBox Main control: History Navigation for User
 * @class NavigationBox
 * @author Juan Serrano <jserrano@viajemos.com>
 */
export class NavigationBox extends ComponentCommon<NavigationLogProp, NavigationLogState> {
    private mounted = false;
    static contextType = MilesContext;

    /**
     * Constructor
     * @param prop 
     */
    constructor(prop: NavigationLogProp) {
        super(prop);
        const me = this;
        me.state = new NavigationLogState();
    }

    /**
     * Prepare NavigationBox component
     */
    async prepare() {
        const me = this;
        // Load last navigationbox
        await me.recoveryNavigation();
        me.listenNavigations();
    }

    componentDidMount(){
        const me = this;
        !me.mounted && me.prepare();
        me.mounted = true;
    }

    componentDidUpdate(prevProps: Readonly<NavigationLogProp>, prevState: Readonly<NavigationLogState>, snapshot?: any) {
        const me = this;
        if(me.props.visible !== prevProps.visible) {
            if(me.props.visible) {
                me.resetNewNavigations(me.props.selectedChat.ChatTopicRaw);
                const listNavigationLog = me.state.listNavigationLog;
                const listNavigationLogToCheck = listNavigationLog.filter(navigationLog=>!navigationLog.checked&&me.props.selectedChat.ChatTopicRaw===navigationLog.chatTopic)
                    .map(navigationLog=> {
                    navigationLog.checked = true;
                    return navigationLog;
                });
                if(listNavigationLogToCheck.length)
                    NavigationLogBL.massiveUpdateNavigationLog(listNavigationLogToCheck).then(
                        listNavigationLogUpdated=> {
                            const newListNavigationLog = listNavigationLog.map(navigationLog=>{
                                const navigationLogToCheck = listNavigationLogUpdated.filter(navigationLogUpdated=>navigationLogUpdated.id===navigationLog.id);
                                if(navigationLogToCheck.length) navigationLog = navigationLogToCheck[0];
                                return navigationLog;
                            });
                            me.setState({listNavigationLog: newListNavigationLog});
                        }
                    );
            }
            me.setState({visible: me.props.visible});
        }
    }

    componentWillUnmount(){
        const me = this;
        me.destroy();
    }

    /**
     * Destroy component, reset vars
     */
    public destroy(){
        const me = this;
        me.setState({
            listNavigationLog: [],
            visible: false
        });
    }

    /**
     * Recover all url navigation stored (from database), call handlers
     */
    private async recoveryNavigation(){
        const me = this;
        const listNavigationLog = await NavigationLogBL.getNavigationLog(me.props.currentChat.ChatTopicRaw);
        me.setState({
            listNavigationLog
        });
        listNavigationLog.filter(navigationLog=>!navigationLog.checked).map(navigationLog=>
        me.updateNewNavigations({
            newTrackUrl: navigationLog.trackUrl,
            chatTopic: navigationLog.chatTopic,
            visible: navigationLog.checked
        }));
    }

    /**
     * add Navigation To Navigation Log
     */
    private listenNavigations() {
        let me = this;
        const chatTopic = me.props.currentChat.chatTopic;
        MercureListener.subscribe(chatTopic, data => {
            const visible = me.props.visible;
            let data_encoded = JSON.parse(data);
            const { message, strIntName } = data_encoded;
            if(strIntName === "navigation") {
                const { trackUrl, idInserted } = JSON.parse(message);
                const listNavigationLog = me.state.listNavigationLog;
                listNavigationLog.push(Reflection.parseEntity({
                    id: idInserted,
                    trackUrl,
                    chatTopic,
                    checked: visible,
                    createdDate: new Date().toString(),
                    updatedDate: null,
                    isUpdated: false
                }, NavigationLogEntity, (nL: NavigationLogEntity) => nL));
                me.setState({
                    listNavigationLog
                });
                if(!visible) me.updateNewNavigations({newTrackUrl: trackUrl, chatTopic, visible});
            }
        });
    }

    private formatDate (strDate: string): string {
        const date = new Date(strDate);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        let hour = date.getHours().toString();
        hour = hour.length>1?hour:`0${hour}`;
        let minute = date.getMinutes().toString();
        minute = minute.length>1?minute:`0${minute}`;
        return `${day}/${month}/${year} ${hour}:${minute}`;
    }

    private resetNewNavigations = (chatTopic: string) => {
        const me = this;
        const currentNewNavigationsByChatTopic = me.state.newNavigations.filter(newNavigation=>newNavigation.chatTopic !== chatTopic);
        me.setState({newNavigations: currentNewNavigationsByChatTopic});
        me.props.updateNewNavigations(currentNewNavigationsByChatTopic, chatTopic);
    }

    private updateNewNavigations = (newNavigation: INewNavigation) => {
        const me = this;
        let currentNewNavigations = me.state.newNavigations;
        const existCurrentNewNavigationsByChatTopic = !!currentNewNavigations.filter(currentNewNavigation=>currentNewNavigation.chatTopic === newNavigation.chatTopic).length;
        if(existCurrentNewNavigationsByChatTopic)
            currentNewNavigations = currentNewNavigations.map(currentNewNavigation=>{
                if(currentNewNavigation.chatTopic === newNavigation.chatTopic) {
                    if(!newNavigation.visible) {
                        currentNewNavigation.listNewTrackUrls.push(newNavigation.newTrackUrl);
                    }
                    else me.resetNewNavigations(currentNewNavigation.chatTopic);
                }
                return currentNewNavigation;
            });
        else if(!existCurrentNewNavigationsByChatTopic && !newNavigation.visible) currentNewNavigations.push({
            listNewTrackUrls: [newNavigation.newTrackUrl],
            newTrackUrl: newNavigation.newTrackUrl,
            chatTopic: newNavigation.chatTopic,
            visible: newNavigation.visible
        });
        me.setState({newNavigations: currentNewNavigations});
        me.props.updateNewNavigations(me.state.newNavigations, newNavigation.chatTopic);
    }

    render(){
        const me = this;
        return (
            me.state.visible ? <div className="chat">
                <div className="chat-history chat-msg-box chat-navigation custom-scrollbar">
                    <ul>
                        {
                            me.state.listNavigationLog.length ? me.state.listNavigationLog.map((navigationLog: NavigationLogEntity)=>
                                <li className="clearfix" key={Math.random()}>
                                    <div className="message my-message float-right">
                                        <span className="message-data-text">El cliente ha visitado
                                            <a className="ml-1" target="_blank" rel="noopener noreferrer" href={navigationLog.trackUrl}>
                                                {navigationLog.trackUrl}
                                            </a>
                                        </span>
                                        <span className="message-data-time">{me.formatDate(navigationLog.createdDate)}
                                            <span className="ml-2 text-primary">
                                                <i className="fa fa-check"></i>
                                            </span>
                                        </span>
                                    </div>
                                </li>) : <li className="clearfix ">
                                <div className="message my-message float-right">
                                    <span className="message-data-text">No se ha registrado navegación</span>
                                </div>
                            </li>
                        }
                    </ul>
                </div>
            </div>
            : <></>
        );
    }
}

export default NavigationBox;