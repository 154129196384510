import SuggestionForm from "../../../component/suggestion/SuggestionForm";
import IState from "../../../core/IState";
import { PairList, Pair } from "../../../helper/Pair";
import { ChatSuggestion } from "../../chat/ChatEntity";
import { Pagination} from "../../control/ControlEntity";
import { UserLanguage } from "../../login/LoginEntity";

export default class SuggestionsPageState implements IState {
    public pagination: Pagination = new Pagination();
    public suggestions: PairList<ChatSuggestion, SuggestionForm> = [];
    public suggestion: Pair<ChatSuggestion, SuggestionForm> = null;
    public criteria: string = "";
    public siteCode: string = "";
    public busy: boolean = false;
    public languages: Array<UserLanguage> = [];
    public language: Array<string> = [];
}