import IState from "../../../core/IState";
import { Pagination } from "../../control/ControlEntity";
import { Place } from "../../place/PlaceEntity";
import { Faqs } from "../FaqsEntity";

export default class FaqsPageState implements IState {
    public pagination: Pagination = new Pagination();
    public criteria: string = "";
    public loadingFaqs: boolean = false;
    public places: Place[] = [];
    public place: number = 0;

    public faqs: Faqs[] = [];
    public faq: Faqs = null;
}