import React from 'react';
import { LandingBL } from '../../bl/Landing/LandingBL';
import { LocaleBL } from '../../bl/locale/LocaleBL';
import ComponentCommon from "../../core/ComponentCommon";
import LandingFormProp from '../../entity/landing/props/LandingFormProp';
import LandingFormState from '../../entity/landing/states/LandingFormState';
import { Row, Col, Button, Form, Card, CardBody, CardHeader, CardFooter } from '../../../cuba/components/utils/reactstrap';
import { UserLanguage } from '../../entity/login/LoginEntity';
import { CustomerSiteLandingPage_Translate, CustomerSiteLandingPageLanguage } from "../../entity/landing/LandingEntity";
import { Location } from '../../entity/location/LocationEntity'
import { Category } from '../../entity/car/CarEntity'
import Alert from '../utils/Alert';
import System from '../../helper/System';
import { IconFa } from '../../../cuba/components/utils/reactstrap'
import { LocationBL } from '../../bl/location/LocationBL';
import { PaginatedRequest, Pagination } from "../../entity/control/ControlEntity";
import Paginator from '../control/Paginator';
import { AuditorConfig } from '../../entity/auditor/AuditorEntity';
import AuditorLogList from '../auditor/AuditorLogList';
import { _ } from '../../bl/admin/AdminLocaleBL';
import TextBox from '../control/TextBox';
import { LANDING_EDITOR_CONFIG } from '../../constants/data/landing';
import { PlaceBL } from '../../bl/place/PlaceBL';
import SchemaList from './SchemaList';

/**
 * Manage an element of static content and its translations
 * @class LandingForm
 */
export class LandingForm extends ComponentCommon<LandingFormProp, LandingFormState>{
    private _textboxRef = null;
    private _paginatorRef = null;
    private _auditorLog = null;
    private _auditorLogConfig: AuditorConfig = null;

    /**
     * Initialize component
     * @param props Property
     */
    constructor(props: LandingFormProp){
        super(props);
        let me = this;
        me.state = new LandingFormState();
        me._paginatorRef = React.createRef();
        me._auditorLog = React.createRef();
        me._textboxRef = React.createRef();
        me._auditorLogConfig = new AuditorConfig();
    }

    /**
     * Component did mount
     */
    componentDidMount(){
        let me = this;
        me.prepare()
    }

    /**
     * Initialyze Component
     */
    private async prepare() {
        let me = this;
        let itemElement = me.props.landingElement
        if (itemElement.inAllSites == false) {
            itemElement.inAllSites = false
        }
        if (itemElement.showSchemas == false) {
            itemElement.showSchemas = false;
        }
        if (itemElement.enabled == false) {
            itemElement.enabled = false;
        }
        let languages = await LocaleBL.getLanguages();

        let dataCustomerSiteLanguage: CustomerSiteLandingPageLanguage = new CustomerSiteLandingPageLanguage();
        if (itemElement.content != null) {
            dataCustomerSiteLanguage.content = itemElement.content;
        }
        if (itemElement.h1 != null) {
            dataCustomerSiteLanguage.h1 = itemElement.h1;
        }
        if (itemElement.h2 != null) {
            dataCustomerSiteLanguage.h2 = itemElement.h2;
        }
        if (itemElement.h3 != null) {
            dataCustomerSiteLanguage.h3 = itemElement.h3;
        }
        if (itemElement.metaDescription != null) {
            dataCustomerSiteLanguage.metaDescription = itemElement.metaDescription;
        }
        if (itemElement.metaKeyword != null) {
            dataCustomerSiteLanguage.metaKeyword = itemElement.metaKeyword;
        }
        if (itemElement.metaTitle != null) {
            dataCustomerSiteLanguage.metaTitle = itemElement.metaTitle;
        }

        if (itemElement.customerUrlSiteLandingPages.length > 1) {
            itemElement.customerUrlSiteLandingPages.map(function(value, key) {
                if (value.customerUrl.language === "EN") {
                    dataCustomerSiteLanguage.urlRequest = value.customerUrl.urlRequest
                }
            })
        } else {
            dataCustomerSiteLanguage.urlRequest = itemElement.customerUrlSiteLandingPages[0].customerUrl.urlRequest
        }

        let carCategories = await LandingBL.getCarCategories()

        me.setState({
            landingElement: itemElement,
            languages: languages,
            currentDataLanguage: dataCustomerSiteLanguage,
            carCategories: carCategories
        });

        me.loadAgency();
        me.loadCountry();
        await me.searchLocations()

        if (itemElement.optionQuickSearch != null && itemElement.optionQuickSearch != 'null') {
            let optionsQuickSearch = JSON.parse(itemElement.optionQuickSearch)
            let currentLocation = await LocationBL.getLocationById(optionsQuickSearch['IdLocation'])
            let selectedCategory = 0;
            if (optionsQuickSearch['CarCategory'].length == 1) {
                selectedCategory = optionsQuickSearch['CarCategory'][0]
            }
            me.setState({
                currentLocation: currentLocation[0],
                optionsQuickSearch: optionsQuickSearch,
                selectedCategory: selectedCategory
            })
        }
    }

    /**
     * Get available languages
     */
    private get AvailableLanguages(): UserLanguage[] {
        let me = this;
        var languages: UserLanguage[] = me.state.languages;
        var langCodes: string[] = ["EN"];
        me.props.landingElement.translates.map( e => {
            langCodes.push(e.language);
        });
        languages = languages.filter( l => langCodes.includes(l.code));
        return languages;
    }

    /**
     * Handle click event
     */
     private handleClick(){
        let me = this;
        // me.props.onClick && me.props.onClick(me.props.LandingForm);
    }

    private changeElement(e){
        let me = this
        let currentLanding = me.state.landingElement
        let currentDatalanguage = me.state.currentDataLanguage
        if (e.target.type === 'checkbox') {
            currentLanding[e.target.id] = e.target.checked
        } else {
            switch(e.target.id) {
                case "h1":
                case "h2":
                case "h3":
                case "content":
                case "metaKeyword":
                case "metaDescription":
                case "metaTitle":
                    if (me.state.currentLanguage === "EN") {
                        currentLanding[e.target.id] = e.target.value
                        currentDatalanguage[e.target.id] = e.target.value
                    } else {
                        me.state.landingElement.translates.map(function(item, key) {
                            if (item.language === me.state.currentLanguage) {
                                currentLanding.translates[key][e.target.id] = e.target.value
                                currentDatalanguage[e.target.id] = e.target.value
                            }
                        })
                    }
                    break;
                default:
                    currentLanding[e.target.id] = e.target.value
                    break;                
            }
        }
        me.setState({
            landingElement: currentLanding,
            currentDataLanguage: currentDatalanguage
        })
    }

    private async saveItem() {
        let me = this;
        let result = await Alert.confirm(_("key_landing_save"), _("key_landing_save_message"))
        if(!result.isConfirmed){
            return; 
        }

        me.props.notifySaving();

        me.setState({
            busy: true,
        })

        let landingElement = me.state.landingElement
        landingElement.content = me._textboxRef.current.getValue();
        if (me.state.currentLocation != null) {
            landingElement.optionQuickSearch = JSON.stringify(me.state.optionsQuickSearch)
        } else {
            landingElement.optionQuickSearch = null
        }
        let data = await LandingBL.updateLandingItem(landingElement);

        me.state.landingElement.translates.map(async function(key, value) {
            let d = await LandingBL.updateLandingItemTranslate(key);
        })

        await System.redisFlushById("landing", me.state.landingElement.id);

        Alert.success();

        //Update logs
        me._auditorLog.current?.update();

        me.setState({
            busy: false
        }, () => {
            me.props.searchLanding()
        })
    }

    /**
     * Establish language of the suggestion
     * @param language 
     */
     private setLanguage(language: UserLanguage){
        let me = this;
        let dataCustomerSiteLanguage: CustomerSiteLandingPageLanguage = new CustomerSiteLandingPageLanguage();
        
        if (language.code == "EN") {
            dataCustomerSiteLanguage.content = me.state.landingElement.content;
            dataCustomerSiteLanguage.h1 = me.state.landingElement.h1;
            dataCustomerSiteLanguage.h2 = me.state.landingElement.h2;
            dataCustomerSiteLanguage.h3 = me.state.landingElement.h3;
            dataCustomerSiteLanguage.metaDescription = me.state.landingElement.metaDescription;
            dataCustomerSiteLanguage.metaKeyword = me.state.landingElement.metaKeyword;
            dataCustomerSiteLanguage.metaTitle = me.state.landingElement.metaTitle;
        } else {
            let itemTranslate: CustomerSiteLandingPage_Translate = null;
            me.state.landingElement.translates.map(function(translate) {
                if (language.code === translate.language) {
                    itemTranslate = translate;
                }
            })

            dataCustomerSiteLanguage.content = itemTranslate.content;
            dataCustomerSiteLanguage.h1 = itemTranslate.h1;
            dataCustomerSiteLanguage.h2 = itemTranslate.h2;
            dataCustomerSiteLanguage.h3 = itemTranslate.h3;
            dataCustomerSiteLanguage.metaDescription = itemTranslate.metaDescription;
            dataCustomerSiteLanguage.metaKeyword = itemTranslate.metaKeyword;
            dataCustomerSiteLanguage.metaTitle = itemTranslate.metaTitle;
        }

        if (me.state.landingElement.customerUrlSiteLandingPages.length > 1) {
            me.state.landingElement.customerUrlSiteLandingPages.map(function (value, key) {
                if (value.customerUrl.language === language.code) {
                    dataCustomerSiteLanguage.urlRequest = value.customerUrl.urlRequest
                }
            })
        } else {
            dataCustomerSiteLanguage.urlRequest = me.state.landingElement.customerUrlSiteLandingPages[0].customerUrl.urlRequest
        }
        // let suggestion = me.state.suggestion;
        // suggestion.idLanguage = language.id;
        me.setState({
            currentLanguage: language.code,
            currentDataLanguage: dataCustomerSiteLanguage
        });
    }

    /**
     * Handle search form
     * @param e Event
     */
     private searchNewLocations(e) {
        let me = this;
        e.preventDefault();
        me.setState({
            locationPagination: me.state.locationPagination.setCurrentPage(1)
        }, () => {
            me.searchLocations();
        });
    }

    private async searchLocations() {
        let me = this;

        // Define ID Place
        var state = me.state.state;
        var city = me.state.city;
        var idPlace = me.state.country;
        state > 0 && (idPlace = state);
        city > 0 && (idPlace = city);

        let data = {
			criteria: me.state.criteriaLocation,
            zipCode: me.state.zipCode,
            idAgency: me.state.idAgency,
            idPlace: idPlace
		};

        let request = new PaginatedRequest();
        request.data = data
        request.pagination = me.state.locationPagination;
        let locations = await LocationBL.getLocation(request);

        me.setState({
            locations: locations.data,
            locationPagination: locations.pagination
        },() => {
            me._paginatorRef.current.update()
        })
    }

    private async selectLocation(location: Location) {
        let me = this;
        let data = {
            criteria: location.place.path
        };
        let request = new PaginatedRequest();
        request.data = data
        request.pagination = me.state.locationPagination;
        let countryPlace = await LandingBL.getCountryPlaceFromPlace(request)
        let optionQuickSearch = me.state.optionsQuickSearch
        optionQuickSearch['Country'] = countryPlace[0]['code']
        optionQuickSearch['Agency'] = location.agency.code
        optionQuickSearch['IdLocation'] = location.id
        optionQuickSearch['OagCode'] = location.oagCode
        me.setState({
            currentLocation: location,
            optionsQuickSearch: optionQuickSearch
        }, () => {
            me.selectCarCategories(me.state.selectedCategory)
        })
    }

    private selectCarCategories(category) {
        let me = this;
        let optionQuickSearch = me.state.optionsQuickSearch
        if (category == 0) {
            optionQuickSearch['CarCategory'] = []
            let categories = []
            me.state.carCategories.map(function (category, key) {
                categories.push(category.id)
            })
            optionQuickSearch['CarCategory'] = categories
        } else {
            optionQuickSearch['CarCategory'] = []
            optionQuickSearch['CarCategory'].push(category)
        }
        me.setState({
            selectedCategory: category,
            optionsQuickSearch: optionQuickSearch
        })
    }

    /**
     * Update pagination state
     * @param pagination Pagination object
     */
    public passPage(pagination: Pagination){
        let me = this;
        me.setState({
            locationPagination: pagination
        }, () => {
            me.searchLocations();
        });
    }

    public cancelUpdateItem() {
        let me = this;
        me.props.cancelUpdate()
    } 
    
    /**
     * Load agency list
     */
     private async loadAgency(){
        let me = this;
        var agencies = await LocationBL.getAgencies();
        me.setState({
            agencies: agencies
        });
    }

    /**
     * Load country list
     */
    private async loadCountry(){
        let me = this;
        var countries = await PlaceBL.findPlace(1);
        me.setState({
            countries,
            states: [],
            cities: [],
            country: 0,
            state: 0,
            city: 0
        });
    }


    /**
     * Load state list
     */
    private async loadState(){
        let me = this;
        var states = await PlaceBL.findPlace(2, me.state.country);
        me.setState({
            states,
            cities: [],
            state: 0,
            city: 0
        });
    }


    /**
     * Load city list
     */
    private async loadCity(){
        let me = this;
        var cities = await PlaceBL.findPlace(3, me.state.state);
        me.setState({
            cities,
            city: 0
        });
    }

    /**
    * Handle onchange place event
    * @param type TypePlace
    */
   private handlePlace(type: number, event: any){
       let me = this;
       var idPlace = parseInt(event.target.value);
       switch (type) {
           case 1:
               var haveState = PlaceBL.isState(idPlace);

               var params = {
                   country: idPlace,
                   showState: haveState
               };

               if(!haveState) {
                   params["state"] = idPlace;
               }

               me.setState(params,  () => {
                   if(haveState) {
                       me.loadState();
                   } else {
                       me.loadCity();
                   }
               });

               break;
           case 2:
               me.setState({state: idPlace}, () => {
                   me.loadCity();
               });
               break;
           case 3:
               me.setState({city: idPlace});
               break;
       }
   }

    /**
    * Update auditor log
    */
    private updateLog(){
        let me = this;
        me._auditorLog.current?.update();
    }

    render(){
        let me = this;

        me._auditorLogConfig.clear();
        me._auditorLogConfig.addCriteria({
            module: "LandingPage", 
            identifier: me.props.landingElement?.id 
        });
        
        return ( 
            <React.Fragment>
                { me.state.landingElement != null &&
                    <React.Fragment>
                        <Card>
                            <CardBody>
                                <Form>
                                    <Row>
                                        <Col>
                                            <div className="form-group">
                                                <label className="text-primary h3">{_('key_url')}: </label>
                                                <label className="form-label h3">{me.state.currentDataLanguage.urlRequest}</label>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12" md="8">
                                            <div className="m-checkbox-inline mb-2">
                                                <h4 className="text-primary form-check-inline col-md-12">{_("key_language")}: </h4>
                                                { me.state.languages && me.AvailableLanguages.map( (lang: UserLanguage) => 
                                                    <div key={lang.id} className={`form-check form-check-inline radio radio-${lang.code == me.state.currentLanguage ? 'success': "primary"}`}>
                                                        <input onChange={ e => me.setLanguage(lang) } checked={lang.code == me.state.currentLanguage} className="form-check-input" id={`label_disp_${lang.id}`} type="radio" name={`chat_dispositions_${lang.id}`}/>
                                                        <label className="form-check-label mb-0" htmlFor={`label_disp_${lang.id}`}>{lang.name}</label>
                                                    </div>
                                                )} 
                                            </div>
                                        </Col>
                                        <Col sm="12" md="4">
                                            <Button onClick={ () => me.cancelUpdateItem() }
                                                    className="float-right my-3 my-md-0 mx-2">
                                                {_("key_return")}
                                            </Button>
                                            <Button onClick={ () => me.saveItem() }
                                                    className="float-right my-3 my-md-0 mx-2"
                                                    color="primary">
                                                {_("key_save")}
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col>
                                            <div className="form-group">
                                                <label className="form-label">{_("key_service")}</label>
                                                <input className="form-control" type="text" placeholder={_("key_service")} id="service" value={me.state.landingElement.service} onChange={ e => me.changeElement(e)} />
                                            </div>
                                        </Col>
                                        
                                        <Col>
                                            <div className="form-group">
                                                <label className="form-label">{_("key_type")}</label>
                                                <input className="form-control" type="text" placeholder={_("key_type")} id="typeLandingPage" value={me.state.landingElement.typeLandingPage} onChange={ e => me.changeElement(e)} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6" xl="4">
                                            <div className="form-group">
                                                <label className="form-label">{_("key_h1")}</label>
                                                <input className="form-control" type="text" placeholder={_("key_h1")} id="h1" value={me.state.currentDataLanguage.h1 != null ? me.state.currentDataLanguage.h1 : ""} onChange={ e => me.changeElement(e)} />
                                            </div>
                                        </Col>
                                        <Col md="6" xl="4">
                                            <div className="form-group">
                                                <label className="form-label">{_("key_h2")}</label>
                                                <input className="form-control" type="text" placeholder={_("key_h2")} id="h2" value={me.state.currentDataLanguage.h2 != null ? me.state.currentDataLanguage.h2 : ""} onChange={ e => me.changeElement(e)} />
                                            </div>
                                        </Col>
                                        <Col md="6" xl="4">
                                            <div className="form-group">
                                                <label className="form-label">{_("key_h3")}</label>
                                                <input className="form-control" type="text" placeholder={_("key_h3")} id="h3" value={me.state.currentDataLanguage.h3 != null ? me.state.currentDataLanguage.h3 : ""} onChange={ e => me.changeElement(e)} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="form-group">
                                                <label className="form-label">{_("key_meta_title")}</label>
                                                <input className="form-control" type="text" placeholder={_("key_meta_title")} id="metaTitle" value={me.state.currentDataLanguage.metaTitle != null ? me.state.currentDataLanguage.metaTitle : ""} onChange={ e => me.changeElement(e)} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="form-group">
                                                <label className="form-label">{_("key_meta_description")}</label>
                                                <input className="form-control" type="text" placeholder={_("key_meta_description")} id="metaDescription" value={me.state.currentDataLanguage.metaDescription != null ? me.state.currentDataLanguage.metaDescription : ""} onChange={ e => me.changeElement(e)} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="form-group">
                                                <label className="form-label">{_("key_landing_information")}</label>
                                                <TextBox
                                                    ref={me._textboxRef}
                                                    config={LANDING_EDITOR_CONFIG}
                                                    value={me.state.currentDataLanguage.content}
                                                    />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="4">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="inAllSites" checked={me.state.landingElement.inAllSites} onChange={ e => me.changeElement(e)}  />
                                                <label className="form-check-label">{_("key_landing_all_sites_enabled")}</label>
                                            </div>
                                        </Col>
                                        <Col md="4">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="showSchemas" checked={me.state.landingElement.showSchemas} onChange={ e => me.changeElement(e)} />
                                                <label className="form-check-label">{_("key_landing_show_scheme")}</label>
                                            </div>
                                        </Col>
                                        <Col md="4">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="enabled" checked={me.state.landingElement.enabled} onChange={ e => me.changeElement(e)} />
                                                <label className="form-check-label">{_("key_enabled")}</label>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader className="pb-4">
                                <Row>
                                    <Col sm="8">
                                        <h4>{_("key_landing_qs_options")}</h4>
                                    </Col>
                                    <Col sm="4">
                                        <Button className="float-right"
                                                onClick={e => me.setState({
                                                currentLocation: null,
                                                selectedCategory: 0,
                                                optionsQuickSearch: {}
                                        })}> {_("key_landing_qs_options_delete")}
                                        </Button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody className="pt-4">
                                { me.state.currentLocation != null &&
                                    <React.Fragment>
                                        <Row>
                                            <Col>
                                                <h5>{_("key_landing_stablished_data")}</h5>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm="6">
                                                <div className="form-group">
                                                    <label className="form-label">{_("key_location_current")}: </label>
                                                    <label className="form-label"> {me.state.currentLocation.locationName}</label>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="form-group">
                                                    <label className="form-label">{_("key_agency")}: </label>
                                                    <label className="form-label"> { me.state.currentLocation.agency.name}</label>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className="form-group">
                                                    <label className="form-label">{_("key_country")}: </label>
                                                    <label className="form-label"> { me.state.optionsQuickSearch['Country']}</label>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <h5>{_("key_categories")}</h5>
                                            </Col>
                                        </Row>
                                        { me.state.selectedCategory == 0 ?
                                            <Row>
                                                { me.state.carCategories.map((category: Category, key) =>
                                                    <Col key={key}>
                                                        <label htmlFor="">{category.name}</label>
                                                    </Col>
                                                )}
                                            </Row>
                                            :
                                            <Row>
                                                { me.state.carCategories.map(function(category: Category, key) {
                                                    if (category.id === me.state.selectedCategory) {
                                                        return(
                                                            <Col key={key}>
                                                                <label htmlFor="">{category.name}</label>
                                                            </Col>
                                                        )
                                                    }
                                                })}
                                                
                                            </Row>       
                                        }
                                    </React.Fragment>
                                }
                                <hr className="dropdown-divider" />
                                <div className="form-group">
                                    <label className="form-label">{_("key_location")}</label>
                                </div>
                                <Row>
                                    <Col sm="12" md="4">
                                        <div className="form-group mb-3">
                                            <h6 className="form-label">{_("key_location_name_address")}</h6>
                                            <input 
                                                onChange={ e => me.setState({criteria: e.target.value}) } 
                                                value={ me.state.criteria } 
                                                className="form-control" 
                                                type="text" 
                                                placeholder={_("key_search_criteria")}
                                                />
                                        </div>
                                    </Col>
                                    <Col sm="12" md="4">
                                        <div className="form-group mb-3">
                                            <h6 className="form-label">{_("key_zip_code")}</h6>
                                            <input 
                                                onChange={ e => me.setState({zipCode: e.target.value}) } 
                                                value={ me.state.zipCode } 
                                                className="form-control" 
                                                type="text" 
                                                placeholder={_("key_location_search_by_zip")}
                                                />
                                        </div>
                                    </Col>
                                    <Col sm="12" md="4">
                                        <div className="form-group mb-3">
                                            <h6 className="form-label">{_("key_agency")}</h6>
                                            <select required={ true } onChange={ e => me.setState({idAgency: parseInt(e.target.value) }) } name="idAgency" value={ me.state.idAgency } className="form-control">
                                                <option value={0}>{_("key_agency_all")}</option>
                                                {me.state.agencies.map( (agency) => {
                                                    return <option key={ agency.id } value={agency.id}>{agency.code}: {agency.name}</option>
                                                })}
                                            </select>
                                        </div>
                                    </Col>
                                </Row>
                                {/* PLACES SELECTOR */}
                                <Row>
                                    <Col sm="12" md="4">
                                        <div className="form-group mb-3">
                                            <h6 className="form-label">{_("key_country")}</h6>
                                            <select required={ true } onChange={ e => me.handlePlace(1, e) } name="country" value={ me.state.country } className="form-control">
                                                <option value={0}>{_("key_country_all")}</option>
                                                {me.state.countries.map( (country) => {
                                                    return <option key={ country.id } value={country.id}>{country.code}: {country.name}</option>
                                                })}
                                            </select>
                                        </div>
                                    </Col>
                                    <Col sm="12" md="4">
                                        <div className="form-group mb-3">
                                            <h6 className="form-label">{_("key_state")}</h6>
                                            <select disabled={!me.state.showState} required={ true } onChange={ e => me.handlePlace(2, e) } name="state" value={ me.state.state } className="form-control">
                                                <option value={0}>{_("key_state_all")}</option>
                                                {me.state.states.map( (state) => {
                                                    return <option key={ state.id } value={state.id}>{state.name} ({state.code})</option>
                                                })}
                                            </select>
                                        </div>
                                    </Col>
                                    <Col sm="12" md="4">
                                        <div className="form-group mb-3">
                                            <h6 className="form-label">{_("key_city")}</h6>
                                            <select required={ true } onChange={ e => me.handlePlace(3, e) } name="city" value={ me.state.city } className="form-control">
                                                <option value={0}>{_("key_city_all")}</option>
                                                {me.state.cities.map( (city) => {
                                                    return <option key={ city.id } value={city.id}>{city.name} ({city.code})</option>
                                                })}
                                            </select>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="text-center p-2">
                                    <Button className="mt-3 mt-md-0" onClick={e => me.searchNewLocations(e)}>{_("key_search")}</Button>
                                 </div>
                                <div className="form-group">
                                    <table className="table tableDsn">
                                        <thead>
                                            <tr>
                                                <th scope="col">{_("key_name")}</th>
                                                <th scope="col">{_("key_agency")}</th>
                                                <th scope="col">{_("key_place")}</th>
                                                <th scope="col">{_("key_address")}</th>
                                                <th scope="col">{_("key_actions")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {me.state.locations.map( (location: Location, index) => 
                                                <tr key={location.id} className={(me.state.currentLocation != null && me.state.currentLocation.id === location.id) ? 'table-active' : ''}>
                                                    <td data-label={_("key_name")}>{location.locationName}</td>
                                                    <td data-label={_("key_agency")}>{location.agency.name}</td>
                                                    <td data-label={_("key_place")}>{location.place.name}</td>
                                                    <td data-label={_("key_address")}>{location.address1}</td>
                                                    <td data-label={_("key_actions")}>
                                                        <Row>
                                                            <Col onClick={ e => me.selectLocation(location) }
                                                                 style={{cursor: "pointer"}}>
                                                                <IconFa icon="check-square"/>
                                                            </Col>
                                                        </Row>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                    <Paginator visible={me.state.locations.length > 0}
                                                  ref={ me._paginatorRef }
                                                  pagination={me.state.locationPagination}
                                                  onChange={ pagination => me.passPage(pagination) }/>
                                </div>
                                <Row>
                                    <Col sm="12">
                                        <h5>{_("key_categories")}:</h5>
                                    </Col>
                                    <Col>
                                        <div className="mb-3 m-t-15 custom-radio-ml">
                                            { me.state.carCategories.map( (category, key) =>
                                                <div key={key} className="form-check radio radio-primary mb-3">
                                                    <input className="form-check-input"
                                                        type="radio"
                                                        name="inlineRadioOptions"
                                                        id={`check`+key}
                                                        value={category.id}
                                                        checked={me.state.selectedCategory === category.id}
                                                        onChange={ e => {
                                                            me.selectCarCategories(category.id)
                                                        }}/>
                                                    <label className="form-check-label" htmlFor={`check`+key}>{category.name}</label>
                                                </div>
                                            )}
                                            <div className="form-check radio radio-primary">
                                                <input className="form-check-input"
                                                    type="radio"
                                                    name="inlineRadioOptions"
                                                    id="checkAll"
                                                    value="all"
                                                    checked={me.state.selectedCategory === 0}
                                                    onChange={ e => {
                                                        me.selectCarCategories(0)
                                                    }}/>
                                                <label className="form-check-label" htmlFor="checkAll">{_("key_categories_all")}</label>
                                            </div>
                                        </div>
                                       
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <SchemaList onChange={ e => me.updateLog() } language={me.state.currentLanguage} landing={me.state.landingElement}/>
                        { me.state.landingElement.id > 0 && <AuditorLogList ref={ me._auditorLog } config={ me._auditorLogConfig }/> }
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}
export default LandingForm;