import axios from 'axios';
import BaseBL from '../../core/BaseBL';
import { ChatSuggestion } from '../../entity/chat/ChatEntity';
import { PaginatedReponse, PaginatedRequest, Pagination } from '../../entity/control/ControlEntity';
import { UserLanguage } from '../../entity/login/LoginEntity';

/**
 * Performs API operations for conversation suggestions
 * @class ChatSuggestionBL
 * @author Samael Fierro <sfierro@viajemos.com>
 * */
export class ChatSuggestionBL extends BaseBL {
    /**
     * Find all suggestions by criteria and language
     * @param language 
     * @param criteria 
     * @returns 
     */
    public static async findSuggestions(language: string, criteria: string, siteCode: string){
        let me = this;
        let data = {
            params: {
                language: language,
                criteria: criteria,
                siteCode: siteCode
            }
        };
        let response = await axios.get('/Home/ChatAPI/GetSuggestions', data);
        let responseData = me.getResponseData(response);
        var suggestions = me.parseEntityArray(responseData, ChatSuggestion);
        return suggestions;
    }

    /**
     * Get a paginal list of all stored suggestions
     * @param paramsData PaginatedRequest object
     * @returns ChatSuggestion array
     */
    public static async getSuggestions(paramsData: PaginatedRequest){
        let me = this;
        let data = {
            params: paramsData
        };
        let response = await axios.get('/Home/ChatAPI/ListSuggestions', data);
        let responseData = me.getResponseData(response);
        var result = me.parseEntity(responseData, PaginatedReponse);
        result.data = me.parseEntityArray(result.data, ChatSuggestion, (suggestion: ChatSuggestion) => {
            suggestion.language = me.parseEntity(suggestion.language, UserLanguage);
            return suggestion;
        });
        result.pagination = me.parseEntity(result.pagination, Pagination);
        return result;
    }

    /**
     * Save user schedule data
     * @param schedule User
     * @returns UserSchedule data
     */
     public static async saveSuggestion(suggestion: ChatSuggestion){
        let me = this;
        var data = me.getEntityFormData(suggestion);
        let response = await axios.post('/Home/ChatAPI/UpdateSuggestion', data);
        let responseData = me.getResponseData(response);
        let result = me.parseEntity(responseData, ChatSuggestion);
        return result;
    }

    /**
     * Save user schedule data
     * @param schedule User
     * @returns UserSchedule data
     */
    public static async deleteSuggestion(suggestion: ChatSuggestion){
        let me = this;
        var data = me.getEntityFormData(suggestion);
        let response = await axios.post('/Home/ChatAPI/DeleteSuggestion', data);
        let responseData = me.getResponseData(response);
        let result = me.parseEntity(responseData, ChatSuggestion);
        return result;
    }
}

export default ChatSuggestionBL;