import React from 'react';
import moment from 'moment';
import ComponentCommon from "../../core/ComponentCommon";
import TimePickerProp from '../../entity/control/props/TimePickerProp';
import TimePickerState from '../../entity/control/states/TimePickerState';
import { _ } from '../../bl/admin/AdminLocaleBL';
import { IconFa } from '../../../cuba/components/utils/reactstrap';
import GeneralFunctions from '../../helper/GeneralFunctions';

/**
 * TimePicker component
 * @class TimePicker
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class TimePicker extends ComponentCommon<TimePickerProp, TimePickerState> {

    private uniquedId = 0;
    private containerList = null;
    private innerList = null;
    private componentBox = null;
    private intervalCode = null;
    private _clickHandler = null;

    /**
     * Constructor
     * @param props Property
     */
    constructor(props: TimePickerProp) {
        super(props);
        let me = this;
        me.uniquedId = Math.random() * 1000000;
        me.state = new TimePickerState();
        me.containerList = React.createRef();
        me.innerList = React.createRef();
        me.componentBox = React.createRef();
    }

    /**
     * On did mount
     */
    componentDidMount() {
        let me = this;
        me.prepare();
    }

    /**
     * On unmount
     */
    componentWillUnmount(){
        let me = this;
        clearInterval(me.intervalCode);
        $(window).off();
    }

    /**
     * Prepare default values
     */
    private prepare() {
        let me = this;

        let defaultTime = me.props.value;
        me.setTime(defaultTime);

        me.setBackdrop();
    }

    /**
     * Set current value
     * @param value 
     */
    public setValue(value:string){
        value = value.trim();
        let me = this;
        me.setTime(value);
    }

    /**
     * Get current value
     */
    public getValue() {
        let me = this;
        return me.updateTime();
    }

    /**
     * Clear timepicker
     */
    public clear() {
        let me = this;
        me.setState({
           selected: false,
           hours: 0,
           minutes: 0,
           ap: "AM",
           selectedTime: ""
        });
    }

    /**
     * Hidden the list of hours by clicking out of the component
     */
    private setBackdrop(){
        let me = this;
        me._clickHandler = (e) => {
            let currentObject = e.target;
            let containerObject = me.componentBox.current;
            let randomId = "id_" + ((Math.random() + "") . replace(".", "_"));

            currentObject.setAttribute("identifier", randomId);
            let result = containerObject.querySelector("[identifier=" + randomId + "]") !== null;
            currentObject.removeAttribute("identifier");
            
            result && e.stopPropagation();
            !result && me.state.showList && me.Mounted && me.setState({
                showList: false
            })
        }

        $(window).click(me._clickHandler);
    }

    /**
     * Scroll animation
     */
    scrollAnimation = () => {
        let me = this;
        let innerList = $(me.innerList.current),
        containerList = $(me.containerList.current);

        let mX = 0, mmAA, mmAAr, mX2 = 0, posX = 0;
        innerList.off("mousemove").mousemove(function(e) {
            let galW = containerList.height() - (containerList.height() * 0.05),
            galSW = innerList.height(),
            wDiff = (galSW / galW) - 1, // widths difference ratio
            mPadd = 60, // mousemove Padding
            damp = 4, // Mmusemove response softness
            scrollTop = 0;
            mmAA = galW - (mPadd * 2), // the mousemove available area
            mmAAr = (galW / mmAA); // get available mousemove didderence ratio
            mX = e.pageY - $(this).parent().offset().top - this.offsetTop;
            mX2 = Math.min(Math.max(0, mX - mPadd), mmAA) * mmAAr;
            posX += (mX2 - posX) / damp; // zeno's paradox equation "catching delay"
            scrollTop = posX * (wDiff);
            containerList.scrollTop(scrollTop);
        });
    }
    
    private toggleShowList(){
        let me = this;
        me.setState({
            showList: !me.state.showList
        })
    }

    /**
     * Return hours
     */
    public get Hours(): number[] {
        let me = this;
        let hours = [...Array(12).keys()].map( h => h + 1 );

        // Initial default value (Unsecue)
        if(me.props.selectedSign && !me.state.selected){
            hours.push(0);
        }

        return hours;
    }

    /**
     * Return minutes
     */
    public get Minutes(): number[] {
        return [...Array(60).keys()];
    }

    /**
     * Return AM / PM
     */
    public get AP(): string[] {
        return ["AM","PM"];
    }

    /**
     * Converting time in HH:mm a format
     * @param time 
     */
    private setTime(time: string){
        let me = this;

        var selected = true;
        if(typeof time !== "string" || time.split(":").length < 2){
            time = "00:00:00";
            selected = false;
        }

        let parts = time.split(":");
        let hours = parseInt(parts[0]);
        let minutes = parseInt(parts[1]);
        let ap = "PM";
        
        if(hours > 12){
            hours -= 12;
        } else {
            // Default label
            var isDefault = me.props.selectedSign && !selected;
    
            if(hours == 0 && selected){
                ap = "AM";
                hours = 12;
            } else if(hours == 0 && isDefault){
                ap = "AM";
                hours = 0;
            } else if(hours <= 11) {
                ap = "AM";
            }
        }

        me.setState({
            selected: selected,
            selectedTime: time,
            hours: hours,
            minutes: minutes,
            ap: ap
        });
    }

    /**
     * Update time value
     */
    private updateTime(): string{
        let me = this;

        let hoursTime = me.state.hours;

        if(me.state.ap == "PM" && hoursTime < 12){
            hoursTime += 12;
        }

        if(me.state.ap == "AM" && hoursTime == 12){
            hoursTime = 0;
        }
        
        let hours = GeneralFunctions.zeroPad(hoursTime, 2);
        let minutes = GeneralFunctions.zeroPad(me.state.minutes, 2);
        let selectedTime = `${hours}:${minutes}:00`;

        me.setState({
            selected: true,
            selectedTime: selectedTime
        });

        return selectedTime;
    }

    /**
     * Handle input change
     * @param e Event 
     * @param convert Convert to integer
     */
    private handleChange(e, convert: boolean = false){
        let me = this;
        var tag = e.target.name;
        let value = e.target.value;
        var state = {};
        state[tag] = convert ? parseInt(value) : e.target.value;

        me.setState(state, () => {
            var time = me.updateTime();
            me.props.onChange && me.props.onChange(time);
        });
    }

    render() {
        let me = this;
        return (
            <div ref={ me.componentBox }>
                <div onClick={ e => me.toggleShowList() } className="text-center timepicker-input timepicker-input__dsn form-control">
                    <select name="hours"
                            className="custom-scrollbar"
                            value={me.state.hours} onChange={ e => me.handleChange(e, true)}>
                        { me.Hours.map( (h) => <option key={h} value={h}>{h < 10 ? 0 : ''}{h}</option>)}
                    </select>
                    <select name="minutes"
                            className="custom-scrollbar"
                            value={me.state.minutes} onChange={ e => me.handleChange(e, true)}>
                        { me.Minutes.map( m => <option key={m} value={m}>{m < 10 ? 0 : ''}{m}</option>)}
                    </select>
                    <select name="ap"
                            className="custom-scrollbar"
                            value={me.state.ap} onChange={ e => me.handleChange(e)}>
                        { me.AP.map( m => <option key={m} value={m}>{m}</option>)}
                    </select>
                    {me.props.selectedSign && 
                        (me.state.selected ? 
                            <IconFa icon="check text-success"/>
                            :
                            <IconFa icon="times text-danger"/>
                        )
                    }
                </div>
            </div>
        );
    }
}

export default TimePicker;