import React, { useState, createContext } from 'react'
import ChatBox from '../component/chat/ChatBox'

// Types
export let Content = { 
    values: { 
        main: {
            Login: null, 
            Session: null
        },
        chat: {
            CurrentChats: Array<ChatBox>(),
            Expand: false
        }
    } 
}

// Exportar contexto
export const MilesContext = createContext( Content.values )

/**
 * Crea un grupo de variables que se manejarán en un contexto común entre páginas
 */
export const MilesContextProvider = ( {children} ) => {
    let [login, setLogin] = useState(null)
    let [session, setSession] = useState("")
    let [pendingChats, setPendingChats] = useState([])
    let [currentChats, setCurrentChats] = useState([])
    let [expand, setExpand] = useState(false)

    //public pendingChats: Array<ChatUnasigned> = [];

    // Variables que se comparten
    Content.values = {
        main: 
        {
            get Session(): string {
                return session
            },
            set Session(s: string) {
                setSession(s)
            },
            get Login(): boolean {
                return login
            },
            set Login(login: boolean) {
                setLogin(login)
            }
        },
        chat: {
            get CurrentChats(): Array<ChatBox> {
                return currentChats
            },
            set CurrentChats(_currentChats: Array<ChatBox>) {
                setCurrentChats(_currentChats)
            },
            get Expand(): boolean {
                return expand;
            },
            set Expand(_expand: boolean){
                setExpand(_expand);
            }
        }
    }

    return (
        <MilesContext.Provider value={ Content.values }>
            {children}
        </MilesContext.Provider>
    )
}