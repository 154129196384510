import IState from "../../../core/IState";
import { Pagination } from "../../control/ControlEntity";
import { Contact } from "../ContactEntity";

export default class ContactPageState implements IState {
    public pagination: Pagination = new Pagination();
    public loadingContacts: boolean = false;
    public contacts: Contact[] = [];
    public criteria: string = "";
    public contact: Contact;
}
