export const MilesLogo = "https://milescdnver.azureedge.net/build/images/logo/logo.svg"

  // Input styles (temporal)
 export const inputStyle = {
    backgroundImage: 'url(&quot;data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAASCAYAAABSO15qAAAAAXNSR0IArs4c6QAAAUBJREFUOBGVVE2ORUAQLvIS4gwzEysHkHgnkMiEc4zEJXCMNwtWTmDh3UGcYoaFhZUFCzFVnu4wIaiE+vvq6+6qTgthGH6O4/jA7x1OiCAIPwj7CoLgSXDxSjEVzAt9k01CBKdWfsFf/2WNuEwc2YqigKZpK9glAlVVwTTNbQJZlnlCkiTAZnF/mePB2biRdhwHdF2HJEmgaRrwPA+qqoI4jle5/8XkXzrCFoHg+/5ICdpm13UTho7Q9/0WnsfwiL/ouHwHrJgQR8WEwVG+oXpMPaDAkdzvd7AsC8qyhCiKJjiRnCKwbRsMw9hcQ5zv9maSBeu6hjRNYRgGFuKaCNwjkjzPoSiK1d1gDDecQobOBwswzabD/D3Np7AHOIrvNpHmPI+Kc2RZBm3bcp8wuwSIot7QQ0PznoR6wYSK0Xb/AGVLcWwc7Ng3AAAAAElFTkSuQmCC&quot;)', 
    backgroundRepeat: 'no-repeat', 
    backgroundAttachment: 'scroll', 
    backgroundSize: '16px 18px', 
    backgroundPosition: '98% 50%', 
    cursor: 'pointer'
  };

/**
 * LOGIN
 */
export const Password = "Password";
export const SignIn = "Sign In";
export const EmailAddress = "Email address";
export const RememberPassword = "Remember password";
export const ForgotPassword = "Forgot password"