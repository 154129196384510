import React from 'react';
import ComponentCommon from "../../core/ComponentCommon";
import PaginatorProp from '../../entity/control/props/PaginatorProp';
import PaginatorState from '../../entity/control/states/PaginatorState';
import { Button } from '../../../cuba/components/utils/reactstrap'
import { PaginatorButtonData } from '../../entity/control/ControlEntity';
import { _ } from '../../bl/admin/AdminLocaleBL';

/**
 * Controls the paging
 * @class Paginator
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class Paginator extends ComponentCommon<PaginatorProp, PaginatorState> {
    /**
     * Constructor
     * @param props Property 
     */
    constructor(props: PaginatorProp) {
        super(props);
        let me = this;
        me.state = new PaginatorState();
    }

    /**
     * On did mount
     */
    componentDidMount() {
        let me = this;
        me.prepare();
        me.update();
    }

    /**
     * Prepare default values
     */
    private prepare() {}

    /**
     * Update paginator
     */
    private update(){
        let me = this;
        me.setState({
            pagination: me.props.pagination
        });
    }

    /**
     * Items per page
     */
    private get PaginationSize(): number[] {
        return [20, 30, 50, 100, 200];
    }

    /**
     * Verify the parameter to show page sizes
     */
    private get ShowPageSizes(): boolean {
        let me = this;
        return me.props.showPageSizes == undefined || me.props.showPageSizes;
    }

	/**
	 * Change the results according to the selected page
	 * @param numberPage Page number
	 */
	public passPage(numberPage) {
		let me = this;
		let page = 0
		if (numberPage === 'back' || numberPage === 'next') {
			page = me.state.pagination.currentPage
		} else {
			page = numberPage
        }
		if (me.state.pagination.totalPages > page && page > 1) {
			if (numberPage === 'back') {
				page = me.state.pagination.currentPage - 1
			} else if (numberPage === 'next') {
				page = me.state.pagination.currentPage + 1
			}
		}

        me.setState({
            pagination: me.state.pagination.setCurrentPage(page)
        }, () => {
            me.props.onChange && me.props.onChange(me.state.pagination);
        });
    }

    /**
     * Calculate the number of visible buttons to avoid overflowing.
     */
    get ButtonList(): PaginatorButtonData {
        let me = this;
        let list = [];

        let paginatorData = new PaginatorButtonData();
        let maxButtons = null;

        if(window.innerWidth > 768){
            maxButtons = me.props.maxButtons !== undefined ? me.props.maxButtons : 7;
        }else{
            maxButtons = me.props.maxButtons !== undefined ? me.props.maxButtons : 3;
        }

        let currentPage = me.state.pagination.currentPage;
        let totalPages = me.state.pagination.totalPages;
        let half = Math.floor(maxButtons / 2);

        // Define current page ranges
        let startRange = currentPage - half;
        let endRange = currentPage + half;

        // Define next back button values
        let previewPage = currentPage > 1 ? currentPage - 1 : null;
        let nextPage = currentPage < totalPages ? currentPage + 1 : null;
        
        startRange = startRange > 0 ? startRange : 1;
        endRange = endRange > totalPages ? totalPages : endRange;
        let start_diff = currentPage - startRange;
        let end_diff = endRange - currentPage;

        // Define the initial and final range number according to the number of permitted buttons
        if(start_diff < half) {
            endRange += half - start_diff;
        } 
        if(end_diff < half) {
            startRange -= half - end_diff;
        } 

        startRange = startRange > 0 ? startRange : 1;
        endRange = endRange > totalPages ? totalPages : endRange;

        let count = startRange;
        while(count <= endRange){
            list.push(count);
            ++count;
        }
        

        // Fill out of data the page object
        paginatorData.range = list;
        paginatorData.lastPage = me.state.pagination.totalPages;
        paginatorData.previewPage = previewPage;
        paginatorData.nextPage = nextPage;
        paginatorData.haveStart = 1 < startRange;
        paginatorData.haveEnd = endRange < totalPages;

        return paginatorData;
    }

    /** Set pagination size */
    private setPaginationSize(size: number){
        let me = this;
        var pagination = me.state.pagination;
        pagination.items = size;
        pagination.setCurrentPage(1);
        me.setState({
            pagination: pagination
        });
        me.passPage(1);
    }

    render() {
        let me = this;
        let buttonList = me.ButtonList;
        return (
            <>
                {(me.props.visible && me.props.pagination.totalItems > 0) && 
                    <div className="text-center">
                        <ul className="paginator pagination-primary">
                            {/** NAVIGATION BUTTONS */}
                            {buttonList.previewPage && buttonList.haveStart &&
                                <>
                                    <li className="page-item"
                                        onClick={ () => me.passPage(1) }>
                                            <a className="page-link">
                                                <span aria-hidden="true">&#xab;</span>
                                            </a>
                                    </li>
                                    <li className="page-item"
                                        onClick={ () => me.passPage(buttonList.previewPage) }>
                                            <a className="page-link">
                                                <span aria-hidden="true">&#x2039;</span>
                                            </a>
                                    </li>
                                </>
                            }

                            {buttonList.range.map((e) => {
                                return <li key={e}
                                           className={`page-item ${e == me.state.pagination.currentPage ? 'active' : ''}` }>
                                    {/* color={ e == me.state.pagination.currentPage ? "secondary" : "white"} */}
                                    <a className="page-link" 
                                        onClick={ () => me.passPage(e) }>{e}
                                    </a>
                                </li>
                            })}

                            {/** NAVIGATION BUTTONS */}
                            {buttonList.nextPage && buttonList.haveEnd && 
                                <>
                                    <li className="page-item"
                                        onClick={ () => me.passPage(buttonList.nextPage) }>
                                            <a className="page-link">
                                                <span aria-hidden="true">&#x203A;</span>
                                            </a>
                                    </li>
                                    <li className="page-item"
                                        onClick={ () => me.passPage(buttonList.lastPage) }>
                                            <a className="page-link">
                                                <span aria-hidden="true">&#xbb;</span>
                                            </a>
                                    </li>
                                </>
                            }
                        </ul>
                        {/* <div className="text-center">
                            Página {me.state.pagination.currentPage} de {me.state.pagination.totalPages} ({me.state.pagination.totalItems} elementos)
                        </div> */}
                        {me.ShowPageSizes && 
                            <div className="input-group pill-input-group pagination-sizes">
                                <span className="input-group-text">{_("key_elements_per_page")} </span>
                                <select className="form-control" onChange={ e => me.setPaginationSize(parseInt(e.target.value)) } value={me.state.pagination.items}>
                                    {me.PaginationSize.map( e => 
                                        <option key={e} value={e}>{e}</option>
                                    )}
                                </select>
                            </div>
                        }
                    </div>
                }
            </>
        );
    }
}

export default Paginator;