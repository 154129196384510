import React from 'react';
import Swal, {SweetAlertResult} from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { _ } from '../../bl/admin/AdminLocaleBL';
import {ChatDisposition} from "../../entity/chat/ChatEntity";
import GeneralFunctions from "../../helper/GeneralFunctions";
const MySwal = withReactContent(Swal);

/**
 * Simplified messages interface with SweeTalert
 * @class Alert
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export default class Alert {
    /**
     * Displays an alert message to the user
     * @param title Title of the dialog box
     * @param message Content of the dialog box
     * @param icon Icon
     */
    public static show(title: any, message: any, icon: any = "success"){
        MySwal.fire({
            title: title,
            html: message,
            icon: icon
        });
    }

    /**
     * Displays an confirm dialog message to the user
     * @param title Title of the dialog box
     * @param message Content of the dialog box
     * @param icon Icon
     * @returns Promise dialog result
     */
    public static async confirm(title: any, message: any, icon: any = "warning"): Promise<SweetAlertResult> {
        return Swal.fire({
            title: title,
            text: message,
            icon: icon,
            showCancelButton: true,
            confirmButtonColor: 'hsl(359, 76%, 59%)',
            cancelButtonColor: '#209986',
            cancelButtonText: _("key_textbox_cancel"),
            confirmButtonText: _("key_alert_confirm")
        });
    }

    /**
     * Displays a message (correcto)
     * @param message Content of the dialog box
     */
    public static success(message: string = _("key_alert_success_message")){
        let me = this;
        me.show(_("key_alert_success"), message, "success");
    }

    /**
     * Displays a message (error)
     * @param message Content of the dialog box
     */
    public static error(message: string = _("key_alert_error_message")){
        let me = this;
        me.show(_("key_alert_error"), message, "error");
    }

    /**
     * Displays a message (cancelled)
     * @param message Content of the dialog box
     */
    public static cancelled(message: string = _("key_alert_cancel_message")){
        let me = this;
        me.show(_("key_alert_cancel"), message, "error");
    }

    /**
     * paint select disposition 
     * @param disposition
     */
    public static async typeSelect(disposition: any) {
        
        let dispositionInput: HTMLSelectElement;
        let reservationInput: HTMLInputElement;
        let selectDispositionControl = document.createElement("select");
        disposition.sort((a, b) => a.position - b.position);
        
        //arma select
        selectDispositionControl.setAttribute("id", "dispositionControl");
        selectDispositionControl.setAttribute("class", "form-control");
        let elementOption = document.createElement("option");
        elementOption.setAttribute("value", "")
        elementOption.innerText = "-";
        disposition.map((element: ChatDisposition) => {
            const optionSecundary = document.createElement("option");
            optionSecundary.setAttribute("value", `${element.code}`)
            optionSecundary.innerText = element.name;
            elementOption.appendChild(optionSecundary);
        });
        
        selectDispositionControl.appendChild(elementOption);
        
        return await Swal.fire({
            icon: "warning",
            title: _("key_chat_close"),
            showCancelButton: true,
            confirmButtonColor: 'hsl(359, 76%, 59%)',
            cancelButtonColor: '#209986',
            cancelButtonText: _("key_textbox_cancel"),
            confirmButtonText: _("key_alert_confirm"),
            html: ` <p>${_("key_chat_close_message")}</p>
                     <div class="form-group form-control-md mb-2">
                      ${selectDispositionControl.outerHTML}
                    </div>
                    <div class="form-group form-control-md mb-2">
                    <input type="text" id="reservationControl" class="form-control initialism d-none" placeholder="${_('key_reservation_number_title')}">
                    </div>
            `,
            focusConfirm: true,
            didOpen: () => {
                const popup = Swal.getPopup()!;
                dispositionInput = popup.querySelector('#dispositionControl') as HTMLSelectElement;
                reservationInput = popup.querySelector('#reservationControl') as HTMLInputElement;
                dispositionInput.onchange = () => {
                    if (dispositionInput.value == "VC"){
                        reservationInput.setAttribute("class", "form-control initialism");
                    } else {
                        reservationInput.value = ""
                        reservationInput.setAttribute("class", "form-control initialism d-none");
                        Swal.resetValidationMessage()
                    }
                };
                reservationInput.onkeyup = () => {
                    const testReservation = reservationInput.value
                    if(reservationInput && testReservation.length > 2) {
                        let isValidation = GeneralFunctions.validNumbReservation(reservationInput.value);
                        !isValidation ? Swal.showValidationMessage(_("key_valid_reservation_number")) : Swal.resetValidationMessage()
                    } else {
                        Swal.resetValidationMessage()
                    }
                    
                }
            },
            preConfirm: () => {
                const dispositionText = dispositionInput.value
                const reservationText = reservationInput.value
                const isValidationReservation = GeneralFunctions.validNumbReservation(reservationText);
                
                if (dispositionText == "VC" && !reservationText) {
                    Swal.showValidationMessage(_("key_reservation_text_select"))
                }else if (reservationText && !isValidationReservation){
                    Swal.showValidationMessage(_("key_valid_reservation_number"))
                } else if(!dispositionText) {
                    Swal.showValidationMessage(_("key_disposition_text_select"))
                }
                return {dispositionText, reservationText}
            },
        });
    }
}