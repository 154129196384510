import React from "react";
import ComponentCommon from "../../core/ComponentCommon";
import RateProp from "../../entity/control/props/RateProp";
import RateState from "../../entity/control/states/RateState";

/**
 * Rating control
 * @class Rate
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class Rate extends ComponentCommon<RateProp, RateState> {
    /**
     * Contructor
     * @param props Property 
     */
    constructor(props: RateProp){
        super(props);
        this.state = new RateState();
    }

    /**
     * Default stars number
     */
     public get Stars(): number {
        let me = this;
        var rate = me.props.stars == undefined ? 5 : me.props.stars;
        return rate;
    }

    /**
     * Component did mount
     */
    componentDidMount(){
        let me = this;
        me.prepare();
    }

    /**
     * Initialize component
     */
    private prepare() {
        let me = this;
        let value = me.props.value !== undefined ? me.props.value : 0;
        me.setState({
            rate: value
        });
    }   

    /**
     * Set component value
     * @param value Value
     */
    public setValue(value: number) {
        let me = this;

        // Readonly mode
        if(me.props.readonly){
            return;
        }

        me.setState({
            rate: value
        });

        // Call onchange event
        me.props.onChange && me.props.onChange(value);
    }
    
    /**
     * Get component values
     * @returns Value
     */
    public getValue(){
        let me = this;
        return me.state.rate;
    }

    render() {
        let me = this;
        return (
            <div className={`rate ${me.props.className}`}>
                {me.props.title?.length > 0 &&
                    <b>{me.props.title}: </b>
                }
                {Array.from(Array(me.Stars + 1).keys()).map( r => {
                    let rate = r;   
                    let className = `mr-1 fa fa-star${ rate > me.state.rate ? "-o" : " text-warning" }`;
                    if(r == 0 && me.props.readonly){
                        return;
                    }
                    if(r == 0 && !me.props.readonly){
                        className = "fa fa-ban text-danger mr-1";
                    }
                    return (
                        <i  key={r} 
                            onClick={ () => {me.setValue(rate) }} 
                            className={className}>
                        </i>
                    );
                })}
            </div>
        );
    }
}

export default Rate;
