import SecurityBL from "../../bl/security/SecurityBL";

/**
 * User login GroupPermissions entity
 */
 export class GroupPermissions {
    public idGroup : number = 0;
    public module : string = "";
    public segment : string = "Page";
    public controller : string = "Home";
    public show : boolean = false;
    public showConfiguration : boolean = false;
    public showHistory : boolean = false;
    public edit : boolean = false;
    public id : number = 0;
}

/**
 * Representa un rol del sistema
 */
export class Role {
    constructor(module: string = "", controller: string = "Home", segment: string = "Page"){
        this.module = module;
        this.controller = controller;
        this.segment = segment;
    }
    public id: number = 0;
    public module : string;
    public segment : string;
    public controller : string;
    public permission: string = "show";

    /**
     * Verificar rol de la ruta
     */
    public check(): boolean {
        let me = this;
        return SecurityBL.checkRole(me);
    }
}

/**
 * Subrole
 */
export class SubRole extends Role {
    constructor(module: string = "", title: string =""){
        super(module);
        this.title = title;
    }
    public title: string = "";
}

/**
 * UNA ESPECIE DE ENUMERADO..
 */
export enum GroupPermisionEnum {
    SWOW_CONFIGURATION = "showConfiguration",
    SWOW_HISTORY = "showHistory",
    EDIT = "edit",
    SWOW = "show"
}