import axios from 'axios';
import moment from 'moment';
import BaseBL from '../../core/BaseBL';
import { Language } from '../../entity/language/LanguageEntity';
import { PaginatedReponse, PaginatedRequest, Pagination } from '../../entity/control/ControlEntity';
import { LanguageSchedule, LanguageScheduleArray } from '../../entity/language/LanguageScheduleEntity';
import LoginBL from '../login/LoginBL';

/**
 * Perform operations in the contact API
 * @author Eduardo Escallon <eescallon@viajemos.com>
 * @class Language
 * */
export class LanguageBL extends BaseBL {

    private static _schedule: Array<LanguageScheduleArray> = [];

    /**
     * Get current system roles
     * @returns Groups
     */
    public static async getAllLanguages(criteria: string): Promise<Language[]>{
        let me = this;
        let data = {
            params: {
                criteria: criteria
            }
        };
        
        let response = await axios.get('/Home/LanguageAPI/GetAllLanguages', data);
        let result = me.getResponseData(response);
        result.data = me.parseEntityArray(result.data, Language);
        return result;
    }

    /**
     * Get current system roles
     * @returns Groups
     */
    public static async getLanguage(paramsData: PaginatedRequest): Promise<PaginatedReponse<Language>>{
        let me = this;
        let data = {
            params: paramsData
        };
        let response = await axios.get('/Home/LanguageAPI/ListLanguage', data);
        let responseData = me.getResponseData(response);
        var result = me.parseEntity(responseData, PaginatedReponse);
        result.data = me.parseEntityArray(result.data, Language);
        result.pagination = me.parseEntity(result.pagination, Pagination);
        return result;
    }

    /**
     * Take the action to save in the API.
     * @param group Entity object
     * @returns response
     */
     public static async updateLanguage(group: Language) {
        let me = this;
        let formData = me.getEntityFormData(group);
        let response = await axios.post('/Home/ContactAPI/UpdateContact', formData);
        let responseData = me.getResponseData(response);
        let groupUpdated = me.parseEntity(responseData, Language);
        return groupUpdated;
    }

    /**
     * Get the schedules assigned to the user
     * @param user User
     * @returns UserSchedule data
     */
    public static async getLanguageSchedule(language: Language, cache: boolean = true){
        let me = this;
        // if(me._schedule !== null && (language.id && me._schedule.length > 0) && cache){
        //     return me._schedule;
        // }
        var data = {
            params: {
                language: language.id
            }
        }
        let response = await axios.get('/Home/LanguageAPI/GetLanguageSchedule', data);
        
        let responseData = me.getResponseData(response);
        let result = me.parseEntityArray(responseData, LanguageSchedule);
        if (cache) {
            let languageFound = false
            me._schedule.map(sch => {
                if (sch.idLanguage == language.id) {
                    languageFound = true;
                    sch.languageSchedule = result
                }
            })
            if (!languageFound) {
                me._schedule.push({idLanguage: language.id, languageSchedule: result})
            }
        }
        return result;
    }

    /**
     * Save user schedule data
     * @param schedule User
     * @returns UserSchedule data
     */
    public static async saveLanguageSchedule(schedule: LanguageSchedule){
        let me = this;
        var data = me.getEntityFormData(schedule);
        let response = await axios.post('/Home/LanguageAPI/UpdateLanguageSchedule', data);
        let responseData = me.getResponseData(response);
        let result = me.parseEntity(responseData, LanguageSchedule);
        return result;
    }

    /**
     * Save user schedule data
     * @param schedule User
     * @returns UserSchedule data
     */
    public static async deleteLanguageSchedule(schedule: LanguageSchedule){
        let me = this;
        var data = me.getEntityFormData(schedule);
        let response = await axios.post('/Home/LanguageAPI/DeleteLanguageSchedule', data);
        let responseData = me.getResponseData(response);
        let result = me.parseEntity(responseData, LanguageSchedule);
        return result;
    }

    /**
     * Verify that the user is within the calendar and schedules configured to the user
     */
    public static async checkSchedule(language: Language, date: Date = null){
        !date && (date = new Date());
        let me = this;

        await me.getLanguageSchedule(language);
        return me.checkScheduleNoAsync(date, language);
    }

    /**
     * Verify that the user is within the calendar and schedules configured to the user
     */
    public static checkScheduleNoAsync(date: Date = null, language: Language){
        !date && (date = new Date());
        let me = this;
        let schedule = me._schedule;

        // If you do not have a configured schedule, it automatically accepts anyone
        // if you are administrador, manage all chats        
        if(schedule == null){
            return true;
        }

        const day_of_week = date.getDay();
        let have = false;
        console.log("schedule - " , schedule)
        schedule.forEach( sch => {
            if (language.id == sch.idLanguage) {
                sch.languageSchedule.map( day => {
                    if(day.day == day_of_week){
                        let start = moment(day.start, ['H:m']).toDate();
                        let end = moment(day.end, ['H:m']).toDate();

                        have = have || (start <= date && date <= end);
                    }
                })
            }
        })
        return have;
    }
}

export default LanguageBL;