import React from 'react';
import ComponentCommon from '../../core/ComponentCommon';
import { Row, Col, Card, CardBody, CardHeader, AlertBar, Switch, SwitchDsn, Form, Media, FormGroup, Label, Input, Select } from '../../../cuba/components/utils/reactstrap'
import UserFormProp from '../../entity/user/props/UserFormProp';
import UserFormState from '../../entity/user/states/UserFormState';
import SecurityBL from '../../bl/security/SecurityBL';
import UserBL from '../../bl/user/UserBL';
import UserLanguageList from './UserLanguageList';
import LoaderBackdrop from '../utils/LoaderBackdrop';
import UserScheduleList from './UserScheduleList';
import Alert from '../utils/Alert';
import FileUploader from '../../helper/FileUploader';
import { AuditorConfig } from '../../entity/auditor/AuditorEntity';
import AuditorLogList from '../auditor/AuditorLogList';
import { _ } from '../../bl/admin/AdminLocaleBL';

/**
 * Performs updated and saved user data operations
 * @class UserForm
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class UserForm extends ComponentCommon<UserFormProp, UserFormState>{
    
    private _auditorLog = null;
    private _auditorLogConfig: AuditorConfig = null;

    /**
     * Constructor
     * @param props 
     */
    public constructor(props){
        super(props);
        this.state = new UserFormState();
        this._auditorLogConfig = new AuditorConfig();
        this._auditorLog = React.createRef();
    }

    /**
     * Component did mount
     */
    componentDidMount() {
        let me = this;
        let user = me.props.user;
        user.password = "{ignore}";
        me.setState({
            user: user
        });
        me.loadRoles();
    }

    /**
     * Load the system roles to show them in the form of the form
     */
    private async loadRoles(){
        let me = this;
        let roles = await SecurityBL.getRoles();
        me.setState({
            roles: roles
        })

        // Set default groupID
        if(roles.length > 0 && !me.state.user.idGroup){
            let user = me.state.user;
            user.idGroup = roles[0].id;
            me.setState({
                user: user
            })
        }
    }

    /**
     * Event handler for inputs
     * @param event Event
     */
    private handleChange(event){
        let me = this;
        let dom = event.target;
        let value = dom.value;
        if(dom.type == "checkbox"){
            value = dom.checked;
        }
        let prop = dom.name;
        let user = me.state.user;
        user[prop] = value;
        me.setState({
            user: user
        });
    }
    
    /**
     * Take the request to the API to save or update the data.
     * @param e Event
     */
    private async processForm(e){
        let me = this;
        e.preventDefault();
        me.setState({busy: true});
        let userUpdated = await UserBL.saveUser(me.state.user);
        userUpdated.password = "{ignore}";

        if(UserBL.hasErrors()){
            var error_data = UserBL.getLastError()
            let key_error = "";
            switch(error_data.response){
                case 'username_taken':
                    key_error = "key_username_taken";
                    break;
            }
            Alert.error(_(key_error));
            me.setState({ busy: false })
            return;
        }

        Alert.show(_("key_user_save"), _("key_user_save_message"), "success");
        me.setState({
            user: userUpdated,
            busy: false
        })
        me._auditorLog.current?.update();
        me.props.onSave && me.props.onSave(userUpdated);
    }

    /**
     * Close the form, call the event to notify the parent component.
     */
    private closeForm() {
        let me = this;
        me.props.onClose && me.props.onClose(me.state.user);
    }

    /**
     * Cargar la imagen de perfil
     * @param e 
     */
    private uploadImage(e){
        let me = this;
        var upload = new FileUploader(e);
        
        var file = e.target.files[0];
        me.setState({
            loadingFile: true
        });
        upload.upload(file, e => {
            var user = me.state.user;
            if(e.file !== null){
                user.image = e.file.url;
                me.setState({
                    user: user,
                    loadingFile: false
                })
            }   
        });
    }

    /**
     * Handle changes in the components
     */
    private handleChanges(){
        let me = this;
        me._auditorLog.current?.update();
    }

    render() {
        let me = this;

        me._auditorLogConfig.clear();
        me._auditorLogConfig.addCriteria({module: "User", identifier: me.state.user?.id});

        if(!me.state.user){
            return <></>
        }

        return (
            <Form onSubmit={ e => me.processForm(e) }>
                <Row>
                    <Col sm="12">
                        <Card className="pl-4 pr-4">
                            <CardHeader>
                                {/* <button className="btn btn-primary m-2 pull-right" type="submit">{"Guardar cambios"}</button>
                                <button onClick={ () => me.closeForm() } className="btn btn-danger m-2 pull-right">{"Volver a listado"}</button>
                                <h2 className="card-title mb-0">Editar Perfil</h2> */}
                                <Row>
                                    <Col md="6" className="p-0">
                                        <h2 className="card-title mb-0">{_("key_edit")}</h2>
                                    </Col>
                                    <Col md="6" className="p-0">
                                        <button className="btn btn-primary my-2 mx-md-2 float-right"
                                                type="submit">{_("key_save")}
                                        </button>
                                        <button onClick={ () => me.closeForm() }
                                                className="btn btn-info my-2 mx-md-2 float-right">
                                                    {_("key_return")}
                                        </button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardHeader className="p-3">
                                <Row className="mb-4">
                                    <LoaderBackdrop visible={ me.state.loadingFile } message={_("key_user_loading_image")} />
                                    <div className="col-auto">
                                        <img className="img-70 rounded-circle update_img_0" src={ me.state.user.Avatar } alt=""/>
                                        <input className="updateimg" type="file" name="img" onChange={ e => me.uploadImage(e) } data-bs-original-title="" title=""/>
                                    </div>
                                    <Col>
                                        <h3 className="mb-1">{ me.state.user.firstName } { me.state.user.lastName }</h3>
                                        <p className="mb-4">{ me.state.user.userName }</p>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody className="p-3">
                                    <Row>
                                        <Col sm="6" md="4">
                                            <FormGroup>
                                                <h6 className="form-label">{_("key_username")}</h6>
                                                <input type="email" required={true} onChange={ e => me.handleChange(e) } name="userName" value={ me.state.user.userName } className="form-control" placeholder="your-email@domain.com" />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6" md="4">
                                            <FormGroup>
                                                <Label className="form-label">{_("key_email_alternative")}</Label>
                                                <input required={false} type="email" onChange={ e => me.handleChange(e) } name="emailAlternative" value={ me.state.user.emailAlternative } className="form-control" placeholder="your-email@domain.com" />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6" md="4">
                                            <FormGroup>
                                                <Label className="form-label">{_("key_name")}</Label>
                                                <input required={true} onChange={ e => me.handleChange(e) } name="firstName" value={ me.state.user.firstName } className="form-control" type="text" placeholder="First name" />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6" md="4">
                                            <FormGroup>
                                                <Label className="form-label">{_("key_lastname")}</Label>
                                                <input required={ true } onChange={ e => me.handleChange(e) } name="lastName" value={ me.state.user.lastName } className="form-control" type="text" placeholder="Last Name" />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6" md="4">
                                            <FormGroup>
                                                <Label className="form-label">{_("key_role")}</Label>
                                                <select required={ true } onChange={ e => me.handleChange(e) } name="idGroup" value={ me.state.user.idGroup || "" } className="form-control">
                                                    {me.state.roles.map( rol => {
                                                        return <option key={ rol.id } value={rol.id}>{rol.name}</option>
                                                    })}
                                                </select>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6" md="4">
                                            <FormGroup>
                                                <Label className="form-label">{_("key_phone")}</Label>
                                                <input required={true} onChange={ e => me.handleChange(e) } name="phone" value={ me.state.user.phone } className="form-control" type="text" placeholder="Teléfono" />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6" md="4">
                                            <FormGroup>
                                                <Label className="form-label">{_("key_city")}</Label>
                                                <input required={true} onChange={ e => me.handleChange(e) } name="city" value={ me.state.user.city } className="form-control" type="text" placeholder="City" />
                                            </FormGroup>
                                        </Col>
                                        <Col sm="6" md="4">
                                            <FormGroup>
                                                <Label className="form-label">{_("key_country")}</Label>
                                                <input required={true} onChange={ e => me.handleChange(e) } name="country" value={ me.state.user.country } className="form-control btn-square"/>
                                            </FormGroup>
                                        </Col>
                                        <Col sm="12" md="4">
                                            <SwitchDsn size="sm" name="enabled" title={_("key_enabled")} checked={ me.state.user.enabled } onChange={ e => me.handleChange(e) } />
                                            <SwitchDsn size="sm" name="changePassword" title={_("key_password_change")} checked={ me.state.user.changePassword } onChange={ e => me.handleChange(e) } />
                                        </Col>
                                    </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                { me.state.user.id > 0 ? 
                    ( /** Note: idgroup == 2 is equivalent to the group of agents, they are only shown for agents */
                    // me.state.user.idGroup == 2 &&
                        <Row>
                            <Col sm="4">
                                <Card>
                                    <AlertBar header={true}>{_("key_user_assigned_languages")}</AlertBar>
                                    <div className="m-4">
                                        <UserLanguageList onChange={ e => me.handleChanges() } user={me.state.user} />
                                    </div>
                                </Card>
                            </Col>
                            {/* <Col sm="8">
                                <Card>
                                    <AlertBar header={true}>{_("key_user_schedule")}</AlertBar>
                                    <div className="m-4">
                                        <UserScheduleList onChange={ e => me.handleChanges() }  user={me.state.user} />
                                    </div>
                                </Card>
                            </Col> */}
                        </Row>
                    )
                    :
                    <Card>
                        <CardBody> 
                            <h3>{_("key_user_before_message")}</h3>
                        </CardBody>
                    </Card>
                }
                { me.state.user.id > 0 && <AuditorLogList ref={ me._auditorLog } config={ me._auditorLogConfig }/> }
            </Form>
        )
    }
}

export default UserForm;