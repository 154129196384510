import IState from "../../../core/IState";
import { FormInputTranslate } from "../../../core/Translate";
import { UserLanguage } from "../../login/LoginEntity";
import { Faqs } from "../FaqsEntity";

export default class FaqsFormState implements IState {
    public selectedLanguage: string = "EN";
    public languages: UserLanguage[] = [];
    public forms: FormInputTranslate[] = [];
    
    public faq: Faqs = null;
}