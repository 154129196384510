import IState from "../../../core/IState";
import { Channel } from "../../channel/ChannelEntity";

export default class ChatCreateState implements IState {
    public profile: string = "";
    public channel: string = "";
    public platform: string = "";
    public showForm: boolean = false;   
    public channels: Channel[] = [];
    public busy: boolean = false;
    public openContactSearch: boolean = false;
}