import React from "react";
import FaqsPageProp from "../entity/faqs/props/FAQSPageProp";
import FaqsPageState from "../entity/faqs/states/FAQSPageState";
import { Card, CardBody, CardHeader, Form, Button, Row, Col, IconFa } from '../../cuba/components/utils/reactstrap'
import Paginator from "../component/control/Paginator";
import { PaginatedRequest, Pagination } from "../entity/control/ControlEntity";
import LoaderMini from "../component/utils/LoaderMini";
import { Faqs } from "../entity/faqs/FaqsEntity";
import { _ } from "../bl/admin/AdminLocaleBL";
import { FaqsBL } from "../bl/faqs/FaqsBL";
import FaqsForm from "../component/faqs/FaqsForm";
import PageCommon from "../core/PageCommon";

/**
 * FAQS Manager component
 * @class FAQSPage
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class FaqsPage extends PageCommon<FaqsPageProp, FaqsPageState> {
    //Paginator reference
    private _paginatorRef = null;

    /**
     * Constructor
     * @param props Property 
     */
    constructor(props: FaqsPageProp) {
        super(props)
        this._paginatorRef = React.createRef();
        this.state = new FaqsPageState();
    }

    /**
     * Component did mount
     */
    componentDidMount(){
        let me = this;
        me.searchResult();
        me.loadPlaces();
    }

    /**
     * Load places
     */
    private async loadPlaces(){
        let me = this;
        var places = await FaqsBL.getFaqsPlaces();  
        me.setState({
            places: places
        });
    }

    /**
     * Handle form submit
     * @param e Event
     */
    private handleFormSubmit(e){
        e.preventDefault();
        let me = this;
        me.searchResult(true);
    }

    /**
     * Select a faq and displays the preview
     * @param faq 
     */
    private selectFaq(faq: Faqs){
        let me = this;
        me.setState({
            faq: faq
        });
    }

    /**
     * Create group
     */
    private createFaq(){
        let me = this;
        let faq = new Faqs();
        me.setState({
            faq: faq
        })
    }

    /**
     * Make the query by page
     */
    private async searchResult(resetCount: boolean = false){
        let me = this;

        let request = new PaginatedRequest<any>();
        request.pagination = me.state.pagination;
        request.data = {
            criteria:  me.state.criteria,
            place: me.state.place
        };

        me.setState({loadingFaqs: true});
        let data = await FaqsBL.getFaqs(request);

        me.setState({
            loadingFaqs: false,
            faqs: data.data,
            pagination: resetCount ? data.pagination.setCurrentPage(1) : data.pagination
        }, () => {
            me._paginatorRef?.current?.update()
        });
    }

    /**
     * Update pagination state
     * @param pagination Pagination object
     */
    public passPage(pagination: Pagination){
        let me = this;
        me.setState({
            pagination: pagination
        }, () => {
            me.searchResult();
        });
    }

    /**
     * Handle on close
     */
    private handleClose(){
        let me = this;
        me.setState({
            faq: null
        })
    }

    render(){
        let me = this;

        return (
            <>
                { !me.state.faq ? 
                    <Card>
                        <CardHeader>
                            <h5>{_("key_mod_page_faqs")}</h5> 
                        </CardHeader>
                        <CardBody> 
                            <Form onSubmit={ e => me.handleFormSubmit(e) } className="mb-3">
                                <Row>
                                    <Col md="4">
                                        <input id="criteria_input" className="form-control" value={me.state.criteria} onChange={ e => me.setState({criteria: e.target.value })} placeholder={_("key_search_criteria")}/>
                                    </Col>
                                    <Col md="4">
                                        <div className="form-group mb-3">
                                            <select required={ true } onChange={ e => me.setState({place: parseInt(e.target.value)}) } name="country" value={ me.state.place } className="form-control">
                                                <option value={0}>{_("key_place_all")}</option>
                                                {me.state.places.map( (place) => {
                                                    return <option key={ place.id } value={place.id}>{place.code}: {place.name}</option>
                                                })}
                                            </select>
                                        </div>
                                    </Col>
                                    <Col md="4">
                                        <button className="btn btn-primary mt-3 mt-md-0 float-right" type="submit">
                                            <IconFa icon="search"/> {_("key_filter_results")}
                                        </button>
                                    </Col>
                                </Row>
                            </Form>
                            <table className="table table-m-2">
                                <thead>
                                    <tr>
                                        <th scope="col">{_("key_place")}</th>
                                        <th className="hide-md" scope="col">{_("key_question")}</th>
                                        <th className="hide-md" scope="col">{_("key_answer")}</th>
                                        <th scope="col">{_("key_state")}</th>
                                        <th scope="col">{_("key_actions")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {me.state.loadingFaqs &&
                                    <tr>
                                        <td colSpan={6}>
                                            <LoaderMini message={_("key_search_loading_results")}/>     
                                        </td>
                                    </tr>
                                }
                                {!me.state.loadingFaqs && me.state.faqs.map( (faq: Faqs) => 
                                    <tr key={faq.id}>
                                        <td>{faq.place.name}</td>
                                        <td className="hide-md">{faq.QuestionText}</td>
                                        <td className="hide-md">{faq.AnswerText}</td>
                                        <td>{faq.enabled ? _("key_enabled") : _("key_disabled")}</td>
                                        <td className="p-2 actions">
                                            <Button className="p-x-md-0" onClick={ e =>  me.selectFaq(faq)} size="sm">
                                                <IconFa icon="pencil"/> {_("key_edit")}
                                            </Button>
                                        </td>
                                    </tr>
                                )}
                                </tbody>
                            </table>                    
                            <Paginator 
                                visible={!me.state.loadingFaqs && me.state.faqs.length > 0} 
                                ref={ me._paginatorRef } 
                                pagination={me.state.pagination} 
                                onChange={ pagination => me.passPage(pagination) } />
                        </CardBody>
                    </Card>
                :
                    <FaqsForm onClose={ e => me.handleClose() } faq={ me.state.faq } />
                }
            </>
        );
    }
}
