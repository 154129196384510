import React from 'react';
import ComponentCommon from "../../core/ComponentCommon";
import FileUploader from '../../helper/FileUploader';
import Config from '../../core/component/chat/config/Config';
import AdminLocaleBL, {_} from '../../bl/admin/AdminLocaleBL';
import TextBoxProp from '../../entity/control/props/TextBoxProp';
import TextBoxState from '../../entity/control/states/TextBoxState';
import {Editor} from '@tinymce/tinymce-react';
import LoaderMini from '../utils/LoaderMini';
import Cookies from '../../helper/Cookies';
import axios from 'axios';
import {TEXTBOX_PLUGINS} from './textbox/plugins';
import BaseBL from '../../core/BaseBL';
import GeneralFunctions from '../../helper/GeneralFunctions';
import ApiTinyBL from "../../bl/api/tiny/ApiTinyBL";

// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';

/**
 * TinyMCE Component helper
 * @class TextBox
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class TextBox extends ComponentCommon<TextBoxProp, TextBoxState> {
    private _fileUploader: FileUploader = null;
    private _editor = null;
    private _tinymce = null;
    private _timeoutSpell: any = null;
    private _activeSpellButton = false;
    private _spellCallback = null;

    /**
     * Constructor
     * @param prop Property
     */
    constructor(prop: TextBoxProp) {
        super(prop);
        let me = this;
        me.prepare();
    }

    /**
     * Initialize component
     */
    private prepare() {
        let me = this;
        let baseUrl = Config.configuration.baseUrl;
        me._fileUploader = new FileUploader(baseUrl);
        me.state = new TextBoxState();
        me._tinymce = React.createRef();
    }

    /**
     * componentDidMount
     */
    async componentDidMount() {
        const me = this;
        await me.updateApiTinyTokenUpdated();
    }

    async updateApiTinyTokenUpdated() {
        const me = this;
        const apiTinyTokenUpdated = await ApiTinyBL.getActiveToken();
        me.setState({
            apiTinyTokenUpdated
        });
    }

    public get Id(): string {
        let me = this;
        return me.props.id;
    }

    public get Editor(): any {
        return this._editor;
    }

    /**
     * Verify if the current theme is dark
     */
    private get DarkTheme(): boolean {
        return Cookies.get("_theme") == "dark-only";
    }

    /**
     * Get the current configuration of the editor
     */
    public get Config(): any {
        let me = this;

        //Default config
        var data = {
            height: 250,
            menubar: false,
            plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount code'
            ],
            special_buttons: ["image"],
            toolbar: 'undo redo | styleselect | formatselect | bold italic | bullist numlist | imgBtn | code'
        }

        // Parameter config
        if (me.props.config) {
            data = me.props.config;
        }

        data = me.setAditionalConfig(data);

        if (me.DarkTheme) {
            var current_style = data["content_style"];
            current_style = current_style ? current_style : "";
            data["content_style"] = current_style + " body { background-color: black;color: #b3b3b3; } ";
        }
        return data;
    }

    /**
     * Initialize custom plugins
     * @param data Config data
     */
    private setCustomPlugins(data) {
        let me = this;
        var custom_plugins = data?.custom_plugins ? data.custom_plugins : [];

        for (var pluginName in TEXTBOX_PLUGINS) {
            if (custom_plugins.includes(pluginName)) {
                TEXTBOX_PLUGINS[pluginName].setup(me);
            }
        }
    }

    /**
     * Insert anchor tag (files)
     * @param image
     */
    insertFileLink(url: string, name: string) {
        let me = this;
        let anchorTag = `<a href="${url}" download target="_blank">${name}</a>`;
        me.insertHtml(anchorTag);
    }

    /**
     * Configure the editor (spell checker, etc)
     * @param data
     * @returns
     */
    private setAditionalConfig(data: any) {
        let me = this;

        if (data["setup_done"]) {
            return data;
        }
        data["setup_done"] = true;
        data["statusbar"] = me.props.statusBar == true;

        data["plugins"].push("spellchecker");
        data["toolbar"] += " | spellchecker";
        data["language"] = me.Language;
        data["menu"] = {
            tools: {title: 'Tools', items: 'spellchecker '}
        };

        // Keep reference
        var last_setup = data["setup"];
        data["setup"] = function (editor) {
            // Upload button
            me._editor = editor;
            if (last_setup) {
                last_setup(editor);
            }
            me.setCustomPlugins(data);

            // Flag spell checker
            me._editor.on('SpellcheckEnd', function () {
                me._activeSpellButton = false;
            });
            // Flag spell checker
            me._editor.on('SpellcheckStart', function () {
                me._activeSpellButton = true;
            });
        };

        let spellLanguage = AdminLocaleBL.Language.toLowerCase();

        if (me.props.spellLanguage) {
            spellLanguage = me.props.spellLanguage.toLowerCase();
        }

        data["spellchecker_language"] = spellLanguage;
        data["spellchecker_active"] = true;
        data["spellchecker_on_load"] = true;
        // Remove language list
        data["spellchecker_languages"] = 'Español=es,English=en,Português=pt';

        // Spell service call
        data["spellchecker_callback"] = async function (method, text, success, failure) {
            var matches = text.match(this.getWordCharPattern());
            matches = matches ? matches : [];
            let words = matches.join(",");
            await me.callSpellService(words).then(result => {
                var spellData = result.data?.data?.response;
                me._spellCallback && me._spellCallback(spellData);
                me._spellCallback = null;
                if (method === "spellcheck") {
                    var suggestions = {};
                    for (var index in spellData) {
                        var word = spellData[index];
                        suggestions[word.word] = word.suggestions;
                    }
                    success({words: suggestions, dictionary: []});
                } else if (method === "addToDictionary") {
                    // Add word to dictionary here
                    success();
                }
            }).then(() => {
                me._editor.notificationManager.close();
            });
        }
        return data;
    }

    /**
     * Call spell checker service
     * @param text Text to check
     * @returns Suggestions
     */
    private async callSpellService(words: string): Promise<any> {
        let me = this;
        var selectedLanguage = me._editor.plugins.spellchecker.getLanguage();
        var data = {
            words: words,
            language: selectedLanguage
        }
        var form = BaseBL.getEntityFormData(data);
        let result = await axios.post('/Home/SpellAPI/Check', form);
        return result;
    }

    /**
     * Check if element is visible
     * @param element Dom element
     * @returns True if visible
     */
    private isHidden(element) {
        return (element.offsetParent === null)
    }

    /**
     * Check the spelling
     */
    private executeSpellCommand(timeout: boolean = true) {
        let me = this;
        //clearTimeout(me._timeoutSpell);
        me._editor.notificationManager.close();
        //me._timeoutSpell = setTimeout(function(){
        if (me.isHidden(me._editor.getContainer())) {
            return;
        }

        if (me._activeSpellButton) {
            me._editor.editorCommands.execCommand("mceSpellCheck");
        }
        me._editor.editorCommands.execCommand("mceSpellCheck");
        //}, timeout ? 3000 : 50);
    }

    /**
     * Handle onKeyPress
     * @param e event
     */
    private handleKeyPress(e: any) {
        let me = this;
        //me.executeSpellCommand();
        me.props.onKeyPress && me.props.onKeyPress(e);
    }

    /**
     * Handle onKeyDown
     * @param e event
     */
    private handleKeyDown(e: any) {
        let me = this;
        me.props.onKeyDown && me.props.onKeyDown(e);
    }

    /**
     * Handle onKeyUp
     * @param e event
     */
    private handleKeyUp(e: any) {
        let me = this;
        me.props.onKeyUp && me.props.onKeyUp(e);
        var value = me.getValue();
        me.props.onChange && me.props.onChange(value);
    }

    /**
     * Clear tags from html text
     * @param msg Text to clean
     * @returns Formatted text
     */
    clearText(msg: string): string {
        let container = document.createElement("div");
        container.innerHTML = msg;
        return msg;
    }

    /**
     * Show dialog: choose insert image mode
     * @param image
     */
    showImagePreview(image: string) {
        let me = this;
        me.setState({
            uploadDialog: true,
            uploadedFile: image
        });
    }

    /**
     * Set uploading state
     * @param state
     */
    public setUploadingState(state: boolean) {
        let me = this;
        me.setState({
            uploadingFile: state
        });
    }

    /**
     * Process: dialog response (Image insert)
     * @param mode Mode
     */
    public insertImage(mode: number) {
        let me = this;
        me.setState({
            uploadDialog: false
        });

        let img_html = `<img src="${me.state.uploadedFile}"/>`;
        switch (mode) {
            case 1:
                // Insertar directamente en el evento
                me.props.onEnter && me.props.onEnter(img_html);
                break;
            case 2:
                // Insertar en el editor
                me.insertHtml(img_html);
                break;
        }
    }

    /**
     * Insert a text with HTML format in the ckeditor component
     * @param html Html text
     */
    public insertHtml(html: string) {
        let me = this;
        me._editor.insertContent(html);
    }

    /**
     * Allows a parent component to change the value of the ckeditor component
     * @param text Html text
     */
    public setValue(text: string) {
        let me = this;
        //me.insertHtml(text);
        me._editor.setContent(text);
    }

    /**
     * Get the current content of the editor
     * @returns string
     */
    public getValue() {
        let me = this;
        let text: string = me._tinymce.current.getContent()
        return text;
    }

    /**
     * Get the current text content of the editor
     * @returns string
     */
    public getText() {
        let me = this;
        return GeneralFunctions.stripTags(me.getValue());
    }

    /**
     * Execute spell checker
     */
    public spellCheck(callback = null) {
        let me = this;
        me._spellCallback = callback;
        me.executeSpellCommand();
    }

    /**
     * Editor language according to the current configuration.
     */
    private get Language() {
        var currentLocale = AdminLocaleBL.Language;
        switch (currentLocale) {
            case "ES":
                return "es";
            case "PT":
                return "pt_BR";
            default:
                return "en_US";
        }
    }

    private focus(editor) {
        setTimeout(function () {
            editor.editorManager.activeEditor.selection.select(editor.editorManager.activeEditor.getBody(), true);
            editor.editorManager.activeEditor.selection.collapse(false);
        }, 100);
    }

    render() {
        let me = this;
        return (
            <div id={`textbox_` + me.props.id}>
                {me.state.uploadDialog &&
                    <div id="backdrop" className="chat_backdrop">
                        <div className="chat_contenedor_image_preview">
                            <div>
                                <img src={me.state.uploadedFile}/>
                            </div>
                            <button onClick={() => me.insertImage(1)}
                                    className="action-send btn btn-sm btn-success">{_("key_textbox_send")}</button>
                            <button onClick={() => me.insertImage(2)}
                                    className="action-edit btn btn-sm btn-success ml-2">{_("key_textbox_editor")}</button>
                            <button onClick={() => me.insertImage(0)}
                                    className="action-cancel btn btn-sm btn-danger ml-2">{_("key_textbox_cancel")}</button>
                        </div>
                    </div>
                }
                <LoaderMini message={_("key_loading")} visible={me.state.uploadingFile}/>
                {me.state.apiTinyTokenUpdated && <Editor
                    apiKey={me.state.apiTinyTokenUpdated?.token}
                    onInit={async (_, editor) => {
                        me._editor = editor;
                        me._tinymce.current = editor;
                        me.focus(editor);
                        //me.props.spellCheckStart && me.executeSpellCommand(false);
                        if (me.state.apiTinyTokenUpdated?.token) {
                            const apiTinyTokenUpdated = await ApiTinyBL.updateTokenByUse(me.state.apiTinyTokenUpdated);
                            me.setState({apiTinyTokenUpdated});
                        }
                    }}
                    onKeyDown={(e) => me.handleKeyDown(e)}
                    onKeyUp={(e) => me.handleKeyUp(e)}
                    onKeyPress={e => me.handleKeyPress(e)}
                    initialValue={me.props.value}
                    init={me.Config}
                />}

                {/* <ReactQuill theme="snow"
                    value={me.props.value}
                    onKeyDown={(e) => me.handleKeyDown(e)}
                    onKeyUp={(e) => me.handleKeyUp(e)}
                    onKeyPress={ e => me.handleKeyPress(e) } /> */}
            </div>
        );
    }
}

export default TextBox;