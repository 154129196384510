/**
  * @Class AdminCookies 
*/
export class AdminCookies { 
	/**
	  * @function generateCookieValue
	  * @desc generate cookie value
	  * @param String basName Base Cookie name
	*/
	public static generateCookieValue(basName: string):string {
		return basName + (Date.now()+Date.now());
	} 

	/**
	  * @function setCookie
	  * @desc set cookie value
	  * @param String name Cookie name 
	  * @param String val  Cookie value
	*/
	public static setCookie(name: string, val: string):void {
	    const date = new Date();
	    const value = val;

	    // Set it expire in 2 hours
	    date.setTime(date.getTime() + (2 * 60 * 60 * 1000));
	    // date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000));

	    // Set it
	    document.cookie = name+"="+value+"; expires="+date.toUTCString()+"; path=/";
	}

	/**
	  * @function getCookie
	  * @desc get cookie value
	  * @param String name Cookie name
	*/
	public static getCookie(name: string):string {
	    const value = "; " + document.cookie;
	    const parts = value.split("; " + name + "=");
	    
	    if (parts.length == 2) {
	        return parts.pop().split(";").shift();
	    }
	}

	/**
	  * @function deleteCookie
	  * @desc delete cookie
	  * @param String name Cookie name
	*/
	public static deleteCookie(name: string):void {
	    const date = new Date();

	    // Set it expire in -1 days
	    date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));

	    // Set it
	    document.cookie = name+"=; expires="+date.toUTCString()+"; path=/";
	}

	/**
	  * @function defineCookie
	  * @desc define cookie
	  * @param String name Cookie name
	*/
	public static defineCookie(name: string):string {
		// console.log("SI ingresa al define cookie: "+document.cookie.indexOf(name));
		// validate Cookie Exist
		if (document.cookie.indexOf(name) == -1) {
			// set Cookie Value
			let cckValue = AdminCookies.generateCookieValue("supportChat_");
			// create cookie
			AdminCookies.setCookie(name, cckValue);
		}
		// default Return
		return AdminCookies.getCookie(name);
	}
}

export default AdminCookies;