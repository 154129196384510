import axios from "axios";
import BaseBL from "../../core/BaseBL";
import { PaginatedReponse, PaginatedRequest, Pagination } from "../../entity/control/ControlEntity";
import { Faqs } from "../../entity/faqs/FaqsEntity";
import { Place } from "../../entity/place/PlaceEntity";

/**
 * Performs Faqs Operations
 * @Class FaqsBL 
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class FaqsBL extends BaseBL {
    /**
     * Get current faqs places
     * @returns Place[]
     */
     public static async getFaqsPlaces(): Promise<Place[]>{
        let me = this;
        let response = await axios.get('/Home/FaqsAPI/GetPlaces');
        let responseData = me.getResponseData(response);
        let data = me.parseEntityArray(responseData, Place);
        return data;
    }

    /**
     * Get current faqs list
     * @returns Faqs
     */
     public static async getFaqs(paramsData: PaginatedRequest): Promise<PaginatedReponse<Faqs>>{
        let me = this;
        let data = {
            params: paramsData
        };
        let response = await axios.get('/Home/FaqsAPI/ListFaqs', data);
        let responseData = me.getResponseData(response);
        var result = me.parseEntity(responseData, PaginatedReponse);
        result.data = me.parseEntityArray(result.data, Faqs, (group: Faqs) => {
            group.place = me.parseEntity(group.place, Place);
            return group;
        });
        result.pagination = me.parseEntity(result.pagination, Pagination);
        return result;
    }

    /**
     * Take the action to save in the API.
     * @param faq Entity object
     * @returns response
     */
     public static async updateFaq(faq: Faqs) {
        let me = this;
        let formData = me.getEntityFormData(faq);
        let response = await axios.post('/Home/FaqsAPI/UpdateFaqs', formData);
        let responseData = me.getResponseData(response);
        let faqUpdated = me.parseEntity(responseData, Faqs);
        return faqUpdated;
    }

    /**
     * Take the action to save in the API.
     * @param faq Entity object
     * @returns response
     */
     public static async updateFaqGeolocation(faq: Faqs) {
        let me = this;
        let formData = me.getEntityFormData(faq);
        let response = await axios.post('/Home/FaqsAPI/UpdateFaqsGeolocation', formData);
        let responseData = me.getResponseData(response);
        let faqUpdated = me.parseEntity(responseData, Faqs);
        return faqUpdated;
    }
}