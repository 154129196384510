
import { UserInfo } from "../../entity/chat/ChatEntity";
import { PaginatedReponse, Pagination } from "../../entity/control/ControlEntity";
import { PaginatedRequest } from "../../entity/control/ControlEntity";
import axios from 'axios';
import { RequestState, RequestTrace } from "../../entity/request/RequestEntity";
import { User } from "../../entity/login/LoginEntity";
import BaseBL from "../../core/BaseBL";

/**
 * Perform a search of user information
 * @Class ChatRequest 
 * @author Samael Fierro <sfierro@viajemos.com>
*/
export class ChatUserInfoBL extends BaseBL {
	/**
	 * Request search: Get result from API
	 * @param baseUrl Base URL of the Miles API
	 * @param params Parameters
	 * @returns 
	 */
	public static async getUserInfo(paramsData: PaginatedRequest): Promise<PaginatedReponse<UserInfo>> {
        let me = this;
        let data = {
            params: paramsData
        };
        let response = await axios.get('/Home/ChatAPI/ListUserInfo', data);
        let responseData = me.getResponseData(response);
        var result = me.parseEntity(responseData, PaginatedReponse);
        result.data = me.parseEntityArray(result.data, UserInfo, (userInfo: UserInfo) => {
            userInfo.trace = me.parseEntityArray(userInfo.trace, RequestTrace, (trace: RequestTrace) => {
                trace.agent = me.parseEntity(trace.agent, User);
                return trace;
            });
            userInfo.state = me.parseEntity(userInfo.state, RequestState);
            return userInfo;
        });
        result.pagination = me.parseEntity(result.pagination, Pagination);
        return result;
	}
    
    /**
	 * Request search: Get result from API
	 * @param baseUrl Base URL of the Miles API
	 * @param params Parameters
	 * @returns 
	 */
	public static async getRequest(idRequest: number): Promise<UserInfo> {
        let me = this;
        let data = {
            params: {
                id: idRequest
            }
        };
        
        let response = await axios.get('/Home/ChatAPI/GetRequest', data);
        let responseData = me.getResponseData(response);
        var request = me.parseEntity(responseData, UserInfo, (userInfo: UserInfo) => {
            userInfo.trace = me.parseEntityArray(userInfo.trace, RequestTrace, (trace: RequestTrace) => {
                trace.agent = me.parseEntity(trace.agent, User);
                return trace;
            });
            userInfo.state = me.parseEntity(userInfo.state, RequestState);
            return userInfo;
        });

        return request;
	}
}

export default ChatUserInfoBL;