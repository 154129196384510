import NotifierBL from '../bl/admin/NotifierBL';
import BaseComponent from './BaseComponent';
import IProps from './IProp';
import IState from './IState';

export abstract class PageCommon<Props extends IProps, State extends IState> extends BaseComponent<Props, State>{
    /**
     * Constructor
     * @param props 
     */
    public constructor(props){
        super(props);
    }

    /**
     * Common calls
     */
    componentWillMount() {
        // Notify helper
        NotifierBL.setSection(this);
    }

    /**
     * Check hash parameters
     */
    public checkHash(){}
}
export default PageCommon;