import React, { Fragment } from 'react';
import LoginBL from '../bl/login/LoginBL';
import { ChatPersistence } from '../core/component/chat/ChatUtils';
import Config from '../core/component/chat/config/Config';
import { Chat, ChatPair } from '../entity/chat/ChatEntity';
import ChatPageProp from "../entity/chat/props/ChatPageProp";
import ChatPageState from "../entity/chat/states/ChatPageState";
import { Row, Col, Card, CardBody, Button, Form, Media } from '../../cuba/components/utils/reactstrap'
import LoaderBackdrop from '../component/utils/LoaderBackdrop';
import ChatBox from '../component/chat/ChatBox';
import LoaderMini from '../component/utils/LoaderMini';
import one from '../../cuba/assets/images/user/1.jpg';
import PageCommon from '../core/PageCommon';
import UserBL from '../bl/user/UserBL';
import ChatElement from '../component/chat/ChatElement';
import { Empty } from '../component/utils/Empty';
import ChatBL from '../bl/chat/ChatBL';
import Mercure from '../helper/Mercure';
import MercureListener from '../helper/MercureListener';
import { _ } from '../bl/admin/AdminLocaleBL';
import ChatCreate from '../component/chat/ChatCreate';
import UrlHash from '../helper/UrlHash';
import SecurityBL from '../bl/security/SecurityBL';
import Alert from '../component/utils/Alert';
import { MilesContext } from '../context/MilesContext';
import {ChatContentTabs} from "../component/utils/ChatContentTabs";
import {IUpdateVisibleNavigationLog} from "../entity/utils/props/ChatTabsProp";
import {ChatTabs} from "../component/utils/ChatTabs";
import NavigationBox from "../component/navigation/NavigationBox";
import {INewNavigation} from "../entity/navigation/states/NavigationLogState";
import {IUpdateNewNavigation, updateNewNavigation} from "../entity/navigation/props/NavigationLogProp";

/**
 * ChatPage: Main view
 * @class ChatPage
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class ChatPage extends PageCommon<ChatPageProp, ChatPageState> {

    private config: Config = null;
    private eventSource: EventSource = null;
    private mounted = false;
    static contextType = MilesContext;

    public constructor(prop: ChatPageProp){
        super(prop);
        this.state = new ChatPageState();
    }

    /**
     * Initialize chatmanager components
     */
    async prepare() {
        let me = this;
        me.config = new Config();
        await me.config.loadConfiguration();
        // Load last chatbox
        me.recoveryChats();
        // Load dispo
        me.loadUnassignedTopics();
        // Start mercure listen
        me.listenMercureEvents();
        // list chat transfer pending
        me.listChatTransferPend();
        //Load chat transfer
        me.notifyChatsTransfer();
    }

    componentDidMount(){
        if(super.componentDidMount){
            super.componentDidMount();
        }
        let me = this;
        !me.mounted && me.prepare();
        me.mounted = true;
    }

    componentWillUnmount(){
        let me = this;
        me.destroy();
    }

    /**
     * Destroy component, reset vars
     */
    public destroy(){
        let me = this;
        MercureListener.unsubscribe(MercureListener.MAIN_AGENT_TOPIC);
        me.setState({
            chatCurrent: [],
            chatAvailable: [],
            chatTransfer: []
        })
    }

    /**
     * Open the chat that agrees with the ID of the URL
     */
    public checkHash(){
        let me = this;
        var id = UrlHash.get("id");
        if(id){
            me.state.chatCurrent.map( chatPair => {
                if(chatPair.entity.id == parseInt(id)){
                    me.changeChatClick(chatPair);
                }
            });
        }
    }

    /**
     * detect Agent Event
     */
    async listenMercureEvents() {
        let me = this;
        MercureListener.subscribe(MercureListener.MAIN_AGENT_TOPIC, data => {
            me.loadUnassignedTopics(false);
            me.notifyChatsTransfer();
            me.listChatTransferPend();

            var dataObject: any = JSON.parse(data);
            let currentUsername = LoginBL.SessionData.userName;
            if(dataObject?.message == "update_chats" && dataObject?.users.includes(currentUsername)){
                me.recoveryChats();
            }
        });
    }

    /**
     * Create chatbox component and chatPair
     * It should only be called when the chatbox is created a single time
     * Ideally, it is to create only once a chatbox and avoid creating several of it
     * To avoid conflicts in the listening of events
     * @param chat Chat
     * @returns chatPair
     */
    private pushChat(chat: Chat) {
        let me = this;
        let pair = new ChatPair();
        pair.entity = chat;
        pair.component = null;

        // Update last message info: dashboard
        let onMessageHandler = (chatChanged: Chat) => {
            // Set current agent
            chatChanged.agent = LoginBL.SessionData;
            pair.entity = chatChanged;
            me.onChatCurrent(pair);
        }

        let component = <ChatBox onClose={ chat => me.onCloseChat(chat) } onMessage={ chat => onMessageHandler(chat) } key={chat.chatTopic} chat={chat} />;
        pair.component = component;

        return pair;
    }

    /**
     * Assign topic to agent
     * @param chat Chat element to asign
     * @param onComplete On complete callback (optional)
     * @returns none
     */
    public async assignChat(chat: Chat, onComplete: CallableFunction){
        let me = this;
        let config: Config = Config.configuration;

        if(chat.chatTopic.length < 1){
            return;
        }

        // Guardar chat
        ChatPersistence.add(chat.chatTopic);

        let data = await ChatBL.updateChatAgent(chat.chatTopic, LoginBL.SessionData.userName);
        if(data.id == 0){
            Alert.error(_("key_chat_has_been_taken"));
            return;
        }
        chat.dateAgentConnect = data.dateAgentConnect
        let chatPair = me.pushChat(chat);
        await me.onChatCurrent(chatPair);
        onComplete && onComplete(chatPair);
    }

    /**
     * load Unassigned Topics
     * @param loader false: not show loader
     */
    async loadUnassignedTopics(loader: boolean = true) {
        let me = this;
        // let languages = await UserBL.getLanguages(LoginBL.SessionData);
        // load Unassigned Chats
        me.setState({loadingChatAvailable: loader});
        // Anticipate the call verification call for the agent

        const pendingChats = await ChatBL.getUnassignedChats();

        me.setState({
            chatAvailable: pendingChats,
            loadingChatAvailable: false
        });
    }

    /**
     * Recover all chats topics stored (from cookies), call handlers
     */
    private async recoveryChats(){
        let me = this;

        let chats = await ChatBL.getAgentChats(LoginBL.SessionData.userName);
        // Remove chats that are not in the list
        var chatCurrent = me.state.chatCurrent.filter( chat => {
            return chats.find( c => c.chatTopic == chat.entity.chatTopic) != undefined;
        });

        me.setState({
            chatCurrent: chatCurrent,
            loadingChatCurrent: chats.length > 0
        }, async () => {
            // load chat pairs
            var pairs = chats.map(chat => {
                let chatPair = me.pushChat(chat);
                me.setState({loadingChatCurrent: false});
                return chatPair;
            });

            // Add chats to list
            for(var x in pairs){
                var chatPair = pairs[x];
                await me.onChatCurrent(chatPair);
            }

            // Check hash notification id, wait a second
            setTimeout(() => {
                me.checkHash();
            }, 1000);
        });
    }

    /**
     * Recover all chats topics stored (from cookies), call handlers
     */
    private async listChatTransferPend(){
        let me = this;

        let chats = await ChatBL.getListTransferChatsPend(LoginBL.SessionData.userName);
        me.setState({chatTransfer: chats})
    }

    /**
     * Notify chats Pending Transfer
     */
    private async notifyChatsTransfer(){
        let me = this;
        let chatsResponse = await ChatBL.getChatsTransferPend(LoginBL.SessionData.userName);

        if (chatsResponse.length > 0) {
            chatsResponse.map(async chat => {
                const result = await Alert.confirm(_("key_chat_transfer"), _("key_accept_chat_transfer", {agent_name: chat.chatAgent}));

                if (result.isConfirmed) {
                    await ChatBL.applyChatTransfer(chat, LoginBL.SessionData);
                    const data = JSON.stringify({
                        message: "update_chats",
                        users: [chat.chatAgent, LoginBL.SessionData.userName]
                    });
                    MercureListener.publish(MercureListener.MAIN_AGENT_TOPIC, data);
                } else {
                    await ChatBL.cancelChatTransfer(chat, result.dismiss);

                    const data = JSON.stringify({
                        message: "update_chats",
                        users: [LoginBL.SessionData.userName, chat.chatAgent]
                    });
                    MercureListener.publish(MercureListener.MAIN_AGENT_TOPIC, data);
                }
            });
        }
    }

    /**
     * Event handler: on chat updated
     * @param {*} chatPair ChatPair
     */
    private async onChatCurrent(chatPair: ChatPair) {
        let me = this;
        let exists = false;
        let chatCurrentNL = [];

        // Update chat info, check exists
        me.state.chatCurrent.forEach( c => {
            if(c.entity.chatTopic == chatPair.entity.chatTopic){
                c.entity = chatPair.entity;
                exists = true;
            }
            chatCurrentNL.push(c);
        })
        !exists && chatCurrentNL.push(chatPair);

        // Update user chat history
        // me.setState({chatCurrent: chatCurrentNL});
        !exists && await me.setStateAsync({chatCurrent: chatCurrentNL});
    }

    /**
     * Set state async
     * @param state State
     */
    private async setStateAsync(state) {
        let me = this;
        return new Promise((resolves) => {
            me.setState(state, () => resolves(0) )
        });
    }

    /**
     * On close chat event
     * @param chat Chat element
     */
    private onCloseChat (chat) {
        let me = this;
        let chatCurrentNL = [];
        me.state.chatCurrent.forEach( chatPair => {
            if(chat.chatTopic !== chatPair.entity.chatTopic){
                chatCurrentNL.push(chatPair);
            }
        });
        me.setState({chatCurrent: chatCurrentNL});
    }

    /**
     * Select available chat
     * @param {*} chat Chat
     */
    private selectAvailableChat (chat) {
        let me = this;
        me.setState({busy: true});
        me.assignChat(chat, chatPair => {
            // Quitar de la lista.
            let newAvailableList = me.state.chatAvailable.filter(c => c.chatTopic != chat.chatTopic);

            // Notify to disappear from the available list of other users
            Mercure.message(MercureListener.MAIN_AGENT_TOPIC, "agent", "SelectAgent", "");

            me.setState({
                busy: false,
                loadingChatCurrent: false,
                selectedChat: chatPair,
                chatAvailable: newAvailableList
            })
        });
    }


    /**
     * Select chat pending transfer
     * @param chat
     * @private
     */
    private async selectChatTransfer(chat){

        let me = this;
        const result = await Alert.confirm(_("key_chat_transfer"), _("key_accept_chat_transfer", {agent_name: chat.chatAgent}));
        if (result.isConfirmed) {
            await ChatBL.applyChatTransfer(chat, LoginBL.SessionData);
            const data = JSON.stringify({
                message: "update_chats",
                users: [chat.chatAgent, LoginBL.SessionData.userName]
            });
            MercureListener.publish(MercureListener.MAIN_AGENT_TOPIC, data);
        } else {
            await ChatBL.cancelChatTransfer(chat, result.dismiss);

            const data = JSON.stringify({
                message: "update_chats",
                users: [LoginBL.SessionData.userName, chat.chatAgent]
            });
            MercureListener.publish(MercureListener.MAIN_AGENT_TOPIC, data);
        }
    }



    /**
     * On change selected chat
     * @param chatPair Chat pair
     */
    private changeChatClick(chatPair) {
        let me = this;
        me.context.chat.Expand = false;
        me.setState({selectedChat: chatPair.entity});
        document.getElementById("tab-chat").click();
    }

    /**
     * Get user info from session
     * @param {*} prop Prop name
     * @param {*} defaults Default value
     * @returns
     */
    private UserData(prop, defaults = "") {
        let session = LoginBL.SessionData;
        return session[prop] ? session[prop] : defaults;
    }

    /**
     * Apply Filters to the current chat list
     */
    public get CurrentChatList(): ChatPair[] {
        let me = this;
        // Sort list
        return me.state.chatCurrent.sort(function(a, b) {
            if(a.entity.NewCount == b.entity.NewCount){
                return a.entity.LastMessageDate?.getTime() - b.entity.LastMessageDate?.getTime();
            }
            return a.entity.NewCount - b.entity.NewCount;
            // Apply text filter
        }).reverse().filter(chatPair => {
            let contains = chatPair.visible;
            if(me.state.filterCurrenChatText.length > 2) {
                contains = JSON.stringify(chatPair.entity.messages).includes(me.state.filterCurrenChatText);
            }
            return contains;
        });
    }

    /**
     * Apply Filters to the available chat list
     */
    public get AvailableChatList(): Chat[] {
        let me = this;

        // Apply schedule
        let userScheduleCheck: boolean = UserBL.checkScheduleNoAsync();
        // let languageScheduleCheck: boolean = LanguageBL.checkScheduleNoAsync();

        // If it is not within the configured schedule, you can not take chats
        if(!userScheduleCheck){
            return [];
        }

        // Sort list
        return me.state.chatAvailable.sort( (a, b) => {
            return a.LastMessageDate.getTime() - b.LastMessageDate.getTime();
        }).reverse();
    }

    /**
     * Apply Filters to the available chat list
     */
    public get ChatListTransfer(): Chat[] {
        let me = this;

        // Apply schedule
        let userScheduleCheck: boolean = UserBL.checkScheduleNoAsync();
        // let languageScheduleCheck: boolean = LanguageBL.checkScheduleNoAsync();

        // If it is not within the configured schedule, you can not take chats
        if(!userScheduleCheck){
            return [];
        }

        // Sort list
        return me.state.chatTransfer.sort( (a, b) => {
            return a.LastMessageDate.getTime() - b.LastMessageDate.getTime();
        }).reverse();
    }

    /**
     * Handle created chat
     * @param chat Created chat
     */
    private handleCreatedChat(chat: Chat){
        let me = this;
        var pair = me.pushChat(chat);
        me.onChatCurrent(pair);
        me.changeChatClick(pair);
    }

    private changeActiveChat(e, type){        
        e.preventDefault();
        switch (type) {
            case 'available':
                this.setState({ chatActiveAvailable: !this.state.chatActiveAvailable });
                break;
            case 'transfer':
                this.setState({ chatTransferAvailable: !this.state.chatTransferAvailable });                
                break;
        }
        
    }
    private updateVisibleNavigationLog: IUpdateVisibleNavigationLog = (isVisible: boolean) => {
        this.setState({visibleNavigationLog: isVisible});
    }
    private updateNewNavigations: IUpdateNewNavigation = (updateNewNavigations:INewNavigation[], chatTopic: string) => {
        const me = this;
        const updatedNewNavigations = updateNewNavigation(me.state.newNavigations, updateNewNavigations, chatTopic);
        this.setState({newNavigations: updatedNewNavigations});
    }

    render() {
        let me = this;
        const chatComponent = (<Card className="card-chat">
            <CardBody className="p-0">
                <Row className="chat-box">
                    <Col className="pr-0 chat-right-aside">
                        { me.state.chatCurrent.sort( (a, b) => a.entity.id - b.entity.id).map( chatPair => {
                            const currentChat = chatPair.entity;
                            const currentTopic = me.state.selectedChat && me.state.selectedChat.chatTopic == currentChat.chatTopic;
                            return <div key={currentChat.chatTopic} className={!currentTopic ? "d-none" : ""}>{chatPair.component}</div>;
                        })}
                    </Col>
                </Row>
            </CardBody>
        </Card>);
        const navigationUserComponent = (<Card className="card-chat-navigation">
            <CardBody className="p-0">
                <Row className="chat-box">
                    <Col className="pr-0 chat-right-aside">
                        { me.state.chatCurrent.sort( (a, b) => a.entity.id - b.entity.id).map( chatPair => {
                            const currentChat = chatPair.entity;
                            const currentTopic = me.state.selectedChat && me.state.selectedChat.chatTopic == currentChat.chatTopic;
                            return <div key={currentChat.chatTopic+"_navigation"} className={!currentTopic ? "d-none" : ""}>
                                <NavigationBox currentChat={currentChat} selectedChat={me.state.selectedChat}
                                               visible={me.state.visibleNavigationLog} updateNewNavigations={me.updateNewNavigations}/>
                            </div>;
                        })}
                    </Col>
                </Row>
            </CardBody>
        </Card>);
        return (
            <Fragment>
                <LoaderBackdrop visible={ me.state.busy } message={_("key_loading_selected")} />
                <div className="chat-container-agent">
                    <div className={`chat-current ${me.context.chat.Expand ? "chat-current--expand" : ""}`}>
                        <Card className="card-chat">
                        <CardBody>
                            <div className="chat-box">
                            <div className="chat-left-aside">
                                <div className="media">
                                    <Media src={ me.UserData('Avatar', one) } className="rounded-circle user-image" alt="" />
                                    {!me.context.chat.Expand &&
                                    <div className="about">
                                        <div className="name f-w-600">{`${me.UserData('firstName')} ${me.UserData('lastName')}`}</div>
                                        <div className="status">
                                            {me.UserData('userName')}
                                        </div>
                                    </div>
                                    }
                                </div>
                                <div className="people-list">
                                    {!me.context.chat.Expand &&
                                    <div className="search">
                                        <Form onSubmit={ e => e.preventDefault() } className="theme-form">
                                            <div className="mb-3">
                                                <input
                                                    onChange={ e => me.setState({filterCurrenChatText: e.target.value}) }
                                                    value={ me.state.filterCurrenChatText }
                                                    className="form-control"
                                                    type="text"
                                                    placeholder={_("key_chat_find")}
                                                    />
                                                <i className="fa fa-search search-icon"></i>
                                            </div>
                                        </Form>
                                    </div>
                                    }
                                    <LoaderMini visible={me.state.loadingChatCurrent} message={_("key_chat_loading_current")}/>
                                    { me.state.chatCurrent.length > 0 ?
                                        <>
                                            <ul className="list chat-container custom-sccrollbar">
                                                { me.CurrentChatList.map((chatPair, i) => {
                                                    let active = me.state.selectedChat && me.state.selectedChat.chatTopic == chatPair.entity.chatTopic;
                                                    //let newCount = chatPair.chat.NewCount;
                                                    return (
                                                        <ChatElement minimize={me.context.chat.Expand} key={i} active={active} chat={chatPair.entity} onClick={ () => me.changeChatClick(chatPair) }/>
                                                    );
                                                })}
                                            </ul>
                                        </>
                                    :
                                        <Empty visible={!me.state.loadingChatCurrent} />
                                    }
                                </div>
                            </div>
                            </div>
                        </CardBody>
                        </Card>
                    </div>
                    <div className="chat-viewer">
                        { me.state.chatCurrent.sort( (a, b) => a.entity.id - b.entity.id).map( chatPair => {
                            const currentChat = chatPair.entity;
                            return <ChatTabs key={Math.random()} updateVisibleNavigationLog={me.updateVisibleNavigationLog} visibleNavigationLog={me.state.visibleNavigationLog} selectedChat={me.state.selectedChat} currentChat={currentChat} newNavigations={me.state.newNavigations}></ChatTabs>;
                        })}
                        <ChatContentTabs chatComponent={chatComponent} visibleNavigationLog={me.state.visibleNavigationLog}></ChatContentTabs>
                        <ChatContentTabs navigationUserComponent={navigationUserComponent} visibleNavigationLog={me.state.visibleNavigationLog}></ChatContentTabs>
                    </div>
                    {me.context.chat.Expand &&
                        <div className="chat-plus" id="expand_container"></div>
                    }
                    <ul className="sidebar-links custom-scrollbar" >
                        <div className={`pl-0  chat-box chat-new ${me.state.menuToggle || true ? 'show' : ''}`}>
                            {/* Chats  Available*/}
                            <li className="sidebar-list" >
                                <a 
                                    href="javascript" 
                                    className={`sidebar-link sidebar-title`} 
                                    onClick={(event) => {me.changeActiveChat(event, 'available')}  }
                                >
                                    <div className="pt-3 text-center">
                                        <h4 className="lan-1">
                                            {me.state.chatActiveAvailable ?
                                                <i className="fa fa-angle-down"></i>
                                                : <i className="fa fa-angle-right"></i>
                                            }
                                            {"  "}
                                            {_("key_chat_available")}
                                        </h4>
                                    </div>
                                </a>
                                {me.state.chatActiveAvailable &&
                                    <>
                                        <hr className="mb-1"/>
                                        {SecurityBL.checkGranted("ChatCreate") &&
                                            <ChatCreate onCreate={ chat => me.handleCreatedChat(chat) } onChange={ state => me.setState({showChatAvailable: !state }) } />
                                        }
                                        { me.state.showChatAvailable &&
                                            <div className="people-list">
                                                <LoaderMini visible={me.state.loadingChatAvailable} message={_("key_chat_loading_available")}/>
                                                <ul className="list chat-container custom-scrollbar">
                                                    {me.AvailableChatList.length > 0 ?
                                                        me.AvailableChatList.map((chat, i) =>
                                                        <ChatElement key={i} active={false} showCounter={true} chat={chat} onClick={ () => me.selectAvailableChat(chat) }/>
                                                    ):
                                                        <Empty visible={!me.state.loadingChatAvailable}/>
                                                    }
                                                </ul>
                                            </div>
                                        }
                                    </>
                                }
                            </li>

                            {/* Chats  Transfer*/}
                            {/* <li className="sidebar-list" >
                                { (LoginBL.SessionData.idGroup === 1 || LoginBL.SessionData.idGroup === 2 || LoginBL.SessionData.idGroup === 3  || LoginBL.SessionData.idGroup === 7 ) &&
                                <Fragment>
                                    <hr className="mb-1"/>
                                    <a 
                                        href="javascript" 
                                        className={`sidebar-link sidebar-title`} 
                                        onClick={(event) => {me.changeActiveChat(event, 'transfer')}  }
                                    >
                                        <div className="pt-3 text-center">
                                            <h4 className="lan-1">
                                                {me.state.chatTransferAvailable ?
                                                    <i className="fa fa-angle-down"></i>
                                                    : <i className="fa fa-angle-right"></i>
                                                }
                                                {"  "}
                                                {_("key_chat_transfer")}
                                            </h4>
                                        </div>
                                    </a>
                                    <hr className="mb-1"/>
                                    {me.state.chatTransferAvailable &&
                                        <>
                                            { me.state.showChatAvailable &&
                                                <div className="people-list">
                                                    <LoaderMini visible={me.state.loadingChatAvailable} message={_("key_chat_loading_available")}/>
                                                    <ul className="list chat-container custom-scrollbar">
                                                        {me.ChatListTransfer.length > 0 ?
                                                        me.ChatListTransfer.map((chat, i) =>
                                                            <ChatElement key={i} active={false} showCounter={true} chat={chat} onClick={ () => me.selectChatTransfer(chat) }/>
                                                        ):
                                                        <Empty visible={!me.state.loadingChatAvailable}/>
                                                        }
                                                    </ul>
                                                </div>
                                            }
                                        </>
                                    }
                                </Fragment>
                                }
                            </li> */}

                        </div>
                    </ul>
                </div>
            </Fragment>
        );
    }
}

export default ChatPage;