import axios from 'axios';
import BaseBL from '../../core/BaseBL';
import { GroupUser } from '../../entity/login/LoginEntity';
import { GroupPermissions, Role } from '../../entity/security/SecurityEntity';
import LoginBL from '../login/LoginBL';

/**
 * Perform operations in the security API
 * @author Samael Fierro <sfierro@viajemos.com>
 * */
export class SecurityBL extends BaseBL {
    /**
     * Get current user session data
     */
    private static get session_data(){
        return LoginBL.SessionData;
    }

    /**
     * Get current system roles
     * @returns Roles
     */
    public static async getRoles(){
        let me = this;
        let response = await axios.get('/Home/SecurityAPI/GetRoles');
        let responseData = me.getResponseData(response);
        let userLista = new Array<GroupUser>();
        userLista = me.parseEntityArray(responseData, GroupUser, (group: GroupUser) => {
            group.groupPermissions = me.parseEntityArray(group.groupPermissions, GroupPermissions)
            return group;
        });
        return userLista;
    }

    /**
     * Gets the permission object attached to the current user according to the specified parameters.
     * @param module Module name
     * @param controller Controller name
     * @param segment Segment name
     * @returns 
     */
     public static getPermision(module: string, controller: string, segment: string): GroupPermissions {
        let me = this;
        let permision = new GroupPermissions();
        me.session_data && me.session_data.groupUser.groupPermissions.forEach( currentPermision => {
            if(currentPermision.module == module 
                && currentPermision.controller == controller 
                && currentPermision.segment == segment) {
                permision = currentPermision;
            }
        })
        return permision;
    }

    /**
     * 
     * @param array 
     * @param permision 
     * @returns 
     */
    public static checkPermisionArray(array, permision: string = "show"): boolean {
        if(!array || !array["module"]){
            return true;
        }
        let me = this;
        let permisionFinal = array["permision"] ? array["permision"] : permision;
        return me.checkPermision(array["module"], array["controller"], array["segment"], permisionFinal);
    }

    /**
     * 
     * @param array 
     * @param permision 
     * @returns 
     */
    public static checkRole(role: Role, permission: string = "show"): boolean {
        if(!role){
            return true;
        }
        let me = this;
        let permisionFinal = role.permission ? role.permission : permission;
        return me.checkPermision(role.module, role.controller, role.segment, permisionFinal);
    }

    /**
     * Check the permission object attached to the current user according to the specified parameters.
     * @param module  Module name
     * @param controller Controller name
     * @param segment Segment name
     * @param permision Property name (Use GroupPermisionEnum)
     * @returns 
     */
    public static checkPermision(module: string, controller: string, segment: string, permision: string): boolean {
        let me = this;
        let permisionObj = me.getPermision(module, controller, segment);
        if(!permisionObj) return false;
        return permisionObj[permision] || me.isAdmin();
    }

    /**
     * Check permission (Reduced version)
     * @param module  Module name
     * @param permision Property name (Use GroupPermisionEnum)
     * @returns 
     */
    public static checkGranted(module: string, permission: string = "show"): boolean {
        let me = this;
        var role = new Role(module);
        return me.checkPermision(module, role.controller, role.segment, permission);
    }

    /**
     * Check if current user is admin
     * @returns True if admin
     */
    private static isAdmin(): boolean{
        let me = this;
        if(me.session_data && me.session_data.isAdministrator) {
            return true;
        }
        return false;
    }

    /**
     * Verify the strength of a password
     * @param password Password to check
     * @returns 0: Weak 1: Medium 2: Strong
     */
    public static getPasswordStrongLevel(password: string): number {
        let level = 0;
        let strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')
        let mediumPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))');
        
        if(strongPassword.test(password)) {
            level = 2;
        } else if(mediumPassword.test(password)) {
            level = 1;
        } else {
            level = 0;
        }
        return level;
    }
}

export default SecurityBL;