import IState from '../../../core/IState';

export class TimePickerState implements IState {
    public defaultState?: boolean = false;
    public selectedTime: string = "";
    public showList: boolean = false;
    public selected: boolean = false;

    public hours: number = 0;
    public minutes: number = 0;
    public ap: string = "AM";
}

export default TimePickerState;