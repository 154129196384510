import React, { Fragment,useState,useEffect,useContext } from 'react';
import ReactDOM from 'react-dom';
import { Container } from 'reactstrap';
import './index.scss';
import App from './components/app';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './store'
import { BrowserRouter,Switch,Route, useHistory, Redirect, HashRouter } from 'react-router-dom'
import { CSSTransition,TransitionGroup } from 'react-transition-group'
import { ROUTES } from '../tsc/constants/router';
import ConfigDB from './data/customizer/config'
import { classes } from './data/layouts';
import LoginForm from '../tsc/component/login/LoginForm';
import { MilesContext, MilesContextProvider, Content } from '../../Resource/tsc/context/MilesContext';
import LoginBL from '../tsc/bl/login/LoginBL';
import SecurityBL from '../tsc/bl/security/SecurityBL';
import Loader from './layout/loader';
import ResetPassword from '../tsc/component/login/ResetPassword';
import Breadcrumb from './layout/breadcrumb'; //'../../../layout/breadcrumb'
import AdminLocaleBL, { _ } from '../tsc/bl/admin/AdminLocaleBL';
import Cookies from '../tsc/helper/Cookies';

const Root = (props) =>  {
  let milesContext = useContext(MilesContext);
  const history = useHistory();
  const [anim, setAnim] = useState("");
  const [login, setLogin] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [loading, setLoading] = useState(true);
  const animation = localStorage.getItem("animation") || ConfigDB.data.router_animation || 'fade'
  const abortController = new AbortController();
  const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
  const layout = localStorage.getItem('layout') ||  Object.keys(defaultLayoutObj).pop();
  const isDark = Cookies.get("_theme") === "dark-only";

  const updateInterface = (state) => {
    // Show reset password form
    setLogin(state);
    checkResetPassword();
  }

  const setTheme = () => {
    if (!isDark) {
      document.body.className = "light";
    } else {
      document.body.className = "dark-only";
    }
  }

  const start = () => {
    setTheme();
    // Load languages
    AdminLocaleBL.load().then(() => {
      // Check login
      LoginBL.check(true).then( logged => {
        setLogin(logged);
        setLoading(false);
        logged && checkResetPassword();
      })
    })
  }

  // Verify reset password.
  const checkResetPassword = () => {
    let userLogin = LoginBL.SessionData;
    setResetPassword(LoginBL.SessionData.changePassword);
  }

  // Close reset password route!
  const onResetPassword = () => {
    setResetPassword(false);
  }

  useEffect(() => {
    setAnim(animation)
    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;
    start();

    return function cleanup() {
      abortController.abort();
    }
  }, []);


  return(
    <MilesContextProvider>
      <Fragment>
        { loading ? 
          <Loader /> : 
        (!login ? 
          <HashRouter basename={`/`}>
            <Switch>
              <Route exact path="/">
                <LoginForm updateInterface={ (state) => updateInterface(state) }/> 
              </Route>
              <Route path="/recovery/:id">
                <ResetPassword />
              </Route>
            </Switch>
          </HashRouter>
        :( resetPassword ? 
          <ResetPassword onClose={ () => onResetPassword() }/>
        :
        <Provider store={store}>
            <BrowserRouter basename={`/`}>
              <Switch>
                <App>
                    <TransitionGroup>
                      {ROUTES.map(({ parent, title, route, role, Component, visible }) => (role.check() || !visible) && (
                          <Route key={route} exact path={route}>
                              {({ match }) => (
                                  <CSSTransition 
                                  in={match != null}
                                  timeout={100}
                                  classNames={anim} 
                                  unmountOnExit
                                  >
                                    <div>
                                      <Breadcrumb parent={parent || 'App'} title={title || "Seccion"} />
                                      <Container fluid={true}>
                                          <Component/>
                                      </Container>
                                    </div>
                                  </CSSTransition> 
                              )}
                          </Route>
                      ))}
                    </TransitionGroup>
                </App>
              </Switch>
            </BrowserRouter>
          </Provider>
        ))}
      </Fragment>
    </MilesContextProvider>
  )
}
ReactDOM.render(<Root/>,
  document.getElementById('root')
);

serviceWorker.unregister();
