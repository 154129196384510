import React from "react";
import ComponentCommon from "../../core/ComponentCommon";
import { AlertBar, Card, CardBody } from '../../../cuba/components/utils/reactstrap'
import AdminLocaleBL, { _, __ } from "../../bl/admin/AdminLocaleBL";
import LoaderMini from "../utils/LoaderMini";
import Switch from "../control/Switch";
import { NotificationListState } from "../../entity/notification/states/NotificationListState";
import NotificationListProp from "../../entity/notification/props/NotificationListProp";
import { Notification, UserNotification } from "../../entity/notification/NotificationEntity";
import NotifierBL from "../../bl/admin/NotifierBL";
import NotificationBL from "../../bl/admin/NotificationBL";

/**
 * Performs updated and saved NotificationList data operations
 * @class NotificationList
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class NotificationList extends ComponentCommon<NotificationListProp, NotificationListState> {
    //Paginator reference
    private _paginatorRef = null;

    /**
     * Constructor
     * @param props Property 
     */
    public constructor(props: NotificationListProp) {
        super(props);
        this.state = new NotificationListState();
    }

    /**
     * Component did mount
     */
    componentDidMount(){
        let me = this;
        me.prepare();
    }

    /**
     * Initialize component
     */
    private async prepare(){
        let me = this;
        await me.getNotifications();
        me.getUserNotifications();
    }

    /**
     * Get related permissions
     */
     private async getNotifications(){
        let me = this;
        me.setState({busy:true});
        let notifications = await NotificationBL.getNotifications()
        me.setState({
            notifications: notifications,
            busy: false
        })
    }

    /**
     * Get related permissions
     */
    private async getUserNotifications(){
        let me = this;
        me.setState({busy:true});
        let userNotifications = await NotificationBL.getUserNotification(me.props.group.id);

        userNotifications = me.state.notifications.map(notification => {
            for(var x in userNotifications) {
                var current = userNotifications[x]; 
                if(current.idNotification == notification.id) {
                    return current;
                }
            }
            var newUserNot = new UserNotification();
            newUserNot.enabled = false;
            newUserNot.idNotification = notification.id;
            newUserNot.idGroup = me.props.group.id;
            newUserNot.notification = notification;
            return newUserNot
        });

        me.setState({
            userNotifications: userNotifications,
            busy: false
        })
    }


    /**
     * Handle permission changes
     * @param checked Check status
     * @param role Role
     */
    private handleChange(checked: boolean, userNotification: UserNotification){
        let me = this;
        me.props.onChange && me.props.onChange();

        var userNotifications = me.state.userNotifications.map( n => {
            if(userNotification.idNotification == n.idNotification){
                n.enabled = checked;
            }
            return n;
        });

        me.setState({
            userNotifications: userNotifications
        });
    }

    /**
     * Save changes
     */
    public async save(){
        let me = this;
        me.state.userNotifications.map( async (n) => {
            await NotificationBL.updateUserNotification(n);
        });
    }

    render () {
        let me = this;

        return (
            <Card>
                <AlertBar className="mb-0" header={true}> {_("key_notify_active")}</AlertBar>
                <LoaderMini visible={me.state.busy} message={_("key_loading")} />
                <CardBody>
                    {!me.state.busy && me.state.userNotifications.map((notification) => 
                    {
                        return(
                            <div key={notification.id}>
                                <Switch 
                                    size="sm" 
                                    title={__(notification.notification)} 
                                    onChange={ e => me.handleChange(e.target.checked, notification) } 
                                    checked={notification.enabled} 
                                    />
                            </div>
                        );
                    })}
                </CardBody>
            </Card>
        );
    }
}

export default Notification;