/**
  * @Class Cookies 
  * @author Samael Fierro <sfierro@energizee.com>
*/
export class Cookies { 

	/**
	  * @function set
	  * @desc set cookie value
	  * @param String name Cookie name 
	  * @param String val  Cookie value
	*/
	public static set(name: string, val: string = "true"):void {
	    const date = new Date();
	    const value = val;
	    date.setTime(date.getTime() + (2 * 60 * 60 * 1000));
	    document.cookie = name+"="+value+"; expires="+date.toUTCString()+"; path=/";
	}

	/**
	  * @function get
	  * @desc get cookie value
	  * @param String name Cookie name
	*/
	public static get(name: string, defaults: string = null):string {
	    const value = "; " + document.cookie;
	    const parts = value.split("; " + name + "=");
	    if (parts.length == 2) {
	        return parts.pop().split(";").shift();
	    }
        return defaults;
	}

	/**
	  * @function delete
	  * @desc delete cookie
	  * @param String name Cookie name
	*/
	public static delete(name: string):void {
	    const date = new Date();
	    date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));
	    document.cookie = name+"=; expires="+date.toUTCString()+"; path=/";
	}
}

export default Cookies;