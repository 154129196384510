import React, { useRef, useState } from 'react';
import * as r from 'reactstrap';

export const Row = (props) => { return <r.Row {...props}></r.Row> }
export const Col = (props) => { return <r.Col {...props}></r.Col> }
export const Card = (props) => { return <r.Card {...props}></r.Card> }
export const CardHeader = (props) => { return <r.CardHeader {...props}></r.CardHeader> }
export const CardBody = (props) => { return <r.CardBody {...props}></r.CardBody> }
export const CardFooter = (props) => { return <div className="card-footer" {...props}></div> }

export const Media = (props) => { return <r.Media {...props}></r.Media> }
export const Form = (props) => { return <r.Form {...props}></r.Form> }
//export const FormGroup = (props) => { return <r.FormGroup {...props}></r.FormGroup> }
//export const Input = (props) => { return <r.Input {...props}></r.Input> }
//export const InputGroup = (props) => { return <r.InputGroup {...props}></r.InputGroup> }
//export const InputGroupAddon = (props) => { return <r.InputGroupAddon {...props}></r.InputGroupAddon> }
export const Button = (props) => { return <r.Button {...props}></r.Button> }
export const Nav = (props) => { return <r.Nav {...props}></r.Nav> }
//export const NavItem = (props) => { return <r.NavItem {...props}></r.NavItem> }
export const NavLink = (props) => { return <r.NavLink {...props}></r.NavLink> }
export const TabContent = (props) => { return <r.TabContent {...props}></r.TabContent> }
export const TabPane = (props) => { return <r.TabPane {...props}></r.TabPane> }
export const Container = (props) => { return <r.Container {...props}></r.Container> }



// HTML componentes
export const FormGroup = (props) => { return <div className="mb-3" {...props}></div> }
export const InputGroup = (props) => { return <div {...props}></div> }
export const InputGroupAddon = (props) => { return <div {...props}></div> }
export const Label = (props) => { return <label className="form-label" {...props}></label> }
export const Select = (props) => { return <select className="form-control" {...props}></select> }
export const TextArea = (props) => { return <textarea className="form-control" {...props}></textarea> }
export const Input = (props) => { 
    let my_props = {};
    Object.keys(props).forEach( key => {
        if(!["children"].includes(key)){
            my_props[key] = props["key"];
        }
    })
    my_props["className"] = (my_props["className"] ? my_props["className"] : "") + " form-control";
    return <input {...my_props}></input> 
}

export const SwitchDsn = (props) => {
    var [ checked, setChecked ] = useState(props.checked);
    var ref = useRef(null);
    var color = checked ? (props.color ?? "primary") : "";
    var sizeClass = props.size ? `switch-${props.size}` : ""; 
    var outline = props.outline == true;
    var labelClass = "";
    
    var onChange = e => {
        e.target.checked = !checked;
        props.onChange && props.onChange(e);
    }

    var onClick = e => {
        setChecked(!checked);
    }

    switch (props.size) {
        case "sm":
            labelClass = "h4";
            break;
        default:
            labelClass = "h2";
            break;
    }

    return (
        <div className="media mb-2">
            <div className={`text-end ${outline ? "switch-outline" : ""} ${sizeClass}`}>
                <label className="d-block">
                    <input className="checkbox_animated" 
                           type="checkbox"
                           name={ props.name }
                           ref={ ref }
                           onChange={ e => onChange(e)}
                           checked={ checked }
                           title={ props.title }
                           onClick={ e => onClick(e) } />                
                </label>
            </div>
            {props.title && <label onClick={ e => ref.current?.click() } className={`${labelClass} ml-3 text-${color}`}>{props.title}</label>}
        </div>
    );
}
export const IconFa = (props) => {
    return <i className={`fa ${props.icon ? "fa-" + props.icon : ""}`}></i>
}
export const AlertBar = (props) => {
    var header = props.header;
    var title = props.title;
    var children = props.children;
    var hasHeader = props.header !== undefined;
    var className = props.className;

    if(header == true){
        hasHeader = true;
        header = children;
        children = null;
    }

    return (
        <div className={`alert alert-${props.type ? props.type : 'primary'} ${header ? 'alert-header' : ''} ${className}`}>
            {hasHeader && <h4 className="alert-heading">{header}</h4>}
            {hasHeader && children && 
                <>
                    <p>{children}</p>
                </> 
            }
            {!hasHeader && children && 
                <>
                    {(title && <b>{title}</b>)} {children}
                </>
            }
            {props.close == true && <button type="button" className="btn-close"></button>}
        </div>
    );
}