import React, { useState } from "react";
import DatePicker from 'react-datepicker';
import AdminLocaleBL, { _ } from "../../../../bl/admin/AdminLocaleBL";
import { Row, Col, IconFa, Button } from '../../../../../cuba/components/utils/reactstrap'
import ReportFormProps from "../../../../entity/report/props/ReportFormProps";
import { ReportsBL } from "../../../../bl/report/ReportBL";
import { ADMIN_SITES } from "../../../../constants/data/sites";

/**
 * Generate CSV
 */
export const DownloadCommentsCSV = async (dateStart, dateEnd) => {
    var data = {
        dateStart,
        dateEnd
    }

    var result = await ReportsBL.getResults("ChatComments", data);

    // Add headers
    result = [... [{
        createdDate: _("key_date"),
        topic: _("key_chat_topic"),
        language: _("key_language"),
        rate: _("key_rate"),
        comment: _("key_related_comments")
    }], ...result];

    // Create csv
    var csvData = "";
    result.forEach((row, i) => {
        var key_rating_label = i > 0 ? _("key_chat_rating" + row.rate) : row.rate;
        csvData += `${row.createdDate};${row.topic};${row.language};${key_rating_label};${row.comment}\n`;
    });
    window.open("data:text/csv;charset=utf-8," + escape(csvData))
}

/**
 * Report form
 */
export const RatingForm = (props: ReportFormProps) => {
    const [dateStart, setDateStart] = useState(new Date());
    const [dateEnd, setDateEnd] = useState(new Date());
    const [siteCode, setSiteCode] = useState("");

    let data = {
        dateStart,
        dateEnd,
        siteCode
    };

    return (
        <form onSubmit={ e => props.onSubmit(e, data)}>
            <Row>
                <Col>
                    <div className="form-group">
                        {/*<label>{_("key_sites")}: </label><br/>*/}
                        <select onChange={ e => setSiteCode(e.target.value) } name="siteCode" value={ siteCode } className="form-control">
                            <option value={""}>{_("key_sites_all")}</option>
                            {ADMIN_SITES.map( (rp) => {
                                return <option key={ rp.code } value={rp.code}>{rp.name}</option>
                            })}
                        </select>
                    </div>
                </Col>
                <Col>
                    <DatePicker 
                        className="form-control" 
                        locale={AdminLocaleBL.Language.toLowerCase()} 
                        maxDate={ dateEnd } 
                        onChange={ e => setDateStart(e) } 
                        selected={dateStart}  
                        required={true}/>
                </Col>
                <Col>
                    <DatePicker 
                        className="form-control" 
                        locale={AdminLocaleBL.Language.toLowerCase()} 
                        minDate={ dateStart } 
                        maxDate={ new Date() } 
                        onChange={ e => setDateEnd(e) } 
                        selected={dateEnd} 
                        required={true} />
                </Col>
            </Row>
            <hr/>
            <div className="text-center pt-2">
                <button type="submit" className="btn btn-success">{_("key_filter_results")}</button>
                <Button 
                    variant="info"
                    className="ml-2"
                    onClick={ () => DownloadCommentsCSV(dateStart, dateEnd) }>
                    <IconFa icon="file"/> {_("key_comments_export")}
                </Button>
            </div>
        </form>
    );
}