import React from 'react';
import ComponentCommon from "../../core/ComponentCommon";
import RequestTraceElementProp from '../../entity/request/Props/RequestTraceElementProp';
import RequestTraceElementState from '../../entity/request/States/RequestTraceElementState';

/**
 * Represents the follow-up of an agent to request
 * @class RequestTraceElement
 * @author Samael Fierro <sfierro@viajemos.coom>
 */
export class RequestTraceElement extends ComponentCommon<RequestTraceElementProp, RequestTraceElementState> {
    constructor(props: RequestTraceElementProp){
        super(props);
        this.state = new RequestTraceElementState();
    }
    
    render(){
        let me = this;
        return (
            <div className="your-msg">
                <div className="media">
                    <img className="img-50 img-fluid m-r-20 rounded-circle" alt="" src={me.props.trace.agent.Avatar} />
                        <div className="media-body">
                            <span className="f-w-600">
                                {me.props.trace.agent.firstName} {me.props.trace.agent.lastName}
                                <span className="mr-2"> {me.props.trace.CreatedDateText}</span>
                            </span>
                        <p>{me.props.trace.content}</p>
                    </div>
                </div>
            </div>
        );
    }
}