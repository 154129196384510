import axios from 'axios';
import BaseBL from "../../../core/BaseBL";
import ApiTinyToken from "../../../entity/api/tiny/ApiTinyTokenEntity";
import {PaginatedReponse, PaginatedRequest, Pagination} from "../../../entity/control/ControlEntity";

/**
 * Perform operations in the Tiny API
 * @author Juan Serrano <jserrano@viajemos.com>
 * @class ApiTinyBL
 * */
export default class ApiTinyBL extends BaseBL {
    /**
     * Get all tokens of the API Tiny
     * @returns Groups
     */
    public static async getAllTokens(paramsData: PaginatedRequest): Promise<PaginatedReponse<ApiTinyToken>>{
        const me = this;
        const data = {
            params: paramsData
        };
        const response = await axios.get('/Home/AdminTinyAPI/GetAllTokens', data);
        const responseData = me.getResponseData(response);
        const result = me.parseEntity(responseData, PaginatedReponse);
        result.data = me.parseEntityArray(result.data, ApiTinyToken);
        result.pagination = me.parseEntity(result.pagination, Pagination);
        return result;
    }

    /**
     * Get An Active API Tiny Token
     * @returns Groups
     */
    public static async getActiveToken(): Promise<ApiTinyToken> {
        const me = this;
        const response = await axios.get(`/Home/AdminTinyAPI/GetActiveToken`);
        const responseData = me.getResponseData(response);
        return me.parseEntity(responseData, ApiTinyToken);
    }

    /**
     * Take the action to save in the API by use (Making an increment in amount of uses).
     * @param apiTinyTokenUpdated Entity object
     * @returns response
     */
    public static async updateTokenByUse(apiTinyTokenUpdated: ApiTinyToken) {
        const me = this;
        const formData = me.getEntityFormData(apiTinyTokenUpdated);
        const response = await axios.post('/Home/AdminTinyAPI/UpdateTokenByUse', formData);
        const responseData = me.getResponseData(response);
        return me.parseEntity(responseData, ApiTinyToken);
    }

    /**
     * Take the action to save in the API.
     * @param apiTinyTokenUpdated Entity object
     * @returns response
     */
    public static async updateToken(apiTinyTokenUpdated: ApiTinyToken) {
        const me = this;
        const formData = me.getEntityFormData(apiTinyTokenUpdated);
        const response = await axios.post('/Home/AdminTinyAPI/UpdateToken', formData);
        const responseData = me.getResponseData(response);
        return me.parseEntity(responseData, ApiTinyToken);
    }
}