import React from "react";
import PageCommon from "../core/PageCommon";
import ReportPageProp from "../entity/report/props/ReportPageProps";
import ReportPageState from "../entity/report/states/ReportPageState";
import { Card, Row, Col, IconFa } from '../../cuba/components/utils/reactstrap'
import { REPORTS_LIST } from "../constants/report";
import { _ } from "../bl/admin/AdminLocaleBL";
import { Empty } from "../component/utils/Empty";
import { ReportsBL } from "../bl/report/ReportBL";
import { Report, ReportCategory } from "../entity/report/ReportEntity";
import UrlHash from "../helper/UrlHash";

/**
 * ReportPage
 * @class ReportPage
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class ReportPage extends PageCommon<ReportPageProp, ReportPageState> {
    /**
     * Constructor
     * @param props Property 
     */
    constructor(props: ReportPageProp) {
        super(props);
        this.state = new ReportPageState();
    }

    /**
     * Component did mount
     */
    componentDidMount(){
        let me = this;
        me.setDefaults();
    }

    /**
     * Get default category
     * @returns Category
     */
    private setDefaults() {
        let me = this;
        var defaultCategory = REPORTS_LIST[0];
        var defaultReport = defaultCategory.reports[0];

        var category = UrlHash.get("category", null);
        var report = UrlHash.get("report", null);

        var selectedCategory = REPORTS_LIST.filter( c => c.name == category)[0];
        var selectedReport = selectedCategory?.reports.filter( r => r.report == report )[0];
        selectedCategory = selectedCategory ? selectedCategory : defaultCategory;
        selectedReport = selectedReport ? selectedReport : defaultReport;
        
        me.setState({
            currentCategory: selectedCategory,
            currentReport: selectedReport
        });
    }

    /**
     * Set current category
     * @param category Category
     */
     private setCategory(category: ReportCategory) {
        let me = this;
        me.setState({
            currentCategory: category,
            currentReport: null
        });
    }

    /**
     * Set current report
     * @param report Report
     */
    private setReport(report: Report) {
        let me = this;
        me.setState({
            currentReport: report
        });
    }

    /**
     * Handle form submit
     * @param e 
     */
    private handleSubmit(e: any, data: any){
        let me = this;
        e.preventDefault();
        me.getResult(data);
    }

    /**
     * Get report results
     * @param data 
     */
    private async getResult(data: any){
        let me = this;
        var data = await ReportsBL.getResults(me.state.currentReport.report, data);

        me.setState({
            data: data
        });
    }

    render() {
        let me = this;

        return (
            <>
                <Row>
                    <Col sm={12} md={3}>
                        <ul className="list-group">
                            <li className="text-center  list-group-item active">{_("key_reports")}</li>
                            {REPORTS_LIST.map( (c, i) => 
                                <React.Fragment key={i}>
                                    <li onClick={ () => me.setCategory(c) } 
                                        className={`list-group-item` + (me.state.currentCategory == c ? ' list-group-item-danger' : '')}>
                                        <IconFa icon="circle"/> { c.Title}
                                    </li>
                                    {me.state.currentCategory == c && c.reports.map( (r, ii) => 
                                        <li key={`${i}_${ii}`} 
                                            onClick={ () => me.setReport(r) } 
                                            className={"p-2 pl-3 ml-4 list-group-item " + (me.state.currentReport == r ? ' list-group-item-primary' : '')}>
                                            <IconFa icon="file"/>  {r.Name}
                                        </li>
                                    )}
                                </React.Fragment>
                            )}
                        </ul>
                    </Col>
                    <Col sm={12} md={9}>
                        {me.state.currentCategory && 
                            <Card>
                                <div className="p-3">
                                    <h4>
                                        {_(me.state.currentCategory.title)}
                                        {me.state.currentReport &&
                                            <><IconFa icon="arrow-right ml-2"/> {me.state.currentReport.Name}</>
                                        }
                                    </h4>
                                </div>
                                {me.state.currentReport ? 
                                    <>
                                        <div className="p-3">
                                            <me.state.currentReport.form onSubmit={ (e, d) => me.handleSubmit(e, d) }/>
                                        </div>
                                        {me.state.data && <me.state.currentReport.result data={me.state.data}/>}
                                    </>
                                : 
                                    <Empty />
                                }
                            </Card>
                        }
                    </Col>
                </Row>
            </>
        );
    }
}

export default ReportPage;
