import axios from "axios";
import BaseBL from "../../core/BaseBL";
import { LocationNote, LocationNoteTranslate } from "../../entity/location/LocationEntity";

/**
 * Get and manage Locations
 * @class LocationNoteBL
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class LocationNoteBL extends BaseBL {
    /**
     * Save the specified static content
     * @param locationNote Location
     * @returns Updated object
     */
     public static async getLocationNotes(locationId: number): Promise<LocationNote[]>{
        let me = this;
        let data = {
            params: {
                id: locationId
            }
        };

        let response = await axios.get('/Home/LocationAPI/GetLocationNotes', data);
        let responseData = me.getResponseData(response);
        let result = me.parseEntityArray(responseData, LocationNote, (note: LocationNote) => {
            note.translates = me.parseEntityArray(note.translates, LocationNoteTranslate);
            return note;
        });
        return result;
    }

    /**
     * Save the specified static content
     * @param locationNote Location
     * @returns Updated object
     */
     public static async updateLocationNote(locationNote: LocationNote): Promise<LocationNote> {
        let me = this;
        let formData = me.getEntityFormData(locationNote);
        let response = await axios.post('/Home/LocationAPI/UpdateLocationNote', formData);
        let responseData = me.getResponseData(response);
        let result = me.parseEntity(responseData, LocationNote);
        return result;
    }

    /**
     * Save the specified static content
     * @param locationNote Location
     * @returns Updated object
     */
     public static async createLocationNote(locationNote: LocationNote): Promise<any> {
        let me = this;
        let formData = me.getEntityFormData(locationNote);
        let response = await axios.post('/Home/LocationAPI/CreateLocationNote', formData);
        var responseFormatted = me.formatResponse(response);

        if(!response.data?.result) {
            return 1;
        }

        let responseData = me.getResponseData(response);
        let result = me.parseEntity(responseData, LocationNote);
        return result;
    }

    /**
     * Save the specified static content
     * @param locationNoteTranslate Location
     * @returns Updated object
     */
     public static async updateLocationNoteTranslate(locationNoteTranslate: LocationNoteTranslate): Promise<LocationNoteTranslate> {
        let me = this;
        let formData = me.getEntityFormData(locationNoteTranslate);
        let response = await axios.post('/Home/LocationAPI/UpdateLocationNoteTranslate', formData);
        let responseData = me.getResponseData(response);
        let result = me.parseEntity(responseData, LocationNoteTranslate);
        return result;
    }
}