import React from "react";
import { _ } from "../../bl/admin/AdminLocaleBL";
import { Place } from "../../entity/place/PlaceEntity";

/**
 * Default Place listing element of the component
 */
 export const ISPlace = (props) => {
    var data = props.data as Place;

    var places = [];
    let loop = true;
    let lastPlace = data;
    while(loop) {
        let type = "";
        switch (lastPlace.typePlace) {
            case 1:
                type = _("key_country");
                break;
            case 2:
                type = _("key_state");
                break;
            case 3:
                type = _("key_city");
                break;
        }
        places.push({
            code: lastPlace.code,
            type: type,
            typePlace: lastPlace.typePlace,
            name: lastPlace.name
        });
        if(lastPlace.parent?.id > 0){
            lastPlace = lastPlace.parent;
        } else {
            loop = false;
        }
    }

    // Remove continents
    let finalPlaces = places.filter( p => p.typePlace > 0);
    if(finalPlaces.length < 1) {
        finalPlaces = places;
    }
    
    return (
        <div className={`p-1`}>
            {finalPlaces.map( (p, i) => 
                <div key={i} className={ p.typePlace < 3 && finalPlaces.length > 1 ? "small" : ""}>
                    <b>{p?.code}: </b>{p?.name} {p.typePlace < 3 && p.type && <span className="text-primary">({p.type})</span>} 
                </div>
            )}
        </div>
    );
}
