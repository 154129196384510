import axios from "axios";
import BaseBL from "../../core/BaseBL";
import {
  Agent,
  Chat,
  ChatDisposition,
  ChatRate,
  ChatTransferLog,
  UserInfo
} from "../../entity/chat/ChatEntity";
import {PaginatedReponse, PaginatedRequest, Pagination} from "../../entity/control/ControlEntity";
import {User} from "../../entity/login/LoginEntity";

/**
 * Performs Chat Operations
 * @Class ChatBL
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class ChatBL extends BaseBL {

  /**
   * Get chats dispositions (API)
   */
  public static async getChatDispositions() {
    let me = this;
    let response = await axios.get('/Home/ChatAPI/GetDispositions');
    let responseData = me.getResponseData(response);
    var suggestions = me.parseEntityArray(responseData, ChatDisposition);
    return suggestions;
  }

  /**
   * Set chats dispositions (API)
   * @param chatTopic Chat topic
   * @param codeDisposition Disposition identifier
   * @param reservation Reservation Number
   * @returns Updated element
   */
  public static async setChatDisposition(chatTopic: string, codeDisposition: string, reservation: string) {
    let me = this;
    let data = {
      chatTopic: chatTopic,
      codeDisposition: codeDisposition,
      reservationNumber: reservation
    };
    let formData = me.getEntityFormData(data);
    let response = await axios.post('/Home/ChatAPI/UpdateDisposition', formData);
    let responseData = me.getResponseData(response);
    const suggestion = me.parseEntity(responseData, ChatDisposition);
    return suggestion;
  }

  /**
   * Set chats agent (API)
   * @param chatTopic Chat topic
   * @param userAgent User agent
   * @returns Updated element
   */
  public static async updateChatAgent(chatTopic: string, userAgent: string) {
    let me = this;
    let data = {
      chatTopic: chatTopic,
      userAgent: userAgent
    };
    let formData = me.getEntityFormData(data);
    let response = await axios.post('/Home/ChatAPI/UpdateChatAgent', formData);
    let responseData = me.getResponseData(response);
    var suggestion = me.parseEntity(responseData, Chat);
    return suggestion;
  }

  /**
   * Transfer chat to agent (API)
   * @param chat Chat element
   * @param user User element
   * @returns Updated element
   */
  public static async transferChatAgent(chat: Chat, user: User) {
    let me = this;
    let data = {
      chatTopic: chat.chatTopic,
      user: user.id
    };
    let formData = me.getEntityFormData(data);
    let response = await axios.post('/Home/ChatAPI/TransferChatAgent', formData);
    let responseData = me.getResponseData(response);

    return responseData
  }

  /**
   * load Unassigned Chats //loadUnassignedChats
   * @param languages Filter by language
   * @returns List of chats available
   */
  public static async getUnassignedChats() {
    let me = this;

    let response = await axios.get('/Home/ChatAPI/GetUnassignedChats');
    let responseData = me.getResponseData(response);
    var chats = me.parseEntityArray(responseData, Chat, (chat: Chat) => {
      chat.messages = JSON.parse(chat.messages as any);
      chat.agents = me.parseEntityArray(chat.agents, Agent);
      chat.agent = me.parseEntity(chat.agent, Agent);
      return chat;
    });
    return chats;
  }

  /**
   * load Unassigned Chats //loadUnassignedChats
   * @param languages Filter by language
   * @returns List of chats available
   */
  public static async getSupervisorChats(languages: Array<string> = []) {
    let me = this;
    let data = {
      params: {
        languages
      }
    };
    let response = await axios.get('/Home/ChatAPI/GetSupervisorChats', data);
    let responseData = me.getResponseData(response);
    var chats = me.parseEntityArray(responseData, Chat, (chat: Chat) => {
      chat.messages = JSON.parse(chat.messages as any);
      chat.agents = me.parseEntityArray(chat.agents, Agent);
      chat.agent = me.parseEntity(chat.agent, Agent);
      return chat;
    });
    return chats;
  }

  /**
   * Get chat historiy
   * @param chatTopic Chat topic
   * @returns Chat element
   */
  public static async getChat(chatTopic: string) {
    let me = this;
    let data = {
      params: {
        chatTopic
      }
    };
    let response = await axios.get('/Home/ChatAPI/GetChat', data);
    let responseData = me.getResponseData(response);
    var chatElement = me.parseEntity(responseData, Chat, (chat: Chat) => {
      chat.info = me.parseEntityArray(chat.info, UserInfo);
      chat.transfer = me.parseEntityArray(chat.transfer, ChatTransferLog);
      chat.messages = JSON.parse(chat.messages as any);
      chat.agents = me.parseEntityArray(chat.agents, Agent);
      chat.agent = me.parseEntity(chat.agent, Agent);
      return chat;
    });
    return chatElement;
  }

  /**
   * Create chat conversation
   * @param profile User profile
   * @param platform Chat platform
   * @returns created element
   */
  public static async createChat(profile: string, platform: string, channel: string, language: string): Promise<Chat> {
    let me = this;
    var data = {
      profile,
      channel,
      platform,
      language
    };
    var formData = me.getEntityFormData(data);
    let response = await axios.post('/Home/ChatAPI/CreateChat', formData);
    let responseData = me.getResponseData(response);
    var chatElement = me.parseEntity(responseData, Chat, (chat: Chat) => {
      chat.messages = JSON.parse(chat.messages as any);
      chat.agents = me.parseEntityArray(chat.agents, Agent);
      chat.agent = me.parseEntity(chat.agent, Agent);
      return chat;
    });
    return chatElement;
  }

  /**
   * Add messages from chat conversation
   * @param messages New messages
   * @param chatTopic Topic del Chat
   * @returns Updated element
   */
  public static async updateChat(messages: Array<any>, chatTopic: string) {
    let me = this;
    var data = {
      messages: JSON.stringify(messages),
      chatTopic
    };
    var formData = me.getEntityFormData(data);
    let response = await axios.post('/Home/ChatAPI/UpdateChat', formData);
    let responseData = me.getResponseData(response);
    var chatElement = me.parseEntity(responseData, Chat);
    return chatElement;
  }

  /**
   * History search: Get result from API
   * @param paramsData Parameters
   * @returns
   */
  public static async getChats(paramsData: PaginatedRequest): Promise<PaginatedReponse<Chat>> {
    let me = this

    let data = {
      params: paramsData
    };

    let response = await axios.get('/Home/ChatAPI/ListChat', data);
    let responseData = me.getResponseData(response);
    let result = me.parseEntity(responseData, PaginatedReponse) as PaginatedReponse;

    result.pagination = me.parseEntity(result.pagination, Pagination);
    result.data = me.parseEntityArray(result.data, Chat, (chat: Chat) => {
      chat.messages = JSON.parse(chat.messages as any);
      chat.agents = me.parseEntityArray(chat.agents, Agent);
      chat.agent = me.parseEntity(chat.agent, Agent);
      chat.rate = me.parseEntity(chat.rate, ChatRate);
      return chat;
    });

    return result;
  }

  /**
   * load Unassigned Chats //loadUnassignedChats
   * @param languages Filter by language
   * @returns List of chats available
   */
  public static async getAgentChats(userName: string) {
    let me = this;
    let data = {
      params: {
        userAgent: userName
      }
    };
    let response = await axios.get('/Home/ChatAPI/GetAgentChats', data);
    let responseData = me.getResponseData(response);
    var chats = me.parseEntityArray(responseData, Chat, (chat: Chat) => {
      chat.messages = JSON.parse(chat.messages as any);
      chat.agents = me.parseEntityArray(chat.agents, Agent);
      chat.agent = me.parseEntity(chat.agent, Agent);
      return chat;
    });
    return chats;
  }

  /**
   * Generates a PDf file with the chat history
   * @param chatTopic The chat topic
   */
  public static async downloadChat(chatTopic: string) {
    window.open('/Home/ChatAPI/GeneratePDF?topic=' + chatTopic);
  }


  /**
   * load Unassigned Chats transfer
   * @returns List of chats transfers
   * @param userAgent
   */
  public static async getChatsTransferPend(userAgent: string) {
    let me = this;
    let data = {
      params: {
        userAgent: userAgent
      }
    };
    let response = await axios.get('/Home/ChatAPI/GetChatsTransferPend', data);
    let responseData = me.getResponseData(response);
    const chats = me.parseEntityArray(responseData, Chat, (chat: Chat) => {
      chat.messages = JSON.parse(chat.messages as any);
      chat.agents = me.parseEntityArray(chat.agents, Agent);
      chat.agent = me.parseEntity(chat.agent, Agent);
      return chat;
    });
    return chats;
  }

  /**
   * Transfer chat to agent (API)
   * @param chat Chat element
   * @param user User element
   * @returns Updated element
   */
  public static async applyChatTransfer(chat: Chat, user: User) {

    let me = this;
    let data = {
      chatTopic: chat.chatTopic,
      user: user.id
    };

    let formData = me.getEntityFormData(data);
    let response = await axios.post('/Home/ChatAPI/ApplyChatTransfer', formData);
    let responseData = me.getResponseData(response);

    return responseData
  }

  /**
   * Cancel Transfer chat to agent (API)
   * @returns Updated element
   * @param chat
   * @param confirmAlert
   */
  public static async cancelChatTransfer(chat: Chat, confirmAlert) {

    let me = this;
    let responseData = '';

    let data = {
      idChat: chat.id,
      chatAgent: chat.chatAgent,
    };

    if (confirmAlert == 'cancel') {
      let formData = me.getEntityFormData(data);
      let response = await axios.post('/Home/ChatAPI/CancelTransferChatAgent', formData);
      responseData = me.getResponseData(response);
    }

    return responseData
  }

  /**
   * List Transfer chat to agent (API)
   * @returns Updated element
   * @param userAgent
   */
  public static async getListTransferChatsPend(userAgent: string) {

    let me = this;

    let data = {
      params: {
        userAgent: userAgent
      }
    };

    let response = await axios.get('/Home/ChatAPI/GetListTransferChatsPend', data);
    let responseData = me.getResponseData(response);
    const chats = me.parseEntityArray(responseData, Chat, (chat: Chat) => {
      chat.messages = JSON.parse(chat.messages as any);
      chat.agents = me.parseEntityArray(chat.agents, Agent);
      chat.agent = me.parseEntity(chat.agent, Agent);
      return chat;
    });

    return chats
  }
}

export default ChatBL;