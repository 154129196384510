/**
 * Default setting with styles for the LandingPages editor
 */
export const LANDING_EDITOR_CONFIG =  {
    height: 500,
    menubar: false,
    plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount code'
    ],
    rel_list: [
        { title: 'None', value: '' },
        { title: 'Follow', value: 'follow' },
        { title: 'No Follow', value: 'nofollow' },
    ],
    content_style: 
        `.list--checked { list-style: none; } .list--checked  li:before {content: '✓ ';}` +
        `.list { color: red; }` +
        `.text-info {color: blue;}` +
        `.text-success {color: green}` +
        `.text-warning {color: orange}` +
        `.text-error {color: red}` +
        `.text-highlighted {color: orangered}` +
        `.text-contrast {color: greenyellow}` +
        `.text-disclaimer {color: orange}` +
        `.heading-M {color: red;size: 30px;}` +
        `.heading-S {color: red;size: 20px;}`,
    formats: {
        // Changes the default format for h1 to have a class of heading
        h1: { block: 'h1', classes: 'heading heading-M heading-xl-L' },
        h2: { block: 'h2', classes: 'heading heading-S' },
    },
    style_formats: [
        // Adds the h1 format defined above to style_formats
        { title: 'Normal', block: 'p' },
        { title: 'Info', inline: 'span', classes: 'text-info' },
        { title: 'Success', inline: 'span', classes: 'text-success' },
        { title: 'Warning', inline: 'span', classes: 'text-warning' },
        { title: 'Error', inline: 'span', classes: 'text-error' },
        { title: 'Highlighted', inline: 'span', classes: 'text-highlighted' },
        { title: 'Contrast', inline: 'span', classes: 'text-contrast' },
        { title: 'Disclaimer', inline: 'span', classes: 'text-disclaimer' },
        { title: 'List',selector: 'ul', classes: 'list'},
        { title: 'List checked',selector: 'ul', classes: 'list--checked'},
    ],
    toolbar: 'undo redo | styleselect | formatselect | align | bold italic | link | bullist numlist | smallBtn | emBtn | code',
    setup: function(editor) { 
        editor.ui.registry.addButton('smallBtn', {
            text: "&lt;small&gt;",
            onAction: function (_) {
                editor.focus();
                editor.selection.setContent('<small>' + editor.selection.getContent() + '</small>');
            }
        });
        editor.ui.registry.addButton('emBtn', {
            text: "&lt;em&gt;",
            onAction: function (_) {
                editor.focus();
                editor.selection.setContent('<em>' + editor.selection.getContent() + '</em>');
            }
        });
    }
};

export const LANDING_SCHEMA_EDITOR_CONFIG =  {
    height: 500,
    menubar: false,
    plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount code'
    ],
    toolbar: 'undo redo | bold italic | bullist numlist | code '
};