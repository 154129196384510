import React from "react";
import PageCommon from "../../core/PageCommon";
import { Card, CardBody, Form, FormGroup, AlertBar, Label, IconFa, Button } from '../../../cuba/components/utils/reactstrap'
import UserBL from "../../bl/user/UserBL";
import LoginBL from "../../bl/login/LoginBL";
import { _ } from "../../bl/admin/AdminLocaleBL";
import Alert from "../../component/utils/Alert";
import SecurityBL from "../../bl/security/SecurityBL";
import ProfilePasswordProp from "../../entity/profile/props/ProfilePasswordProp";
import ProfilePasswordState from "../../entity/profile/states/ProfilePasswordState";
import LoaderBackdrop from "../utils/LoaderBackdrop";

/**
 * ProfilePassword
 * @class ProfilePassword
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class ProfilePassword extends PageCommon<ProfilePasswordProp, ProfilePasswordState> {
    /**
     * Constructor
     * @param props Property 
     */
    constructor(props: ProfilePasswordProp) {
        super(props);
        this.state = new ProfilePasswordState();
    }

    /**
     * Component did mount
     */
    componentDidMount() {
        let me = this;
        me.prepare();
    }

    /**
     * Initialize component
     */
    private async prepare(){
        let me = this;
    }

    /**
     * Get the labels for the password rating
     */
    private get PasswordLevel(){
        let messages = {
            0: {
                style: "danger",
                message: _("key_password_level_1")
            },
            1: {
                style: "warning",
                message: _("key_password_level_2")
            },
            2: {
                style: "success",
                message: _("key_password_level_3")
            },
            3: {
                style: "danger",
                message: _("key_password_different")
            },
            4: {
                style: "danger",
                message: _("key_password_must_be_new")
            }
        }
        return messages;
    }

    /**
     * Gets the current password rating
     */
    private get PasswordRate(): number {
        let me = this;
        return SecurityBL.getPasswordStrongLevel(me.state.password);
    }
    
    /**
     * Updates the data of the two passwords in the state and get tags
     * @param event Event handler
     * @param name Input name
     */
    private updatePassword(event){
        let me = this;
        let options = {};
        let value = event.target.value;
        let name = event.target.name;

        // Update state
        options[name] = value;

        me.setState(options, () => {
            // Get password rate
            let passwordRate = me.PasswordRate;
            // They must coincide with
            passwordRate = me.state.password == me.state.passwordConfirm ? passwordRate : 3;
            // It must be different from the old password
            passwordRate = me.state.passwordConfirm != me.state.passwordCurrent ? passwordRate : 4;

            me.setState({
                passwordLevel: me.PasswordLevel[passwordRate]
            })
        });
    }

    /**
     * Verify if the password finally meets all criteria
     */
    private get ValidPassword(): boolean {
        let me = this;
        return me.state.password == me.state.passwordConfirm 
            && me.state.passwordCurrent !== me.state.passwordConfirm 
            && me.PasswordRate > 0;
    }

    /**
     * Change password
     */
    private async changePassword(e){
        e.preventDefault();
        let me = this;

        var user = LoginBL.SessionData;
        if(me.ValidPassword){

            me.setState({busy: true});
            // Change password
            var result = await UserBL.updateProfilePassword(user, me.state.passwordCurrent, me.state.passwordConfirm);
            me.setState({busy: false});

            if(result){
                Alert.success();
            } else {
                Alert.error(_("key_login_invalid"));
            }
        }
    }

    render() {
        let me = this;
        
        return (
            <Card>
                <AlertBar header={true}>{_("key_password_change")}</AlertBar>
                <CardBody>
                    <LoaderBackdrop visible={me.state.busy} message={_("key_data_saving")}/>
                    <Form onSubmit={ e => me.changePassword(e) }>
                        <FormGroup>
                            <Label className="form-label">{_("key_password_current")}</Label>
                            <input 
                                required={true} 
                                onChange={ e => me.updatePassword(e) } 
                                name="passwordCurrent" 
                                value={ me.state.passwordCurrent } 
                                className="form-control" 
                                type="password" />
                        </FormGroup>
                        <FormGroup>
                            <Label className="form-label">{_("key_password_change")}</Label>
                            <input 
                                required={true} 
                                onChange={ e => me.updatePassword(e) } 
                                name="password" 
                                value={ me.state.password } 
                                className="form-control" 
                                type="password" />
                        </FormGroup>
                        <FormGroup>
                            <Label className="form-label">{_("key_password_confirm")}</Label>
                            <input 
                                required={true} 
                                onChange={ e => me.updatePassword(e) } 
                                name="passwordConfirm" 
                                value={ me.state.passwordConfirm } 
                                className="form-control" 
                                type="password" />
                        </FormGroup>
                        <div>
                            <div className={`alert alert-${me.state.passwordLevel["style"]}`}>
                                {me.state.passwordLevel["message"]}
                            </div>

                            {me.state.message.length > 0 && 
                                <div className="alert alert-danger">
                                    {me.state.message}
                                </div>
                            }
                            <div className="text-center">
                                <Button disabled={!me.ValidPassword} color="primary" type="submit">
                                    <IconFa icon="save"/> {_("key_password_change")}
                                </Button>
                            </div>
                        </div>
                    </Form>
                </CardBody>
            </Card>
        );
    }
}

export default ProfilePassword;
