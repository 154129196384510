import React from "react";
import PageCommon from "../core/PageCommon";
import LocationPageProp from "../entity/location/props/LocationPageProp";
import LocationPageState from "../entity/location/states/LocationPageState";
import { Row, Col, Card, CardBody, CardHeader, Button, Form, IconFa } from '../../cuba/components/utils/reactstrap'
import LoaderMini from "../component/utils/LoaderMini";
import LoaderBackdrop from "../component/utils/LoaderBackdrop";
import { PaginatedRequest, Pagination } from "../entity/control/ControlEntity";
import { LocationBL } from "../bl/location/LocationBL";
import Paginator from "../component/control/Paginator";
import { Location } from "../entity/location/LocationEntity";
import LocationForm from "../component/location/LocationForm";
import { PlaceBL } from "../bl/place/PlaceBL";
import { _ } from "../bl/admin/AdminLocaleBL";

/**
 * LocationPage: Main view
 * @class LocationPage
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class LocationPage extends PageCommon<LocationPageProp, LocationPageState>{
    
    // Paginator ref
    private _paginator = null;

    /**
     * Contructor
     * @param prop Property 
     */
    public constructor(prop: LocationPageProp){
        super(prop);
        this.state = new LocationPageState();
    }

    /**
     * Component did mount
     */
    componentDidMount() {
        let me = this;
        me.prepare();
    }

    /**
     * Prepare component
     */
    private async prepare() {
        let me = this;
        me._paginator = React.createRef();
        me.loadAgency();
        me.loadCountry();
        me.searchResult();
    }

    /**
     * Load agency list
     */
    private async loadAgency(){
        let me = this;
        var agencies = await LocationBL.getAgencies();
        me.setState({
            agencies: agencies
        });
    }

    /**
     * Load country list
     */
    private async loadCountry(){
        let me = this;
        var countries = await PlaceBL.findPlace(1);
        me.setState({
            countries,
            states: [],
            cities: [],
            country: 0,
            state: 0,
            city: 0
        });
    }


    /**
     * Load state list
     */
    private async loadState(){
        let me = this;
        var states = await PlaceBL.findPlace(2, me.state.country);
        me.setState({
            states,
            cities: [],
            state: 0,
            city: 0
        });
    }


    /**
     * Load city list
     */
    private async loadCity(){
        let me = this;
        var cities = await PlaceBL.findPlace(3, me.state.state);
        me.setState({
            cities,
            city: 0
        });
    }

    /**
     * Handle search form
     * @param e Event
     */
     private search(e) {
        let me = this;
        e.preventDefault();
        me.setState({
            pagination: me.state.pagination.setCurrentPage(1)
        }, () => {
            me.searchResult();
        });
    }

	/**
	 * Realiza la solicitud de búsqueda a la API
	 */
     public async searchResult(loader: boolean = true) {
		let me = this;
        me.setState({
            loadingLocationResults: loader
        })

        var state = me.state.state;
        var city = me.state.city;
        var idPlace = me.state.country;
        state > 0 && (idPlace = state);
        city > 0 && (idPlace = city);

		let data = {
			criteria: me.state.criteria,
            zipCode: me.state.zipCode,
            idAgency: me.state.idAgency,
            idPlace: idPlace
		};

        // Prepare request!
        let request = new PaginatedRequest();
        request.data = data;
        request.pagination = me.state.pagination;
        let results = await LocationBL.getLocation(request);
       
        me.setState({
            pagination: results.pagination,
            loadingLocationResults: false,
            locationList: results.data
        }, () => {
            // Update paginator
            me._paginator?.current?.update();
        });
	}

    /**
     * Update pagination state
     * @param pagination Pagination object
     */
    public passPage(pagination: Pagination){
        let me = this;
        me.setState({
            pagination: pagination
        }, () => {
            me.searchResult();
        });
    }

    /**
     * Select current Location from list
     * @param location Location element
     */
    private selectLocation(location: Location){
        let me = this;
        // Delete current component
        me.setState({
            currentLocation: null
        }, () => {
            me.setState({
                currentLocation: location
            });
        });
    }

    /**
     * Handle LocationForm onSave
     */
    private handleSave() {
        let me = this;
        me.searchResult(false);
    }

    /**
     * Create a new town
     */
    private handleNew() {
        let me = this;

        let newLocation = new Location();
        me.selectLocation(newLocation);
    }

    /**
     * Handle onchange place event
     * @param type TypePlace
     */
    private handlePlace(type: number, event: any){
        let me = this;
        var idPlace = parseInt(event.target.value);
        switch (type) {
            case 1:
                var haveState = PlaceBL.isState(idPlace);

                var params = {
                    country: idPlace,
                    showState: haveState
                };

                if(!haveState) {
                    params["state"] = idPlace;
                }

                me.setState(params,  () => {
                    if(haveState) {
                        me.loadState();
                    } else {
                        me.loadCity();
                    }
                });

                break;
            case 2:
                me.setState({state: idPlace}, () => {
                    me.loadCity();
                });
                break;
            case 3:
                me.setState({city: idPlace});
                break;
        }
    }

    render (){
        let me = this;
        return (
            <>
                {!me.state.currentLocation ?
                    <Card>
                        <CardBody>
                            <LoaderBackdrop visible={ me.state.busy } message={_("key_location_loading_selected") }/>
                            <Form onSubmit={ e => me.search(e) } className="theme-form">
                                <Row>
                                    <Col sm="12" md="4">
                                        <div className="form-group mb-3">
                                            <h6 className="form-label">{_("key_location_name_address")}</h6>
                                            <input 
                                                onChange={ e => me.setState({criteria: e.target.value}) } 
                                                value={ me.state.criteria } 
                                                className="form-control" 
                                                type="text" 
                                                placeholder={_("key_search_criteria")}
                                                />
                                        </div>
                                    </Col>
                                    <Col sm="12" md="4">
                                        <div className="form-group mb-3">
                                            <h6 className="form-label">{_("key_zip_code")}</h6>
                                            <input 
                                                onChange={ e => me.setState({zipCode: e.target.value}) } 
                                                value={ me.state.zipCode } 
                                                className="form-control" 
                                                type="text" 
                                                placeholder={_("key_location_search_by_zip")}
                                                />
                                        </div>
                                    </Col>
                                    <Col sm="12" md="4">
                                        <div className="form-group mb-3">
                                            <h6 className="form-label">{_("key_agency")}</h6>
                                            <select required={ true } onChange={ e => me.setState({idAgency: parseInt(e.target.value) }) } name="idAgency" value={ me.state.idAgency } className="form-control">
                                                <option value={0}>{_("key_agency_all")}</option>
                                                {me.state.agencies.map( (agency) => {
                                                    return <option key={ agency.id } value={agency.id}>{agency.code}: {agency.name}</option>
                                                })}
                                            </select>
                                        </div>
                                    </Col>
                                </Row>
                                {/* PLACES SELECTOR */}
                                <Row>
                                    <Col sm="12" md="4">
                                        <div className="form-group mb-3">
                                            <h6 className="form-label">{_("key_country")}</h6>
                                            <select required={ true } onChange={ e => me.handlePlace(1, e) } name="country" value={ me.state.country } className="form-control">
                                                <option value={0}>{_("key_country_all")}</option>
                                                {me.state.countries.map( (country) => {
                                                    return <option key={ country.id } value={country.id}>{country.code}: {country.name}</option>
                                                })}
                                            </select>
                                        </div>
                                    </Col>
                                    <Col sm="12" md="4">
                                        <div className="form-group mb-3">
                                            <h6 className="form-label">{_("key_state")}</h6>
                                            <select disabled={!me.state.showState} required={ true } onChange={ e => me.handlePlace(2, e) } name="state" value={ me.state.state } className="form-control">
                                                <option value={0}>{_("key_state_all")}</option>
                                                {me.state.states.map( (state) => {
                                                    return <option key={ state.id } value={state.id}>{state.name} ({state.code})</option>
                                                })}
                                            </select>
                                        </div>
                                    </Col>
                                    <Col sm="12" md="4">
                                        <div className="form-group mb-3">
                                            <h6 className="form-label">{_("key_city")}</h6>
                                            <select required={ true } onChange={ e => me.handlePlace(3, e) } name="city" value={ me.state.city } className="form-control">
                                                <option value={0}>{_("key_city_all")}</option>
                                                {me.state.cities.map( (city) => {
                                                    return <option key={ city.id } value={city.id}>{city.name} ({city.code})</option>
                                                })}
                                            </select>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="text-center p-2">
                                    <Button type="submit" color="primary"> 
                                        <IconFa icon="search"/> {_("key_filter_results")}
                                    </Button>
                                    <Button onClick={ e => me.handleNew() }
                                            color="success"
                                            className="ml-md-2 my-3 my-md-0"
                                            type="button">
                                        <IconFa icon="plus"/> {_("key_create_new")}
                                    </Button>
                                </div>
                            </Form>
                            <table className="table tableDsn">
                                <thead>
                                    <tr>
                                        <th scope="col">{_("key_name")}</th>
                                        <th scope="col">{_("key_agency")}</th>
                                        <th scope="col">{_("key_place")}</th>
                                        <th scope="col">{_("key_address")}</th>
                                        <th scope="col">{_("key_actions")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {me.state.loadingLocationResults &&
                                        <tr>
                                            <td colSpan={5}>
                                                <LoaderMini message={_("key_search_loading_results")}/>     
                                            </td>
                                        </tr>
                                    }
                                    {me.state.locationList.map( (location: Location) => 
                                        <tr key={location.id}>
                                            <td data-label="Nombre">{location.locationName}</td>
                                            <td data-label="Agencia">{location.agency.name}</td>
                                            <td data-label="Lugar">{location.place.name}</td>
                                            <td data-label="Dirección">{location.address1}</td>
                                            <td data-label="Acciones" className="p-2">
                                                <Button onClick={ e =>  me.selectLocation(location)} size="sm">
                                                    <IconFa icon="pencil"/> {_("key_edit")}
                                                </Button>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>               
                            <Paginator ref={ me._paginator }
                                          visible={ me.state.locationList.length > 0 }
                                          pagination={ me.state.pagination }
                                          onChange={ pagination => me.passPage(pagination) } />   
                        </CardBody>
                    </Card>
                :
                    <LocationForm location={me.state.currentLocation} onClose={ () => me.setState({currentLocation: null}) } onSave={ e => me.handleSave() } />
                }
            </>
        );;
    }
}

export default LocationPage;