import React from "react";
import ComponentCommon from "../../core/ComponentCommon";
import ChatContentTabsProp from "../../entity/utils/props/ChatContentTabsProp";
import ChatContentTabsState from "../../entity/utils/states/ChatContentTabsState";

export class ChatContentTabs extends ComponentCommon<ChatContentTabsProp, ChatContentTabsState> {
    render() {
        const me = this;
        return (<div className="tab-content px-1 pb-1" id="tab-content">
            <div className={'tab-pane '+(me.props.visibleNavigationLog?'':'active')} id="tab-panel-chat" role="tabpanel"
                 aria-labelledby="tab-panel-chat">
                {me.props.chatComponent}
            </div>
            <div className={'tab-pane '+(me.props.visibleNavigationLog?'active':'')} id="tab-panel-navigation-user" role="tabpanel"
                 aria-labelledby="tab-panel-navigation-user">
                {me.props.navigationUserComponent}
            </div>
        </div>);
    }
}