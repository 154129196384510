import IState from "../../../core/IState";
import { RequestState, RequestTrace } from "../RequestEntity";

export default class RequestFormState implements IState {
    public traceContent: string = "";
    public currentState: number = null;
    public states: Array<RequestState> = [];
    public traces: Array<RequestTrace> = [];
    public busy: boolean = false;
    public readOnly: boolean = false;
}