import IState from '../../../core/IState';

export class LoginFormState implements IState {
    public defaultState?: boolean = false;
    public togglePassword: boolean = false;
    public email: string = "";
    public password: string = "";
    public selected: string = "login";
    public busy: boolean = false;
    public error: string = "";
    public message: string = "";
}

export default LoginFormState;