import axios from 'axios';
import BaseBL from '../../core/BaseBL';
import { PaginatedReponse, PaginatedRequest, Pagination } from '../../entity/control/ControlEntity';
import { GroupUser } from '../../entity/login/LoginEntity';
import { GroupPermissions, Role } from '../../entity/security/SecurityEntity';
import LoginBL from '../login/LoginBL';

/**
 * Perform operations in the security API
 * @author Samael Fierro <sfierro@viajemos.com>
 * */
export class GroupPermissionBL extends BaseBL {
    /**
     * Get current system roles
     * @returns Groups
     */
    public static async listPermissions(paramsData: PaginatedRequest): Promise<PaginatedReponse<GroupPermissions>>{
        let me = this;
        let data = {
            params: paramsData
        };
        let response = await axios.get('/Home/UserAPI/ListPermission', data);
        let responseData = me.getResponseData(response);
        var result = me.parseEntity(responseData, PaginatedReponse);
        result.data = me.parseEntityArray(result.data, GroupPermissions);
        result.pagination = me.parseEntity(result.pagination, Pagination);
        return result;
    }

    /**
     * Get current system roles
     * @returns Groups
     */
    public static async getPermissions(id: number): Promise<GroupPermissions[]>{
        let me = this;
        let data = {
            params: {
                id: id
            }
        };
        let response = await axios.get('/Home/UserAPI/GetPermission', data);
        let responseData = me.getResponseData(response);
        responseData = me.parseEntityArray(responseData, GroupPermissions);
        return responseData;
    }

    /**
     * Take the action to save in the API.
     * @param group Entity object
     * @returns response
     */
     public static async updatePermission(group: GroupPermissions): Promise<GroupPermissions> {
        let me = this;
        let formData = me.getEntityFormData(group);
        let response = await axios.post('/Home/UserAPI/UpdatePermission', formData);
        let responseData = me.getResponseData(response);
        let groupUpdated = me.parseEntity(responseData, GroupPermissions);
        return groupUpdated;
    }

    /**
     * Update multiples permissions.
     * @param group Entity object
     * @returns response
     */
     public static async updatePermissions(group: GroupUser, permissions: string[]): Promise<GroupPermissions> {
        let me = this;
        let data = {
            id: group.id,
            permissions: permissions
        }
        let formData = me.getEntityFormData(data);
        let response = await axios.post('/Home/UserAPI/UpdatePermissions', formData);
        let responseData = me.getResponseData(response);
        let groupUpdated = me.parseEntity(responseData, GroupPermissions);
        return groupUpdated;
    }

    /**
     * Take the action to save in the API.
     * @param group Entity object
     * @returns response
     */
     public static async deletePermission(group: GroupPermissions) {
        let me = this;
        let formData = me.getEntityFormData(group);
        let response = await axios.post('/Home/UserAPI/DeletePermission', formData);
        let responseData = me.getResponseData(response);
    }
}

export default GroupPermissionBL;