import moment from "moment";
import { User } from "../login/LoginEntity";

/**
 * Represents the status of a chat application
 */
export class RequestState {
    public id: number = null;
    public name: string = "";
    public state: number = 0;
}

/**
 * Represents the responses of agents to an application ChatUserInfo
 */
export class RequestTrace {
    public id: number = null;
    public content: string = "";
    public idUserAgent: number = null;
    public idChatsUsersInfo: number = null;
    public agent: User = null;
    public createdDate: string = null;

	/**
	 * define Last Message Date text
	 */
	 public get CreatedDateText() {
		// default Var
		let me = this;
		let date = "";
		let dateTime = me.createdDate; 
		dateTime && (date = moment(dateTime).format('DD/MM/YYYY H:mm:ss'));
		return date;
	}
}

/**
 * Rer
 */
export class RequestListRequest {
    public criteria: string = "";
    public startDate: string = "";
    public endDate: string = "";
}