import axios from "axios";
import BaseBL from "../../core/BaseBL";
import { UserInfo } from "../../entity/chat/ChatEntity";
import { User } from "../../entity/login/LoginEntity";
import { RequestState, RequestTrace } from "../../entity/request/RequestEntity";

/**
 * Perform operations in the request trace API
 * @class RequestBL
 * @author Samael Fierro <sfierro@viajemos.com>
 * */
export class RequestBL extends BaseBL {
    /**
     * Cache
     */
    private static _requestState: Array<RequestState> = [];

    /**
     * Load defaults
     */
    public static prepare(){
        this.getRequestState();
    }

    /**
     * Take the action to save in the API.
     * @param trace Entity object
     * @returns response
     */
    public static async updateRequestTrace(trace: RequestTrace): Promise<RequestTrace> {
        let me = this;
        let formData = me.getEntityFormData(trace);
        let response = await axios.post('/Home/ChatAPI/UpdateRequestTrace', formData);
        let responseData = me.getResponseData(response);
        let updated = me.parseEntity(responseData, RequestTrace);
        return updated;
    }

    /**
     * Take the action to save in the API.
     * @param trace Entity object
     * @returns response
     */
     public static async setRequestState(request: UserInfo, state: RequestState): Promise<UserInfo> {
        let me = this;
        let formData = me.getEntityFormData({
            request: request.id,
            state: state.id
        });
        let response = await axios.post('/Home/ChatAPI/SetRequestState', formData);
        let responseData = me.getResponseData(response);
        let updated = me.parseEntity(responseData, UserInfo, (userInfo: UserInfo) => {
            userInfo.state = me.parseEntity(userInfo.state, RequestState);
            return userInfo;
        });
        return updated;
    }

    /**
     * Check the listing of stored agents
     * @returns Array of User Entity
     */
    public static async getRequestTrace(userInfo: UserInfo): Promise<Array<RequestTrace>> {
        let me = this;
        let data = {
            params: {
                id: userInfo.id
            }
        }
        let response = await axios.get('/Home/ChatAPI/GetRequestTrace', data);
        let responseData = me.getResponseData(response);
        let traces = me.parseEntityArray(responseData, RequestTrace, (trace: RequestTrace) => {
            trace.agent = me.parseEntity(trace.agent, User);
            return trace;
        });
        return traces;
    }

    /**
     * Check the listing of stored agents
     * @returns Array of User Entity
     */
    public static async getRequestState(): Promise<Array<RequestState>> {
        let me = this;
        if(me._requestState.length > 0){
            return me._requestState;
        }
        let response = await axios.get('/Home/ChatAPI/GetRequestState');
        let responseData = me.getResponseData(response);
        me._requestState = me.parseEntityArray(responseData, RequestState);
        return me._requestState;
    }
}

// Load defaults!
RequestBL.prepare();

export default RequestBL;