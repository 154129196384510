import Config from "../core/component/chat/config/Config";
import Mercure from "./Mercure";

/**
 * Simulates the use of multiple channels by a single Mercure channel
 * as a solution to the Mercure connection limit.
 * @class MercureListener
 * @author Samael Fierro <sfierro@viajemos.com>
 */
export class MercureListener {
    public static MAIN_AGENT_TOPIC: string = 'agentRequest2021';
    private static MAIN_TOPIC: string = "GENERAL_TOPIC_CHANNEL";
    private static config: Config = null;
    private static eventSource: EventSource = null;
    private static eventHandlers = [];

    /**
     * Initialize mercure listener
     */
    public static async prepare() {
        let me = this;
        me.config = new Config();
        await me.config.loadConfiguration();
        me.listenMercureEvents();
    }

    /**
     * Subscribe to a message channel
     * @param topic Chat topic
     * @param callback Callback
     */
    public static subscribe(topic: string, callback: CallableFunction) {
        let me = this;
        // Add callback
        me.eventHandlers[topic] == undefined && (me.eventHandlers[topic] = []);
        me.eventHandlers[topic].push(callback);
    }

    /**
     * Unsubscribe to a message channel
     * @param topic Chat topic
     */
    public static unsubscribe(topic: string) {
        let me = this;
        me.eventHandlers[topic] = [];
    }

    /**
     * Unsubscribe by handler (callable function)
     * @param handler Chat topic
     */
     public static unsubscribeHandler(handler: CallableFunction) {
        let me = this;
        for(var x in me.eventHandlers){
            var events = me.eventHandlers[x] as Array<any>;
            events = events.filter( e => e !== handler);
            me.eventHandlers[x] = events;
        }
    }
    /**
     * Post a message on the specified channel
     * @param topic Chat topic
     * @param data Data
     */
    public static publish(topic: string, data: any) {
        Mercure.publish(topic, data);
    }

    /**
     * Call eventhandlers
     * @param data Data
     */
    private static call(data) {
        let me = this;
        var topic = data.topic;
        // Call 
        me.eventHandlers[topic] == undefined && (me.eventHandlers[topic] = []);
        me.eventHandlers[topic].forEach(callback => {
            callback(data.data);
        });
    }

    /**
     * detect Agent Event
     */
    public static async listenMercureEvents() {
        let me = this;
        let subscribeURL = new URL(me.config.baseUrlMercure);
        // append Topic
        subscribeURL.searchParams.append('topic', me.MAIN_TOPIC);
        // event Source Call
        me.eventSource = new EventSource( String(subscribeURL), {} );
        me.eventSource.onmessage = ({ data }) => {
            var objectData = JSON.parse(data);
            me.call(objectData);
        }
        me.eventSource.onerror = (error) => {
            // Reset connection
            me.eventSource.close();
            me.listenMercureEvents();
        };
    }
}

// Start listen to events
MercureListener.prepare();

export default MercureListener;