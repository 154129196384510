import IState from "../../../core/IState";

export default class ProfilePasswordState implements IState {
    public busy: boolean = false;
    public password: string = "";
    public passwordCurrent: string = "";
    public passwordConfirm: string = "";
    
    public passwordLevel: any = {};
    public message: string = "";
}