import {Code, Headphones, Settings, Shield} from 'react-feather';
import {LayoutCategory, LayoutGroup, LayoutRoute} from '../entity/layout/LayoutEntity';
import {Role, SubRole} from '../entity/security/SecurityEntity';
import LocationPage from '../page/LocationPage';
import ChatPage from '../page/ChatPage';
import HistoryPage from '../page/HistoryPage';
import LocalePage from '../page/LocalePage';
import LandingPage from '../page/LandingPage';
import RequestPage from '../page/RequestPage';
import SuggestionsPage from '../page/SuggestionsPage';
import SupervisorPage from '../page/SupervisorPage';
import UserPage from '../page/UserPage';
import GroupPage from '../page/GroupPage';
import {FaqsPage} from '../page/FAQSPage';
import ProfilePage from '../page/ProfilePage';
import {NotificationPage} from '../page/NotificationPage';
import ReportPage from '../page/ReportPage';
import ChannelPage from '../page/ChannelPage';
import ContactPage from '../page/ContactPage';
import LanguagePage from '../page/LanguagePage';
import ApiTinyTokenPage from "../page/ApiTinyTokenPage";

/**
 * Simplify and join routes, menus and pages in one place
 */

/**
 * Menu categories
 */
let CATEGORIES = {
    chat: new LayoutCategory({
        title: "key_mod_cat_title_chat",
        content: "key_mod_cat_content_chat"
    }),
    settings: new LayoutCategory({
        title: "key_mod_cat_title_content",
        content: "key_mod_cat_content_content",
        role: new Role('Locale')
    }),
    user: new LayoutCategory({
        title: "key_mod_cat_title_users",
        content: "key_mod_cat_content_user",
        role: new Role('User')
    }),
    developer: new LayoutCategory({
        title: "key_mod_cat_title_developer",
        content: "key_mod_cat_content_developer",
        role: new Role('Developer')
    })
};

/**
 * Subgroups of categories
 */
export const GROUPS = {
    chat: new LayoutGroup({
        title: "key_mod_group_chat",
        icon: Headphones
    }),
    user: new LayoutGroup({
        title: "key_mod_group_users",
        icon: Shield
    }),
    locale: new LayoutGroup({
        title: "key_mod_group_content",
        icon: Settings
    }),
    api: new LayoutGroup({
        title: "key_mod_group_api",
        icon: Code
    })
}

/**
 * Components and routes
 */
export const PAGES = {
    history: new LayoutRoute({
        route: `${process.env.PUBLIC_URL}/history/:layout`,
        parent: 'key_dashboard',
        title: 'key_mod_page_history',
        component: HistoryPage,
        role: new Role('History')
    }),
    chat: new LayoutRoute({
        route: `${process.env.PUBLIC_URL}/chat/:layout`,
        parent: 'key_dashboard',
        title: 'key_mod_page_chat',
        component: ChatPage,
        role: new Role('Chat'),
        subRoles: [
            new SubRole('ChatCreate', "key_new_conversation"),
            new SubRole('ChatTransfer', "key_chat_transfer"),
            new SubRole('ChatDownload', "key_chat_download"),
        ]
    }),
    supervisor: new LayoutRoute({
        route: `${process.env.PUBLIC_URL}/supervisor/:layout`,
        parent: 'key_dashboard',
        title: 'key_mod_page_supervisor',
        component: SupervisorPage,
        role: new Role('Supervisor')
    }),
    user: new LayoutRoute({
        route: `${process.env.PUBLIC_URL}/users/:layout`,
        parent: 'key_dashboard',
        title: 'key_mod_page_user',
        component: UserPage,
        role: new Role('User')
    }),
    request: new LayoutRoute({
        route: `${process.env.PUBLIC_URL}/request/:layout`,
        parent: 'key_dashboard',
        title: 'key_mod_page_request',
        component: RequestPage,
        role: new Role('Request')
    }),
    locale: new LayoutRoute({
        route: `${process.env.PUBLIC_URL}/locale/:layout`,
        parent: 'key_dashboard',
        title: 'key_mod_page_locale',
        component: LocalePage,
        role: new Role('Locale')
    }),
    landing: new LayoutRoute({
        route: `${process.env.PUBLIC_URL}/landing/:layout`,
        parent: 'key_dashboard',
        title: 'key_mod_page_landing',
        component: LandingPage,
        role: new Role('Landing')
    }),
    suggestions: new LayoutRoute({
        route: `${process.env.PUBLIC_URL}/suggestions/:layout`,
        parent: 'key_dashboard',
        title: 'key_mod_page_suggestions',
        component: SuggestionsPage,
        role: new Role('Suggestions')
    }),
    location: new LayoutRoute({
        route: `${process.env.PUBLIC_URL}/location/:layout`,
        parent: 'key_dashboard',
        title: 'key_mod_page_location',
        component: LocationPage,
        // TODO: Cambiar rol 
        role: new Role('Location')
    }),
    group: new LayoutRoute({
        route: `${process.env.PUBLIC_URL}/role/:layout`,
        parent: 'key_dashboard',
        title: 'key_mod_page_group',
        component: GroupPage,
        role: new Role('Group')
    }),
    faqs: new LayoutRoute({
        route: `${process.env.PUBLIC_URL}/faqs/:layout`,
        parent: 'key_dashboard',
        title: 'key_mod_page_faqs',
        component: FaqsPage,
        role: new Role('Faqs')
    }),
    profile: new LayoutRoute({
        route: `${process.env.PUBLIC_URL}/profile/:layout`,
        parent: 'key_dashboard',
        title: 'key_mod_page_profile',
        component: ProfilePage,
        role: new Role('Profile'),
        visible: false
    }),
    notification: new LayoutRoute({
        route: `${process.env.PUBLIC_URL}/notification/:layout`,
        parent: 'key_dashboard',
        title: 'key_my_notifications',
        component: NotificationPage,
        role: new Role('Notification'),
        visible: false
    }),
    reports: new LayoutRoute({
        route: `${process.env.PUBLIC_URL}/report/:layout`,
        parameters: {category: 'chat', report: "ChatRatings"},
        parent: 'key_dashboard',
        title: 'key_reports_chat_rating',
        component: ReportPage,
        role: new Role('Reports')
    }),
    channels: new LayoutRoute({
        route: `${process.env.PUBLIC_URL}/channel/:layout`,
        parent: 'key_dashboard',
        title: 'key_mod_page_channels',
        component: ChannelPage,
        role: new Role('Channel')
    }),
    contact: new LayoutRoute({
        route: `${process.env.PUBLIC_URL}/contact/:layout`,
        parent: 'key_dashboard',
        title: 'key_mod_page_contacts',
        component: ContactPage,
        role: new Role('Contact')
    }),
    language: new LayoutRoute({
        route: `${process.env.PUBLIC_URL}/language/:layout`,
        parent: 'key_dashboard',
        title: 'key_mod_page_language',
        component: LanguagePage,
        role: new Role('Language')
    }),
    apiTiny: new LayoutRoute({
        route: `${process.env.PUBLIC_URL}/apiTiny/:layout`,
        parent: 'key_dashboard',
        title: 'key_mod_page_api_tiny',
        component: ApiTinyTokenPage,
        role: new Role('ApiTiny')
    }),
};

/**
 * Here the menu is built
 */

CATEGORIES.chat
    .addGroup(GROUPS.chat)
    .addComponent(PAGES.chat)
    .addComponent(PAGES.history)
    .addComponent(PAGES.request)
    .addComponent(PAGES.suggestions)
    .addComponent(PAGES.supervisor)
    .addComponent(PAGES.reports)
    .addComponent(PAGES.channels)
    .addComponent(PAGES.contact)
    .addComponent(PAGES.language);

CATEGORIES.user
    .addGroup(GROUPS.user)
    .addComponent(PAGES.user)
    .addComponent(PAGES.group)
    .addComponent(PAGES.profile)
    .addComponent(PAGES.notification);

CATEGORIES.settings
    .addGroup(GROUPS.locale)
    .addComponent(PAGES.locale)
    .addComponent(PAGES.landing)
    .addComponent(PAGES.location)
    .addComponent(PAGES.faqs);

CATEGORIES.developer
    .addGroup(GROUPS.api)
    .addComponent(PAGES.apiTiny);

export const LAYOUT = Object.values(CATEGORIES);
export const ROUTES = Object.values(PAGES);
