import { getRoles } from "@testing-library/dom";
import { Icon } from "react-feather";
import SecurityBL from "../../bl/security/SecurityBL";
import PageCommon from "../../core/PageCommon";
import UrlHash from "../../helper/UrlHash";
import { Role, SubRole } from "../security/SecurityEntity";

export class LayoutCategory {

    constructor({title, content, role = null}){
        let me = this;
        me.title = title;
        me.content = content;
        me.role = role;
    }

    public title:string = "";
    public content: string = "";
    public groups: Array<LayoutGroup> = [];
    public role: Role = null;

    // Properties and Filters by Roles for Cuba JSX
    public get Items() { 
        return this.groups.filter(group => {
            return SecurityBL.checkRole(group.role) && group.children.length > 0;
        })
    };

    public get menutitle() { return this.title; }
    public get menucontent() { return this.content; }

    public addGroup(group: LayoutGroup): LayoutGroup {
        let me = this;
        group.parent = me;
        me.groups.push(group);
        return group;
    }
}

export class LayoutGroup {
    constructor({title, icon, role = null}){
        let me = this;
        me.title = title;
        me.icon = icon;
        me.role = role;
    }

    public title:string = "";
    public routes: Array<LayoutRoute> = []
    public icon: Icon = null;
    public role: Role = null;
    public parent: LayoutCategory = null;
    public type = "sub";
    public active = false;

    // Properties and role filter for Cuba JSX
    public get children() {
        return this.routes.filter( route => {
            return SecurityBL.checkRole(route.role) && route.visible;
        });
    }

    public addComponent(route: LayoutRoute): LayoutGroup  {
        let me = this;
        me.routes.push(route);
        return me;
    }

    public getCategory(): LayoutCategory {
        return this.parent
    }

    public getPermissions(): any[] {
        let me = this;
        var subRoles = [];
        var roles =  me.children.map( r => {
            
            // Additional roles
            r.subRoles.map( (sr: SubRole) => {
                subRoles.push({
                    title: sr.title,
                    role: sr.module
                })
            });

            return {
                title: r.title,
                role: r.role.module
            }
        });
        return [...roles, ...subRoles];
    }
}

export class LayoutRoute {
    constructor({title, route, parameters = null, component, role, subRoles = [], visible = true, parent = ""}){
        this.title = title;
        this.route = route;
        this.Component = component;
        this.role = role;
        this.subRoles = subRoles;
        this.parent = parent;
        this.visible = visible;
        this.parameters = parameters;
    }

    public type: string = "link";
    public get path(){
        let me = this;
        let path: string = this.route.split(":")[0];
        path = path.slice(0, path.length -1);
        return `${path}/${me.layout}${me.search}`;
    }

    public get search(){
        let me = this;
        if(me.parameters){
            return "#" + UrlHash.encode(me.parameters);
        }
        return "";
    }

    public layout: string = "Dubai";
    public parameters: any = null;
    public visible: boolean = true;
    public route: string = "";
    public parent:string = "Dashboard";
    public title:string = "";
    public role: Role = null;
    public subRoles: SubRole[] = [];
    public Component: PageCommon<any, any> = null;
}
